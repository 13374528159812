import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useMemo } from "react";
import { DeadState } from "../../../../../../common/components/DeadState";
import { styles } from "../../../../../../common/css/styles";

export const Guarantors = ({ data }) => {
  const columns = useMemo(
    () => [
      { id: "firstName", label: "First Name", minWidth: 50, visible: true },
      {
        id: "lastName",
        label: "Last Name",
        minWidth: 50,
        visible: true,
      },
      { id: "email", label: "Email", minWidth: 50, visible: true },
      {
        id: "phoneNo",
        label: "Phone Number",
        minWidth: 50,
        visible: true,
      },
      { id: "mandate", label: "Role", minWidth: 50, visible: true },
    ],
    []
  );

  return (
    <Box>
      <TableContainer
        component={Paper}
        sx={{
          maxHeight: 400,
          boxShadow: "none",
          mt: 2,
        }}
      >
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow
              sx={{
                "& .MuiTableCell-head": {
                  color: "#000",
                  backgroundColor: "#fff",
                  borderTop: 0.5,
                  borderBottom: 0.5,
                  borderColor: "#B5B5B5",
                  py: 2,
                },
              }}
            >
              {columns
                ?.filter((column) => column.visible === true)
                .map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    <Typography
                      noWrap
                      sx={{
                        color: "#000",
                        backgroundColor: "#fff",
                        fontSize: 13,
                        fontWeight: 600,
                      }}
                    >
                      {column.label}
                    </Typography>
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.length > 0 ? (
              data?.map((guarantor) => (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={guarantor.email}
                  sx={{
                    "& .MuiTableCell-body": {
                      color: "#000",
                      backgroundColor: "#fff",
                      borderBottom: 0.5,
                      borderColor: "#F7F8F4",
                    },
                  }}
                >
                  {columns.map((v, i) => (
                    <TableCell key={i} align="left">
                      <Typography sx={styles.table.text.uid}>
                        {v.render ? v.render(guarantor) : guarantor[v.id]}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableCell colSpan={columns?.length} sx={{ textAlign: "center" }}>
                <DeadState />
              </TableCell>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* {!loans.loading && loanList?.length > 5 ? (
      <PageNavigation
        page={page}
        totalRecords={loans.totalRecords}
        handlePageChange={handlePageChange}
      />
    ) : (
      <></>
    )} */}
    </Box>
  );
};

import { Box, Button, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Add } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { searchCustomers } from "../customers/store/customersSlice";
import { styles } from "../../common/css/styles";
import { WhiteBox } from "../../common/components/WhiteBox";
import { SearchBar } from "../../common/components/SearchBar";
import { SectionHeader } from "../../common/components/SectionHeader";
import { BridgeTable } from "./BridgeTable";
import {
  filteredBridgeLoans,
  searchBridgeLoans,
} from "./store/bridgeFinanceLoansSlice";
import { bridgeFinanceFilters } from "./constants/bridgeFinanceFilters";
import { roles } from "../../common/constants/roles";
import { useLocation } from "react-router-dom";

export const BridgeFinance = () => {
  const [page, setPage] = useState(0);
  const bridgeLoans = useSelector((state) => state.bridgeLoans);
  const user = useSelector(({ auth }) => auth.user);
  const role = user?.roles[0]?.name;
  const dispatch = useDispatch();
  const location = useLocation();

  const [activeFilter, setActiveFilter] = useState(
    location.state?.filter ?? bridgeFinanceFilters.ALL
  );

  const handleSearch = (searchTerm) => {
    dispatch(searchBridgeLoans({ page: 1, searchTerm }));
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage - 1);
    dispatch(
      filteredBridgeLoans({ page: newPage, filterOption: activeFilter })
    );
  };

  const handleFilter = (filter) => setActiveFilter(filter);
  const filters = [
    { label: "All", value: bridgeFinanceFilters.ALL, visible: true },
    {
      label: "Pending Recommendation",
      value: bridgeFinanceFilters.PENDING_RECOMMENDATION,
      visible: true,
    },
    {
      label: "Pending Offer",
      value: bridgeFinanceFilters.PENDING_OFFER,
      visible: role === roles.MANAGER ? true : false,
    },
    {
      label: "Pending Lien",
      value: bridgeFinanceFilters.PENDING_LIEN,
      visible: true,
    },
    { label: "Declined", value: bridgeFinanceFilters.DECLINED, visible: true },
    { label: "Offered", value: bridgeFinanceFilters.OFFERED, visible: true },
    {
      label: "Pay Interest",
      value: bridgeFinanceFilters.PAY_INTEREST,
      visible: true,
    },
    {
      label: "Pending Disbursement",
      value: bridgeFinanceFilters.PENDING_DISBURSEMENT,
      visible: true,
    },
    {
      label: "Rejected",
      value: bridgeFinanceFilters.OFFER_REJECTED,
      visible: true,
    },
    { label: "Active", value: bridgeFinanceFilters.ACTIVE, visible: true },
    {
      label: "Defaulting",
      value: bridgeFinanceFilters.DEFAULTING,
      visible: true,
    },
    {
      label: "Completed",
      value: bridgeFinanceFilters.COMPLETED,
      visible: true,
    },
    { label: "NTU", value: bridgeFinanceFilters.NTU, visible: true },
  ];

  useEffect(() => {
    dispatch(filteredBridgeLoans({ page: 1, filterOption: activeFilter }));
  }, [activeFilter]);

  return (
    <>
      <SectionHeader headerText="Bridge Financing" />
      <WhiteBox>
        <Box
          sx={{
            p: 2,
          }}
        >
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <SearchBar onSearch={handleSearch} />
            {/* <Button
              variant="outlined"
              href="/add-user"
              sx={{
                height: 30,
                textTransform: "capitalize",
                color: "#000",
                borderColor: "#000",
                mt: 2.5,
              }}
            >
              <Add sx={{ fontSize: 12, px: 1 }} />
              <Typography sx={{ fontSize: 12, px: 1 }}>Add User</Typography>
            </Button> */}
          </Stack>
          <Box sx={{ py: 2 }}>
            {filters
              .filter((filter) => filter.visible === true)
              .map((filter) => (
                <Button
                  key={filter.value}
                  onClick={() => handleFilter(filter.value)}
                  variant="outlined"
                  sx={
                    activeFilter === filter.value
                      ? styles.tableFilter.active
                      : styles.tableFilter.inactive
                  }
                >
                  {filter.label}
                </Button>
              ))}
          </Box>
          <BridgeTable
            bridgeLoans={bridgeLoans}
            filterOption={activeFilter}
            page={page}
            handlePageChange={handlePageChange}
          />
        </Box>
      </WhiteBox>
    </>
  );
};

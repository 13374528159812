/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import {
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Box,
  Typography,
  Grid,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  TextField,
} from "@mui/material";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Delete as DeleteIcon } from "@mui/icons-material";
import DownloadIcon from "@mui/icons-material/Download";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { uploadLienDocs } from "../store/bridgeFinanceLoanSlice";
import { InfoDialog } from "../../../common/components/InfoDialog";
import { useEffect } from "react";
import { feedback } from "../../../common/constants/feedback";
import { styles } from "../../../common/css/styles";
import { bridgeFinanceFilters } from "../constants/bridgeFinanceFilters";
import { toTitleCase } from "../../../common/constants/utility";

const defaultValues = {
  files: null,
  lienBankAccountNumber: "",
  lienBankAccountName: "",
  lienBankName: "",
  lienBankSortCode: "",
};

const schema = yup.object().shape({
  files: yup.mixed().required("Please upload lien documents"),
  lienBankAccountNumber: yup
    .string()
    .required("Bank account number is required"),
  lienBankAccountName: yup.string(),
  lienBankName: yup.string(),
  lienBankSortCode: yup.string(),
});

export const LienDocs = ({ loanUid, offerer, customer }) => {
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitSuccessful },
    reset,
    watch,
    setValue,
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  const [open, setOpen] = useState(false);
  const [isError, setIsError] = useState(false);
  const [infoText, setInfoText] = useState("");
  const [showUpload, setShowUpload] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const user = useSelector(({ auth }) => auth.user);
  const bridgeLoan = useSelector((state) => state.bridgeLoan);
  const dispatch = useDispatch();
  const lienBankAccountNumber = watch("lienBankAccountNumber");

  const defaultTag =
    bridgeLoan?.loan?.user?.firstName +
    "_" +
    bridgeLoan?.loan?.user?.lastName +
    "_";

  const pdfFiles = bridgeLoan?.loan?.signedDocumentsUrls;

  const showReuploadTab = () => {
    return bridgeLoan.loan?.status === bridgeFinanceFilters.PENDING_LIEN;
  };

  const handleClose = () => setOpen(false);

  const handleFileChange = (e) => {
    const files = e.target.files;
    if (files) {
      setSelectedFiles([...files]);
    } else {
      setSelectedFiles([]);
    }
  };

  const handleRemoveFile = (index) => {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    setSelectedFiles(updatedFiles);
  };

  const handleDownloadFile = (fileUrl) => {
    // Create a hidden link element
    const link = document.createElement("a");
    link.style.display = "none";
    document.body.appendChild(link);

    // Set the link's href to the file URL
    link.href = fileUrl;

    // Set the download attribute with the desired file name
    link.download = "downloaded_file.pdf"; // You can customize the file name here

    // Trigger a click on the link to start the download
    link.click();

    // Remove the link element from the DOM
    document.body.removeChild(link);
  };

  const onSubmit = (data) => {
    dispatch(
      uploadLienDocs({
        loanUid: loanUid,
        adminOverseeingLoan: `${offerer?.firstName} ${offerer?.lastName}`,
        adminPlacingLien: `${user.firstName} ${user.lastName}`,
        ...data,
      })
    );
  };

  useEffect(() => {
    if (lienBankAccountNumber) {
      const bank = customer.banks.find(
        (bank) => bank.bankAccountNumber === lienBankAccountNumber
      );
      setValue("lienBankAccountName", bank.bankAccountName, {
        shouldDirty: true,
        shouldTouch: true,
      });
      setValue("lienBankName", bank.bankName, {
        shouldDirty: true,
        shouldTouch: true,
      });
      setValue("lienBankSortCode", bank.bankSortCode, {
        shouldDirty: true,
        shouldTouch: true,
      });
    }
  }, [lienBankAccountNumber]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
      setSelectedFiles([]);
    }
  }, [isSubmitSuccessful]);

  useEffect(() => {
    if (bridgeLoan.lienUpload === feedback.FAILED) {
      setIsError(true);
      setInfoText("Lien documents upload failed");
      setOpen(true);
    } else if (bridgeLoan.lienUpload === feedback.SUCCESSFUL) {
      setIsError(false);
      setInfoText("Lien documents upload successful");
      setOpen(true);
    }
  }, [bridgeLoan.lienUpload]);

  return (
    <>
      <Box sx={{ p: 4, width: "100%" }}>
        <Box sx={{ py: 2 }}>
          <Button
            variant="outlined"
            onClick={() => setShowUpload(false)}
            sx={
              !showUpload
                ? styles.tableFilter.active
                : styles.tableFilter.inactive
            }
          >
            View
          </Button>
          {showReuploadTab() ? (
            <Button
              variant="outlined"
              onClick={() => setShowUpload(true)}
              sx={
                showUpload
                  ? styles.tableFilter.active
                  : styles.tableFilter.inactive
              }
            >
              Reupload
            </Button>
          ) : null}
        </Box>
        {!showUpload && (
          <>
            <Box sx={{ py: 2, width: "50%" }}>
              <Typography sx={{ fontWeight: 700, fontSize: 18, py: 2 }}>
                Uploaded Lien Documents
              </Typography>

              <List sx={{ maxWidth: "350px" }}>
                {pdfFiles?.map((file, index) => {
                  // Extracting the first 10 digits after removing the specified prefix
                  const fileName = file
                    .replace(/^https:\/\/larkloan\.s3\.amazonaws\.com\//, "")
                    .substr(0, 10);

                  return (
                    <>
                      <ListItem
                        key={index}
                        sx={{
                          border: 1,
                          my: 1,
                          borderColor: "#E3E3E7",
                          borderRadius: 2,
                        }}
                      >
                        <ListItemText
                          primary={defaultTag + fileName}
                          style={{ cursor: "pointer", color: "#000" }}
                        />
                        <ListItemSecondaryAction>
                          {/* <IconButton
                            edge="end"
                            // onClick={() => handlePreviewFile(file)}
                          >
                            <PreviewIcon />
                          </IconButton> */}
                          <IconButton
                            edge="end"
                            onClick={() => handleDownloadFile(file)}
                          >
                            <DownloadIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    </>
                  );
                })}
              </List>
            </Box>
          </>
        )}
        {showUpload && (
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
            sx={{ p: 2 }}
          >
            <Controller
              name="files"
              control={control}
              render={({ field }) => (
                <label htmlFor="fileInput">
                  <input
                    type="file"
                    accept=".pdf"
                    multiple
                    style={{ display: "none" }}
                    id="fileInput"
                    onChange={(e) => {
                      handleFileChange(e);
                      field.onChange(e.target.files);
                      // field.onChange([...e.target.files]);
                    }}
                  />

                  <Button
                    variant="contained"
                    component="span"
                    sx={{
                      backgroundColor: "#E3E3E7",
                      textTransform: "capitalize",
                      width: 350,
                      boxShadow: "none",
                      "&:hover": {
                        boxShadow: "none",
                        color: "#fff",
                      },
                    }}
                  >
                    Select Lien Documents to upload
                  </Button>
                </label>
              )}
            />
            <List sx={{ maxWidth: "350px" }}>
              {selectedFiles.map((file, index) => (
                <>
                  <ListItem
                    key={index}
                    sx={{
                      border: 1,
                      my: 1,
                      borderColor: "#E3E3E7",
                      borderRadius: 2,
                    }}
                  >
                    <ListItemText primary={file.name} />
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        onClick={() => handleRemoveFile(index)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                </>
              ))}
            </List>
            <Grid item xs={6} py={2}>
              <Typography sx={styles.formFieldHeading}>
                Confirm Liened Bank
              </Typography>
              <Controller
                name="lienBankAccountNumber"
                control={control}
                render={({ field }) => (
                  <FormControl fullWidth sx={{ width: 350 }}>
                    <Select
                      {...field}
                      required
                      size={styles.textfieldSize}
                      sx={styles.selectField}
                    >
                      {customer?.banks?.map((bank) => (
                        <MenuItem
                          key={bank.bankAccountNumber}
                          value={bank.bankAccountNumber}
                        >
                          {`${bank.bankAccountNumber} | ${toTitleCase(
                            bank.bankAccountName
                          )} | ${toTitleCase(bank.bankName)}`}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.lienBankAccountNumber ? (
                      <FormHelperText error>
                        {errors?.lienBankAccountNumber?.message}
                      </FormHelperText>
                    ) : (
                      <></>
                    )}
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={6} py={2}>
              <Controller
                name="lienBankAccountName"
                control={control}
                render={({ field }) => (
                  <TextField {...field} sx={{ display: "none" }} />
                )}
              />
            </Grid>
            <Grid item xs={6} py={2}>
              <Controller
                name="lienBankName"
                control={control}
                render={({ field }) => (
                  <TextField {...field} sx={{ display: "none" }} />
                )}
              />
            </Grid>
            <Grid item xs={6} py={2}>
              <Controller
                name="lienBankSortCode"
                control={control}
                render={({ field }) => (
                  <TextField {...field} sx={{ display: "none" }} />
                )}
              />
            </Grid>
            <Button
              variant="contained"
              type="submit"
              disabled={selectedFiles.length === 0}
              sx={{
                backgroundColor: "#E3E3E7",
                textTransform: "capitalize",
                width: 350,
                boxShadow: "none",
                "&:hover": {
                  boxShadow: "none",
                  color: "#fff",
                },
              }}
            >
              Upload All
            </Button>
          </Box>
        )}
      </Box>

      <InfoDialog
        text={infoText}
        error={isError}
        open={open}
        handleClose={handleClose}
      />
    </>
  );
};

import { Box, Button } from "@mui/material";
import React, { useState } from "react";
import { styles } from "../../../../common/css/styles";
import { businessInfoFilters } from "../../constants/businessFilters";
import { stage } from "./stages/index";
import { Details } from "./stages/Details";
import { Profile } from "./stages/Profile";
import { Address } from "./stages/Address";
import { Operations } from "./stages/Operations";
import { AddressVerification } from "./stages/AddressVerification";

export const BusinessInfo = ({ data }) => {
  const [currentStage, setCurrentStage] = useState(stage.DETAILS);
  const [activeFilter, setActiveFilter] = useState(businessInfoFilters.DETAILS);

  const filters = [
    {
      label: "Details",
      value: businessInfoFilters.DETAILS,
      visible: true,
    },
    {
      label: "Address",
      value: businessInfoFilters.ADDRESS,
      visible: true,
    },
    {
      label: "Profile",
      value: businessInfoFilters.PROFILE,
      visible: true,
    },
    {
      label: "Operations",
      value: businessInfoFilters.OPERATIONS,
      visible: true,
    },
    {
      label: "Address Verification",
      value: businessInfoFilters.ADDRESS_VERIFICATION,
      visible: true,
    },
  ];
  const handleFilter = (filter) => {
    setActiveFilter(filter);
  };

  const getBusinessInfoStage = () => {
    switch (activeFilter) {
      case stage.DETAILS:
        return <Details data={data} />;
      case stage.ADDRESS:
        return <Address data={data} />;
      case stage.PROFILE:
        return <Profile data={data} />;
      case stage.OPERATIONS:
        return <Operations data={data} />;
      case stage.ADDRESS_VERIFICATION:
        return <AddressVerification data={data} />;
      default:
        return <Details data={data} />;
    }
  };

  return (
    <Box>
      <Box sx={{ py: 3, display: "flex" }}>
        {filters
          .filter((filter) => filter.visible)
          .map((filter) => (
            <Button
              onClick={() => handleFilter(filter.value)}
              variant="outlined"
              sx={
                activeFilter === filter.value
                  ? { ...styles.tableFilter.active, fontSize: 14, p: 2 }
                  : { ...styles.tableFilter.inactive, fontSize: 14, p: 2 }
              }
            >
              {filter.label}
            </Button>
          ))}
      </Box>
      <Box sx={{ width: "150%" }}>{getBusinessInfoStage()}</Box>
    </Box>
  );
};

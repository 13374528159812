import { Box, Button, Collapse, Typography } from "@mui/material";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { styles } from "../css/styles";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const SidebarItem = ({ item }) => {
  const [open, setOpen] = useState(item.open);
  const location = useLocation();
  const navigate = useNavigate();

  const handleClick = (path) => navigate(path);

  return item.children ? (
    // item has children, dropdown sub menu
    <>
      <Button
        key={item.text}
        sx={
          location.pathname.startsWith(item.path)
            ? {
                ...styles.sidebar.buttonActive,
                display: "flex",
                justifyContent: "space-between",
                "&:hover": { backgroundColor: "primary.main" },
              }
            : {
                ...styles.sidebar.button,
                display: "flex",
                justifyContent: "space-between",
                "&:hover": { backgroundColor: "#27AE601A" },
              }
        }
        onClick={() => setOpen(!open)}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box
            width="30px"
            height="30px"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <img
              src={
                location.pathname.startsWith(item.path)
                  ? item.activeIcon
                  : item.icon
              }
              alt={`${item.text}-Icon`}
            />
          </Box>
          <Typography sx={styles.sidebar.buttonText}>{item.text}</Typography>
        </Box>
        {open ? (
          <ExpandLessIcon sx={{ color: "#616663" }} />
        ) : (
          <ExpandMoreIcon sx={{ color: "#616663" }} />
        )}
      </Button>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {item.children.map((child) => (
          <Button
            key={child.id}
            sx={
              location.pathname.startsWith(child.path)
                ? {
                    ...styles.sidebar.buttonActive,
                    width: "100%",
                    backgroundColor: "#BCE6CE",
                    color: "#000",
                    pl: 8,
                    height: 40,
                    "&:hover": { backgroundColor: "#BCE6CE" },
                  }
                : {
                    ...styles.sidebar.button,
                    width: "100%",
                    pl: 8,
                    height: 40,
                    "&:hover": { backgroundColor: "#27AE601A" },
                  }
            }
            onClick={() => handleClick(child.path)}
          >
            {/* <Box
              width="30px"
              height="30px"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <img
                src={
                  location.pathname.startsWith(child.path)
                    ? child.activeIcon
                    : child.icon
                }
                alt={`${child.text}-Icon`}
              />
            </Box> */}
            <Typography sx={styles.sidebar.buttonText}>{child.text}</Typography>
          </Button>
        ))}
      </Collapse>
    </>
  ) : (
    <Button
      key={item.text}
      sx={
        location.pathname.startsWith(item.path)
          ? {
              ...styles.sidebar.buttonActive,
              "&:hover": { backgroundColor: "primary.main" },
            }
          : {
              ...styles.sidebar.button,
              "&:hover": { backgroundColor: "#27AE601A" },
            }
      }
      onClick={() => handleClick(item.path)}
    >
      <Box
        width="30px"
        height="30px"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <img
          src={
            location.pathname.startsWith(item.path)
              ? item.activeIcon
              : item.icon
          }
          alt={`${item.text}-Icon`}
        />
      </Box>
      <Typography sx={styles.sidebar.buttonText}>{item.text}</Typography>
    </Button>
  );
};

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getLogs,
  searchLogs,
  searchLogsByDate,
} from "../../common/api/auditLogs";
import { handleError } from "../../common/constants/utility";

const initialState = {
  logs: [],
  totalRecords: 0,
  loading: false,
  error: [],
};

export const fetchAuditLogs = createAsyncThunk(
  "auditLogs/fetch",
  async (page, { dispatch, rejectWithValue }) => {
    try {
      const response = await getLogs(page);
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);

export const searchAuditLogs = createAsyncThunk(
  "auditLogs/search",
  async ({ page, searchTerm }, { dispatch, rejectWithValue }) => {
    try {
      const response = await searchLogs(page, searchTerm);
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);

export const searchAuditLogsByDate = createAsyncThunk(
  "auditLogs/searchByDate",
  async (model, { dispatch, rejectWithValue }) => {
    try {
      const response = await searchLogsByDate(model);
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);

const auditLogsSlice = createSlice({
  name: "auditLogs",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchAuditLogs.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchAuditLogs.fulfilled, (state, action) => {
      state.logs = action.payload.data.auditLogs;
      state.totalRecords = action.payload.data.totalRecords;
      state.loading = false;
      state.error = [];
    });
    builder.addCase(fetchAuditLogs.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(searchAuditLogs.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(searchAuditLogs.fulfilled, (state, action) => {
      state.logs = action.payload.data.auditLogs;
      state.totalRecords = action.payload.data.totalRecords;
      state.loading = false;
      state.error = [];
    });
    builder.addCase(searchAuditLogs.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(searchAuditLogsByDate.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(searchAuditLogsByDate.fulfilled, (state, action) => {
      state.logs = action.payload.data.auditLogs;
      state.totalRecords = action.payload.data.totalRecords;
      state.loading = false;
      state.error = [];
    });
    builder.addCase(searchAuditLogsByDate.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default auditLogsSlice.reducer;

export const feedback = {
  CREATED: "CREATED",
  CREATE_FAILED: "CREATE_FAILED",
  READ: "READ",
  READ_FAILED: "READ_FAILED",
  UPDATED: "UPDATED",
  UPDATE_FAILED: "UPDATE_FAILED",
  DELETED: "DELETED",
  DELETE_FAILED: "DELETE_FAILED",
  PENDING: "PENDING",
  SUCCESSFUL: "SUCCESSFUL",
  FAILED: "FAILED",
};

import { ArrowBack } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import React from "react";

export const PreviousButton = ({ handlePreviousClick }) => {
  return (
    <Box>
      <Button
        variant="contained"
        onClick={handlePreviousClick}
        startIcon={<ArrowBack />}
        sx={{
          textTransform: "capitalize",
          height: 40,
          color: "#000",
          boxShadow: "none",
          fontSize: 16,
          backgroundColor: "#ACACA980",
          "&:hover": {
            backgroundColor: "#000000CC",
            color: "#fff",
            boxShadow: "none",
          },
        }}
      >
        Previous
      </Button>
    </Box>
  );
};

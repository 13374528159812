import React from "react";
import {
  Box,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
} from "@mui/material";
import { styles } from "../../common/css/styles";
import { format } from "date-fns";
import { businessFilters } from "./constants/businessFilters";
import { DeadState } from "../../common/components/DeadState";
import { PageNavigation } from "../../common/components/PageNavigation";

export const BusinessTable = ({
  businesses,
  filterOption,
  page,
  handlePageChange,
  handleView,
}) => {
  const businessList = businesses.businesses;

  const columns = [
    { id: "businessName", label: "Business Name", minWidth: 50, visible: true },
    { id: "email", label: "Business Email", minWidth: 50, visible: true },
    { id: "phoneNo", label: "Business Number", minWidth: 50, visible: true },
    { id: "date", label: "Date", minWidth: 50, visible: true },
    {
      id: "kycCompletionStatus",
      label: "KYC Completion Status",
      minWidth: 50,
      visible: filterOption === businessFilters.PROSPECT ? true : false,
    },
    {
      id: "verification",
      label: "ID Verification",
      minWidth: 50,
      visible: filterOption === businessFilters.ALL ? true : false,
    },
    {
      id: "status",
      label: "Account Status",
      minWidth: 50,
      visible:
        filterOption === businessFilters.ALL ||
        filterOption === businessFilters.VERIFIED
          ? true
          : false,
    },
    {
      id: "view",
      label: "View",
      minWidth: 50,
      visible:
        filterOption === businessFilters.ALL ||
        filterOption === businessFilters.PROSPECT
          ? false
          : true,
    },
  ];

  const getStatusDisplay = (status) => {
    switch (status) {
      case businessFilters.ACTIVE:
        return (
          <Typography sx={styles.table.text.customerStatus.verified}>
            Active
          </Typography>
        );
      case businessFilters.PENDING:
        return (
          <Typography sx={styles.table.text.customerStatus.pending}>
            Pending
          </Typography>
        );
      case businessFilters.DELETED:
        return (
          <Typography sx={styles.table.text.customerStatus.rejected}>
            Deleted
          </Typography>
        );
      case businessFilters.DENIED:
        return (
          <Typography sx={styles.table.text.customerStatus.rejected}>
            Denied
          </Typography>
        );
      case businessFilters.INACTIVE:
        return (
          <Typography sx={styles.cardTopStatus.suspended}>Inactive</Typography>
        );
      case businessFilters.PROSPECT:
        return (
          <Typography sx={styles.table.text.customerStatus.prospect}>
            Prospect
          </Typography>
        );
      case businessFilters.SUSPENDED:
        return (
          <Typography sx={styles.cardTopStatus.suspended}>Suspended</Typography>
        );
      case businessFilters.VERIFIED:
        return (
          <Typography sx={styles.table.text.customerStatus.verified}>
            Active
          </Typography>
        );
      default:
        break;
    }
  };

  return (
    <Box>
      <TableContainer
        component={Paper}
        sx={{
          maxHeight: 400,
          boxShadow: "none",
          mt: 2,
        }}
      >
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow
              sx={{
                "& .MuiTableCell-head": {
                  color: "#000",
                  backgroundColor: "#fff",
                  borderTop: 0.5,
                  borderBottom: 0.5,
                  borderColor: "#B5B5B5",
                  py: 2,
                },
              }}
            >
              {columns
                .filter((column) => column.visible === true)
                .map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    <Typography
                      noWrap
                      sx={{
                        color: "#000",
                        backgroundColor: "#fff",
                        fontSize: 13,
                        fontWeight: 600,
                      }}
                    >
                      {column.label}
                    </Typography>
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {businesses.loading ? (
              <TableCell
                colSpan={columns.length}
                sx={{ textAlign: "center", borderBottom: 0, pt: 4 }}
              >
                <CircularProgress />
              </TableCell>
            ) : businessList.length > 0 ? (
              businessList.map((business) => (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={business.id}
                  sx={{
                    "& .MuiTableCell-body": {
                      color: "#000",
                      backgroundColor: "#fff",
                      borderBottom: 0.5,
                      borderColor: "#F7F8F4",
                    },
                  }}
                >
                  <TableCell align="left">
                    <Typography sx={styles.table.text}>
                      {business.businessName}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography
                      sx={{
                        ...styles.table.text,
                        textTransform: "lowercase",
                      }}
                    >
                      {business.businessEmail}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography sx={styles.table.text}>
                      {business.businessPhone}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography sx={styles.table.text}>
                      {business.createdAt &&
                        format(
                          new Date(business.createdAt),
                          "dd/MM/yyyy hh:mm:ss aa"
                        )}
                    </Typography>
                  </TableCell>
                  {columns.find((column) => column.id === "kycCompletionStatus")
                    .visible ? (
                    <TableCell align="left">
                      <Box
                        sx={{
                          position: "relative",
                          display: "inline-flex",
                        }}
                      >
                        <CircularProgress
                          variant="determinate"
                          value={100}
                          thickness={2}
                          size={35}
                          sx={{
                            color: "#0000002A",
                            ml: 1,
                            position: "absolute",
                          }}
                        />
                        <CircularProgress
                          variant="determinate"
                          value={business.smeKycStatus}
                          size={35}
                          sx={{
                            color: "primary.main",
                            ml: 1,
                          }}
                          thickness={2}
                        />

                        <Box
                          sx={{
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            position: "absolute",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Typography
                            variant="caption"
                            component="div"
                            sx={{
                              color: "#000",
                              ml: 1.2,
                              fontSize: 10,
                              fontWeight: 600,
                            }}
                          >
                            {business.smeKycStatus}%
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                  ) : null}
                  {columns.find((column) => column.id === "verification")
                    .visible ? (
                    <TableCell align="left">
                      {business.status === businessFilters.ACTIVE ? (
                        <Typography
                          sx={styles.table.text.verification.approved}
                        >
                          Verified
                        </Typography>
                      ) : (
                        <Typography sx={styles.table.text.verification.pending}>
                          Not Verified
                        </Typography>
                      )}
                    </TableCell>
                  ) : null}
                  {columns.find((column) => column.id === "status").visible ? (
                    <TableCell align="left">
                      {getStatusDisplay(business.status)}
                    </TableCell>
                  ) : null}
                  {columns.find((column) => column.id === "view").visible ? (
                    <TableCell align="left">
                      <Button
                        variant="outlined"
                        onClick={() => handleView(business.id)}
                        sx={styles.table.text.viewButton}
                      >
                        View
                      </Button>
                    </TableCell>
                  ) : null}
                </TableRow>
              ))
            ) : (
              <TableCell colSpan={columns.length} sx={{ textAlign: "center" }}>
                <DeadState />
              </TableCell>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {!businesses.loading && businessList.length > 0 ? (
        <PageNavigation
          page={page}
          totalRecords={businesses.totalRecords}
          handlePageChange={handlePageChange}
        />
      ) : (
        <></>
      )}
    </Box>
  );
};

import React, { useMemo, useState } from "react";
import { DeadState } from "../../common/components/DeadState";

import {
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  formatCurrencyvalue,
  getTranactionStatusDisplay,
} from "../../common/util";
import { format } from "date-fns";
import { styles } from "../../common/css/styles";
import { PageNavigation } from "../../common/components/PageNavigation";
import { useQuery } from "@tanstack/react-query";
import { getLoanPenalty } from "../../common/api/loans";

export default function LoanPenalty({ loanId }) {
  const [page, setPage] = useState(0);
  const handlePageChange = (event, newPage) => {
    setPage(newPage - 1);
  };

  const { data, isLoading } = useQuery({
    queryKey: [loanId, page, "penalty"],
    queryFn: () => getLoanPenalty({ loanId, page: page + 1 }),
    refetchOnWindowFocus: false,
  });
  const { penaltyList, totalRecord } = data?.data?.data ?? {};

  const columns = useMemo(
    () => [
      {
        id: "penaltyAmount",
        label: "Penalty Amount",
        minWidth: 50,
        visible: true,
        render: (penalty) => formatCurrencyvalue(penalty.penaltyAmount),
      },

      // {
      //   id: "date",
      //   label: "Repayment Date",
      //   minWidth: 50,
      //   visible: true,
      //   render: (penalty) =>
      //     penalty.repaymentDate &&
      //     format(new Date(penalty.repaymentDate), "dd/MM/yyyy hh:mm:ss aa"),
      // },
      {
        id: "date",
        label: "Due Date",
        minWidth: 50,
        visible: true,
        render: (penalty) =>
          penalty.dueDate &&
          format(new Date(penalty.dueDate), "dd/MM/yyyy hh:mm:ss aa"),
      },
      {
        id: "date",
        label: "Status",
        minWidth: 50,
        visible: true,
        render: (penalty) => getTranactionStatusDisplay(penalty.status),
      },
    ],
    []
  );

  return (
    <>
      <Box
        sx={{
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            px: 2,
            py: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              pb: 2,
            }}
          >
            {/* <Button
                onClick={handleAddButtonClick}
                //   variant="outlined"
                sx={{
                  height: 29,
                  border: 1,
                  color: "#494D4A",
                  borderColor: "#61666333",
                  mt: 2.5,
                  textTransform: "capitalize",
                  fontSize: 12,
                }}
              >
                <Add sx={{ fontSize: 12, pr: 1 }} /> Add
              </Button> */}
          </Box>
          <Paper
            sx={{
              width: "100%",
              overflow: "hidden",
              border: "none",
              boxShadow: "none",
            }}
          >
            <TableContainer sx={{ maxHeight: 400 }}>
              <Table
                stickyHeader
                aria-label="sticky table"
                sx={{ backgroundColor: "transparent" }}
              >
                <TableHead>
                  <TableRow
                    sx={{
                      "& .MuiTableCell-head": {
                        color: "#000",
                        backgroundColor: "transparent",
                        border: "4px solid black",
                      },
                    }}
                  >
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{
                          minWidth: column.minWidth,
                          borderTop: "1px solid #B5B5B5",
                          borderLeft: "none",
                          borderRight: "none",
                          borderBottom: "1px solid #B5B5B5",
                          fontSize: 13,
                          fontWeight: 600,
                          height: 20,
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isLoading ? (
                    <TableCell
                      colSpan={columns.length}
                      sx={{ textAlign: "center", borderBottom: 0, pt: 4 }}
                    >
                      <CircularProgress />
                    </TableCell>
                  ) : penaltyList?.length > 0 ? (
                    penaltyList?.map((loan, i) => (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={loan.uid}
                        sx={{
                          "& .MuiTableCell-body": {
                            color: "#62635E",
                            backgroundColor: "#fff",
                            border: "none",
                            fontSize: 13,
                            fontWeight: 400,
                          },
                        }}
                      >
                        {columns.map((v, i) => (
                          <TableCell key={i} align="left">
                            <Typography sx={styles.table.text.uid}>
                              {v.render ? v.render(loan) : loan[v.id]}
                            </Typography>
                          </TableCell>
                        ))}
                      </TableRow>
                    ))
                  ) : (
                    <TableCell
                      colSpan={columns.length}
                      sx={{ textAlign: "center" }}
                    >
                      <DeadState />
                    </TableCell>
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            {!isLoading && penaltyList?.length > 0 && (
              <PageNavigation
                page={page}
                totalRecords={totalRecord}
                handlePageChange={handlePageChange}
              />
            )}
          </Paper>
        </Box>
      </Box>
    </>
  );
}

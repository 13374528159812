import { client } from ".";

const size = process.env.REACT_APP_PAGESIZE;

export const getDisbursements = (page) => {
  return client.get(`disbursements?page=${page}&size=${size}`);
};

export const getDisbursement = (uid) => {
  return client.get(`disbursements/${uid}`);
};

export const searchDisbursements = (page, searchTerm) => {
  return client.get(
    `disbursements?page=${page}&size=${size}&search=${searchTerm}`
  );
};

export const searchDisbursementsByDate = (
  page,
  searchTerm,
  startDate,
  endDate
) => {
  return client.get(
    `disbursements?page=${page}&size=${size}&search=${searchTerm}&startDate=${startDate}&endDate=${endDate}`
  );
};

import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import { EditButton } from "../components/EditButton";
import { PersonalEdit } from "../components/PersonalEdit";
import { styles } from "../../../common/css/styles";
import { customerFilters } from "../../customers/constants/customerFilters";

export const Personal = ({ customer }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const openDialog = () => {
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: customer?.status === customerFilters.PENDING ? "100%" : "70%",
        }}
      >
        <Box>
          <Box sx={{ py: 1 }}>
            <Typography sx={styles.kyc.headerText}>First Name</Typography>
            <Typography sx={styles.kyc.bodyText}>
              {`${customer?.firstName
                ?.charAt(0)
                .toUpperCase()}${customer?.firstName?.substring(1)}`}
            </Typography>
          </Box>
          <Box sx={{ py: 1 }}>
            <Typography sx={styles.kyc.headerText}>Last Name</Typography>
            <Typography sx={styles.kyc.bodyText}>
              {`${customer?.lastName
                ?.charAt(0)
                .toUpperCase()}${customer?.lastName?.substring(1)}`}
            </Typography>
          </Box>
          <Box sx={{ py: 1 }}>
            <Typography sx={styles.kyc.headerText}>Email Address</Typography>
            <Typography
              sx={{ ...styles.kyc.bodyText, textTransform: "lowercase" }}
            >
              {customer?.email}
            </Typography>
          </Box>
          <Box sx={{ py: 1 }}>
            <Typography sx={styles.kyc.headerText}>Phone Number</Typography>
            <Typography sx={styles.kyc.bodyText}>
              {customer?.phoneNumber}
            </Typography>
          </Box>
          <Box sx={{ py: 1 }}>
            <Typography sx={styles.kyc.headerText}>DOB</Typography>
            <Typography sx={styles.kyc.bodyText}>
              {customer?.dateOfBirth?.replaceAll("-", "/")}
            </Typography>
          </Box>
        </Box>

        {/* {customer?.status === customerFilters.PENDING ? ( */}
        <EditButton openDialog={openDialog} />
        {/* // ) : null} */}

        <PersonalEdit
          open={isDialogOpen}
          handleClose={closeDialog}
          customer={customer}
        />
      </Box>
    </>
  );
};

import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import { EditButton } from "../components/EditButton";
import { ResidenceEdit } from "../components/ResidenceEdit";
import { styles } from "../../../common/css/styles";
import { customerFilters } from "../../customers/constants/customerFilters";

export const Residence = ({ customer, data }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const openDialog = () => {
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: customer?.status === customerFilters.PENDING ? "100%" : "70%",
        }}
      >
        <Box>
          <Box sx={{ py: 1 }}>
            <Typography sx={styles.kyc.headerText}>Address</Typography>
            <Typography sx={styles.kyc.bodyText}>
              {data?.residentialAddress ?? "Not Available"}
            </Typography>
          </Box>
          <Box sx={{ py: 1 }}>
            <Typography sx={styles.kyc.headerText}>LGA</Typography>
            <Typography sx={styles.kyc.bodyText}>
              {data?.city ?? "Not Available"}
            </Typography>
          </Box>
          <Box sx={{ py: 1 }}>
            <Typography sx={styles.kyc.headerText}>State</Typography>
            <Typography sx={styles.kyc.bodyText}>
              {data?.state ?? "Not Available"}
            </Typography>
          </Box>
        </Box>

        {/* {customer?.status === customerFilters.PENDING ? ( */}
        <EditButton openDialog={openDialog} />
        {/* ) : null} */}

        <ResidenceEdit
          open={isDialogOpen}
          handleClose={closeDialog}
          kycData={data}
        />
      </Box>
    </>
  );
};

import React, { useState } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Box,
  TableContainer,
  Paper,
  Typography,
  CircularProgress,
  Pagination,
} from "@mui/material";
import { styles } from "../../../common/css/styles";
import { DeadState } from "../../../common/components/DeadState";
import { format } from "date-fns";
import { ProceedAddDevice } from "../components/ProceedAddDevice";
import { PageNavigation } from "../../../common/components/PageNavigation";

export const AddDeviceTable = ({ devices, page, handlePageChange }) => {
  const [openAddDevice, setOpenAddDevice] = useState(false);
  const [customer, setCustomer] = useState(null);
  const customerList = devices.customers;

  const handleAuthorize = (uid) => {
    setCustomer(customerList.find((x) => x.uid === uid));
    setOpenAddDevice(true);
  };

  const columns = [
    { id: "firstName", label: "First Name", minWidth: 20, visible: true },
    { id: "lastName", label: "Last Name", minWidth: 20, visible: true },
    { id: "email", label: "Email", minWidth: 20, visible: true },
    { id: "phoneNo", label: "Phone Number", minWidth: 20, visible: true },
    { id: "date", label: "Date", minWidth: 20, visible: true },
    // {
    //   id: "role",
    //   label: "NOK Phone Number",
    //   minWidth: 20,
    //   align: "left",
    //   visible: true,
    // },
    { id: "action", label: "Actions", minWidth: 10, visible: true },
  ];

  return (
    <Box>
      <TableContainer
        component={Paper}
        sx={{
          maxHeight: 400,
          boxShadow: "none",
          mt: 2,
        }}
      >
        <Table size="small">
          <TableHead>
            <TableRow
              sx={{
                "& .MuiTableCell-head": {
                  color: "#000",
                  backgroundColor: "#fff",
                  fontSize: 13,
                  borderTop: 0.5,
                  borderBottom: 0.5,
                  borderColor: "#B5B5B5",
                  py: 2,
                },
              }}
            >
              {columns
                .filter((column) => column.visible === true)
                .map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    <Typography
                      noWrap
                      sx={{
                        color: "#000",
                        backgroundColor: "#fff",
                        fontSize: 13,
                        fontWeight: 600,
                      }}
                    >
                      {column.label}
                    </Typography>
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {devices.loading ? (
              <TableCell
                colSpan={columns.length}
                sx={{ textAlign: "center", borderBottom: 0, pt: 4 }}
              >
                <CircularProgress />
              </TableCell>
            ) : customerList.length > 0 ? (
              customerList.map((customer) => (
                <TableRow
                  key={customer.uid}
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  sx={{
                    "& .MuiTableCell-body": {
                      color: "#000",
                      backgroundColor: "#fff",
                      border: "none",
                    },
                  }}
                >
                  <TableCell align="left">
                    <Typography sx={styles.table.text}>
                      {customer.firstName}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography sx={styles.table.text}>
                      {customer.lastName}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography
                      sx={{
                        ...styles.table.text,
                        textTransform: "lowercase",
                      }}
                    >
                      {customer.email}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography sx={styles.table.text}>
                      {customer.phoneNumber}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography sx={styles.table.text}>
                      {customer.createdAt &&
                        format(
                          new Date(customer.createdAt),
                          "dd/MM/yyyy hh:mm:ss aa"
                        )}
                    </Typography>
                  </TableCell>

                  <TableCell>
                    {/* <IconButton
                      aria-label="menu"
                      aria-controls={`menu-${user.id}`}
                      aria-haspopup="true"
                      onClick={(event) => handleClick(event, user.id)}
                    >
                      <MoreVert />
                    </IconButton>
                    <Menu
                      id={`menu-${user.id}`}
                      anchorEl={anchorEl}
                      open={selectedRow === user.id}
                      onClose={handleClose}
                    >
                      <MenuItem onClick={handleClose}>Accept</MenuItem>
                      <MenuItem onClick={handleClose}>Deny</MenuItem>
                    </Menu> */}
                    <Button
                      variant="outlined"
                      onClick={() => handleAuthorize(customer.uid)}
                      sx={styles.table.text.viewButton}
                    >
                      Authorize
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableCell colSpan={columns.length} sx={{ textAlign: "center" }}>
                <DeadState />
              </TableCell>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {!devices.loading && customerList.length > 0 ? (
        <PageNavigation
          page={page}
          totalRecords={devices.totalRecords}
          handlePageChange={handlePageChange}
        />
      ) : (
        <></>
      )}

      <ProceedAddDevice
        open={openAddDevice}
        handleClose={() => setOpenAddDevice(false)}
        customer={customer}
      />
      {/* <ProceedChangeDevice
        open={openAddDevice}
        handleClose={() => setOpenAddDevice(false)}
      /> */}
    </Box>
  );
};

import { client } from ".";

export const login = (email, password) => {
  return client.post("login", { email, password });
};

export const refreshUserToken = (refreshToken) => {
  return client.post("auth/refresh", { refreshToken });
};

export const createUser = (model) => {
  return client.post("user/retail", { ...model });
};

export const logout = () => {
  return client.post("auth/logout");
};

export const changePassword = ({ otp, password, confirmPassword }) => {
  return client.post("auth/change-password", {
    otp,
    password,
    confirmPassword,
  });
};

export const stage = {
  PERSONAL: "PERSONAL",
  RESIDENCE: "RESIDENCE",
  PHOTO: "PHOTO",
  BVN: "BVN",
  ADDRESS_VERIFICATION: "ADDRESS_VERIFICATION",
  IDENTIFICATION: "IDENTIFICATION",
  DETAILS: "DETAILS",
  ADDRESS: "ADDRESS",
  PROFILE: "PROFILE",
  OPERATIONS: "OPERATIONS",
};

import { Box, Button, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { styles } from "../../../common/css/styles";
import DownloadIcon from "@mui/icons-material/Download";
import { InfoDialog } from "../../../common/components/InfoDialog";

export const BusinessDocuments = ({ data }) => {
  const [open, setOpen] = useState(false);
  const [isError, setIsError] = useState(false);
  const [infoText, setInfoText] = useState("");

  const handleDownloadFile = (url, name) => {
    if (!!!url) {
      setIsError(true);
      setInfoText("File not found");
      setOpen(true);
    } else {
      const link = document.createElement("a");
      link.href = url;
      link.download = name; // You can specify a default file name here

      // Append the anchor element to the document body
      document.body.appendChild(link);

      // Programmatically click the anchor element to trigger the download
      link.click();

      // Remove the anchor element from the document
      document.body.removeChild(link);
    }
  };
  return (
    <Box
      sx={{
        display: "flex",
        // justifyContent: "space-between",
        width: "100%",
      }}
    >
      <Box sx={{ mr: 25 }}>
        <Stack>
          <Box sx={{ py: 1 }}>
            <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
              RC Number
            </Typography>
            <Typography sx={{ fontWeight: 700, color: "#494D4A" }}>
              {data?.rcNumber ?? "Not Available"}
            </Typography>
          </Box>
          <Box sx={{ py: 1 }}>
            <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
              CAC Number
            </Typography>
            <Typography sx={{ fontWeight: 700, color: "#494D4A" }}>
              {data?.cacNumber ?? "Not Available"}
            </Typography>
          </Box>
          <Box sx={{ py: 1 }}>
            <Typography sx={styles.kyc.headerText}>CAC</Typography>
            <Button
              sx={{
                border: 1,
                py: 1,
                px: 2,
                borderColor: "#DFF3E7",
                borderRadius: 2,
                backgroundColor: "#F5FAF7",
                textTransform: "none",
              }}
              onClick={() => handleDownloadFile(data?.cacUrlForm7, "cac.png")}
            >
              <DownloadIcon /> Download
            </Button>
          </Box>
          <Box sx={{ py: 1 }}>
            <Typography sx={styles.kyc.headerText}>MEMART</Typography>
            <Button
              sx={{
                border: 1,
                py: 1,
                px: 2,
                borderColor: "#DFF3E7",
                borderRadius: 2,
                backgroundColor: "#F5FAF7",
                textTransform: "none",
              }}
              onClick={() => handleDownloadFile(data?.memartUrl, "memart.png")}
            >
              <DownloadIcon /> Download
            </Button>
          </Box>
        </Stack>
      </Box>
      <Box>
        <Stack>
          <Box sx={{ py: 1 }}>
            <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
              Tax ID Number
            </Typography>
            <Typography sx={styles.kyc.bodyText}>
              {data?.taxIdentificationNumber ?? "Not Available"}
            </Typography>
          </Box>
          <Box sx={{ py: 5 }}></Box>
          <Box sx={{ py: 1 }}>
            <Typography sx={styles.kyc.headerText}>
              Certificate of business name
            </Typography>
            <Button
              sx={{
                border: 1,
                py: 1,
                px: 2,
                borderColor: "#DFF3E7",
                borderRadius: 2,
                backgroundColor: "#F5FAF7",
                textTransform: "none",
              }}
              onClick={() =>
                handleDownloadFile(
                  data?.certificateOfBusinessNameUrl,
                  "certifiacteOfBusinessName.png"
                )
              }
            >
              <DownloadIcon /> Download
            </Button>
          </Box>
          <Box sx={{ py: 1 }}>
            <Typography sx={styles.kyc.headerText}>SCUML</Typography>
            <Button
              sx={{
                border: 1,
                py: 1,
                px: 2,
                borderColor: "#DFF3E7",
                borderRadius: 2,
                backgroundColor: "#F5FAF7",
                textTransform: "none",
              }}
              onClick={() => handleDownloadFile(data?.scumlUrl, "memart.png")}
            >
              <DownloadIcon /> Download
            </Button>
          </Box>
        </Stack>
      </Box>
      <InfoDialog
        open={open}
        error={isError}
        text={infoText}
        handleClose={() => setOpen(false)}
      />
    </Box>
  );
};

import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { styles } from "../../../../common/css/styles";

export const AddressVerification = ({ kycData }) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "row" }}>
      {/* User Input */}
      <Box sx={{ py: 2, width: "50%" }}>
        <Typography sx={{ fontWeight: 700, fontSize: 18, py: 2 }}>
          Address Verification
        </Typography>

        <Box
          sx={{
            border: 1,
            borderRadius: 2,
            minHeight: 200,
            maxWidth: "90%",
            px: 2,
          }}
        >
          <Box sx={{ width: "100%", py: 1 }}>
            <img
              src={kycData?.utilityBillUrl}
              alt="utility bill"
              width="100%"
            />
          </Box>
        </Box>
      </Box>
      {/* BVN Generated */}
      <Box sx={{ py: 2, width: "50%" }}>
        <Typography sx={{ fontWeight: 700, py: 2 }}>User Input</Typography>
        <Box
          sx={{
            border: 1,
            borderRadius: 2,
            minHeight: 200,
            maxWidth: "90%",
            px: 2,
            mt: 0.3,
          }}
        >
          {/* <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ py: 1 }}>
              <Typography sx={styles.bvn.topLeft}>First Name</Typography>
              <Typography sx={styles.bvn.bottomLeft}>Charles</Typography>
            </Box>
            <Box sx={{ py: 1 }}>
              <Typography sx={styles.bvn.topRight}>Last Name</Typography>
              <Typography sx={styles.bvn.bottomRight}>Bradenton</Typography>
            </Box>
          </Stack> */}
          {/* <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ py: 1 }}>
              <Typography sx={styles.bvn.topLeft}>Email Address</Typography>
              <Typography sx={styles.bvn.bottomLeft}>
                Charlesbrad@gmail.com
              </Typography>
            </Box>
            <Box sx={{ py: 1 }}>
              <Typography sx={styles.bvn.topRight}>Phone Number</Typography>
              <Typography sx={styles.bvn.bottomRight}>
                +234 80 1234 5678
              </Typography>
            </Box>
          </Stack> */}
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ py: 1 }}>
              <Typography sx={styles.bvn.topLeft}>Address</Typography>
              <Typography sx={styles.bvn.bottomLeft}>
                {kycData?.residentialAddress ?? "Not Available"}
              </Typography>
            </Box>{" "}
            {/* <Box sx={{ py: 1 }}>
              <Typography sx={styles.bvn.topRight}>City</Typography>
              <Typography sx={styles.bvn.bottomRight}>
                {kycData.city}
              </Typography>
            </Box> */}
          </Stack>
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ py: 1 }}>
              <Typography sx={styles.bvn.topLeft}>State</Typography>
              <Typography sx={styles.bvn.bottomLeft}>
                {kycData?.state ?? "Not Available"}
              </Typography>
            </Box>
            <Box sx={{ py: 1 }}>
              <Typography sx={styles.bvn.topRight}>LGA</Typography>
              <Typography sx={styles.bvn.bottomRight}>
                {kycData?.city ?? "Not Available"}
              </Typography>
            </Box>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

import { Typography } from "@mui/material";
import { styles } from "./css/styles";
import { businessLoanFilters } from "../features/smeLoans/businessLoan/constants/businessLoanFilters";
import { repaymentStatus } from "../features/wallet/constants/repaymentStatus";
import { client } from "./api";

export const formatCurrencyvalue = (val) =>
  val?.toLocaleString("en-NG", {
    style: "currency",
    currency: "NGN",
  }) ?? "₦0";

export const parseStringToObject = (val) => {
  let parsed = {};

  try {
    parsed = JSON.parse(val);
  } catch (e) {
    try {
      const splitedVal = val.split(",");

      for (let index = 0; index < splitedVal.length; index++) {
        const [key, val] = splitedVal[index].split(":");
        parsed[key] = val;
      }
      return parsed;
    } catch (error) {
      return parsed;
    }
  }

  return parsed;
};

export function getRoundedAmount(amount) {
  if (amount > 1000000000) {
    return `₦${amount / 1000000000}B`;
  }
  if (amount > 1000000) {
    return `₦${amount / 1000000}M`;
  } else if (amount > 1000) {
    return `₦${amount / 1000}K`;
  }
  return `₦${amount}K`;
}

export const parseUrlFileName = (url) => url.split("/").pop();

export const getLoanStatusDisplay = (status) => {
  switch (status) {
    case businessLoanFilters.OFFER_ACCEPTED:
      return (
        <Typography
          sx={{ ...styles.cardTopStatus.accepted, whiteSpace: "nowrap", px: 1 }}
        >
          Offer Accepted
        </Typography>
      );
    case businessLoanFilters.LOAN_REJECTED:
      return (
        <Typography sx={styles.cardTopStatus.loanRejected}>
          Loan Rejected
        </Typography>
      );
    case businessLoanFilters.PENDING_AUTHORIZATION:
      return (
        <Typography sx={styles.cardTopStatus.pendingAuth}>
          Pending Autorization
        </Typography>
      );
    case businessLoanFilters.PENDING_OFFER:
      return (
        <Typography sx={styles.cardTopStatus.pending}>Pending Offer</Typography>
      );
    case businessLoanFilters.PENDING_RECOMMENDATION:
      return (
        <Typography sx={styles.cardTopStatus.pendingRecommendation}>
          Pending Recommendation
        </Typography>
      );
    case businessLoanFilters.PENDING_DISBURSEMENT:
      return (
        <Typography sx={styles.cardTopStatus.pendingDisbursement}>
          Pending Disbursement
        </Typography>
      );
    case businessLoanFilters.PENDING_APPROVAL:
      return (
        <Typography sx={styles.cardTopStatus.pendingReview}>
          Pending Approval
        </Typography>
      );
    case businessLoanFilters.PAY_INTEREST:
      return (
        <Typography sx={styles.cardTopStatus.pendingReview}>
          Pay Interest
        </Typography>
      );
    case businessLoanFilters.UNDER_REVIEW:
      return (
        <Typography sx={styles.cardTopStatus.underReview}>
          Under Review
        </Typography>
      );
    case businessLoanFilters.ACTIVE:
      return <Typography sx={styles.cardTopStatus.active}>Active</Typography>;
    case businessLoanFilters.COMPLETED:
      return (
        <Typography sx={styles.cardTopStatus.active}>Completed</Typography>
      );
    case businessLoanFilters.DEFAULTING:
      return (
        <Typography sx={styles.cardTopStatus.suspended}>Defaulting</Typography>
      );
    case businessLoanFilters.NTU:
      return <Typography sx={styles.cardTopStatus.ntu}>NTU</Typography>;
    case businessLoanFilters.REQUEST_DECLINED:
      return (
        <Typography sx={styles.cardTopStatus.denied}>
          Request Declined
        </Typography>
      );
    case businessLoanFilters.OFFERED:
      return <Typography sx={styles.cardTopStatus.offered}>Offered</Typography>;
    case businessLoanFilters.OFFER_REJECTED:
      return (
        <Typography sx={styles.cardTopStatus.rejected}>
          Offer Rejected
        </Typography>
      );
    case businessLoanFilters.LOST:
      return <Typography sx={styles.cardTopStatus.rejected}>Lost</Typography>;
    default:
      break;
  }
};

export const getTranactionStatusDisplay = (status) => {
  switch (status) {
    case repaymentStatus.SUCCESSFUL:
      return (
        <Typography sx={styles.table.text.customerStatus.verified}>
          Succesfull
        </Typography>
      );
    case repaymentStatus.FAILED:
      return (
        <Typography sx={styles.table.text.customerStatus.rejected}>
          Failed
        </Typography>
      );
    case repaymentStatus.PENDING:
      return (
        <Typography sx={styles.table.text.customerStatus.pending}>
          Deleted
        </Typography>
      );

    default:
      return (
        <Typography
          sx={{
            ...styles.table.text.customerStatus.pending,
            textTransform: "capitalize",
          }}
        >
          {status.toLowercase()}
        </Typography>
      );
  }
};

export function parseJwt(token) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url?.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

export const validateAuth = () => {
  const token = localStorage.getItem("userToken");
  if (token && token !== "undefined") {
    const tokenData = parseJwt(token);
    const now = Date.now();
    const exp = tokenData["exp"];
    if (exp) {
      return exp > now / 1000;
    }
  }
  return false;
};

export const shouldRefresh = () => {
  const token = localStorage.getItem("userToken");

  if (token && token !== "undefined") {
    const tokenData = parseJwt(token);
    const now = Date.now();
    const exp = tokenData["exp"];
    if (exp) {
      // exp is not at least some minutes ahead of current time
      // return exp < now / 1000 + 880;
      return exp < now / 1000 + 300;
    }
  }
  return false;
};

export const reactivateSession = () => {
  const token = localStorage.getItem("userToken");
  client.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

export const setSession = (data) => {
  if (data) {
    localStorage.setItem("userData", JSON.stringify(data));
    client.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${data.auth.access_token}`;
  }
};

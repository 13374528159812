import {
  Box,
  // Button,
  // CircularProgress,
  FormControl,
  MenuItem,
  Select,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { styles } from "../../../common/css/styles";
import { InfoOutlined } from "@mui/icons-material";
// import { useNavigate } from "react-router-dom";
// import { bridgeFinanceFilters } from "../../bridgeFinance/constants/bridgeFinanceFilters";
import { overviewFilters } from "../overviewFilters";
// import { loanType } from "../../smeLoans/constants/loanType";
// import { retailFilters } from "../../loans/constants/retailFilters";
import { useLoanParams } from "../hooks/useLoanParams";
import dayjs from "dayjs";
import { format } from "date-fns";
import CustomDate from "../../../common/components/CustomDate";

const d = new Date();

export const LoanPortfolioStatistics = ({ overview, loanTypes, ranges }) => {
  const [filter, setFilter] = useState(overviewFilters.LAST7DAYS);
  const [loanTypeFilter, setLoanTypeFilter] = useState("");
  const [customFilter, setCustomFilter] = useState({
    open: false,
    startDate: format(d.setDate(d.getDate()), "yyyy-MM-dd"),
    endDate: format(d.setDate(d.getDate()), "yyyy-MM-dd"),
  });

  // const loansStatistics = overview.statistics.loansStatistics;

  const { getDateRange, fetchLoanStats, dispatch } = useLoanParams();

  const handleLoanTypeFilterChange = (event) => {
    setLoanTypeFilter(event.target.value);
    const range = getDateRange(filter);
    dispatch(
      fetchLoanStats({
        type: event.target.value === "" ? "ALL" : event.target.value,
        ...range,
      })
    );
  };

  const handleStatisticsFilterChange = (event) => {
    setFilter(event.target.value);
    const range = getDateRange(event.target.value);
    if (event.target.value !== "CUSTOM") {
      setCustomFilter({ ...customFilter, open: false });
      dispatch(
        fetchLoanStats({
          type: loanTypeFilter === "" ? "ALL" : loanTypeFilter,
          ...range,
        })
      );
    } else {
      setCustomFilter({
        startDate: range.startDate,
        endDate: range.endDate,
        open: true,
      });
    }
  };
  const handleCustomFilter = (name, value) => {
    setCustomFilter({
      ...customFilter,
      [name]: dayjs(value.$d).format("YYYY-MM-DD"),
    });
  };
  const applyCustomFilter = () => {
    setCustomFilter({ ...customFilter, open: false });
    dispatch(
      fetchLoanStats({
        startDate: customFilter.startDate,
        endDate: customFilter.endDate,
        type: loanTypeFilter === "" ? "ALL" : loanTypeFilter,
      })
    );
  };
  return (
    <Box
      item
      sx={{
        width: "100%",
        // height: 180,
        backgroundColor: "#fff",
        borderRadius: 2,
        mt: 2,
      }}
    >
      <Box
        sx={{
          py: 2,
          pl: 3,
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography sx={{ fontWeight: 600, fontSize: 16 }}>
            Loan Portfolio Statistics
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              position: "relative",
            }}
          >
            <FormControl sx={{ mr: 2.5, mt: 1 }}>
              <Select
                value={loanTypeFilter}
                size="small"
                onChange={handleLoanTypeFilterChange}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                sx={styles.dropdown}
              >
                {loanTypes.map((type) => (
                  <MenuItem key={type.value} value={type.value}>
                    {type.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* {filter} */}
            <FormControl sx={{ mr: 3.5, mt: 1 }}>
              <Select
                value={filter}
                size="small"
                onChange={handleStatisticsFilterChange}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                sx={styles.dropdown}
              >
                {ranges.map((range) => (
                  <MenuItem
                    onClick={() =>
                      setCustomFilter({ ...customFilter, open: true })
                    }
                    key={range.value}
                    value={range.value}
                  >
                    {range.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {customFilter.open ? (
              <CustomDate
                handleCustomFilter={handleCustomFilter}
                customFilter={customFilter}
                close={() => setCustomFilter({ ...customFilter, open: false })}
                applyCustomFilter={applyCustomFilter}
              />
            ) : (
              <></>
            )}
          </Box>
        </Box>

        {overview.loadingLoanStats ? (
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr",
              gap: "20px",
              pr: 2,
              pt: 2,
            }}
          >
            {[1, 2, 3, 4, 5, 6].map((n) => (
              <Skeleton
                key={n}
                variant="rectangular"
                width={"100%"}
                height={100}
                style={{ borderRadius: "10px" }}
              />
            ))}
          </Box>
        ) : (
          <Box
            item
            sx={{
              width: "100%",
              height: 280,
              borderRadius: 2,
            }}
          >
            <Box sx={{ pt: 2 }}>
              <Stack sx={{ pr: 2 }}>
                <Box
                  sx={{
                    display: "flex",
                    // justifyContent: "space-between",
                    // width: "100%",
                  }}
                >
                  <Box sx={styles.overview.statistics.box}>
                    <Stack sx={{ pl: 2 }}>
                      <Typography sx={styles.overview.statistics.info}>
                        Applications
                      </Typography>
                      <Typography sx={styles.overview.statistics.number}>
                        {/* {loansStatistics?.totalLoanApplications} */}
                        {overview?.loanStats?.application}
                      </Typography>
                    </Stack>

                    <Tooltip
                      title="Total Loan Applications"
                      placement="top"
                      sx={{ alignSelf: "normal" }}
                    >
                      <InfoOutlined sx={styles.overview.statistics.tooltip} />
                    </Tooltip>
                  </Box>
                  <Box sx={styles.overview.statistics.box}>
                    <Stack sx={{ pl: 2 }}>
                      <Typography sx={styles.overview.statistics.info}>
                        Offered
                      </Typography>
                      <Typography sx={styles.overview.statistics.number}>
                        {/* {loansStatistics?.totalOfferedLoans} */}
                        {overview?.loanStats?.offered}
                      </Typography>
                    </Stack>

                    <Tooltip
                      title="Total Loans Offered"
                      placement="top"
                      sx={{ alignSelf: "normal" }}
                    >
                      <InfoOutlined sx={styles.overview.statistics.tooltip} />
                    </Tooltip>
                  </Box>
                  <Box sx={styles.overview.statistics.box}>
                    <Stack sx={{ pl: 2 }}>
                      <Typography sx={styles.overview.statistics.info}>
                        NTU
                      </Typography>
                      <Typography sx={styles.overview.statistics.number}>
                        {/* {loansStatistics?.totalNtuLoans} */}
                        {overview?.loanStats?.ntu}
                      </Typography>
                    </Stack>

                    <Tooltip
                      title="Total Loans Not Taken Up"
                      placement="top"
                      sx={{ alignSelf: "normal" }}
                    >
                      <InfoOutlined sx={styles.overview.statistics.tooltip} />
                    </Tooltip>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    mt: 2,
                  }}
                >
                  <Box sx={styles.overview.statistics.box}>
                    <Stack sx={{ pl: 2 }}>
                      <Typography sx={styles.overview.statistics.info}>
                        Active
                      </Typography>
                      <Typography sx={styles.overview.statistics.number}>
                        {/* {loansStatistics?.totalActiveLoans} */}
                        {overview?.loanStats?.active}
                      </Typography>
                    </Stack>

                    <Tooltip
                      title="Total Active Loans"
                      placement="top"
                      sx={{ alignSelf: "normal" }}
                    >
                      <InfoOutlined sx={styles.overview.statistics.tooltip} />
                    </Tooltip>
                  </Box>
                  <Box sx={styles.overview.statistics.box}>
                    <Stack sx={{ pl: 2 }}>
                      <Typography sx={styles.overview.statistics.info}>
                        Defaulting
                      </Typography>
                      <Typography sx={styles.overview.statistics.number}>
                        {/* {loansStatistics?.totalDefaultingLoans} */}
                        {overview?.loanStats?.defaulting}
                      </Typography>
                    </Stack>

                    <Tooltip
                      title="Total Defaulting Loans"
                      placement="top"
                      sx={{ alignSelf: "normal" }}
                    >
                      <InfoOutlined sx={styles.overview.statistics.tooltip} />
                    </Tooltip>
                  </Box>
                  <Box sx={styles.overview.statistics.box}>
                    <Stack sx={{ pl: 2 }}>
                      <Typography sx={styles.overview.statistics.info}>
                        Approval Rate
                      </Typography>
                      <Typography sx={styles.overview.statistics.number}>
                        {/* {loansStatistics?.loanApprovalRate}% */}
                        {overview?.loanStats?.approvalRate}%
                      </Typography>
                    </Stack>

                    <Tooltip
                      title="Loan Approval Rate"
                      placement="top"
                      sx={{ alignSelf: "normal" }}
                    >
                      <InfoOutlined sx={styles.overview.statistics.tooltip} />
                    </Tooltip>
                  </Box>
                </Box>
              </Stack>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

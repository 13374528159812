import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Box, Grid, Typography } from "@mui/material";
import doneIcon from "../../../common/images/done-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { recommendOrDeclineOffer } from "../store/bridgeFinanceLoanSlice";
import { useNavigate } from "react-router-dom";

const defaultValues = {
  reasonsForRecommendation: [],
  otherReasons: "",
};

const schema = yup.object().shape({
  reasonsForRecommendation: yup
    .array()
    .min(1, "Please select at least one reason")
    .required("Reasons are required"),
  otherReasons: yup.string().when("reasonsForRecommendation", {
    is: (val) => val.length === 0,
    then: () => yup.string().required("Other reason is required"),
    otherwise: () => yup.string().notRequired(),
  }),
});

export const RecommendForDecline = ({ open, handleClose }) => {
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitSuccessful },
    reset,
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  const [openSecondDialog, setOpenSecondDialog] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const options = [
    "Lack of credit History",
    "Poor credit history",
    "insufficient income",
    "unstable Employment",
    "Age",
    "Residency requirement",
    "Incomplete application",
    "Defaulted loan",
  ];
  const loan = useSelector(({ bridgeLoan }) => bridgeLoan.loan);

  const handleCloseSecondDialog = () => {
    setOpenSecondDialog(false);

    navigate("/bridge-finance");
  };

  const onSubmit = (data) => {
    dispatch(
      recommendOrDeclineOffer({
        ...data,
        loanUid: loan.uid,
        recommendOffer: false,
        reasonsForRecommendation: [
          ...data.reasonsForRecommendation,
          data.otherReasons,
        ].filter((n) => n),
      })
    );
    handleClose();
    setOpenSecondDialog(true);
  };

  useEffect(() => {
    if (isSubmitSuccessful) reset();
  }, [isSubmitSuccessful]);

  useEffect(() => {
    let timeoutId;
    if (openSecondDialog) {
      timeoutId = setTimeout(() => {
        handleCloseSecondDialog();
      }, 2000);
    }

    return () => clearTimeout(timeoutId);
  }, [openSecondDialog]);

  return (
    <>
      <>
        <Dialog
          open={open}
          onClose={() => {
            handleClose();
            reset();
          }}
        >
          <Grid
            component="form"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
            py={2}
            sx={{ width: 448 }}
          >
            <DialogTitle sx={{ color: "#000" }}>
              Recommend For Decline
            </DialogTitle>

            <DialogContent>
              <Grid container spacing={3}>
                <Grid item sx={{ width: "100%" }}>
                  <Typography sx={{ fontSize: 14, fontWeight: 400, pb: 1 }}>
                    Please provide your reasons for recommending a decline of
                    this loan request.
                  </Typography>
                  <Controller
                    name="reasonsForRecommendation"
                    control={control}
                    render={({ field: { onChange } }) => (
                      <Autocomplete
                        multiple
                        id="multiselect-chip"
                        options={options}
                        onChange={(event, newValue) => onChange(newValue)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={!!errors.reasonsForRecommendation}
                            helperText={
                              errors.reasonsForRecommendation?.message
                            }
                            variant="outlined"
                            label="Select Reason"
                            placeholder="Options"
                            size="small"
                          />
                        )}
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <Chip label={option} {...getTagProps({ index })} />
                          ))
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item sx={{ width: "100%" }}>
                  <Typography sx={{ fontSize: 14, fontWeight: 400, pb: 1 }}>
                    Other reasons for decline recommendation
                  </Typography>
                  <Controller
                    name="otherReasons"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        error={!!errors.otherReasons}
                        helperText={errors.otherReasons?.message}
                        fullWidth
                        size="small"
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  handleClose();
                  reset();
                }}
                sx={{
                  textTransform: "capitalize",
                  fontSize: 14,
                  color: "#616663",
                }}
              >
                Close
              </Button>
              <Button
                variant="contained"
                type="submit"
                sx={{
                  boxShadow: "none",
                  color: "#D41818",
                  textTransform: "capitalize",
                  // px: 3,
                  fontSize: 12,
                  fontWeight: 600,
                  borderRadius: 2,
                  backgroundColor: "#FFF2F2",
                  mr: 2,
                  // ml: 2,
                  "&:hover": {
                    backgroundColor: "#f6adad",
                    boxShadow: "none",
                  },
                }}
              >
                Recommend for Decline
              </Button>
            </DialogActions>
          </Grid>
        </Dialog>

        {/* Second Dialog */}
        <Dialog open={openSecondDialog} onClose={handleCloseSecondDialog}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <DialogContent sx={{ minWidth: 300 }}>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img src={doneIcon} alt="done-icon" />
              </Box>
              <DialogContentText sx={{ textAlign: "center" }}>
                This request has been recommended for decline.
              </DialogContentText>
            </DialogContent>
          </Box>
        </Dialog>
      </>
    </>
  );
};

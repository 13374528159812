import { Box, Button, Grid, Typography } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import React from "react";
import { styles } from "../../../../../../common/css/styles";
import { parseUrlFileName } from "../../../../../../common/util";
import { FileOpenOutlined } from "@mui/icons-material";

export const Collateral = ({ data }) => {
  const collateralInfo = data?.length > 0 ? data[0] : null;

  const handleDownloadFile = (url, name) => {
    const link = document.createElement("a");
    link.href = url;
    link.download = name; // You can specify a default file name here

    // Append the anchor element to the document body
    document.body.appendChild(link);

    // Programmatically click the anchor element to trigger the download
    link.click();

    // Remove the anchor element from the document
    document.body.removeChild(link);
  };

  return (
    <Box
      sx={{
        display: "flex",
        pb: 36,
      }}
    >
      <Box sx={{}}>
        <Box sx={{ width: "100%", height: "100%" }}>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2 }}>
            <Grid item xs={6}>
              <Box>
                <Box>
                  <Typography sx={{ fontWeight: 400, color: "#62635E", pb: 1 }}>
                    Type of collateral
                  </Typography>
                  <Typography sx={{ fontWeight: 700, color: "#494D4A", pb: 2 }}>
                    {collateralInfo?.typeOfCollateral ?? "Not Available"}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box>
                <Typography sx={{ fontWeight: 400, color: "#62635E", pb: 1 }}>
                  Value of collateral
                </Typography>
                <Typography sx={styles.kyc.bodyText}>
                  {collateralInfo?.valueOfCollateral?.toLocaleString("en-NG", {
                    style: "currency",
                    currency: "NGN",
                  }) ?? "Not Available"}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box>
                <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
                  Certificate of ownership
                </Typography>

                <Box
                  sx={{
                    border: 1,
                    py: 1,
                    px: 2,
                    borderColor: "#DFF3E7",
                    borderRadius: 2,
                    backgroundColor: "#F5FAF7",
                    textTransform: "none",
                    width: "90%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <FileOpenOutlined />
                  <Typography
                    sx={{
                      fontWeight: 600,
                      // color: "#62635E",
                      flex: "1",
                      px: 1,
                      textOverflow: "ellipsis",
                      textWrap: "nowrap",
                      overflow: "hidden",
                    }}
                  >
                    {collateralInfo?.certificateOfOwnershipUrl
                      ? parseUrlFileName(
                          collateralInfo?.certificateOfOwnershipUrl
                        )
                      : "Not Available"}
                  </Typography>

                  {collateralInfo?.certificateOfOwnershipUrl && (
                    <DownloadIcon
                      sx={{ cursor: "pointer" }}
                      onClick={() =>
                        handleDownloadFile(
                          collateralInfo?.certificateOfOwnershipUrl,
                          "certificateOfOwnership.png"
                        )
                      }
                    />
                  )}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box>
                <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
                  Picture
                </Typography>

                <Box
                  sx={{
                    border: 1,
                    py: 1,
                    px: 2,
                    borderColor: "#DFF3E7",
                    borderRadius: 2,
                    backgroundColor: "#F5FAF7",
                    textTransform: "none",
                    width: "90%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <FileOpenOutlined />
                  <Typography
                    sx={{
                      fontWeight: 600,
                      // color: "#62635E",
                      flex: "1",
                      px: 1,
                      textOverflow: "ellipsis",
                      textWrap: "nowrap",
                      overflow: "hidden",
                    }}
                  >
                    {collateralInfo?.collateralUrl
                      ? parseUrlFileName(collateralInfo?.collateralUrl)
                      : "Not Available"}
                  </Typography>

                  {collateralInfo?.collateralUrl && (
                    <DownloadIcon
                      sx={{ cursor: "pointer" }}
                      onClick={() =>
                        handleDownloadFile(
                          collateralInfo?.collateralUrl,
                          "collateral.png"
                        )
                      }
                    />
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

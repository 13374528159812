import {
  clearAuth,
  clearSession,
  setError,
} from "../../features/auth/authSlice";
import { displayErrorMessage } from "../notificationSlice";

export const toTitleCase = (str) => {
  return str?.replace(
    /\w*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  );
};

export const handleError = async (error, dispatch, rejectWithValue) => {
  if (error.code === "ERR_NETWORK") {
    // Handle network connection error and dispatch an action
    return rejectWithValue("Network connection error");
  }

  const message = error.response?.data?.message.join().toLowerCase();
  if (
    error.response.config.url !== "login" &&
    error.response.config.url !== "auth/refresh" &&
    message.includes("token")
  ) {
    clearSession();
    dispatch(
      setError([
        "Sorry, your session has expired. Kindly login again to continue",
      ])
    );
    return rejectWithValue(
      "Sorry, your session has expired. Kindly login again to continue"
    );
  } else if (message.includes("java")) {
    return rejectWithValue("Oops! An error occured. Please contact support");
  } else {
    // Handle other errors
    return rejectWithValue(
      error.response.data
        ? error.response.data.message
        : "Oops! An error occured. Please contact support"
    ); // This will propagate other errors to the rejected state
  }
};

export const handleErrorAlt = async (error, dispatch) => {
  if (error.code === "ERR_NETWORK") {
    // Handle network connection error and dispatch an action
    dispatch(
      displayErrorMessage({
        message: "Network connection error",
      })
    );
    return;
  }

  const message = error.response?.data?.message.join().toLowerCase();
  if (
    error.response?.config?.url !== "login" &&
    error.response?.config?.url !== "auth/refresh" &&
    message?.includes("token")
  ) {
    clearSession();
    dispatch(
      displayErrorMessage({
        message:
          "Sorry, your session has expired. Kindly login again to continue",
      })
    );
    dispatch(clearAuth());
    return;
  } else if (error.response?.config?.url === "auth/refresh") {
    return;
  } else if (message?.includes("java") || message?.includes("token")) {
    // clearSession();

    dispatch(
      displayErrorMessage({
        message: "Oops! An error occured. Please contact support",
      })
    );
    return;
  } else {
    // Handle other errors
    dispatch(
      displayErrorMessage({
        message: error?.response?.data
          ? message
          : "Oops! An error occured. Please contact support",
      })
    );
    return; // This will propagate other errors to the rejected state
  }
};

export const parseError = (error) => {
  if (error.code === "ERR_NETWORK") {
    return "Network connection error";
  }

  const message = error.response?.data?.message.join().toLowerCase();
  if (
    (error.response.config.url !== "login" &&
      error.response.config.url !== "auth/refresh" &&
      message.includes("token")) ||
    message.includes("java")
  ) {
    return "Oops! An error occured. Please contact support";
  } else {
    // Handle other errors

    return error.response.data
      ? message
      : "Oops! An error occured. Please contact support";
  }
};

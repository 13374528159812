import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { EditOutlined } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { fetchInterestRates } from "./settingsSlice";
import { ManageGracePeriod } from "./components/ManageGracePeriod";

export const GracePeriod = ({ data = {}, refetch }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const dispatch = useDispatch();

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  useEffect(() => {
    dispatch(fetchInterestRates());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Box
        sx={{
          width: "100%",
          backgroundColor: "white",
          borderRadius: "0.5rem",
        }}
      >
        <Typography sx={{ paddingX: 2, py: 2, fontSize: 20, fontWeight: 500 }}>
          Grace Period
        </Typography>
        <Box
          sx={{
            display: "flex",
            px: 4,
            pb: 2,
            justifyContent: "space-between",
            color: "#323232",
          }}
        >
          <Typography sx={{ color: "#303332" }}>
            {data?.value ?? 0} day{data?.value && "s"}
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={handleDialogOpen}
          >
            <Typography sx={{ color: "#303332", mr: 1 }}>Edit</Typography>
            <EditOutlined fontSize="15" />
          </Box>
        </Box>
      </Box>
      {dialogOpen && (
        <ManageGracePeriod
          open={dialogOpen}
          handleClose={handleDialogClose}
          data={data}
          refetch={refetch}
        />
      )}
    </>
  );
};

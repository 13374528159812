
import { useDispatch } from "react-redux";
import {
  fetchCustomerStats,
  fetchCustomersPie,
  fetchLoanMetrics,
  fetchLoanStats,
  fetchLoansPie,
  fetchPendingCustomers,
  fetchPendingLoans,
  fetchStatistics,
  fetchLoanInsights,
} from "../overviewSlice";
import { overviewFilters } from "../overviewFilters";
import { format } from "date-fns";
import { retailFilters } from "../../loans/constants/retailFilters";
import { loanType } from "../../smeLoans/constants/loanType";

export const useLoanParams = () => {
  const dispatch = useDispatch();
  const loanStatus = [
    { label: "All Loan Status", value: "" },
    {
      label: "Pending Recommendation",
      value: retailFilters.PENDING_RECOMMENDATION,
    },
    { label: "Pending Offer", value: retailFilters.PENDING_OFFER },
    { label: "Pending Disbursed", value: retailFilters.PENDING_DISBURSEMENT },
    { label: "Offered", value: retailFilters.OFFERED },
    { label: "Rejected Offer", value: retailFilters.OFFER_REJECTED },
    { label: "Declined Applications", value: retailFilters.DECLINED },
    { label: "Active", value: retailFilters.ACTIVE },
    { label: "Defaulting", value: retailFilters.DEFAULTING },
    { label: "Completed", value: retailFilters.COMPLETED },
    { label: "NTU", value: retailFilters.NTUS },
  ];

  const loanTypes = [
    { label: "All Loan Types", value: "" },
    {
      label: "Bridge Financing",
      value: loanType.BRIDGE_FINANCING,
    },
    { label: "Retail Loans", value: loanType.RETAIL },
    { label: "Business Loans", value: loanType.BUSINESS },
    { label: "Asset Financing", value: loanType.ASSET_FINANCING },
  ];

  const ranges = [
    { label: "Last 7 days", value: overviewFilters.LAST7DAYS },
    {
      label: "Last 14 days",
      value: overviewFilters.LAST14DAYS,
    },
    { label: "Last 1 month", value: overviewFilters.ONE_MONTH },
    { label: "Last 2 months", value: overviewFilters.TWO_MONTHS },
    { label: "Last 3 months", value: overviewFilters.THREE_MONTHS },
    { label: "Custom Date", value: overviewFilters.CUSTOM },
  ];

  const getDateRange = (filter) => {
    const d = new Date();
    const range = {
      startDate: "",
      endDate: format(d, "yyyy-MM-dd"),
    };

    switch (filter) {
      case overviewFilters.LAST7DAYS:
        range.startDate = format(d.setDate(d.getDate() - 7), "yyyy-MM-dd");
        break;
      case overviewFilters.LAST14DAYS:
        range.startDate = format(d.setDate(d.getDate() - 14), "yyyy-MM-dd");
        break;
      case overviewFilters.ONE_MONTH:
        range.startDate = format(d.setMonth(d.getMonth() - 1), "yyyy-MM-dd");
        break;
      case overviewFilters.TWO_MONTHS:
        range.startDate = format(d.setMonth(d.getMonth() - 2), "yyyy-MM-dd");
        break;
      case overviewFilters.THREE_MONTHS:
        range.startDate = format(d.setMonth(d.getMonth() - 3), "yyyy-MM-dd");
        break;
      default:
        range.startDate = format(d.setDate(d.getDate() - 7), "yyyy-MM-dd");
        break;
    }
    return range;
  };
  return {
    getDateRange,
    dispatch,
    loanStatus,
    loanTypes,
    ranges,
    fetchCustomerStats,
    fetchCustomersPie,
    fetchLoanMetrics,
    fetchLoanStats,
    fetchLoansPie,
    fetchPendingCustomers,
    fetchPendingLoans,
    fetchStatistics,
    fetchLoanInsights,
  };
};

import { Grid, Typography } from "@mui/material";
import React from "react";

export const TopBar = () => {
  return (
    <Grid
      container
      xs={12}
      md={12}
      // sx={{
      //   backgroundColor: "yellow",
      //   height: "5vh",
      // }}
    >
      <Grid
        container
        item
        sx={{
          backgroundColor: "#000",
          height: "5vh",
          position: "fixed",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          top: 0,
        }}
      >
        <Typography sx={{ color: "#fff" }}>Krevus Admin</Typography>
      </Grid>
    </Grid>
  );
};

import { Avatar, Box, Button, Stack, Typography } from "@mui/material";
import React from "react";
import avatarDummy from "../../../../common/images/avatar-image.jpg";
import checkIcon from "../../../../common/images/check-icon.svg";
import closeIcon from "../../../../common/images/close-1con.svg";
import { useState } from "react";
import { Decline } from "./Decline";
import { Verify } from "./Verify";
import { useSelector } from "react-redux";
import { customerFilters } from "../../../customers/constants/customerFilters";
import { styles } from "../../../../common/css/styles";
import PersonIcon from "@mui/icons-material/Person";

export const CardTop = () => {
  return (
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      <Stack>
        <Box sx={{ display: "flex" }}>
          <Typography sx={{ fontWeight: 700, fontSize: 24 }}>
            Customer Details
          </Typography>
        </Box>
        {/* <Typography sx={{ mt: 1.2 }}>{setStatus()}</Typography> */}
      </Stack>
      {/* <Button
        variant="contained"
        sx={{ color: "#fff", textTransform: "capitalize" }}
      >
        Add Customer
      </Button> */}
    </Box>
  );
};

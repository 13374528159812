import {
  Box,
  Button,
  CircularProgress,
  Fade,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { ArrowBack } from "@mui/icons-material";
import { styles } from "../../common/css/styles";
import { CardTop } from "./components/CardTop";
import { useDispatch, useSelector } from "react-redux";
import { stage } from "../kyc/stages";
import { Personal } from "../kyc/stages/Personal";
import { Residence } from "../kyc/stages/Residence";
import { Employment } from "../kyc/stages/Employment";
import { Bank } from "../kyc/stages/Bank";
import { NextOfKin } from "../kyc/stages/NextOfKin";
import { Photo } from "../kyc/stages/Photo";
import { useNavigate, useParams } from "react-router-dom";
import { getKycInfo, resetKyc } from "../kyc/kycSlice";
import { WhiteBox } from "../../common/components/WhiteBox";
import { SectionHeader } from "../../common/components/SectionHeader";
import { Reupload } from "./kyc/Reupload";
import { Documents } from "../kyc/stages/Documents";
import { customerStage } from "./constants/customerStage";
import { clearCustomer, fetchCustomer } from "./store/customerSlice";
import { Wallet } from "../kyc/stages/Wallet";

export const CustomerKYC = () => {
  const [currentStage, setCurrentStage] = useState(stage.PERSONAL);
  const customer = useSelector((state) => state.customer);
  const customerData = customer.customer;
  const kycData = useSelector(({ kyc }) => kyc.data);
  const bvnData = kycData && JSON.parse(kycData?.bvnDetails);
  const ninData = kycData && JSON.parse(kycData?.nationalIdDetails);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { uid } = useParams();

  const getKYCStage = () => {
    switch (currentStage) {
      case stage.PERSONAL:
        return <Personal customer={customerData} />;
      case stage.RESIDENCE:
        return <Residence customer={customerData} data={kycData} />;
      case stage.EMPLOYMENT:
        return <Employment customer={customerData} data={kycData} />;
      case stage.BANK:
        return <Bank customer={customerData} data={kycData} />;
      case stage.NEXT_OF_KIN:
        return <NextOfKin customer={customerData} data={kycData} />;
      case stage.WALLET:
        return <Wallet customer={customerData} data={kycData} />;
      case customerStage.DOCUMENTS:
        return (
          <Documents
            customer={customerData}
            kycData={kycData}
            bvnData={bvnData}
            ninData={ninData}
          />
        );
      case customerStage.REUPLOAD:
        return <Reupload customer={customerData} data={kycData} />;
      case customerStage.PHOTO:
        return <Photo data={kycData} />;
      default:
        return <Personal customer={customerData} />;
    }
  };

  const handleBackToTable = () => navigate("/customers/accounts");
  const handleTabChange = (stage) => setCurrentStage(stage);

  useEffect(() => {
    dispatch(fetchCustomer(uid));
    dispatch(getKycInfo(uid));

    return () => {
      dispatch(clearCustomer());
      dispatch(resetKyc());
    };
  }, []);

  return (
    <>
      <SectionHeader
        headerText={
          <Typography sx={{ fontWeight: 700, fontSize: 32 }}>
            <Tooltip
              title="Back to Customers' Table"
              arrow
              placement="top-start"
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
            >
              <IconButton
                aria-label="back"
                onClick={handleBackToTable}
                sx={{ mb: 0.8 }}
              >
                <ArrowBack sx={{ fontSize: 30, color: "#000" }} />
              </IconButton>
            </Tooltip>
            Customer Account Verification
          </Typography>
        }
      />
      <WhiteBox>
        {customer.loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "70vh",
            }}
          >
            <CircularProgress sx={{ alignItems: "center" }} />
          </Box>
        ) : (
          <Box sx={{ p: 4 }}>
            {/* Top */}
            <CardTop customer={customerData} />
            {/* Section Selectors */}
            <Box sx={{ mt: 1, borderBottom: 1, borderBottomColor: "#F0F1ED" }}>
              <Button
                onClick={() => handleTabChange(stage.PERSONAL)}
                sx={
                  currentStage === stage.PERSONAL
                    ? styles.customerAccountVerification.sectionButton.selected
                    : styles.customerAccountVerification.sectionButton
                        .notSelected
                }
              >
                Personal
              </Button>
              <Button
                onClick={() => handleTabChange(stage.RESIDENCE)}
                sx={
                  currentStage === stage.RESIDENCE
                    ? styles.customerAccountVerification.sectionButton.selected
                    : styles.customerAccountVerification.sectionButton
                        .notSelected
                }
              >
                Residence
              </Button>
              <Button
                onClick={() => handleTabChange(stage.EMPLOYMENT)}
                sx={
                  currentStage === stage.EMPLOYMENT
                    ? styles.customerAccountVerification.sectionButton.selected
                    : styles.customerAccountVerification.sectionButton
                        .notSelected
                }
              >
                Employment
              </Button>
              <Button
                onClick={() => handleTabChange(stage.BANK)}
                sx={
                  currentStage === stage.BANK
                    ? styles.customerAccountVerification.sectionButton.selected
                    : styles.customerAccountVerification.sectionButton
                        .notSelected
                }
              >
                Bank
              </Button>
              <Button
                onClick={() => handleTabChange(stage.NEXT_OF_KIN)}
                sx={
                  currentStage === stage.NEXT_OF_KIN
                    ? styles.customerAccountVerification.sectionButton.selected
                    : styles.customerAccountVerification.sectionButton
                        .notSelected
                }
              >
                Next of Kin
              </Button>

              <Button
                onClick={() => handleTabChange(customerStage.PHOTO)}
                sx={
                  currentStage === customerStage.PHOTO
                    ? styles.customerAccountVerification.sectionButton.selected
                    : styles.customerAccountVerification.sectionButton
                        .notSelected
                }
              >
                Photo
              </Button>
              <Button
                onClick={() => handleTabChange(customerStage.DOCUMENTS)}
                sx={
                  currentStage === customerStage.DOCUMENTS
                    ? styles.customerAccountVerification.sectionButton.selected
                    : styles.customerAccountVerification.sectionButton
                        .notSelected
                }
              >
                Documents
              </Button>
              {/* {customerData?.status === customerFilters.PENDING ? ( */}
              <Button
                onClick={() => handleTabChange(customerStage.REUPLOAD)}
                sx={
                  currentStage === customerStage.REUPLOAD
                    ? styles.customerAccountVerification.sectionButton.selected
                    : styles.customerAccountVerification.sectionButton
                        .notSelected
                }
              >
                Reupload
              </Button>
              {/* ) : null} */}
              <Button
                onClick={() => handleTabChange(stage.WALLET)}
                sx={
                  currentStage === stage.WALLET
                    ? styles.customerAccountVerification.sectionButton.selected
                    : styles.customerAccountVerification.sectionButton
                        .notSelected
                }
              >
                Wallet
              </Button>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                //   alignItems: "normal",
              }}
            >
              {/* stages  */}
              {getKYCStage()}
            </Box>
          </Box>
        )}
      </WhiteBox>
    </>
  );
};

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { styles } from "../../../common/css/styles";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { OTPSuccess } from "./OTPSuccess";
import { OTPFailed } from "./OTPFailed";
import { clearFeedback, sendOTPFromAdmin } from "../../auth/authSlice";
import { feedback } from "../../../common/constants/feedback";
import { LoadingButton } from "@mui/lab";

export const ProceedAddDevice = ({ open, handleClose, customer }) => {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const [openSentOTP, setopenSentOTP] = useState(false);

  const handleSendOTP = () => {
    handleClose();
    dispatch(
      sendOTPFromAdmin({
        recipient: customer.email,
        operation: "ADD_MOBILE_DEVICE",
        // channel: "SMS",
      })
    );
  };

  useEffect(() => {
    if (auth.feedback === feedback.SUCCESSFUL) {
      setopenSentOTP(true);
      dispatch(clearFeedback());
    }
  }, [auth.feedback]);

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            //   alignItems: "center",
            width: "500px",
          }}
        >
          <DialogContent>
            <Box
              sx={{ width: "280px", display: "flex", justifyContent: "center" }}
            ></Box>
            <DialogContentText>
              <Typography sx={{ fontSize: 17, fontWeight: 500 }}>
                Authorize
              </Typography>
              <Typography
                sx={{ fontSize: 14, fontWeight: 500, color: "#969997" }}
              >
                Proceed to send OTP to authorize customer to add a mobile device
                to their account
              </Typography>
            </DialogContentText>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                mt: 2,
              }}
            >
              <Button
                variant="contained"
                onClick={handleClose}
                sx={{
                  ...styles.bridgeCardTop.error,
                  fontSize: 11,
                  px: 1,
                  mx: 1,
                }}
              >
                Cancel
              </Button>
              <LoadingButton
                variant="contained"
                sx={{ ...styles.bridgeCardTop.success, fontSize: 11, px: 1 }}
                onClick={handleSendOTP}
                loading={auth.otpSent === feedback.PENDING}
              >
                Send OTP
              </LoadingButton>
            </Box>
          </DialogContent>
        </Box>
      </Dialog>
      <OTPSuccess
        open={openSentOTP}
        handleClose={() => setopenSentOTP(false)}
      />
      {/* <OTPFailed open={openSentOTP} handleClose={() => setopenSentOTP(false)} /> */}
    </>
  );
};

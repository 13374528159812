import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
} from "@mui/material";
import { styles } from "../../common/css/styles";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { bridgeFinanceFilters } from "./constants/bridgeFinanceFilters";
import { DeadState } from "../../common/components/DeadState";
import { PreventOfferAttempt } from "./components/PreventOfferAttempt";
import { PageNavigation } from "../../common/components/PageNavigation";
import { ProceedToRecommend } from "./components/ProceedToRecommend";
import { ProceedToOffer } from "./components/ProceedToOffer";
import { DenyLoanView } from "./components/DenyLoanView";
import { fetchBridgeLoan } from "./store/bridgeFinanceLoanSlice";

const getStatusDisplay = (status) => {
  switch (status) {
    case bridgeFinanceFilters.PENDING_RECOMMENDATION:
      return (
        <Typography sx={styles.bridgeTable.status.pendingVerification}>
          Pending Recommendation
        </Typography>
      );
    case bridgeFinanceFilters.PENDING_OFFER:
      return (
        <Typography sx={styles.bridgeTable.status.pendingOffer}>
          Pending Offer
        </Typography>
      );
    case bridgeFinanceFilters.PENDING_LIEN:
      return (
        <Typography sx={styles.bridgeTable.status.pendingLien}>
          Pending Lien
        </Typography>
      );
    case bridgeFinanceFilters.DECLINED:
      return (
        <Typography sx={styles.bridgeTable.status.declined}>
          Declined
        </Typography>
      );
    case bridgeFinanceFilters.OFFERED:
      return (
        <Typography sx={styles.bridgeTable.status.offered}>Offered</Typography>
      );
    case bridgeFinanceFilters.PAY_INTEREST:
      return (
        <Typography sx={styles.bridgeTable.status.payInterest}>
          Pay Interest
        </Typography>
      );
    case bridgeFinanceFilters.ACTIVE:
      return (
        <Typography sx={styles.bridgeTable.status.active}>Active</Typography>
      );
    case bridgeFinanceFilters.DEFAULTING:
      return (
        <Typography sx={styles.bridgeTable.status.defaulting}>
          Defaulting
        </Typography>
      );
    case bridgeFinanceFilters.NTU:
      return <Typography sx={styles.bridgeTable.status.ntu}>NTU</Typography>;
    case bridgeFinanceFilters.OFFER_REJECTED:
      return (
        <Typography sx={styles.bridgeTable.status.rejected}>
          Offer Rejected
        </Typography>
      );
    case bridgeFinanceFilters.COMPLETED:
      return (
        <Typography sx={styles.bridgeTable.status.active}>Completed</Typography>
      );
    case bridgeFinanceFilters.PENDING_DISBURSEMENT:
      return (
        <Typography sx={styles.bridgeTable.status.offered}>
          Pending Disbursement
        </Typography>
      );
    default:
      break;
  }
};

export const BridgeTable = ({
  bridgeLoans,
  filterOption,
  page,
  handlePageChange,
}) => {
  const [openProceedToRecommend, setOpenProceedToRecommend] = useState(false);
  const [openProceedToOffer, setOpenProceedToOffer] = useState(false);
  const [openPreventOfferAttempt, setOpenPreventOfferAttempt] = useState(false);
  const [openDenyLoanView, setOpenDenyLoanView] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loans = bridgeLoans.loans;
  const user = useSelector(({ auth }) => auth.user);
  // const role = user?.roles[0]?.name;

  const columns = [
    { id: "uid", label: "UID", minWidth: 20, visible: true },
    { id: "recommender", label: "Recommender", minWidth: 20, visible: true },
    { id: "offerer", label: "Offerer", minWidth: 20, visible: true },
    { id: "firstName", label: "First Name", minWidth: 20, visible: true },
    { id: "lastName", label: "Last Name", minWidth: 20, visible: true },
    {
      id: "email",
      label: "Email",
      minWidth: 20,
      visible: filterOption === bridgeFinanceFilters.ALL ? true : false,
    },
    // {
    //   id: "phoneNo",
    //   label: "Phone Number",
    //   minWidth: 20,
    //   visible: filterOption === bridgeFinanceFilters.ALL ? true : false,
    // },
    { id: "amount", label: "Amount (₦)", minWidth: 20, visible: true },
    // {
    //   id: "tenure",
    //   label: "Tenure (months)",
    //   align: "center",
    //   minWidth: 20,
    //   visible: true,
    // },
    {
      id: "status",
      label: "Status",
      minWidth: 20,
      visible: filterOption === bridgeFinanceFilters.ALL ? true : false,
    },
    {
      id: "view",
      label: "View",
      minWidth: 20,
      visible: true,
    },
  ];

  const handleView = (uid) => {
    dispatch(fetchBridgeLoan(uid));

    const loan = loans.find((loan) => loan.uid === uid);

    let assigned = false;
    if (loan?.status === bridgeFinanceFilters.PENDING_RECOMMENDATION) {
      assigned = !!loan.recommender;
      if (!assigned) setOpenProceedToRecommend(true);
      else navigate(`/bridge-finance/kyc/${loan.uid}`);
    } else if (loan?.status === bridgeFinanceFilters.PENDING_OFFER) {
      assigned = !!loan.offerer;
      if (!assigned && loan.recommender?.uid === user.uid)
        setOpenPreventOfferAttempt(true);
      else if (!assigned && loan.recommender?.uid !== user.uid)
        setOpenProceedToOffer(true);
      else navigate(`/bridge-finance/kyc/${loan.uid}`);
    } else {
      navigate(`/bridge-finance/kyc/${loan.uid}`);
    }
    // if (loan?.status === bridgeFinanceFilters.PENDING_RECOMMENDATION) {
    //   assigned = !!loan.recommender;
    //   if (!assigned) {
    //     setOpenProceedToRecommend(true);
    //   } else if (assigned && loan.recommender?.uid === user.uid) {
    //     navigate(`/bridge-finance/kyc/${loan.uid}`, {
    //       state: { option: loanOptions.RECOMMEND },
    //     });
    //   } else {
    //     setOpenAlreadyAssigned(true);
    //   }
    // }

    // if (loan?.status === bridgeFinanceFilters.PENDING_OFFER) {
    //   assigned = !!loan.offerer;
    //   if (!assigned) {
    //     setOpenProceedToOffer(true);
    //   } else if (assigned && loan.offerer?.uid === user.uid) {
    //     navigate(`/bridge-finance/kyc/${loan.uid}`, {
    //       state: { option: loanOptions.MAKE_OFFER },
    //     });
    //   } else {
    //     setOpenAlreadyAssigned(true);
    //   }
    // }

    // if (loan?.status === bridgeFinanceFilters.OFFERED) {
    //   navigate(`/bridge-finance/kyc/${loan.uid}`, {
    //     state: { option: loanOptions.MAKE_OFFER },
    //   });
    // }

    // if (loan?.status === bridgeFinanceFilters.PENDING_LIEN) {
    //   navigate(`/bridge-finance/kyc/${loan.uid}`, {
    //     state: { option: loanOptions.PENDING_LIEN },
    //   });
    // }

    // if (loan?.status === bridgeFinanceFilters.PENDING_DISBURSEMENT) {
    //   navigate(`/bridge-finance/kyc/${loan.uid}`, {
    //     state: { option: loanOptions.PENDING_DISBURSEMENT },
    //   });
    // }

    // if (loan?.status === bridgeFinanceFilters.ACTIVE) {
    //   // navigate(`/bridge-finance/kyc/${loan.uid}`);
    //   if (
    //     loan.recommender?.uid === user.uid ||
    //     loan.offerer?.uid === user.uid
    //   ) {
    //     navigate(`/bridge-finance/kyc/${loan.uid}`);
    //   } else {
    //     setOpenDenyLoanView(true);
    //   }
    // }
  };

  return (
    <Box>
      <TableContainer
        component={Paper}
        sx={{
          maxHeight: 400,
          boxShadow: "none",
          mt: 2,
        }}
      >
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow
              sx={{
                "& .MuiTableCell-head": {
                  color: "#000",
                  backgroundColor: "#fff",
                  fontSize: 13,
                  borderTop: 0.5,
                  borderBottom: 0.5,
                  borderColor: "#B5B5B5",
                  py: 2,
                },
              }}
            >
              {columns
                .filter((column) => column.visible === true)
                .map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    <Typography
                      noWrap
                      sx={{
                        color: "#000",
                        backgroundColor: "#fff",
                        fontSize: 13,
                        fontWeight: 600,
                      }}
                    >
                      {column.label}
                    </Typography>
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {bridgeLoans.loading ? (
              <TableCell
                colSpan={columns.length}
                sx={{ textAlign: "center", borderBottom: 0, pt: 4 }}
              >
                <CircularProgress />
              </TableCell>
            ) : loans.length > 0 ? (
              loans.map((loan) => (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={loan.uid}
                  sx={{
                    "& .MuiTableCell-body": {
                      color: "#000",
                      backgroundColor: "#fff",
                      border: "none",
                    },
                  }}
                >
                  <TableCell align="left">
                    <Typography sx={styles.table.text}>{loan.uid}</Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography sx={styles.table.text}>
                      {loan.recommender
                        ? `${loan.recommender.firstName} ${loan.recommender.lastName}`
                        : "Unassigned"}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography sx={styles.table.text}>
                      {loan.offerer
                        ? `${loan.offerer.firstName} ${loan.offerer.lastName}`
                        : "Unassigned"}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography sx={styles.table.text}>
                      {loan.user.firstName}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography sx={styles.table.text}>
                      {loan.user.lastName}
                    </Typography>
                  </TableCell>
                  {columns.find((column) => column.id === "status").visible ? (
                    <TableCell align="left">
                      <Typography
                        sx={{
                          ...styles.table.text,
                          textTransform: "lowercase",
                        }}
                      >
                        {loan.user.email}
                      </Typography>
                    </TableCell>
                  ) : null}
                  {/* {columns.find((column) => column.id === "status").visible ? (
                    <TableCell align="left">
                      <Typography
                        textTransform="lowercase"
                        sx={styles.table.text}
                      >
                        {loan.user.phoneNumber}
                      </Typography>
                    </TableCell>
                  ) : null} */}
                  <TableCell align="left">
                    <Typography sx={styles.table.text}>
                      {parseFloat(loan.loanAmount).toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                      })}
                    </Typography>
                  </TableCell>
                  {/* <TableCell align="left">
                    <Typography
                      sx={{
                        ...styles.table.text,
                        textTransform: "lowercase",
                        justifyContent: "center",
                      }}
                    >
                      {loan.loanTenure}
                    </Typography>
                  </TableCell> */}

                  {columns.find((column) => column.id === "status").visible ? (
                    <TableCell align="left">
                      <Typography noWrap sx={styles.table.text}>
                        {getStatusDisplay(loan.status)}
                      </Typography>
                    </TableCell>
                  ) : null}
                  {columns.find((column) => column.id === "view").visible ? (
                    <TableCell align="left">
                      <Button
                        variant="outlined"
                        onClick={() => handleView(loan.uid)}
                        sx={styles.table.text.viewButton}
                      >
                        View
                      </Button>
                    </TableCell>
                  ) : null}
                </TableRow>
              ))
            ) : (
              <TableCell colSpan={columns.length} sx={{ textAlign: "center" }}>
                <DeadState />
              </TableCell>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {!bridgeLoans.loading && loans.length > 0 ? (
        <PageNavigation
          page={page}
          totalRecords={bridgeLoans.totalRecords}
          handlePageChange={handlePageChange}
        />
      ) : (
        <></>
      )}
      <ProceedToRecommend
        open={openProceedToRecommend}
        handleClose={() => setOpenProceedToRecommend(false)}
      />
      <ProceedToOffer
        open={openProceedToOffer}
        handleClose={() => setOpenProceedToOffer(false)}
      />

      {/* <ProceedToAction
        open={openProceedToAction}
        handleClose={() => setOpenProceedToAction(false)}
        role={role}
      /> */}
      <PreventOfferAttempt
        open={openPreventOfferAttempt}
        handleClose={() => setOpenPreventOfferAttempt(false)}
      />
      <DenyLoanView
        open={openDenyLoanView}
        handleClose={() => setOpenDenyLoanView(false)}
      />
    </Box>
  );
};

import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { styles } from "../../../common/css/styles";
import { BankStatementRequest } from "../components/BankStatmentRequest";

export const BankStatement = ({ customer, data }) => {
  const navigate = useNavigate();
  const [showBankStatement, setBankStatement] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const openDialog = () => {
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  const hadleComplete = () => {
    setIsDialogOpen(false);
    setBankStatement(true);
  };

  return (
    <>
      <Box
        sx={{
          // display: "flex",
          justifyContent: "space-between",
          mt: 4,
          // width: "70%",
          // width: customer?.status === customerFilters.PENDING ? "100%" : "70%",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ py: 1 }}>
            <Typography sx={styles.kyc.headerText}>Bank Name</Typography>
            <Typography sx={styles.kyc.bodyText}>Longitech</Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ py: 1 }}>
            <Typography sx={styles.kyc.headerText}>Account Name</Typography>
            <Typography
              sx={{ ...styles.kyc.bodyText, textTransform: "lowercase" }}
            >
              Charles Bradenton
            </Typography>
          </Box>
        </Box>

        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 3 }}>
          <Box sx={{ py: 1 }}>
            <Typography sx={styles.kyc.headerText}>Account Name</Typography>
            <Typography
              sx={{ ...styles.kyc.bodyText, textTransform: "lowercase" }}
            >
              Charles Bradenton
            </Typography>
          </Box>
        </Box>
        <Box>
          {!showBankStatement ? (
            <Button
              variant="contained"
              onClick={openDialog}
              sx={{
                boxShadow: "none",
                color: "#fff",
                textTransform: "capitalize",
                px: 4,
                py: 1,
                display: "flex",
                fontSize: 12,
                fontWeight: 600,
                borderRadius: 1,
              }}
            >
              Request Bank Statement
            </Button>
          ) : (
            <Box sx={{ py: 1 }}>
              <Typography sx={styles.kyc.headerText}>Bank Statement</Typography>
              <Typography
                sx={{
                  fontSize: 12,
                  fontWeight: 600,
                  mt: 1,
                  backgroundColor: "#19AD421A",
                  width: "80%",
                  textAlign: "center",
                  py: 2,
                  px: 4,
                  borderRadius: 2,
                  display: "flex",
                  // color: "primary.main",
                }}
              >
                20230101.pdf
              </Typography>
            </Box>
          )}
        </Box>

        <BankStatementRequest
          open={isDialogOpen}
          handleClose={closeDialog}
          customer={customer}
          hadleComplete={hadleComplete}
        />
      </Box>
    </>
  );
};

import React, { useState } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Box,
  TableContainer,
  Paper,
  Typography,
  CircularProgress,
} from "@mui/material";
import { Delete, Edit, MoreVert } from "@mui/icons-material";
import { styles } from "../../common/css/styles";
import { DeadState } from "../../common/components/DeadState";
import { EditInterestRate } from "./components/EditInterestRate";
import { useDispatch } from "react-redux";
import { setInterestRate, setRate } from "./settingsSlice";
import { InfoDialog } from "../../common/components/InfoDialog";

export const InterestRateTable = ({ settings, handleEdit }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [openInfoDialog, setOpenInfoDialog] = useState(false);
  const [infoText, setInfoText] = useState("");
  const [isError, setIsError] = useState(false);
  const dispatch = useDispatch();
  const interestRates = settings.interestRates;

  const handleClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(id);
  };

  const handleDialogOpen = (rate) => {
    dispatch(setRate(rate));
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleInfoDialogClose = () => {
    setOpenInfoDialog(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const handleDelete = (uid) => {
    const newInterestRates = interestRates.filter((x) => x.uid !== uid);
    dispatch(
      setInterestRate({
        loanType: "Retail",
        interestRates: newInterestRates,
      })
    );
    setOpenInfoDialog(true);
  };

  const columns = [
    { id: "lower", label: "Lower Bound", minWidth: "20vw", visible: true },
    { id: "upper", label: "Upper Bound", minWidth: "20vw", visible: true },
    { id: "lastName", label: "Interest Rate", minWidth: "20vw", visible: true },
    { id: "edit", label: "", minWidth: 10, visible: true },
    { id: "delete", label: "", minWidth: 10, visible: true },
  ];

  return (
    <>
      <Box>
        <TableContainer
          component={Paper}
          sx={{
            maxHeight: 400,
            boxShadow: "none",
            mt: 2,
          }}
        >
          <Table size="small">
            <TableHead>
              <TableRow
                sx={{
                  "& .MuiTableCell-head": {
                    color: "#000",
                    backgroundColor: "#fff",
                    fontSize: 13,
                    borderTop: 0.5,
                    borderBottom: 0.5,
                    borderColor: "#B5B5B5",
                    py: 2,
                  },
                }}
              >
                {columns
                  .filter((column) => column.visible === true)
                  .map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      <Typography
                        noWrap
                        sx={{
                          color: "#000",
                          backgroundColor: "#fff",
                          fontSize: 13,
                          fontWeight: 600,
                        }}
                      >
                        {column.label}
                      </Typography>
                    </TableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {settings.loading ? (
                <TableCell
                  colSpan={columns.length}
                  sx={{ textAlign: "center", borderBottom: 0, pt: 4 }}
                >
                  <CircularProgress />
                </TableCell>
              ) : interestRates.length > 0 ? (
                interestRates.map((rate, index) => (
                  <TableRow
                    key={index}
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    sx={{
                      "& .MuiTableCell-body": {
                        color: "#000",
                        backgroundColor: "#fff",
                        border: "none",
                      },
                    }}
                  >
                    <TableCell align="left">
                      <Typography sx={styles.table.text}>
                        {rate?.lowerBound?.toLocaleString("en-NG", {
                          style: "currency",
                          currency: "NGN",
                        })}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography sx={styles.table.text}>
                        {rate?.upperBound?.toLocaleString("en-NG", {
                          style: "currency",
                          currency: "NGN",
                        })}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography sx={styles.table.text}>
                        {rate.interestRate}%
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <IconButton
                        onClick={() => {
                          handleDialogOpen(rate);
                        }}
                        size="small"
                      >
                        <Edit fontSize="small" />
                      </IconButton>
                    </TableCell>
                    <TableCell align="left">
                      <IconButton
                        onClick={() => handleDelete(rate.uid)}
                        size="small"
                      >
                        <Delete fontSize="small" />
                      </IconButton>
                    </TableCell>

                    {/* <TableCell sx={{ textAlign: "center" }}>
                    <IconButton
                      aria-label="menu"
                      aria-controls={`menu-${rate.id}`}
                      aria-haspopup="true"
                      onClick={(event) => handleClick(event, rate.id)}
                    >
                      <Edit />
                    </IconButton>
                    <IconButton
                      aria-label="menu"
                      aria-controls={`menu-${rate.id}`}
                      aria-haspopup="true"
                      onClick={(event) => handleClick(event, rate.id)}
                    >
                      <Delete sx={{ color: "#D41818" }} />
                    </IconButton>
                    <Menu
                    id={`menu-${row.id}`}
                    anchorEl={anchorEl}
                    open={selectedRow === row.id}
                    onClose={handleClose}
                  >
                    <MenuItem onClick={handleClose}>View</MenuItem>
                    <MenuItem onClick={handleClose}>Edit</MenuItem>
                    <MenuItem onClick={handleClose}>Delete</MenuItem>
                  </Menu>
                  </TableCell> */}
                  </TableRow>
                ))
              ) : (
                <TableCell
                  colSpan={columns.length}
                  sx={{ textAlign: "center" }}
                >
                  <DeadState />
                </TableCell>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <EditInterestRate open={dialogOpen} handleClose={handleDialogClose} />
      <InfoDialog
        open={openInfoDialog}
        handleClose={handleInfoDialogClose}
        text="Interest rate deleted successfully"
        error={isError}
      />
    </>
  );
};

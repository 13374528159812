/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import { DeadState } from "../../../../common/components/DeadState";
import { styles } from "../../../../common/css/styles";
import { useNavigate } from "react-router-dom";
import { PageNavigation } from "../../../../common/components/PageNavigation";
import { format } from "date-fns";
import { getSmeLoanHistory } from "../../../../common/api/loans";
import { useQuery } from "@tanstack/react-query";
import {
  formatCurrencyvalue,
  getLoanStatusDisplay,
} from "../../../../common/util";

export const LoanHistorySME = ({ businessId }) => {
  const [page, setPage] = useState(0);
  const { data, isLoading } = useQuery({
    queryKey: [businessId, page, "smehistory"],
    queryFn: () => getSmeLoanHistory({ businessId, page: page + 1 }),
    refetchOnWindowFocus: false,
  });
  const { loans, totalRecords } = data?.data?.data ?? {};

  const navigate = useNavigate();

  const handlePageChange = (event, newPage) => {
    setPage(newPage - 1);
    // dispatch(fetchRetailLoanHistory({ userUid: customer.uid, page: newPage }));
  };

  const handleView = (loan) => {
    const { type, uid } = loan;
    const loanType =
      type === "BUSINESS" || type === "ASSET_FINANCING" ? "sme" : "retail";
    navigate(`/loans/loan-preview/${loanType}/${uid}`);
  };

  const columns = useMemo(
    () => [
      {
        id: "loanOfferAmount",
        label: "Loan Amount",
        minWidth: 50,
        visible: true,
        render: (loan) => formatCurrencyvalue(loan.loanOfferAmount),
      },
      {
        id: "loanOfferTenure",
        label: "Tenure",
        minWidth: 50,
        visible: true,
        render: (loan) =>
          loan?.loanOfferTenure ? (
            <Typography sx={{ whiteSpace: "nowrap" }}>
              {loan.loanOfferTenure === 1
                ? `${loan.loanOfferTenure} month`
                : `${loan.loanOfferTenure} months`}
            </Typography>
          ) : (
            "Not available"
          ),
      },
      {
        id: "date",
        label: "Date",
        minWidth: 50,
        visible: true,
        render: (loan) =>
          loan.createdAt &&
          format(new Date(loan.createdAt), "dd/MM/yyyy hh:mm:ss aa"),
      },
      {
        id: "loanOfferTotalInterest",
        label: "Total Interest",
        minWidth: 50,
        visible: true,
        render: (loan) => formatCurrencyvalue(loan.loanOfferTotalInterest),
      },
      {
        id: "paid",
        label: "Total Repayment",
        minWidth: 50,
        visible: true,
        render: (loan) => formatCurrencyvalue(loan.loanOfferTotalRepayment),
      },
      {
        id: "status",
        label: "Loan Status",
        minWidth: 20,
        visible: true,

        render: (loan) => getLoanStatusDisplay(loan.status),
      },

      {
        id: "view",
        label: "View",
        minWidth: 50,
        visible: true,
        render: (loan) => (
          <Button
            variant="outlined"
            onClick={() => handleView(loan)}
            sx={styles.table.text.viewButton}
          >
            View
          </Button>
        ),
      },
    ],
    []
  );

  return (
    <>
      <Box
        sx={{
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            px: 2,
            py: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              pb: 2,
            }}
          >
            {/* <Button
                onClick={handleAddButtonClick}
                //   variant="outlined"
                sx={{
                  height: 29,
                  border: 1,
                  color: "#494D4A",
                  borderColor: "#61666333",
                  mt: 2.5,
                  textTransform: "capitalize",
                  fontSize: 12,
                }}
              >
                <Add sx={{ fontSize: 12, pr: 1 }} /> Add
              </Button> */}
          </Box>
          <Paper
            sx={{
              width: "100%",
              overflow: "hidden",
              border: "none",
              boxShadow: "none",
            }}
          >
            <TableContainer sx={{ maxHeight: 400 }}>
              <Table
                stickyHeader
                aria-label="sticky table"
                sx={{ backgroundColor: "transparent" }}
              >
                <TableHead>
                  <TableRow
                    sx={{
                      "& .MuiTableCell-head": {
                        color: "#000",
                        backgroundColor: "#FFF",
                        border: "4px solid black",
                      },
                    }}
                  >
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{
                          minWidth: column.minWidth,
                          borderTop: "1px solid #B5B5B5",
                          borderLeft: "none",
                          borderRight: "none",
                          borderBottom: "1px solid #B5B5B5",
                          fontSize: 13,
                          fontWeight: 600,
                          height: 20,
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isLoading ? (
                    <TableCell
                      colSpan={columns.length}
                      sx={{ textAlign: "center", borderBottom: 0, pt: 4 }}
                    >
                      <CircularProgress />
                    </TableCell>
                  ) : loans ? (
                    loans?.map((loan, i) => (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={loan.uid}
                        sx={{
                          "& .MuiTableCell-body": {
                            color: "#62635E",
                            backgroundColor: "#fff",
                            border: "none",
                            fontSize: 13,
                            fontWeight: 400,
                          },
                        }}
                      >
                        {columns.map((v, i) => (
                          <TableCell key={i} align="left">
                            <Typography sx={styles.table.text.uid}>
                              {v.render ? v.render(loan) : loan[v.id]}
                            </Typography>
                          </TableCell>
                        ))}
                      </TableRow>
                    ))
                  ) : (
                    <TableCell
                      colSpan={columns.length}
                      sx={{ textAlign: "center" }}
                    >
                      <DeadState />
                    </TableCell>
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            {!isLoading && (
              <PageNavigation
                page={page}
                totalRecords={totalRecords}
                handlePageChange={handlePageChange}
              />
            )}
          </Paper>
        </Box>
      </Box>
    </>
  );
};

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  type: null,
  message: "",
  open: false,
  timeOut: 600,
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    displayMessage: (state, action) => {
      const { type, message, timeOut } = action.payload;
      return {
        type: type ?? "info",
        message,
        timeOut: timeOut ?? 600,
        open: true,
      };
    },
    displayErrorMessage: (state, action) => {
      const { message, timeOut } = action.payload;
      return {
        type: "error",
        message,
        timeOut: timeOut ?? 3000,
        open: true,
      };
    },
    hideMessage: (state, action) => {
      // state = { ...initialState };
      return initialState;
    },
  },
});

export const { displayMessage, hideMessage, displayErrorMessage } =
  notificationSlice.actions;
export default notificationSlice.reducer;

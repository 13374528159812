import { Box, Button } from "@mui/material";
import React, { useState } from "react";
import { styles } from "../../../../common/css/styles";
import { loansDetailsFilters } from "../../constants/retailFilters";
import { stage } from "./stages/index";
import { Bank } from "./stages/Bank";
import { NIN } from "./stages/NIN";
import { PersonalDetails } from "./stages/PersonalDetails";
import { Residence } from "./stages/Residence";
import { Employment } from "./stages/Employment";
import { NextOfKin } from "./stages/NextOfKin";

export const Personal = ({ customer, data }) => {
  const [activeFilter, setActiveFilter] = useState(
    loansDetailsFilters.PERSONAL
  );

  const filters = [
    { label: "Personal", value: stage.PERSONAL, visible: true },
    { label: "Residence", value: stage.RESIDENCE, visible: true },
    {
      label: "Employment",
      value: stage.EMPLOYMENT,
      visible: true,
    },
    { label: "Bank Info", value: stage.BANK, visible: true },
    {
      label: "Next Of Kin",
      value: stage.NEXT_OF_KIN,
      visible: true,
    },
    {
      label: "ID Documents",
      value: stage.IDENTIFICATION,
      visible: true,
    },
  ];
  const handleFilter = (filter) => {
    setActiveFilter(filter);
  };

  const getPersonalStage = () => {
    switch (activeFilter) {
      case stage.PERSONAL:
        return <PersonalDetails customer={customer} />;
      case stage.RESIDENCE:
        return <Residence data={data} />;
      case stage.EMPLOYMENT:
        return <Employment data={data} />;
      case stage.BANK:
        return <Bank customer={customer} data={data} />;
      case stage.IDENTIFICATION:
        return <NIN data={data} />;
      case stage.NEXT_OF_KIN:
        return <NextOfKin data={data} />;
      default:
        return <PersonalDetails customer={customer} />;
    }
  };

  return (
    <Box>
      <Box sx={{ py: 3, display: "flex" }}>
        {filters
          .filter((filter) => filter.visible)
          .map((filter) => (
            <Button
              onClick={() => handleFilter(filter.value)}
              variant="outlined"
              sx={
                activeFilter === filter.value
                  ? { ...styles.tableFilter.active, fontSize: 14, p: 2 }
                  : { ...styles.tableFilter.inactive, fontSize: 14, p: 2 }
              }
            >
              {filter.label}
            </Button>
          ))}
      </Box>
      {getPersonalStage()}
    </Box>
  );
};

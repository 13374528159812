import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { styles } from "../../../../../common/css/styles";

export const PersonalDetails = ({ customer }) => {
  return (
    <Box
      sx={{
        display: "flex",
        // justifyContent: "space-between",
        width: "100%",
      }}
    >
      <Box sx={{ mr: 25 }}>
        <Stack>
          <Box sx={{ py: 1 }}>
            <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
              First Name
            </Typography>
            <Typography sx={{ fontWeight: 700, color: "#494D4A" }}>
              {`${customer?.firstName
                ?.charAt(0)
                .toUpperCase()}${customer?.firstName?.substring(1)}`}
            </Typography>
          </Box>
          <Box sx={{ py: 1 }}>
            <Typography sx={styles.kyc.headerText}>Email Address</Typography>
            <Typography
              sx={{ ...styles.kyc.bodyText, textTransform: "lowercase" }}
            >
              {customer?.email}
            </Typography>
          </Box>
          <Box sx={{ py: 1 }}>
            <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
              DOB
            </Typography>
            <Typography sx={styles.kyc.bodyText}>
              {customer?.dateOfBirth?.replaceAll("-", "/")}
            </Typography>
          </Box>
        </Stack>
      </Box>
      <Box>
        <Stack>
          <Box sx={{ py: 1 }}>
            <Typography sx={styles.kyc.headerText}>Last Name</Typography>
            <Typography sx={styles.kyc.bodyText}>
              {`${customer?.lastName
                ?.charAt(0)
                .toUpperCase()}${customer?.lastName?.substring(1)}`}
            </Typography>
          </Box>
          <Box sx={{ py: 1 }}>
            <Typography sx={styles.kyc.headerText}>Phone Number</Typography>
            <Typography sx={styles.kyc.bodyText}>
              {customer?.phoneNumber}
            </Typography>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};

import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { Grid, Typography } from "@mui/material";
import { Edit } from "@mui/icons-material";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { styles } from "../../../common/css/styles";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format, parse } from "date-fns";
import { phoneRegex } from "../../../common/constants/regex";
import { updatePersonal } from "../../customers/store/customerSlice";
import { clearKycFeedback } from "../kycSlice";
import { feedback } from "../../../common/constants/feedback";
import { InfoDialog } from "../../../common/components/InfoDialog";
import { toTitleCase } from "../../../common/constants/utility";

const defaultValues = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  dateOfBirth: null,
};

const schema = yup.object().shape({
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name is required"),
  email: yup
    .string()
    .email("Please enter a valid email address")
    .required("Email address is required"),
  phoneNumber: yup
    .string()
    .matches(phoneRegex, "Enter a valid phone number")
    .required("Phone number is required"),
  dateOfBirth: yup
    .date()
    .transform(function (value, originalValue) {
      if (this.isType(value)) {
        return value;
      }
      const result = parse(originalValue, "MM/dd/yyyy", new Date());
      return result;
    })
    .typeError("Please enter a valid date")
    .required("Date of birth is required"),
});

export const PersonalEdit = ({ open, handleClose, customer }) => {
  const [openSecondDialog, setOpenSecondDialog] = useState(false);
  const [isError, setIsError] = useState(false);
  const [infoText, setInfoText] = useState("");
  const kyc = useSelector((state) => state.kyc);
  const dispatch = useDispatch();

  const handleCloseSecondDialog = () => {
    dispatch(clearKycFeedback());
    setOpenSecondDialog(false);
  };

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitSuccessful },
    reset,
    setValue,
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  const onSubmit = (data) => {
    dispatch(
      updatePersonal({
        ...data,
        dateOfBirth: format(data.dateOfBirth, "dd-MM-yyyy"),
        userUid: customer.uid,
      })
    );
    handleClose();
  };

  useEffect(() => {
    if (isSubmitSuccessful) reset();
  }, [isSubmitSuccessful]);

  useEffect(() => {
    if (kyc.feedback === feedback.UPDATED) {
      setInfoText("Personal details successfully updated.");
      setIsError(false);
      setOpenSecondDialog(true);
    }
    if (kyc.feedback === feedback.UPDATE_FAILED) {
      setInfoText(kyc?.error?.join("\n") ?? "Failed to update details");
      setIsError(true);
      setOpenSecondDialog(true);
    }
  }, [kyc.feedback]);

  useEffect(() => {
    if (customer) {
      setValue("firstName", `${toTitleCase(customer?.firstName)}`);
      setValue("lastName", `${toTitleCase(customer?.lastName)}`);
      setValue("email", customer?.email);
      setValue("phoneNumber", customer?.phoneNumber);
      setValue(
        "dateOfBirth",
        parse(customer?.dateOfBirth, "dd-MM-yyyy", new Date())
      );
    }
  }, [open]);

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          reset();
          handleClose();
        }}
      >
        <Grid
          py={2}
          sx={{ width: 500 }}
          component="form"
          noValidate
          onSubmit={handleSubmit(onSubmit)}
        >
          <DialogTitle sx={{ color: "#000" }}>
            Edit Personal Information
          </DialogTitle>

          <DialogContent>
            <Grid container spacing={2} direction="column" pr={2}>
              <Grid item>
                <Typography variant="body1" sx={styles.formFieldHeading}>
                  First Name
                </Typography>
                <Controller
                  name="firstName"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      required
                      variant="outlined"
                      fullWidth
                      size={styles.textfieldSize}
                      placeholder="e.g. John"
                      error={!!errors.firstName}
                      helperText={errors.firstName?.message}
                      sx={{
                        ...styles.textField,
                        "& fieldset": { width: "100%" },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item>
                <Typography variant="body1" sx={styles.formFieldHeading}>
                  Last Name
                </Typography>
                <Controller
                  name="lastName"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      required
                      variant="outlined"
                      fullWidth
                      size={styles.textfieldSize}
                      placeholder="e.g. Doe"
                      error={!!errors.lastName}
                      helperText={errors.lastName?.message}
                      sx={{
                        ...styles.textField,
                        "& fieldset": { width: "100%" },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item>
                <Typography variant="body1" sx={styles.formFieldHeading}>
                  Email Address
                </Typography>
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      required
                      variant="outlined"
                      fullWidth
                      size={styles.textfieldSize}
                      placeholder="e.g. johndoe@gmail.com"
                      error={!!errors.email}
                      helperText={errors.email?.message}
                      sx={{
                        ...styles.textField,
                        "& fieldset": { width: "100%" },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item>
                <Typography variant="body1" sx={styles.formFieldHeading}>
                  Phone Number
                </Typography>
                <Controller
                  name="phoneNumber"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      required
                      variant="outlined"
                      fullWidth
                      size={styles.textfieldSize}
                      placeholder="e.g. 12345678901"
                      error={!!errors.phoneNumber}
                      helperText={errors.phoneNumber?.message}
                      sx={{
                        ...styles.textField,
                        "& fieldset": { width: "100%" },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item>
                <Typography variant="body1" sx={styles.formFieldHeading}>
                  DOB
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Controller
                    name="dateOfBirth"
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        {...field}
                        inputFormat="MM/dd/yyyy"
                        sx={styles.datePicker}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            error={!!errors.dateOfBirth}
                            helperText={errors.dateOfBirth?.message}
                            fullWidth
                            // size={styles.textfieldSize}
                            autoComplete="off"
                            variant="outlined"
                            // sx={styles.datePicker}
                          />
                        )}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                reset();
                handleClose();
              }}
              sx={{
                textTransform: "capitalize",
                fontSize: 14,
                color: "#616663",
              }}
            >
              Close
            </Button>
            <Button
              variant="contained"
              type="submit"
              sx={{
                boxShadow: "none",
                color: "#fff",
                textTransform: "capitalize",
                px: 2,
                fontSize: 12,
                fontWeight: 600,
                borderRadius: 2,
                backgroundColor: "primary.main",
                mx: 2,
              }}
            >
              Edit <Edit sx={{ fontSize: 12, pl: 1 }} />
            </Button>
          </DialogActions>
        </Grid>
      </Dialog>

      {/* Second Dialog */}
      <InfoDialog
        open={openSecondDialog}
        handleClose={handleCloseSecondDialog}
        text={infoText}
        error={isError}
      />
    </>
  );
};

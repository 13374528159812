import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getLoans, searchLoans } from "../../../common/api/bridgeFinance";
import { handleError } from "../../../common/constants/utility";

const initialState = {
  loans: [],
  totalRecords: 0,
  loading: false,
  error: [],
};

export const filteredBridgeLoans = createAsyncThunk(
  "bridgeFinance/loans/filter",
  async ({ page, filterOption }, { dispatch, rejectWithValue }) => {
    try {
      const response = await getLoans(page, filterOption);
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);

export const searchBridgeLoans = createAsyncThunk(
  "bridgeFinance/loans/search",
  async ({ page, searchTerm }, { dispatch, rejectWithValue }) => {
    try {
      const response = await searchLoans(page, searchTerm);
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);

const bridgeFinanceLoansSlice = createSlice({
  name: "bridgeFinance/loans",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(filteredBridgeLoans.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(filteredBridgeLoans.fulfilled, (state, action) => {
      state.loans = action.payload.data.loans;
      state.totalRecords = action.payload.data.totalRecords;
      state.loading = false;
      state.error = [];
    });
    builder.addCase(filteredBridgeLoans.rejected, (state, action) => {
      state.loans = [];
      state.totalRecords = 0;
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(searchBridgeLoans.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(searchBridgeLoans.fulfilled, (state, action) => {
      state.loans = action.payload.data.loans;
      state.totalRecords = action.payload.data.totalRecords;
      state.loading = false;
      state.error = [];
    });
    builder.addCase(searchBridgeLoans.rejected, (state, action) => {
      state.loans = [];
      state.totalRecords = 0;
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default bridgeFinanceLoansSlice.reducer;

import { client } from ".";

const size = process.env.REACT_APP_PAGESIZE;

export const getTransactions = (page) => {
  return client.get(`transactions?page=${page}&size=${size}`);
};

export const getTransaction = (uid) => {
  return client.get(`transactions/${uid}`);
};

export const searchTransactions = (page, searchTerm) => {
  return client.get(
    `transactions?page=${page}&size=${size}&search=${searchTerm}`
  );
};

export const searchTransactionsByDate = ({
  page,
  searchTerm,
  startDate,
  endDate,
}) => {
  return client.get(
    `transactions/filter?page=${page}&size=${size}&search=${searchTerm}&startDate=${startDate}&endDate=${endDate}`
  );
};

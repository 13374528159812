import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { handleError } from "../../../../common/constants/utility";
import { getLoans, searchLoans } from "../../../../common/api/sme";

const initialState = {
  loans: [],
  totalRecords: 0,
  loading: false,
  error: [],
};

export const filteredSmeLoans = createAsyncThunk(
  "sme/loans/filter",
  async (model, { dispatch, rejectWithValue }) => {
    try {
      const response = await getLoans(model);
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);

export const searchBusinessLoans = createAsyncThunk(
  "sme/loans/search",
  async ({ page, searchTerm }, { dispatch, rejectWithValue }) => {
    try {
      const response = await searchLoans(page, searchTerm);
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);

const businessLoansSlice = createSlice({
  name: "sme/loans",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(filteredSmeLoans.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(filteredSmeLoans.fulfilled, (state, action) => {
      state.loans = action.payload.data.loans;
      state.totalRecords = action.payload.data.totalRecords;
      state.loading = false;
      state.error = [];
    });
    builder.addCase(filteredSmeLoans.rejected, (state, action) => {
      state.loans = [];
      state.totalRecords = 0;
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(searchBusinessLoans.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(searchBusinessLoans.fulfilled, (state, action) => {
      state.loans = action.payload.data.loans;
      state.totalRecords = action.payload.data.totalRecords;
      state.loading = false;
      state.error = "";
    });
    builder.addCase(searchBusinessLoans.rejected, (state, action) => {
      state.loans = [];
      state.totalRecords = 0;
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default businessLoansSlice.reducer;

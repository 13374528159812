/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Grid, Typography } from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import { formatCurrencyvalue } from "../../../common/util";
import { format } from "date-fns";

export const DisburseMentViewModal = ({ open, handleClose, data = {} }) => {
  const items = [
    {
      label: "Source",
      value: "Wallet",
    },
    {
      label: "Processor",
      value: data.provider,
    },
    {
      label: "Amount",
      value: data.disbursementAmount
        ? formatCurrencyvalue(data.disbursementAmount)
        : "N/A",
    },
    {
      label: "Beneficiary Account",
      value: data.accountNumber,
    },
    {
      label: "Account Name ",
      value: data.accountName,
    },
    {
      label: "Bank",
      value: data.bankName,
    },

    {
      label: "Transaction Date",
      value:
        data.disbursementDate &&
        format(new Date(data.disbursementDate), "dd/MM/yyyy hh:mm:ss aa"),
    },
    {
      label: "Narration",
      value: data.narration,
    },
  ];

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          handleClose();
        }}
      >
        <Grid
          component="form"
          noValidate
          // onSubmit={handleSubmit(onSubmit)}
          py={2}
          sx={{ width: 448 }}
        >
          <DialogTitle
            sx={{
              color: "#000",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={{ fontSize: 20, fontWeight: 500 }}>
              Disbursement Details
            </Typography>
            <CloseOutlined sx={{ cursor: "pointer" }} onClick={handleClose} />
          </DialogTitle>

          <DialogContent>
            {items.map((item, i) => (
              <Box
                key={i}
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <Typography sx={{ fontSize: 14, fontWeight: 400, pb: 1 }}>
                  {item.label}
                </Typography>
                <Typography
                  sx={{
                    fontSize: 14,
                    fontWeight: 400,
                    pb: 1,
                    color: "#000000DE",
                    ml: 2,
                    textAlign: "right",
                  }}
                >
                  {item.value}
                </Typography>
              </Box>
            ))}
          </DialogContent>
        </Grid>
      </Dialog>
    </>
  );
};

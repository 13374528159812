import { client } from ".";

const size = process.env.REACT_APP_PAGESIZE;

export const getCustomers = (page, filterBy) => {
  return client.get(`customers?page=${page}&size=${size}&filterBy=${filterBy}`);
};

export const getCustomer = (customerUid) => {
  return client.get(`users/${customerUid}`);
};

export const postCustomer = ({
  firstName,
  lastName,
  email,
  gender,
  phoneNumber,
  dateOfBirth,
  password,
  confirmPassword,
  // transactionPin,
  // confirmTransactionPin,
}) => {
  return client.post("user/retail/admin/create", {
    firstName,
    lastName,
    email,
    gender,
    phoneNumber,
    dateOfBirth,
    password,
    confirmPassword,
    // transactionPin,
    // confirmTransactionPin,
  });
};

export const verificationDecision = ({
  isKycAccepted,
  userUid,
  kycRejectionReasons,
}) => {
  return client.post("customer/verification-decision", {
    isKycAccepted,
    userUid,
    kycRejectionReasons,
  });
};

export const search = (page, searchTerm) => {
  return client.get(`customers?size=${size}&page=${page}&search=${searchTerm}`);
};

export const updatePersonalInfo = (model) => {
  return client.patch("user/personal-info", { ...model });
};

export const getBanks = (customerUid) => {
  return client.get(`customer/${customerUid}/banks`);
};

export const getWallets = (customerUid) => {
  return client.get(`user/${customerUid}/retail/wallet`);
};

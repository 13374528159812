import { ArrowBack, InfoOutlined } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  Fade,
  Grid,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { DeadState } from "../../common/components/DeadState";
import { SectionHeaderWithButton } from "../../common/components/SectionHeader";
import { styles } from "../../common/css/styles";
import { repaymentFilters, retailFilters } from "./constants/retailFilters";
import { format } from "date-fns";

const tempLoanList = [
  // {
  //   loanAmount: "N2,500,000",
  //   date: "23/09/2023",
  //   source: "Wallet",
  //   status: "Paid",
  // },
];

const columns = [
  {
    id: "amount",
    label: "Amount",
    minWidth: 50,
    align: "left",
  },
  {
    id: "date",
    label: "Repayment Date",
    minWidth: 50,
    align: "left",
  },
  {
    id: "source",
    label: "Source",
    minWidth: 50,
    align: "left",
  },
  {
    id: "repaymentStatus",
    label: "Status",
    minWidth: 50,
    align: "left",
  },
];

const getStatusDisplay = (status) => {
  switch (status) {
    case retailFilters.COMPLETED:
      return (
        <Typography sx={styles.cardTopStatus.verified}>Completed</Typography>
      );
    case retailFilters.ACTIVE:
      return <Typography sx={styles.cardTopStatus.active}>Active</Typography>;
    case retailFilters.PENDING_RECOMMENDATION:
      return (
        <Typography sx={styles.cardTopStatus.pendingRecommendation}>
          Pending Recommendation
        </Typography>
      );
    case retailFilters.PENDING_OFFER:
      return (
        <Typography sx={styles.cardTopStatus.pending}>Pending Offer</Typography>
      );
    case retailFilters.PENDING_DISBURSEMENT:
      return (
        <Typography sx={styles.cardTopStatus.pendingDisbursement}>
          Pending Disbursement
        </Typography>
      );
    case retailFilters.DECLINED:
      return <Typography sx={styles.cardTopStatus.denied}>Declined</Typography>;
    case retailFilters.DEFAULTING:
      return (
        <Typography sx={styles.cardTopStatus.denied}>Defaulting</Typography>
      );
    case retailFilters.OFFERED:
      return <Typography sx={styles.cardTopStatus.offered}>Offered</Typography>;
    case retailFilters.OFFER_REJECTED:
      return (
        <Typography sx={styles.cardTopStatus.rejected}>
          Offer Rejected
        </Typography>
      );
    case retailFilters.NTUS:
      return <Typography sx={styles.cardTopStatus.ntu}>NTU</Typography>;
    default:
      break;
  }
};

const getRepaymentStatusDisplay = (status) => {
  switch (status) {
    case repaymentFilters.SUCCESSFUL:
      return (
        <Typography sx={styles.cardTopStatus.active}>Successful</Typography>
      );
    case repaymentFilters.FAILED:
      return <Typography sx={styles.cardTopStatus.denied}>Failed</Typography>;
    case repaymentFilters.PENDING:
      return <Typography sx={styles.cardTopStatus.pending}>Pending</Typography>;
    default:
      return (
        <Typography sx={styles.cardTopStatus.active}>Successful</Typography>
      );
  }
};

export const LoanDetails = () => {
  const { loanid } = useParams();
  const loanHistory = useSelector(
    ({ retailLoan }) => retailLoan.history?.loans
  );
  const loan = loanHistory.find((loan) => loan?.uid === loanid);

  const navigate = useNavigate();

  const loanOfferTotalRepayment = loan?.loanOfferTotalRepayment ?? 0;
  const totalCollectedAmount = loan?.totalCollectedAmount ?? 0;
  const pendingAmount = loanOfferTotalRepayment - totalCollectedAmount;
  const repaymentPercentage =
    loanOfferTotalRepayment === 0
      ? 0
      : (totalCollectedAmount / loanOfferTotalRepayment) * 100;

  const handleBackToTable = () => navigate(-1);

  return (
    <>
      <SectionHeaderWithButton
        headerText={
          <Typography sx={{ fontWeight: 700, fontSize: 32 }}>
            <Tooltip
              title="Back to loan history table"
              arrow
              placement="top-start"
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
            >
              <IconButton
                aria-label="back"
                onClick={handleBackToTable}
                sx={{ mb: 0.8 }}
              >
                <ArrowBack sx={{ fontSize: 30, color: "#000" }} />
              </IconButton>
            </Tooltip>
            Loan History
          </Typography>
        }
      />

      <Box
        sx={{
          p: 2,
          pt: 4,
          backgroundColor: "#fff",
          width: "100%",
          borderRadius: 2,
          mb: 2,
        }}
      >
        <Grid
          container
          sx={{
            display: {
              // xs: "none",
              md: "block",
              // backgroundColor: "yellow"
            },
            borderBottom: 1,
            mt: 2,
            pb: 3,
            borderBottomColor: "#F0F1ED",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Stack>
              <Box sx={{ display: "flex" }}>
                <Box
                  sx={{
                    position: "relative",
                    display: "inline-flex",
                  }}
                >
                  <CircularProgress
                    variant="determinate"
                    value={100}
                    thickness={7}
                    size={95}
                    sx={{
                      color: "#0000002A",
                      ml: 1,
                      position: "absolute",
                    }}
                  />
                  <CircularProgress
                    variant="determinate"
                    value={repaymentPercentage}
                    size={95}
                    sx={{
                      color: "primary.main",
                      ml: 1,
                    }}
                    thickness={7}
                  />

                  <Box
                    sx={{
                      top: 0,
                      left: 0,
                      bottom: 0,
                      right: 0,
                      position: "absolute",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      variant="caption"
                      component="div"
                      sx={{
                        color: "#000",
                        ml: 1.2,
                        fontSize: 13,
                        fontWeight: 600,
                      }}
                    >
                      {repaymentPercentage}%
                    </Typography>
                  </Box>
                </Box>
                <Stack sx={{ ml: 4 }}>
                  <Typography sx={{ fontWeight: 700, fontSize: 28 }}>
                    Loan Details
                  </Typography>
                  {getStatusDisplay(loan.status)}
                </Stack>
              </Box>
              {/* <Typography sx={{ mt: 1.2 }}>{setStatus()}</Typography> */}
            </Stack>
            <Box>
              <Stack>
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontSize: 24,
                    color: "#3C3D39",
                    textAlign: "Right",
                  }}
                >
                  {loan?.loanOfferAmount?.toLocaleString("en-NG", {
                    style: "currency",
                    currency: "NGN",
                  }) ?? "₦0.00"}{" "}
                  <Typography
                    component="sup"
                    sx={{
                      display: "inline",
                      fontWeight: 700,
                      fontSize: 10,
                      color: "#3C3D39",
                      textAlign: "Right",
                    }}
                  >
                    ({loan.loanOfferInterestRate}%/Month)
                  </Typography>
                </Typography>
                <Typography
                  sx={{
                    fontSize: 11,
                    fontWeight: 600,
                    color: "#62635E",
                    textAlign: "Right",
                    mr: 0.3,
                  }}
                >
                  <span style={{ color: "#27AE60" }}> Duration:</span>{" "}
                  {loan?.loanTenure}{" "}
                  {loan?.loanTenure === 1 ? "month" : "months"}
                </Typography>
              </Stack>
            </Box>
          </Box>
        </Grid>

        <Box
          item
          sx={{
            width: "100%",

            borderRadius: 2,
            my: 2,
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between", pb: 2 }}>
            <Typography sx={{ px: 2, pl: 3.5, fontWeight: 600, fontSize: 20 }}>
              Loan Details
            </Typography>
          </Box>

          <Box sx={{ px: 2.5 }}>
            <Stack>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  // width: "100%",
                }}
              >
                <Box sx={styles.overview.statistics.smallBox}>
                  <Stack sx={{ px: 1 }}>
                    <Typography sx={styles.overview.statistics.info}>
                      Penalty({loan.loanOfferInterestRate}%)
                    </Typography>
                    <Typography sx={styles.overview.statistics.number}>
                      N/A
                    </Typography>
                  </Stack>

                  <Tooltip
                    title="Defaulting penalty fee"
                    placement="top"
                    sx={{ alignSelf: "normal" }}
                  >
                    <InfoOutlined sx={styles.overview.statistics.tooltip} />
                  </Tooltip>
                </Box>
                <Box sx={styles.overview.statistics.smallBox}>
                  <Stack sx={{ pl: 2 }}>
                    <Typography sx={styles.overview.statistics.info}>
                      Next Due Date
                    </Typography>
                    <Typography sx={styles.overview.statistics.number}>
                      {loan?.nextRepaymentDate
                        ? format(
                            new Date(loan?.nextRepaymentDate),
                            "dd/MM/yyyy"
                          )
                        : "N/A"}
                    </Typography>
                  </Stack>

                  <Tooltip
                    title="Repayment next due date"
                    placement="top"
                    sx={{ alignSelf: "normal" }}
                  >
                    <InfoOutlined sx={styles.overview.statistics.tooltip} />
                  </Tooltip>
                </Box>
                <Box sx={styles.overview.statistics.smallBox}>
                  <Stack sx={{ pl: 2 }}>
                    <Typography sx={styles.overview.statistics.info}>
                      Amount Repaid
                    </Typography>
                    <Typography sx={styles.overview.statistics.number}>
                      {totalCollectedAmount?.toLocaleString("en-NG", {
                        style: "currency",
                        currency: "NGN",
                      }) ?? "₦0"}
                    </Typography>
                  </Stack>

                  <Tooltip
                    title="Amount repaid so far"
                    placement="top"
                    sx={{ alignSelf: "normal" }}
                  >
                    <InfoOutlined sx={styles.overview.statistics.tooltip} />
                  </Tooltip>
                </Box>
                <Box sx={styles.overview.statistics.smallBox}>
                  <Stack sx={{ pl: 2 }}>
                    <Typography sx={styles.overview.statistics.info}>
                      Pending Payment
                    </Typography>
                    <Typography sx={styles.overview.statistics.number}>
                      {pendingAmount?.toLocaleString("en-NG", {
                        style: "currency",
                        currency: "NGN",
                      }) ?? "₦0"}
                    </Typography>
                  </Stack>

                  <Tooltip
                    title="Repayment outstanding amount"
                    placement="top"
                    sx={{ alignSelf: "normal" }}
                  >
                    <InfoOutlined sx={styles.overview.statistics.tooltip} />
                  </Tooltip>
                </Box>
                <Box sx={styles.overview.statistics.smallBox}>
                  <Stack sx={{ pl: 2 }}>
                    <Typography sx={styles.overview.statistics.info}>
                      Total Repayment
                    </Typography>
                    <Typography sx={styles.overview.statistics.number}>
                      {totalCollectedAmount?.toLocaleString("en-NG", {
                        style: "currency",
                        currency: "NGN",
                      }) ?? "₦0"}
                    </Typography>
                  </Stack>

                  <Tooltip
                    title="Total amount to be repaid"
                    placement="top"
                    sx={{ alignSelf: "normal" }}
                  >
                    <InfoOutlined sx={styles.overview.statistics.tooltip} />
                  </Tooltip>
                </Box>
              </Box>
            </Stack>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          p: 2,
          pt: 4,
          backgroundColor: "#fff",
          width: "100%",
          borderRadius: 2,
          mb: 2,
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between", pb: 2 }}>
          <Typography sx={{ px: 2, pl: 3.5, fontWeight: 600, fontSize: 20 }}>
            Repayment History
          </Typography>
        </Box>

        <Paper
          sx={{
            width: "100%",
            overflow: "hidden",
            border: "none",
            boxShadow: "none",
          }}
        >
          <TableContainer sx={{ maxHeight: 400 }}>
            <Table
              stickyHeader
              aria-label="sticky table"
              sx={{ backgroundColor: "transparent" }}
            >
              <TableHead>
                <TableRow
                  sx={{
                    "& .MuiTableCell-head": {
                      color: "#000",
                      backgroundColor: "transparent",
                      border: "4px solid black",
                    },
                  }}
                >
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                        borderTop: "1px solid #B5B5B5",
                        borderLeft: "none",
                        borderRight: "none",
                        borderBottom: "1px solid #B5B5B5",
                        fontSize: 13,
                        fontWeight: 600,
                        height: 20,
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {tempLoanList.length > 0 ? (
                  tempLoanList?.map((loan) => (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={loan.uid}
                      sx={{
                        "& .MuiTableCell-body": {
                          color: "#62635E",
                          backgroundColor: "#fff",
                          border: "none",
                          fontSize: 13,
                          fontWeight: 400,
                        },
                      }}
                    >
                      <TableCell sx={{ textTransform: "capitalize" }}>
                        {loan.loanAmount}
                      </TableCell>
                      <TableCell sx={{ textTransform: "capitalize" }}>
                        {loan.date}
                      </TableCell>
                      <TableCell sx={{ textTransform: "capitalize" }}>
                        {loan.source}
                      </TableCell>
                      <TableCell align="left">
                        {getRepaymentStatusDisplay(loan.status)}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableCell
                    colSpan={columns.length}
                    sx={{ textAlign: "center" }}
                  >
                    <DeadState />
                  </TableCell>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    </>
  );
};

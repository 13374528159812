import React from "react";
import { useLocation } from "react-router-dom";
import { bridgeFinanceFilters } from "../../bridgeFinance/constants/bridgeFinanceFilters";
import { UpdatedLoanDetails } from "../UpdatedLoanDetails";
import { BridgeFinanceKYC } from "../../bridgeFinance/BridgeFinanceKYC";

const NewBridgeFinancingLoanOverview = () => {
  const location = useLocation();
  const loanStatus = location?.state?.loanStatus;

  return loanStatus === bridgeFinanceFilters?.ACTIVE ||
    loanStatus === bridgeFinanceFilters?.COMPLETED ||
    loanStatus === bridgeFinanceFilters?.DEFAULTING ? (
    <UpdatedLoanDetails />
  ) : (
    <BridgeFinanceKYC />
  );
};

export default NewBridgeFinancingLoanOverview;

import { Box, Button, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Upload } from "@mui/icons-material";
import { styles } from "../../../common/css/styles";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { uploadPassport } from "../store/bridgeFinanceLoanSlice";
import { InfoDialog } from "../../../common/components/InfoDialog";

const defaultValues = {
  image: "",
};

const schema = yup.object().shape({
  image: yup.mixed().required("Please upload international passport"),
});

export const InternationalPassport = ({ loan, data }) => {
  const {
    handleSubmit,
    control,
    formState: { isSubmitSuccessful },
    reset,
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  const [open, setOpen] = useState(false);
  const [isError, setIsError] = useState(false);
  const [infoText, setInfoText] = useState("");
  const [showUpload, setShowUpload] = useState(false);
  const [selectedFile, setSelectedFile] = useState("");
  const [previewURL, setPreviewURL] = useState("");
  const error = useSelector(({ bridgeLoan }) => bridgeLoan.error);
  const dispatch = useDispatch();

  const handleClose = () => setOpen(false);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewURL(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setSelectedFile("");
      setPreviewURL("");
    }
  };

  const onSubmit = (data) => {
    dispatch(
      uploadPassport({
        ...data,
        loanUid: loan?.uid,
      })
    );
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
      setSelectedFile("");
      setPreviewURL("");
      if (error.length > 0) {
        setIsError(true);
        setInfoText("Passport upload failed");
        setOpen(true);
      } else {
        setIsError(false);
        setInfoText("Passport upload successful");
        setOpen(true);
      }
    }
  }, [isSubmitSuccessful]);

  return (
    <Box sx={{ p: 4, width: "100%" }}>
      {/* Subsection Selectors */}
      <Box sx={{ py: 2 }}>
        <Button
          variant="outlined"
          onClick={() => setShowUpload(false)}
          sx={{
            height: 30,
            textTransform: "capitalize",
            color: "#000",
            borderColor: "#B5B5B5",
            borderRadius: 0,
            py: 2,
            fontSize: 12,
            // mt: 2,
          }}
        >
          View
        </Button>
        <Button
          variant="outlined"
          onClick={() => setShowUpload(true)}
          sx={{
            height: 30,
            textTransform: "capitalize",
            color: "#B5B5B5",
            borderColor: "#B5B5B5",

            borderRadius: 0,
            backgroundColor: "#F7F8F4",
            py: 2,
            fontSize: 12,
            // mt: 2,
          }}
        >
          Reupload
        </Button>
      </Box>

      <Box sx={{ display: "flex", flexDirection: "row" }}>
        {!showUpload && (
          <>
            <Box sx={{ py: 2, width: "50%" }}>
              <Typography sx={{ fontWeight: 700, fontSize: 18, py: 2 }}>
                International Passport
              </Typography>

              <Box
                sx={{
                  border: 1,
                  borderRadius: 2,
                  minHeight: 200,
                  maxWidth: "85%",
                  px: 2,
                  borderColor: "#979797",
                }}
              >
                {
                  /* {data?.idMode === "NIGERIAN_PASSPORT" ? (
                  <>
                    <Typography sx={{ fontWeight: 400, fontSize: 16, py: 1 }}>
                      Passport no.
                    </Typography>
                    <Typography sx={{ fontWeight: 700, fontSize: 16, py: 1 }}>
                      {data?.idNumber}
                    </Typography>
                    <Box sx={{ width: "90%", py: 1 }}>
                      <img src={data?.idPhotoUrl} alt="passport" width="100%" />
                    </Box>
                  </>
                ) : (
                  <Typography sx={{ py: 1 }}>Not available</Typography>
                )} */
                  loan?.internationalPassportUrl ? (
                    <Box sx={{ width: "90%", py: 1 }}>
                      <img
                        src={loan?.internationalPassportUrl}
                        alt="passport"
                        width="100%"
                      />
                    </Box>
                  ) : (
                    <Typography sx={{ py: 1 }}>Not available</Typography>
                  )
                }
              </Box>
            </Box>
            {/* BVN Generated */}
            {/* <Box sx={{ py: 2, width: "50%" }}>
              <Typography sx={{ fontWeight: 700, py: 2 }}>
                International Passport
              </Typography>
              <Box
                sx={{
                  border: 1,
                  borderRadius: 2,
                  minHeight: 200,
                  maxWidth: "85%",
                  px: 2,
                  borderColor: "#979797",
                }}
              >
                <Stack
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Box sx={{ py: 1 }}>
                    <Typography sx={styles.bvn.topLeft}>First Name</Typography>
                    <Typography sx={styles.bvn.bottomLeft}>Charles</Typography>
                  </Box>
                  <Box sx={{ py: 1 }}>
                    <Typography sx={styles.bvn.topRight}>Last Name</Typography>
                    <Typography sx={styles.bvn.bottomRight}>
                      Bradenton
                    </Typography>
                  </Box>
                </Stack>
                <Stack
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Box sx={{ py: 1 }}>
                    <Typography sx={styles.bvn.topLeft}>
                      Email Address
                    </Typography>
                    <Typography sx={styles.bvn.bottomLeft}>
                      Charlesbrad@gmail.com
                    </Typography>
                  </Box>
                  <Box sx={{ py: 1 }}>
                    <Typography sx={styles.bvn.topRight}>
                      Phone Number
                    </Typography>
                    <Typography sx={styles.bvn.bottomRight}>
                      +234 80 1234 5678
                    </Typography>
                  </Box>
                </Stack>
                <Stack
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Box sx={{ py: 1 }}>
                    <Typography sx={styles.bvn.topLeft}>DOB</Typography>
                    <Typography sx={styles.bvn.bottomLeft}>
                      09/08/1991
                    </Typography>
                  </Box>
                  <Box sx={{ py: 1 }}>
                    <Typography sx={styles.bvn.topRight}>
                      Passport no.
                    </Typography>
                    <Typography sx={styles.bvn.bottomRight}>
                      A07725368
                    </Typography>
                  </Box>
                </Stack>
                <Stack
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Box sx={{ py: 1 }}>
                    <Typography sx={styles.bvn.topLeft}>Address</Typography>
                    <Typography sx={styles.bvn.bottomLeft}>
                      4th Mary high street
                    </Typography>
                  </Box>
                  <Box sx={{ py: 1 }}>
                    <Typography sx={styles.bvn.topRight}>LGA</Typography>
                    <Typography sx={styles.bvn.bottomRight}>Ikeja</Typography>
                  </Box>
                </Stack>
                <Stack
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Box sx={{ py: 1 }}>
                    <Typography sx={styles.bvn.topLeft}>State</Typography>
                    <Typography sx={styles.bvn.bottomLeft}>Lagos</Typography>
                  </Box>
                  <Box sx={{ py: 1 }}>
                    <Typography sx={styles.bvn.topRight}>Exp. Date </Typography>
                    <Typography sx={styles.bvn.bottomRight}>
                      23/04/2020
                    </Typography>
                  </Box>
                </Stack>
                <Stack
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Box sx={{ py: 1 }}>
                    <Typography sx={styles.bvn.topLeft}>
                      Salary Range
                    </Typography>
                    <Typography sx={styles.bvn.bottomLeft}>
                      100,000-1,000,000
                    </Typography>
                  </Box>
                </Stack>
                <Stack
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Box sx={{ py: 1 }}>
                    <Typography sx={styles.bvn.topLeft}>DOB</Typography>
                    <Typography sx={styles.bvn.bottomLeft}>
                      09/08/1991
                    </Typography>
                  </Box>
                </Stack>
              </Box>
            </Box> */}
            {/* User Input */}
          </>
        )}
        {showUpload && (
          <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="image"
              control={control}
              render={({ field }) => (
                <label htmlFor="file-input">
                  <input
                    accept="image/*"
                    style={{ display: "none" }}
                    id="file-input"
                    type="file"
                    onChange={(e) => {
                      handleFileChange(e);
                      field.onChange(e.target.files);
                    }}
                  />
                  <Button
                    variant="contained"
                    component="span"
                    sx={{
                      backgroundColor: "#E3E3E7",
                      textTransform: "capitalize",
                      width: 400,
                      boxShadow: "none",
                      "&:hover": {
                        boxShadow: "none",
                        color: "#fff",
                      },
                    }}
                  >
                    {selectedFile ? (
                      "Change File"
                    ) : (
                      <>
                        <Upload sx={{ fontSize: 12, pr: 1 }} />
                        Upload Photo
                      </>
                    )}
                  </Button>
                </label>
              )}
            />
            <Box mt={2}>
              {selectedFile && (
                <Stack>
                  <Typography variant="subtitle1">File Preview:</Typography>
                  <img src={previewURL} alt="Preview" width="400" />
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    sx={{
                      textTransform: "capitalize",
                      width: 400,
                      mt: 2,
                      boxShadow: "none",
                      "&:hover": {
                        boxShadow: "none",
                        color: "#fff",
                      },
                    }}
                  >
                    Upload
                  </Button>
                </Stack>
              )}
            </Box>
          </Box>
        )}
      </Box>
      <InfoDialog
        text={infoText}
        error={isError}
        open={open}
        handleClose={handleClose}
      />
    </Box>
  );
};

import { client } from ".";

const size = process.env.REACT_APP_PAGESIZE;

export const getLogs = (page) => {
  return client.get(`audit-logs?page=${page}&size=${size}`);
};

export const searchLogs = (page, searchTerm) => {
  return client.get(
    `audit-logs?page=${page}&size=${size}&search=${searchTerm}`
  );
};

export const searchLogsByDate = ({ page, searchTerm, startDate, endDate }) => {
  return client.get(
    `audit-logs/filter?page=${page}&size=${size}&search=${searchTerm}&startDate=${startDate}&endDate=${endDate}`
  );
};

import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { InfoDialog } from "../../../common/components/InfoDialog";
import { feedback } from "../../../common/constants/feedback";
import { styles } from "../../../common/css/styles";
import { clearKycFeedback } from "../kycSlice";

const defaultValues = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  dateOfBirth: null,
};

const schema = yup.object().shape({
  ticketNumber: yup.string().required("Ticket Number is required"),
  password: yup.string().required("Password is required"),
});

export const BankStatementRequest = ({
  open,
  handleClose,
  customer,
  hadleComplete,
}) => {
  const [openSecondDialog, setOpenSecondDialog] = useState(false);
  const [isError, setIsError] = useState(false);
  const [infoText, setInfoText] = useState("");
  const kyc = useSelector((state) => state.kyc);
  const dispatch = useDispatch();

  const handleCloseSecondDialog = () => {
    dispatch(clearKycFeedback());
    setOpenSecondDialog(false);
  };

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitSuccessful },
    reset,
    setValue,
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  const onSubmit = (data) => {
    // dispatch(
    //   updatePersonal({
    //     ...data,
    //     dateOfBirth: format(data.dateOfBirth, "dd-MM-yyyy"),
    //     userUid: customer.uid,
    //   })
    // );
    hadleComplete();
  };

  useEffect(() => {
    if (isSubmitSuccessful) reset();
  }, [isSubmitSuccessful]);

  useEffect(() => {
    if (kyc.feedback === feedback.UPDATED) {
      setInfoText("Personal details successfully updated.");
      setIsError(false);
      setOpenSecondDialog(true);
    }
    if (kyc.feedback === feedback.UPDATE_FAILED) {
      setInfoText(kyc?.error?.join("\n") ?? "Failed to update details");
      setIsError(true);
      setOpenSecondDialog(true);
    }
  }, [kyc.feedback]);

  // useEffect(() => {
  //   if (customer) {
  //     setValue("firstName", `${toTitleCase(customer?.firstName)}`);
  //     setValue("lastName", `${toTitleCase(customer?.lastName)}`);
  //     setValue("email", customer?.email);
  //     setValue("phoneNumber", customer?.phoneNumber);
  //     setValue(
  //       "dateOfBirth",
  //       parse(customer?.dateOfBirth, "dd-MM-yyyy", new Date())
  //     );
  //   }
  // }, [open]);

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          reset();
          handleClose();
        }}
      >
        <Grid
          py={2}
          sx={{ width: 500 }}
          component="form"
          noValidate
          onSubmit={handleSubmit(onSubmit)}
        >
          <DialogTitle sx={{ color: "#000" }}>
            Input ticket number and Password to continue
          </DialogTitle>

          <DialogContent>
            <Grid container spacing={2} direction="column" pr={2}>
              <Grid item>
                <Typography variant="body1" sx={styles.formFieldHeading}>
                  Ticket Number
                </Typography>
                <Controller
                  name="ticketNumber"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      required
                      variant="outlined"
                      fullWidth
                      size={styles.textfieldSize}
                      placeholder=""
                      error={!!errors.ticketNumber}
                      helperText={errors.ticketNumber?.message}
                      sx={{
                        ...styles.textField,
                        "& fieldset": { width: "100%" },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item>
                <Typography variant="body1" sx={styles.formFieldHeading}>
                  Password
                </Typography>
                <Controller
                  name="password"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      required
                      variant="outlined"
                      fullWidth
                      size={styles.textfieldSize}
                      placeholder=""
                      error={!!errors.password}
                      helperText={errors.password?.message}
                      sx={{
                        ...styles.textField,
                        "& fieldset": { width: "100%" },
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                reset();
                handleClose();
              }}
              sx={{
                textTransform: "capitalize",
                fontSize: 14,
                color: "#616663",
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              type="submit"
              sx={{
                boxShadow: "none",
                color: "#fff",
                textTransform: "capitalize",
                px: 2,
                fontSize: 12,
                fontWeight: 600,
                borderRadius: 2,
                backgroundColor: "primary.main",
                mx: 2,
              }}
            >
              Get Statement
            </Button>
          </DialogActions>
        </Grid>
      </Dialog>

      {/* Second Dialog */}
      <InfoDialog
        open={openSecondDialog}
        handleClose={handleCloseSecondDialog}
        text={infoText}
        error={isError}
      />
    </>
  );
};

import { Box, Typography } from "@mui/material";
import React from "react";
import { styles } from "../../../../../common/css/styles";

export const Residence = ({ data }) => {
  return (
    <Box>
      <Box
        sx={{
          // display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ py: 1 }}>
            <Typography sx={styles.kyc.headerText}>Address</Typography>
            <Typography sx={styles.kyc.bodyText}>
              {data?.residentialAddress ?? "Not Available"}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ py: 1 }}>
            <Typography sx={styles.kyc.headerText}>City</Typography>
            <Typography sx={styles.kyc.bodyText}>
              {data?.city ?? "Not Available"}
            </Typography>
          </Box>
        </Box>
        <Box>
          <Box sx={{ py: 1 }}>
            <Typography sx={styles.kyc.headerText}>State</Typography>
            <Typography sx={styles.kyc.bodyText}>
              {data?.state ?? "Not Available"}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

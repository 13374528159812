import {
  Box,
  Button,
  CircularProgress,
  Fade,
  IconButton,
  Tooltip,
  Typography,
  Stack,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { ArrowBack } from "@mui/icons-material";
import { styles } from "../../common/css/styles";
import { CardTop } from "./components/CardTop";
import { useDispatch, useSelector } from "react-redux";
import { stage } from "./kyb/index";
import { Residence } from "../kyc/stages/Residence";
import { Employment } from "../kyc/stages/Employment";
import { Bank } from "../kyc/stages/Bank";
import { NextOfKin } from "../kyc/stages/NextOfKin";
import { Photo } from "../kyc/stages/Photo";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { WhiteBox } from "../../common/components/WhiteBox";
import { SectionHeader } from "../../common/components/SectionHeader";
import { Reupload } from "./kyc/Reupload";
import { Documents } from "../kyc/stages/Documents";
import { businessStage } from "./constants/businessStage";
import { Wallet } from "../kyc/stages/Wallet";
import { Personal } from "./stages/Personal";
import {
  clearBusiness,
  fetchBusinessDocuments,
  fetchBusinessInfo,
  fetchContactInfo,
  fetchOwnerInfo,
} from "./store/businessSlice";
import { ContactInfo } from "./kyb/contactInfo/ContactInfo";
import { BusinessInfo } from "./kyb/businessInfo/BusinessInfo";
import { BusinessDocuments } from "./kyb/BusinessDocuments";
import { OwnerInfo } from "./kyb/ownerInfo/OwnerInfo";
import { OwnerFullDetails } from "./kyb/ownerInfo/components/OwnerFullDetails";
import { businessType } from "./constants/businessFilters";

export const BusinessInformation = () => {
  const [currentStage, setCurrentStage] = useState(stage.CONTACT_INFO);
  const business = useSelector((state) => state.business);
  const businessInfo = business.businessInfo;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { uid } = useParams();
  const type = location.state?.type;

  const getKYCStage = () => {
    switch (currentStage) {
      case stage.CONTACT_INFO:
        return <ContactInfo data={business.contactInfo} />;
      case stage.BUSINESS_INFO:
        return <BusinessInfo data={business.businessInfo} />;
      case stage.OWNER_INFO:
        return <OwnerInfo data={business.ownerInfo} />;
      case stage.BUSINESS_DOCUMENTS:
        return <BusinessDocuments data={business.documents} />;
      default:
        return <Personal customer={businessInfo} />;
    }
  };

  const handleBackToTable = () => navigate(-1);
  const handleTabChange = (stage) => setCurrentStage(stage);

  useEffect(() => {
    dispatch(fetchContactInfo(uid));
    dispatch(fetchBusinessInfo(uid));
    dispatch(fetchOwnerInfo({ businessUid: uid, page: 1 }));
    dispatch(fetchBusinessDocuments(uid));

    return () => {
      dispatch(clearBusiness());
    };
  }, []);

  return (
    <>
      <SectionHeader
        headerText={
          <Typography sx={{ fontWeight: 700, fontSize: 32 }}>
            <Tooltip
              title="Back to Business' Table"
              arrow
              placement="top-start"
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
            >
              <IconButton
                aria-label="back"
                onClick={handleBackToTable}
                sx={{ mb: 0.8 }}
              >
                <ArrowBack sx={{ fontSize: 30, color: "#000" }} />
              </IconButton>
            </Tooltip>
            Business Information
          </Typography>
        }
      />
      <WhiteBox>
        {business.loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "70vh",
            }}
          >
            <CircularProgress sx={{ alignItems: "center" }} />
          </Box>
        ) : (
          <Box sx={{ p: 4 }}>
            {/* Top */}
            <CardTop />
            {/* Section Selectors */}
            <Box sx={{ mt: 1, borderBottom: 1, borderBottomColor: "#F0F1ED" }}>
              <Button
                onClick={() => handleTabChange(stage.CONTACT_INFO)}
                sx={
                  currentStage === stage.CONTACT_INFO
                    ? styles.customerAccountVerification.sectionButton.selected
                    : styles.customerAccountVerification.sectionButton
                        .notSelected
                }
              >
                Contact Info
              </Button>
              <Button
                onClick={() => handleTabChange(stage.BUSINESS_INFO)}
                sx={
                  currentStage === stage.BUSINESS_INFO
                    ? styles.customerAccountVerification.sectionButton.selected
                    : styles.customerAccountVerification.sectionButton
                        .notSelected
                }
              >
                Business Info
              </Button>
              {type === businessType.REGISTERED &&
              businessInfo?.companyType !== "SOLE_PROPRIETORSHIP" ? (
                <Button
                  onClick={() => handleTabChange(stage.OWNER_INFO)}
                  sx={
                    currentStage === stage.OWNER_INFO
                      ? styles.customerAccountVerification.sectionButton
                          .selected
                      : styles.customerAccountVerification.sectionButton
                          .notSelected
                  }
                >
                  Owner Details
                </Button>
              ) : null}
              {type === businessType.REGISTERED ? (
                <Button
                  onClick={() => handleTabChange(stage.BUSINESS_DOCUMENTS)}
                  sx={
                    currentStage === stage.BUSINESS_DOCUMENTS
                      ? styles.customerAccountVerification.sectionButton
                          .selected
                      : styles.customerAccountVerification.sectionButton
                          .notSelected
                  }
                >
                  Business Documents
                </Button>
              ) : null}
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                //   alignItems: "normal",
              }}
            >
              {/* stages  */}
              {getKYCStage()}
            </Box>
          </Box>
        )}
      </WhiteBox>
    </>
  );
};

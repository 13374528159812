import React, { useEffect, useState } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Box,
  TableContainer,
  Paper,
  Typography,
  CircularProgress,
} from "@mui/material";
import { styles } from "../../../common/css/styles";
import { DeadState } from "../../../common/components/DeadState";
import { format } from "date-fns";
import { useDispatch } from "react-redux";
import { ProceedChangeDevice } from "../components/ProceedChangeDevice";
import { feedback } from "../../../common/constants/feedback";
import { PageNavigation } from "../../../common/components/PageNavigation";
import { clearDevicesFeedback, fetchDeviceChangeInfo } from "../devicesSlice";
import { InfoDialog } from "../../../common/components/InfoDialog";
import { LoadingButton } from "@mui/lab";

export const ChangeDeviceTable = ({ devices, page, handlePageChange }) => {
  const dispatch = useDispatch();

  const [openChangeDevice, setOpenChangeDevice] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [customer, setCustomer] = useState(null);
  const [clickId, setClickId] = useState(null);
  const customerList = devices.customers;

  const columns = [
    { id: "firstName", label: "First Name", minWidth: 20, visible: true },
    { id: "lastName", label: "Last Name", minWidth: 20, visible: true },
    { id: "email", label: "Email", minWidth: 20, visible: true },
    { id: "phoneNo", label: "Phone Number", minWidth: 20, visible: true },
    { id: "date", label: "Date", minWidth: 20, visible: true },
    // {
    //   id: "role",
    //   label: "NOK Phone Number",
    //   minWidth: 20,
    //   align: "left",
    //   visible: true,
    // },
    { id: "action", label: "Actions", minWidth: 10, visible: true },
  ];

  const handleVerify = (uid) => {
    setClickId(uid);
    setCustomer(customerList.find((x) => x.uid === uid));
    dispatch(fetchDeviceChangeInfo(uid));
  };

  const handleErrorClose = () => {
    dispatch(clearDevicesFeedback());
    setOpenError(false);
  };

  useEffect(() => {
    if (devices.feedback === feedback.SUCCESSFUL) {
      setOpenChangeDevice(true);
      dispatch(clearDevicesFeedback());
    }
    if (devices.feedback === feedback.FAILED) {
      setErrorText(devices.error);
      setOpenError(true);
    }
  }, [devices.feedback]);

  return (
    <Box>
      <TableContainer
        component={Paper}
        sx={{
          maxHeight: 400,
          boxShadow: "none",
          mt: 2,
        }}
      >
        <Table size="small">
          <TableHead>
            <TableRow
              sx={{
                "& .MuiTableCell-head": {
                  color: "#000",
                  backgroundColor: "#fff",
                  fontSize: 13,
                  borderTop: 0.5,
                  borderBottom: 0.5,
                  borderColor: "#B5B5B5",
                  py: 2,
                },
              }}
            >
              {columns
                .filter((column) => column.visible === true)
                .map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    <Typography
                      noWrap
                      sx={{
                        color: "#000",
                        backgroundColor: "#fff",
                        fontSize: 13,
                        fontWeight: 600,
                      }}
                    >
                      {column.label}
                    </Typography>
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {devices.loading ? (
              <TableCell
                colSpan={columns.length}
                sx={{ textAlign: "center", borderBottom: 0, pt: 4 }}
              >
                <CircularProgress />
              </TableCell>
            ) : customerList.length > 0 ? (
              customerList.map((customer) => (
                <TableRow
                  key={customer?.uid}
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  sx={{
                    "& .MuiTableCell-body": {
                      color: "#000",
                      backgroundColor: "#fff",
                      border: "none",
                    },
                  }}
                >
                  <TableCell align="left">
                    <Typography sx={styles.table.text}>
                      {customer?.firstName}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography sx={styles.table.text}>
                      {customer?.lastName}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography
                      sx={{
                        ...styles.table.text,
                        textTransform: "lowercase",
                      }}
                    >
                      {customer?.email}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography sx={styles.table.text}>
                      {customer?.phoneNumber}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography sx={styles.table.text}>
                      {customer?.createdAt &&
                        format(
                          new Date(customer?.createdAt),
                          "dd/MM/yyyy hh:mm:ss aa"
                        )}
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <LoadingButton
                      variant="outlined"
                      onClick={() => handleVerify(customer?.uid)}
                      loading={
                        devices.feedback === feedback.PENDING &&
                        customer?.uid === clickId
                      }
                      sx={styles.table.text.viewButton}
                    >
                      Verify Identity
                    </LoadingButton>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableCell colSpan={columns.length} sx={{ textAlign: "center" }}>
                <DeadState />
              </TableCell>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {!devices.loading && customerList.length > 0 ? (
        <PageNavigation
          page={page}
          totalRecords={customerList.length}
          handlePageChange={handlePageChange}
        />
      ) : (
        <></>
      )}

      <ProceedChangeDevice
        open={openChangeDevice}
        handleClose={() => setOpenChangeDevice(false)}
        customer={customer}
      />
      <InfoDialog
        open={openError}
        error={true}
        text={errorText}
        handleClose={handleErrorClose}
      />
    </Box>
  );
};

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getCustomers, search } from "../../../common/api/customers";
import { handleError } from "../../../common/constants/utility";

const initialState = {
  customers: [],
  totalRecords: 0,
  loading: false,
  error: [],
};

export const filteredCustomers = createAsyncThunk(
  "customers/filterBy",
  async ({ page, filterOption }, { dispatch, rejectWithValue }) => {
    try {
      const response = await getCustomers(page, filterOption);
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);

export const searchCustomers = createAsyncThunk(
  "customers/search",
  async ({ page, searchTerm }, { dispatch, rejectWithValue }) => {
    try {
      const response = await search(page, searchTerm);
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);

const customersSlice = createSlice({
  name: "customers",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(filteredCustomers.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(filteredCustomers.fulfilled, (state, action) => {
      state.customers = action.payload.data.users;
      state.totalRecords = action.payload.data.totalRecords;
      state.loading = false;
      state.error = [];
    });
    builder.addCase(filteredCustomers.rejected, (state, action) => {
      state.customers = [];
      state.totalRecords = 0;
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(searchCustomers.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(searchCustomers.fulfilled, (state, action) => {
      state.customers = action.payload.data.users;
      state.totalRecords = action.payload.data.totalRecords;
      state.loading = false;
      state.error = "";
    });
    builder.addCase(searchCustomers.rejected, (state, action) => {
      state.customers = [];
      state.totalRecords = 0;
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default customersSlice.reducer;

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { styles } from "../../../common/css/styles";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { OTPSuccess } from "./OTPSuccess";
import { OTPFailed } from "./OTPFailed";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ChangeDeviceRejected } from "./ChangeDeviceRejected";
import { clearDevicesFeedback, denyDeviceChangeRequest } from "../devicesSlice";
import { feedback } from "../../../common/constants/feedback";
import { InfoDialog } from "../../../common/components/InfoDialog";

const defaultValues = {
  reasonsForRejection: "",
};

const schema = yup.object().shape({
  reasonsForRejection: yup.string().required("Rejection reason is required"),
});

export const RejectRequest = ({ open, handleClose, customer }) => {
  const [openSentOTP, setopenSentOTP] = useState(false);
  const [openReject, setOpenReject] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const devices = useSelector((state) => state.devices);
  const dispatch = useDispatch();

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitSuccessful },
    reset,
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  const handleCloseRejected = () => {
    dispatch(clearDevicesFeedback());
    setOpenReject(false);
  };

  const handleErrorClose = () => {
    dispatch(clearDevicesFeedback());
    setOpenError(false);
  };

  const onSubmit = (data) => {
    dispatch(
      denyDeviceChangeRequest({
        userUid: customer.uid,
        reasonsForRejection: [data.reasonsForRejection],
      })
    );
    handleClose();
  };

  useEffect(() => {
    if (devices.feedback === feedback.SUCCESSFUL) {
      setOpenReject(true);
    }
    if (devices.feedback === feedback.FAILED) {
      setOpenError(true);
      setErrorText(devices.error);
    }
  }, [devices.loadingInfo]);

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{
            display: "flex",
            flexDirection: "column",
            //   alignItems: "center",
            minWidth: "500px",
          }}
        >
          <DialogContent>
            <Box
              sx={{ width: "280px", display: "flex", justifyContent: "center" }}
            ></Box>
            <DialogContentText>
              <Typography sx={{ fontSize: 17, fontWeight: 500 }}>
                Input reason for Rejecting Customer Change Device request
              </Typography>
              <Grid item sx={{ width: "100%", py: 1 }}>
                {/* <Typography sx={{ fontSize: 14, fontWeight: 400, pb: 1 }}>
                  Other reasons 
                </Typography> */}
                <Controller
                  name="reasonsForRejection"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={!!errors.reasonsForRejection}
                      helperText={errors.reasonsForRejection?.message}
                      fullWidth
                      size="small"
                    />
                  )}
                />
              </Grid>
            </DialogContentText>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                mt: 2,
              }}
            >
              <Button
                variant="outlined"
                onClick={handleClose}
                sx={{
                  ...styles.bridgeCardTop.error,
                  backgroundColor: "none",
                  borderColor: "#000",
                  color: "#000",
                  fontSize: 11,
                  px: 1,
                  mx: 1,
                  "&:hover": null,
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                type="submit"
                sx={{
                  ...styles.bridgeCardTop.error,
                  fontSize: 11,
                  px: 1,
                  mx: 1,
                }}
              >
                Reject
              </Button>
            </Box>
          </DialogContent>
        </Box>
      </Dialog>

      {/* <OTPSuccess
        open={openSentOTP}
        handleClose={() => setopenSentOTP(false)}
      /> */}
      {/* <OTPFailed open={openSentOTP} handleClose={() => setopenSentOTP(false)} /> */}
      <ChangeDeviceRejected
        open={openReject}
        handleClose={handleCloseRejected}
      />
      <InfoDialog
        open={openError}
        error={true}
        text={errorText}
        handleClose={handleErrorClose}
      />
    </>
  );
};

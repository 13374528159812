import { useQuery } from "@tanstack/react-query";
import {
  getCollateral,
  getDocuments,
  getGuarantors,
  getSmeLoan,
} from "../../../common/api/sme";
import { getBusinessOwnwersById } from "../../../common/api/businesses";
import { getLoan } from "../../../common/api/loans";

export const useSmeLoans = (loanid, businessId) => {
  const { data, isLoading } = useQuery({
    queryKey: [loanid, `loan${loanid}`],
    queryFn: () => getSmeLoan(loanid),
    refetchOnWindowFocus: false,
  });
  const loan = data?.data?.data ?? {};

  const { data: loanAltData, isLoading: loanAltLoading } = useQuery({
    queryKey: [loanid, `loanAltData${loanid}`],
    queryFn: () => getLoan(loanid),
    refetchOnWindowFocus: false,
  });
  const loanAlt = loanAltData?.data?.data ?? {};

  const { data: businessDocumentsData, isLoading: businessDocumentsIsLoading } =
    useQuery({
      queryKey: [loanid, `documents${loanid}`],
      queryFn: () => getDocuments(loanid),
      refetchOnWindowFocus: false,
    });
  const businessDocuments = businessDocumentsData?.data?.data ?? {};

  const {
    data: businessTeamMembersData,
    isLoading: businessTeamMembersIsLoading,
  } = useQuery({
    queryKey: [businessId, `teamMembers${businessId}`],
    queryFn: () => (businessId ? getBusinessOwnwersById(businessId) : null),
    refetchOnWindowFocus: false,
  });
  const businessTeamMembers = businessTeamMembersData?.data?.data ?? {};

  const {
    data: businessCollateralData,
    isLoading: businessCollateralIsLoading,
  } = useQuery({
    queryKey: [loanid, `collateral${loanid}`],
    queryFn: () => getCollateral(loanid),
    refetchOnWindowFocus: false,
  });
  const businessCollateral = businessCollateralData?.data?.data ?? {};

  const {
    data: businessGuarantorsData,
    isLoading: businessGuarantorsIsLoading,
  } = useQuery({
    queryKey: [loanid, `Guarantors${loanid}`],
    queryFn: () => getGuarantors(loanid),
    refetchOnWindowFocus: false,
  });
  const businessGuarantors = businessGuarantorsData?.data?.data ?? {};

  return {
    loan,
    businessDocuments,
    businessTeamMembers,
    businessCollateral,
    businessGuarantors,
    loanAlt,
    isLoading,
    businessDocumentsIsLoading,
    businessTeamMembersIsLoading,
    businessCollateralIsLoading,
    businessGuarantorsIsLoading,
    loanAltLoading,
    anyLoading:
      isLoading ||
      businessDocumentsIsLoading ||
      businessTeamMembersIsLoading ||
      businessCollateralIsLoading ||
      businessGuarantorsIsLoading ||
      loanAltLoading,
  };
};

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  login,
  refreshUserToken,
  logout,
  changePassword,
} from "../../common/api/auth";
import { sendOTP, sendOTPAdmin, verifyOTP } from "../../common/api/otp";
import { client } from "../../common/api";
import { feedback } from "../../common/constants/feedback";
import { handleError } from "../../common/constants/utility";

const initialState = {
  user: null,
  access_token: null,
  isAuthenticated: "",
  feedback: "",
  otpValidated: "",
  reset: "",
  error: [],
};

const setSession = (data) => {
  if (data) {
    localStorage.setItem("userData", JSON.stringify(data));
    client.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${data.auth.access_token}`;
  }
};

export const clearSession = () => {
  localStorage.removeItem("userData");
  delete client.defaults.headers.common.Authorization;
};

export const userLogin = createAsyncThunk(
  "user/login",
  async ({ email, password }, { dispatch, rejectWithValue }) => {
    try {
      const response = await login(email, password);
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);

export const refreshToken = createAsyncThunk(
  "user/refreshToken",
  async (refreshToken, { dispatch, rejectWithValue }) => {
    try {
      const response = await refreshUserToken(refreshToken);
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);

export const userLogout = createAsyncThunk(
  "user/logout",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await logout();
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);

export const sendResetOTP = createAsyncThunk(
  "user/reset/send-otp",
  async (model, { dispatch, rejectWithValue }) => {
    try {
      const response = await sendOTP(model);
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);

export const sendOTPFromAdmin = createAsyncThunk(
  "user/send-otp-admin",
  async (model, { dispatch, rejectWithValue }) => {
    try {
      const response = await sendOTPAdmin(model);
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);

export const validateOTP = createAsyncThunk(
  "user/reset/validate-otp",
  async (model, { dispatch, rejectWithValue }) => {
    try {
      const response = await verifyOTP(model);
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);

export const resetPassword = createAsyncThunk(
  "user/reset-password",
  async (model, { dispatch, rejectWithValue }) => {
    try {
      const response = await changePassword(model);
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload.user;
      state.access_token = action.payload?.auth?.access_token;
      state.isAuthenticated = feedback.SUCCESSFUL;
      state.error = null;
      setSession({
        auth: { ...action.payload.auth },
        user: action.payload.user,
      });
    },
    setError: (state, action) => {
      state.isAuthenticated = feedback.FAILED;
      state.error = action.payload;
    },
    clearFeedback: (state, action) => {
      state.feedback = "";
    },
    clearAuth: (state, action) => {
      return { ...initialState };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(userLogin.pending, (state) => {
      state.isAuthenticated = feedback.PENDING;
      state.error = [];
    });
    builder.addCase(userLogin.fulfilled, (state, action) => {
      state.user = action.payload.data.user;
      state.access_token = action.payload.data.access_token;
      state.isAuthenticated = feedback.SUCCESSFUL;
      state.error = [];
      setSession({
        auth: { ...action.payload.data.auth, lastLogin: Date.now() },
        user: action.payload.data.user,
      });
      localStorage.setItem(
        "userToken",
        action.payload?.data?.auth?.access_token
      );
      localStorage.setItem(
        "refreshToken",
        action.payload?.data?.auth?.refresh_token
      );
    });
    builder.addCase(userLogin.rejected, (state, action) => {
      state.user = null;
      state.isAuthenticated = feedback.FAILED;
      state.access_token = null;
      state.error = action.payload;
      clearSession();
    });
    builder.addCase(refreshToken.fulfilled, (state, action) => {
      const userData = JSON.parse(localStorage.getItem("userData"));
      state.user = userData.user;
      state.access_token = action.payload.data.access_token;
      state.isAuthenticated = feedback.SUCCESSFUL;
      state.error = [];
      setSession({
        auth: {
          ...userData.auth,
          access_expires_in: action.payload.data.access_expires_in,
          access_token: action.payload.data.access_token,
          refresh_token: action.payload.data.refresh_token,
          refresh_expires_in: action.payload.data.refresh_expires_in,
          lastLogin: Date.now(),
        },
        user: userData.user,
      });
    });
    builder.addCase(refreshToken.rejected, (state, action) => {
      // state.user = null;
      // state.isAuthenticated = feedback.FAILED;
      // state.access_token = null;
      state.error = action.payload;
      // clearSession();
    });
    builder.addCase(userLogout.fulfilled, (state, action) => {
      state.user = null;
      state.isAuthenticated = feedback.PENDING;
      clearSession();
    });
    builder.addCase(userLogout.rejected, (state, action) => {
      state.user = null;
      state.isAuthenticated = feedback.FAILED;
      state.error = action.payload;
      clearSession();
    });
    builder.addCase(sendResetOTP.fulfilled, (state, action) => {
      state.error = [];
    });
    builder.addCase(sendResetOTP.rejected, (state, action) => {
      state.error = action.payload;
    });
    builder.addCase(validateOTP.pending, (state, action) => {
      state.otpValidated = feedback.PENDING;
      state.error = [];
    });
    builder.addCase(validateOTP.fulfilled, (state, action) => {
      state.otpValidated = feedback.SUCCESSFUL;
      state.error = [];
    });
    builder.addCase(validateOTP.rejected, (state, action) => {
      state.otpValidated = feedback.FAILED;
      state.error = action.payload;
    });
    builder.addCase(resetPassword.pending, (state, action) => {
      state.reset = feedback.PENDING;
      state.error = [];
    });
    builder.addCase(resetPassword.fulfilled, (state, action) => {
      state.reset = feedback.SUCCESSFUL;
      state.error = [];
    });
    builder.addCase(resetPassword.rejected, (state, action) => {
      state.reset = feedback.FAILED;
      state.error = action.payload;
    });
    builder.addCase(sendOTPFromAdmin.pending, (state, action) => {
      state.feedback = feedback.PENDING;
      state.error = [];
    });
    builder.addCase(sendOTPFromAdmin.fulfilled, (state, action) => {
      state.feedback = feedback.SUCCESSFUL;
      state.error = [];
    });
    builder.addCase(sendOTPFromAdmin.rejected, (state, action) => {
      state.feedback = feedback.FAILED;
      state.error = action.payload;
    });
  },
});

export const { setUser, setError, clearFeedback, clearAuth } =
  authSlice.actions;
export default authSlice.reducer;

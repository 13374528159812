import { Box, Typography } from "@mui/material";
import React from "react";
import deadState from "../../common/images/dead-state.svg";
import { BackgroundLoader } from "./BackgroundLoader";

export const DeadState = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        // backgroundColor: "purple",
        height: "39vh",
      }}
    >
      {/* <BackgroundLoader /> */}
      <img src={deadState} alt="dead-icon" width="120" />
      <Typography sx={{ fontSize: 20, fontWeight: 500, pt: 2 }}>
        Nothing to see here yet
      </Typography>
    </Box>
  );
};

import {
  Box,
  Divider,
  Fade,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import { styles } from "../../../../../common/css/styles";
import { ArrowBack } from "@mui/icons-material";
import { toTitleCase } from "../../../../../common/constants/utility";

export const OwnerFullDetails = ({ data, handleBackToTable }) => {
  return (
    <Box width="70%" sx={{ pt: 1 }}>
      <Stack
        divider={<Divider orientation="horizontal" flexItem />}
        spacing={2}
      >
        <Box>
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: 20,
              color: "#000",
            }}
          >
            <Tooltip
              title="Back to Owner's Details"
              arrow
              placement="top-start"
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
              sx={{ pl: 0 }}
            >
              <IconButton
                aria-label="back"
                onClick={handleBackToTable}
                sx={{ mb: 0.8, pl: 0 }}
              >
                <ArrowBack sx={{ fontSize: 25, color: "#000" }} />
              </IconButton>
            </Tooltip>
            Owners Details
          </Typography>
          <Box
            sx={{
              display: "flex",
              width: "100%",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
                  First Name
                </Typography>
                <Typography sx={{ fontWeight: 700, color: "#494D4A" }}>
                  {toTitleCase(data?.firstName)}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
                  Last Name
                </Typography>
                <Typography sx={{ fontWeight: 700, color: "#494D4A" }}>
                  {toTitleCase(data?.lastName)}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
                  Email Address
                </Typography>
                <Typography sx={{ fontWeight: 700, color: "#494D4A" }}>
                  {data?.email ?? "Not Available"}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
                  Phone Number
                </Typography>
                <Typography sx={styles.kyc.bodyText}>
                  {data?.phoneNumber}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
                  Role
                </Typography>
                <Typography sx={styles.kyc.bodyText}>
                  {toTitleCase(data?.role.toLowerCase())}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
                  Mandate
                </Typography>
                <Typography sx={styles.kyc.bodyText}>
                  {toTitleCase(data?.mandate.toLowerCase())}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            width: "100%",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
                Address
              </Typography>
              <Typography sx={{ fontWeight: 700, color: "#494D4A" }}>
                {toTitleCase(data?.street)}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
                City
              </Typography>
              <Typography sx={{ fontWeight: 700, color: "#494D4A" }}>
                {toTitleCase(data?.city)}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
                State
              </Typography>
              <Typography sx={{ fontWeight: 700, color: "#494D4A" }}>
                {data?.state}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Stack>
                <Typography sx={{ fontWeight: 700, mb: 3 }}>NIN </Typography>
                <Box
                  sx={{
                    border: 1,
                    borderRadius: 2,
                    px: 2,
                    borderColor: "#979797",
                  }}
                >
                  <Typography sx={{ ...styles.bvn.topLeft, py: 1 }}>
                    NIN no.
                  </Typography>
                  <Typography sx={styles.bvn.bottomLeft}>
                    {data?.nin ?? "Not Available"}
                  </Typography>
                  <img
                    src={data?.ninUrl}
                    alt="dead-icon"
                    width="100%"
                    height="350"
                  />
                </Box>
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack>
                <Typography sx={{ fontWeight: 700, mb: 3 }}>
                  NIN Generated
                </Typography>
                <Box
                  sx={{
                    border: 1,
                    borderRadius: 2,
                    px: 2,
                    borderColor: "#979797",
                  }}
                >
                  <Stack
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      mb: 4,
                    }}
                  >
                    <Box sx={{ py: 1 }}>
                      <Typography sx={styles.bvn.topLeft}>
                        First Name
                      </Typography>
                      <Typography sx={styles.bvn.bottomLeft}>
                        {data?.firstName ? data?.firstName : "Not Available"}
                      </Typography>
                    </Box>
                    <Box sx={{ py: 1 }}>
                      <Typography sx={styles.bvn.topRight}>Surname</Typography>
                      <Typography sx={styles.bvn.bottomRight}>
                        {data?.lastName ? data?.lastName : "Not Available"}
                      </Typography>
                    </Box>
                  </Stack>
                  <Stack
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      mb: 3,
                    }}
                  >
                    <Box sx={{ py: 1 }}>
                      <Typography sx={styles.bvn.topLeft}>
                        EmailAddress
                      </Typography>
                      <Typography
                        sx={{
                          ...styles.bvn.bottomLeft,
                          textTransform: "lowercase",
                        }}
                      >
                        {data?.email ? data?.email : "Not Available"}
                      </Typography>
                    </Box>
                    <Box sx={{ py: 1 }}>
                      <Typography sx={styles.bvn.topLeft}>
                        Phone number
                      </Typography>
                      <Typography sx={styles.bvn.bottomLeft}>
                        {data?.phoneNumber
                          ? data?.phoneNumber
                          : "Not Available"}
                      </Typography>
                    </Box>
                  </Stack>
                </Box>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Stack>
    </Box>
  );
};

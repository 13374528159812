import React, { useState } from "react";
import { Box, Popover, Typography } from "@mui/material";

export const Photo = ({ data }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <Box>
        <Box sx={{ width: "100%", py: 2 }}>
          <img
            src={data?.photoUrl}
            alt="avatar"
            width="200px"
            onClick={handleOpenPopover}
            style={{ cursor: "pointer" }}
          />
        </Box>
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
      >
        <Box p={2}>
          <img src={data?.photoUrl} alt="avatar" width="400px" />
        </Box>
      </Popover>
    </>
  );
};

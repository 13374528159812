import { client } from ".";

export const getbanks = () => {
  return client.get("nigerian-banks");
};

export const verifyBankAccount = (accountNumber, bankSortCode) => {
  return client.post("verify/bank-account", { accountNumber, bankSortCode });
};

export const validateBVN = (userUid, bvn) => {
  return client.post(`admin/${userUid}/verify-bvn`, { bvn });
};

import { Box, Typography } from "@mui/material";
import React from "react";
import { customerFilters } from "../../customers/constants/customerFilters";
import { styles } from "../../../common/css/styles";

export const Wallet = ({ customer, data }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        width: customer?.status === customerFilters.PENDING ? "100%" : "50%",
      }}
    >
      <Box>
        <Box sx={{ py: 1 }}>
          <Typography sx={styles.kyc.headerText}>Account number</Typography>
          <Typography sx={styles.kyc.bodyText}>
            {customer?.wallet?.accountNumber ?? "Not Available"}
          </Typography>
        </Box>
        <Box sx={{ py: 1 }}>
          <Typography sx={styles.kyc.headerText}>Account Name</Typography>
          <Typography sx={styles.kyc.bodyText}>
            {customer?.wallet?.accountName ?? "Not Available"}
          </Typography>
        </Box>
        <Box sx={{ py: 1 }}>
          <Typography sx={styles.kyc.headerText}>Bank Name</Typography>
          <Typography sx={styles.kyc.bodyText}>
            {customer?.wallet?.bank ?? "Not Available"}
          </Typography>
        </Box>
        <Box sx={{ py: 1 }}>
          <Typography sx={styles.kyc.headerText}>Balance</Typography>
          <Typography sx={styles.kyc.bodyText}>
            {customer?.wallet?.balance?.toLocaleString("en-NG", {
              style: "currency",
              currency: "NGN",
            }) ?? "Not Available"}
          </Typography>
        </Box>
        <Box sx={{ py: 1 }}>
          <Typography sx={styles.kyc.headerText}>Account Status</Typography>
          <Typography
            sx={
              customer?.wallet?.status === "ACTIVE"
                ? { ...styles.kyc.bodyText, color: "primary.main" }
                : { ...styles.kyc.bodyText, color: "#D41818" }
            }
          >
            {customer?.wallet?.status ?? "Not Available"}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  CircularProgress,
} from "@mui/material";
import React from "react";
import { DeadState } from "../../../../../common/components/DeadState";
// import { EditButton } from "../components/EditButton";
// import { BVNEdit } from "../components/BVNEdit";
// import { Add, Delete } from "@mui/icons-material";
// import { BankEdit } from "../components/BankEdit";
import { PageNavigation } from "../../../../../common/components/PageNavigation";
import { styles } from "../../../../../common/css/styles";

export const Guarantee = ({
  loans,
  filterOption,
  page,
  handlePageChange,
  handleView,
  customer,
  data,
}) => {
  const tempLoanList = [
    {
      firstName: "Sandy",
      lastName: " Boyer",
      email: "Sandy7@yahoo.com",
      phoneNumber: "08023333333",
      role: "Stakeholder",
    },
    {
      firstName: "Sandy",
      lastName: " Boyer",
      email: "Sandy7@yahoo.com",
      phoneNumber: "08023333333",
      role: "Stakeholder",
    },
    {
      firstName: "Sandy",
      lastName: " Boyer",
      email: "Sandy7@yahoo.com",
      phoneNumber: "08023333333",
      role: "Stakeholder",
    },
    {
      firstName: "Sandy",
      lastName: " Boyer",
      email: "Sandy7@yahoo.com",
      phoneNumber: "08023333333",
      role: "Stakeholder",
    },
    {
      firstName: "Sandy",
      lastName: " Boyer",
      email: "Sandy7@yahoo.com",
      phoneNumber: "08023333333",
      role: "Stakeholder",
    },
    {
      firstName: "Sandy",
      lastName: " Boyer",
      email: "Sandy7@yahoo.com",
      phoneNumber: "08023333333",
      role: "Stakeholder",
    },
  ];

  const columns = [
    { id: "firstName", label: " First Name", minWidth: 10, visible: true },
    { id: "lastName", label: "Last Name", minWidth: 50, visible: true },
    { id: "email", label: "Email", minWidth: 50, visible: true },
    { id: "phoneNumber", label: "Phone number", minWidth: 50, visible: true },
    { id: "role", label: "Role", minWidth: 50, visible: true },
  ];

  // const getStatusDisplay = (status) => {
  //   switch (status) {
  //     case loansFilters.APPLICATION_STATUS:
  //       return (
  //         <Typography sx={styles.table.text.customerStatus.prospect}>
  //           Application
  //         </Typography>
  //       );
  //     case loansFilters.OFFERED:
  //       return (
  //         <Typography sx={styles.cardTopStatus.offered}>Offered</Typography>
  //       );
  //     case loansFilters.REJECTED_OFFERS:
  //       return (
  //         <Typography sx={styles.cardTopStatus.rejected}>
  //           Rejected Offer
  //         </Typography>
  //       );
  //     case loansFilters.DEFAULTING:
  //       return (
  //         <Typography sx={styles.table.text.customerStatus.rejected}>
  //           Defaulting
  //         </Typography>
  //       );
  //     case loansFilters.DECLINED:
  //       return (
  //         <Typography sx={styles.cardTopStatus.rejected}>Declined</Typography>
  //       );
  //     case loansFilters.ACTIVE:
  //       return (
  //         <Typography sx={styles.table.text.customerStatus.verified}>
  //           Active
  //         </Typography>
  //       );
  //     case loansFilters.COMPLETED:
  //       return (
  //         <Typography sx={styles.table.text.customerStatus.pending}>
  //           Completed
  //         </Typography>
  //       );
  //     case loansFilters.NTUS:
  //       return (
  //         <Typography sx={styles.table.text.customerStatus.prospect}>
  //           NTU
  //         </Typography>
  //       );
  //     default:
  //       return (
  //         <Typography sx={styles.table.text.customerStatus.pending}>
  //           Completed
  //         </Typography>
  //       );
  //   }
  // };

  return (
    // <>
    //   <Box
    //     sx={{
    //       display: "flex",
    //       justifyContent: "space-between",
    //       width: "100%",
    //       // width: customer?.status === customerFilters.PENDING ? "100%" : "70%",
    //     }}
    //   >
    //     <Box sx={{ width: "100%" }}>
    //       <Box
    //         sx={{
    //           display: "flex",
    //           justifyContent: "space-between",
    //         }}
    //       >
    //         {/* <Box sx={{ py: 2, pb: 4 }}>
    //           <EditButton openDialog={openDialog} />
    //         </Box> */}
    //       </Box>

    //       <Box
    //         sx={{
    //           px: 2,
    //           py: 1,
    //           borderColor: "#0000000D",
    //         }}
    //       >
    //         <Box
    //           sx={{
    //             display: "flex",
    //             justifyContent: "space-between",
    //             alignItems: "center",
    //             pb: 2,
    //           }}
    //         >

    //         </Box>
    //         <Paper
    //           sx={{
    //             width: "100%",
    //             overflow: "hidden",
    //             border: "none",
    //             boxShadow: "none",
    //           }}
    //         >
    //           <TableContainer sx={{ maxHeight: 400 }}>
    //             <Table
    //               stickyHeader
    //               aria-label="sticky table"
    //               sx={{ backgroundColor: "transparent" }}
    //             >
    //               <TableHead>
    //                 <TableRow
    //                   sx={{
    //                     "& .MuiTableCell-head": {
    //                       color: "#000",
    //                       backgroundColor: "transparent",
    //                       border: "4px solid black",
    //                     },
    //                   }}
    //                 >
    //                   {columns.map((column) => (
    //                     <TableCell
    //                       key={column.id}
    //                       align={column.align}
    //                       style={{
    //                         minWidth: column.minWidth,
    //                         borderTop: "1px solid #B5B5B5",
    //                         borderLeft: "none",
    //                         borderRight: "none",
    //                         borderBottom: "1px solid #B5B5B5",
    //                         fontSize: 13,
    //                         fontWeight: 600,
    //                         height: 20,
    //                       }}
    //                     >
    //                       {column.label}
    //                     </TableCell>
    //                   ))}
    //                 </TableRow>
    //               </TableHead>
    //               <TableBody>
    //                 {customer?.banks ? (
    //                   customer?.banks?.map((bank) => (
    //                     <TableRow
    //                       hover
    //                       role="checkbox"
    //                       tabIndex={-1}
    //                       key={bank.uid}
    //                       sx={{
    //                         "& .MuiTableCell-body": {
    //                           color: "#62635E",
    //                           backgroundColor: "#fff",
    //                           border: "none",
    //                           fontSize: 13,
    //                           fontWeight: 400,
    //                         },
    //                       }}
    //                     >
    //                       <TableCell sx={{ textTransform: "capitalize" }}>
    //                         {bank.bankAccountName}
    //                       </TableCell>
    //                       <TableCell sx={{ textTransform: "capitalize" }}>
    //                         {bank.bankAccountNumber}
    //                       </TableCell>
    //                       <TableCell sx={{ textTransform: "capitalize" }}>
    //                         {bank.bankName}
    //                       </TableCell>
    //                       <TableCell>
    //                         {bank.primaryBank ? (
    //                           <Typography
    //                             sx={{
    //                               backgroundColor: "#19AD421A",
    //                               color: "#19AD42",
    //                               borderRadius: 3,
    //                               width: 50,
    //                               textAlign: "center",
    //                               fontSize: 11,
    //                             }}
    //                           >
    //                             True
    //                           </Typography>
    //                         ) : (
    //                           <Typography
    //                             sx={{
    //                               backgroundColor: "#D418181A",
    //                               color: "#D41818",
    //                               borderRadius: 3,
    //                               width: 60,
    //                               textAlign: "center",
    //                               fontSize: 11,
    //                             }}
    //                           >
    //                             False
    //                           </Typography>
    //                         )}
    //                       </TableCell>
    //                       {/* <TableCell sx={{ textTransform: "capitalize" }}>
    //                         <IconButton>
    //                           <Delete sx={{ fontSize: 18, color: "#D41818" }} />
    //                         </IconButton>
    //                       </TableCell> */}
    //                     </TableRow>
    //                   ))
    //                 ) : (
    //                   <TableCell
    //                     colSpan={columns.length}
    //                     sx={{ textAlign: "center" }}
    //                   >
    //                     <DeadState />
    //                   </TableCell>
    //                 )}
    //               </TableBody>
    //             </Table>
    //           </TableContainer>
    //         </Paper>
    //       </Box>
    //     </Box>
    //   </Box>
    // </>

    <Box>
      <TableContainer
        component={Paper}
        sx={{
          maxHeight: 400,
          boxShadow: "none",
          mt: 2,
        }}
      >
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow
              sx={{
                "& .MuiTableCell-head": {
                  color: "#000",
                  backgroundColor: "#fff",
                  borderTop: 0.5,
                  borderBottom: 0.5,
                  borderColor: "#B5B5B5",
                  py: 2,
                },
              }}
            >
              {columns
                ?.filter((column) => column.visible === true)
                .map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    <Typography
                      noWrap
                      sx={{
                        color: "#000",
                        backgroundColor: "#fff",
                        fontSize: 13,
                        fontWeight: 600,
                      }}
                    >
                      {column.label}
                    </Typography>
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loans.loading ? (
              <TableCell
                colSpan={columns.length}
                sx={{ textAlign: "center", borderBottom: 0, pt: 4 }}
              >
                <CircularProgress />
              </TableCell>
            ) : tempLoanList?.length > 0 ? (
              tempLoanList?.map((loan) => (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={loan.uid}
                  sx={{
                    "& .MuiTableCell-body": {
                      color: "#000",
                      backgroundColor: "#fff",
                      borderBottom: 0.5,
                      borderColor: "#F7F8F4",
                    },
                  }}
                >
                  <TableCell align="left">
                    <Typography sx={styles.table.text}>
                      {loan.firstName}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography sx={styles.table.text}>
                      {loan.lastName}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography sx={styles.table.text}>{loan.email}</Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography sx={styles.table.text}>
                      {loan.phoneNumber}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography sx={styles.table.text}>{loan.role}</Typography>
                  </TableCell>
                  {columns?.find((column) => column.id === "status")
                    ?.visible ? (
                    <TableCell align="left">
                      {/* {getStatusDisplay(loan.status)} */}
                    </TableCell>
                  ) : null}
                  {columns?.find((column) => column.id === "view")?.visible ? (
                    <TableCell align="left">
                      {/* <Button
                        variant="outlined"
                        // onClick={() => handleView(loan.uid)}
                        onClick={() => handleView()}
                        sx={styles.table.text.viewButton}
                      >
                        View
                      </Button> */}
                    </TableCell>
                  ) : null}
                </TableRow>
              ))
            ) : (
              <TableCell colSpan={columns?.length} sx={{ textAlign: "center" }}>
                <DeadState />
              </TableCell>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <PageNavigation
        page={page}
        totalRecords={loans.totalRecords}
        handlePageChange={handlePageChange}
      />
      {/* {!loans.loading && loanList?.length > 5 ? (
      <PageNavigation
        page={page}
        totalRecords={loans.totalRecords}
        handlePageChange={handlePageChange}
      />
    ) : (
      <></>
    )} */}
    </Box>
  );
};

import React from "react";
import { SectionHeader } from "../../common/components/SectionHeader";
import { WhiteBox } from "../../common/components/WhiteBox";
import { SearchBar } from "../../common/components/SearchBar";
import { Box, Stack } from "@mui/material";
import { useDispatch } from "react-redux";
import { searchCustomers } from "../customers/store/customersSlice";
import { CollectionTable } from "./components/CollectionsTable";
import { FilterSection } from "../../common/components/FilterSection";

export const Collection = () => {
  const dispatch = useDispatch();

  const handleSearch = (searchTerm) => {
    dispatch(searchCustomers(1, searchTerm));
  };

  return (
    <>
      <SectionHeader headerText="Collection" />
      <WhiteBox>
        <Box
          sx={{
            p: 2,
          }}
        >
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <SearchBar onSearch={handleSearch} />
            <FilterSection />
          </Stack>
          <CollectionTable />
        </Box>
      </WhiteBox>
    </>
  );
};

import { Box } from "@mui/material";
import React from "react";

export const WhiteBox = ({ children, minHeight }) => {
  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        width: "100%",
        minHeight: minHeight ?? "70vh",
        borderRadius: 2,
        display: "flex",
        flexDirection: "column",
      }}
    >
      {children}
    </Box>
  );
};

import React from "react";
import { SectionHeader } from "../../common/components/SectionHeader";
import { Box, Grid, Skeleton } from "@mui/material";
import { GracePeriod } from "./GracePeriod";
import { PenaltyPeriod } from "./PenaltyPeriod";
import { getCollection } from "../../common/api/settings";
import { useQuery } from "@tanstack/react-query";

export const CollectionSetting = () => {
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["collection settings"],
    queryFn: () => getCollection(),
    refetchOnWindowFocus: false,
  });

  const collectionSettings = data?.data.data ?? [];

  const gracePeriod = collectionSettings.find(
    (v) => v.name?.toLowerCase() === "grace_period"
  );
  const penalty = collectionSettings.find(
    (v) => v.name?.toLowerCase() === "penalty"
  );

  console.info({ collectionSettings });

  return (
    <>
      <SectionHeader headerText="Collection Setting" />
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            px: 2,
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <Skeleton
            variant="rectangular"
            height={200}
            sx={{ mt: { xs: "10px", md: 0 }, width: { xs: "100%", md: "49%" } }}
          />
          <Skeleton
            variant="rectangular"
            height={200}
            sx={{ mt: { xs: "10px", md: 0 }, width: { xs: "100%", md: "49%" } }}
          />
        </Box>
      ) : (
        <Grid container paddingX={2} rowSpacing={2} columnSpacing={2}>
          <Grid xs={12} md={6} item>
            <GracePeriod data={gracePeriod ?? {}} refetch={refetch} />
          </Grid>
          <Grid xs={12} md={6} item>
            <PenaltyPeriod data={penalty ?? {}} refetch={refetch} />
          </Grid>
        </Grid>
      )}
    </>
  );
};

import React from "react";
import { useDispatch } from "react-redux";
import { UserFormFields } from "./UserFormFields";
import * as yup from "yup";
import { passwordRegex, phoneRegex } from "../../../common/constants/regex";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { addNewUser } from "../usersSlice";

const defaultValues = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  password: "",
  confirmPassword: "",
  role: "",
  gender: "",
  // profileImage: "",
};

const schema = yup.object().shape({
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name is required"),
  email: yup
    .string()
    .email("Please enter a valid email address")
    .required("Email address is required"),
  phoneNumber: yup
    .string()
    .matches(phoneRegex, "Enter a valid phone number")
    .required("Phone number is required"),
  password: yup
    .string()
    .required("Password is required")
    .matches(passwordRegex, "Password does not match required rules"),
  confirmPassword: yup
    .string()
    .required("Password confirmation is required")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
  role: yup.string().required("Please select a role"),
  gender: yup.string().required("Please select a gender"),
  // profileImage: yup.mixed().required("Profile Image is required"),
});

export const AddUser = ({ handleComplete }) => {
  const formObject = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  const dispatch = useDispatch();

  const onSubmit = (data) => {
    dispatch(addNewUser(data));
  };

  return (
    <>
      <UserFormFields
        buttonText="Add"
        formObject={formObject}
        onSubmit={onSubmit}
        handleComplete={handleComplete}
      />
    </>
  );
};

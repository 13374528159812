import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { UserFormFields } from "./UserFormFields";
import * as yup from "yup";
import { phoneRegex } from "../../../common/constants/regex";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { updateUserInfo } from "../usersSlice";

const defaultValues = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  role: "",
  gender: "",
  // profileImage: "",
};

const schema = yup.object().shape({
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name is required"),
  email: yup
    .string()
    .email("Please enter a valid email address")
    .required("Email address is required"),
  phoneNumber: yup
    .string()
    .matches(phoneRegex, "Enter a valid phone number")
    .required("Phone number is required"),
  role: yup.string().required("Please select a role"),
  gender: yup.string().required("Please select a gender"),
  // profileImage: yup.mixed().required("Profile Image is required"),
});

export const EditUser = ({ handleComplete }) => {
  const formObject = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  const user = useSelector(({ users }) => users.user);
  const dispatch = useDispatch();

  const onSubmit = (data) => {
    dispatch(updateUserInfo({ userUid: user.uid, ...data }));
  };

  return (
    <>
      <UserFormFields
        buttonText="Update"
        formObject={formObject}
        onSubmit={onSubmit}
        handleComplete={handleComplete}
      />
    </>
  );
};

import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import { EditButton } from "../components/EditButton";
import { EmploymentEdit } from "../components/EmploymentEdit";
import { styles } from "../../../common/css/styles";
import { customerFilters } from "../../customers/constants/customerFilters";
import { toTitleCase } from "../../../common/constants/utility";

export const Employment = ({ customer, data }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const openDialog = () => {
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: customer?.status === customerFilters.PENDING ? "100%" : "70%",
        }}
      >
        <Box>
          <Box sx={{ py: 1 }}>
            <Typography sx={styles.kyc.headerText}>Company</Typography>
            <Typography sx={styles.kyc.bodyText}>
              {data?.companyName ?? "Not Available"}
            </Typography>
          </Box>
          <Box sx={{ py: 1 }}>
            <Typography sx={styles.kyc.headerText}>Date Joined</Typography>
            <Typography sx={styles.kyc.bodyText}>
              {data?.dateJoined?.replaceAll("-", "/") ?? "Not Available"}
            </Typography>
          </Box>
          <Box sx={{ py: 1 }}>
            <Typography sx={styles.kyc.headerText}>Salary Range</Typography>
            <Typography sx={styles.kyc.bodyText}>
              {data?.salaryRange ?? "Not Available"}
            </Typography>
          </Box>
          {/* <Box sx={{ py: 1 }}>
            <Typography sx={styles.kyc.headerText}>City</Typography>
            <Typography sx={styles.kyc.bodyText}>
              {data?.companyCity}
            </Typography>
          </Box>
          <Box sx={{ py: 1 }}>
            <Typography sx={styles.kyc.headerText}>Role</Typography>
            <Typography sx={styles.kyc.bodyText}>{data?.role}</Typography>
          </Box> */}
        </Box>
        <Box>
          {/* <Box sx={{ py: 1 }}>
            <Typography sx={styles.kyc.headerText}>Address</Typography>
            <Typography sx={styles.kyc.bodyText}>
              {data?.companyAddress}
            </Typography>
          </Box>
          <Box sx={{ py: 1 }}>
            <Typography sx={styles.kyc.headerText}>State</Typography>
            <Typography sx={styles.kyc.bodyText}>{data?.state}</Typography>
          </Box> */}
          <Box sx={{ py: 1 }}>
            <Typography sx={styles.kyc.headerText}>
              Employment Status
            </Typography>
            <Typography sx={styles.kyc.bodyText}>
              {toTitleCase(data?.employmentStatus?.replace("_", " ")) ??
                "Not Available"}
            </Typography>
          </Box>
          <Box sx={{ py: 1 }}>
            <Typography sx={styles.kyc.headerText}>
              Education History
            </Typography>
            <Typography sx={styles.kyc.bodyText}>
              {data?.educationHistory ?? "Not Available"}
            </Typography>
          </Box>
        </Box>

        {/* {customer?.status === customerFilters.PENDING ? ( */}
        <EditButton openDialog={openDialog} />
        {/* ) : null} */}
        <EmploymentEdit
          open={isDialogOpen}
          handleClose={closeDialog}
          kycData={data}
        />
      </Box>
    </>
  );
};

import { Avatar, Box, Button, Stack, Typography } from "@mui/material";
import React from "react";
import avatarDummy from "../../../common/images/avatar-image.jpg";
import checkIcon from "../../../common/images/check-icon.svg";
import closeIcon from "../../../common/images/close-1con.svg";
import { useState } from "react";
import { Decline } from "./Decline";
import { Verify } from "./Verify";
import { useSelector } from "react-redux";
import { customerFilters } from "../constants/customerFilters";
import { styles } from "../../../common/css/styles";
import PersonIcon from "@mui/icons-material/Person";

export const CardTop = () => {
  const [openVerifyDialog, setOpenVerifyDialog] = useState(false);
  const [openDeclineDialog, setOpenDeclineDialog] = useState(false);
  const customer = useSelector(({ customer }) => customer.customer);
  const kycData = useSelector(({ kyc }) => kyc.data);

  const handleOpenVerifyDialog = () => setOpenVerifyDialog(true);
  const handleCloseVerifyDialog = () => setOpenVerifyDialog(false);
  const handleOpenDeclineDialog = () => setOpenDeclineDialog(true);
  const handleCloseDeclineDialog = () => setOpenDeclineDialog(false);

  const setStatus = () => {
    switch (customer?.status) {
      case customerFilters.VERIFIED:
        return (
          <Typography sx={styles.cardTopStatus.verified}>Verified</Typography>
        );
      case customerFilters.ACTIVE:
        return <Typography sx={styles.cardTopStatus.active}>Active</Typography>;
      case customerFilters.PENDING:
        return (
          <Typography sx={styles.cardTopStatus.pendingRecommendation}>
            Pending Verification
          </Typography>
        );
      case customerFilters.PROSPECT:
        return (
          <Typography sx={styles.cardTopStatus.prospect}>Prospect</Typography>
        );
      case customerFilters.DENIED:
        return <Typography sx={styles.cardTopStatus.denied}>Denied</Typography>;
      case customerFilters.DELETED:
        return (
          <Typography sx={styles.cardTopStatus.deleted}>Deleted</Typography>
        );
      case customerFilters.INACTIVE:
        return (
          <Typography sx={styles.cardTopStatus.inactive}>Inactive</Typography>
        );
      case customerFilters.SUSPENDED:
        return (
          <Typography sx={styles.cardTopStatus.suspended}>Suspended</Typography>
        );

      default:
        break;
    }
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      <Stack>
        <Box sx={{ display: "flex" }}>
          {/* {customer?.profileImageUrl ? (
            <Avatar
              src={customer?.profileImageUrl}
              sx={{ width: 60, height: 60 }}
            />
          ) : (
            <Avatar sx={{ width: 60, height: 60 }}>
              <PersonIcon />
            </Avatar>
          )} */}
          {kycData?.photoUrl ? (
            <Avatar src={kycData?.photoUrl} sx={{ width: 60, height: 60 }} />
          ) : (
            <Avatar sx={{ width: 60, height: 60 }}>
              <PersonIcon />
            </Avatar>
          )}
          <Stack sx={{ ml: 2 }}>
            <Typography sx={{ fontWeight: 700, fontSize: 24 }}>
              {`${customer?.firstName
                ?.charAt(0)
                .toUpperCase()}${customer?.firstName?.substring(
                1
              )} ${customer?.lastName
                ?.charAt(0)
                .toUpperCase()}${customer?.lastName?.substring(1)}`}
            </Typography>
            <Typography
              sx={{ fontSize: 14, fontWeight: 500, color: "#616663" }}
            >
              {customer?.email}
            </Typography>
          </Stack>
        </Box>
        <Typography sx={{ mt: 1.2 }}>{setStatus()}</Typography>
      </Stack>
      <Box>
        {customer?.status === customerFilters.PENDING ||
        customer?.status === customerFilters.DENIED ? (
          <Button
            variant="contained"
            onClick={handleOpenVerifyDialog}
            sx={{
              boxShadow: "none",
              color: "#fff",
              textTransform: "capitalize",
              px: 3,
              fontSize: 12,
              fontWeight: 600,
              borderRadius: 2,
            }}
          >
            <Box sx={{ mt: 1, pr: 1 }}>
              <img src={checkIcon} alt="check-icon" />
            </Box>
            Verify
          </Button>
        ) : (
          <></>
        )}

        {customer?.status === customerFilters.PENDING ? (
          <Button
            variant="contained"
            onClick={handleOpenDeclineDialog}
            sx={{
              boxShadow: "none",
              color: "#D41818",
              textTransform: "capitalize",
              px: 3,
              fontSize: 12,
              fontWeight: 600,
              borderRadius: 2,
              backgroundColor: "#FFF2F2",
              ml: 2,
              "&:hover": {
                backgroundColor: "#f6adad",
                boxShadow: "none",
              },
            }}
          >
            <Box sx={{ mt: 1, pr: 1 }}>
              <img src={closeIcon} alt="close-icon" />
            </Box>
            Reject
          </Button>
        ) : (
          <></>
        )}
      </Box>

      <Decline
        open={openDeclineDialog}
        handleClose={handleCloseDeclineDialog}
        customer={customer}
      />
      <Verify
        open={openVerifyDialog}
        handleClose={handleCloseVerifyDialog}
        customer={customer}
      />
    </Box>
  );
};

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  Typography,
} from "@mui/material";
import React from "react";
import { styles } from "../../../common/css/styles";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import doneIcon from "../../../common/images/done-icon.svg";
import failedIcon from "../../../common/images/close-1con.svg";

export const OTPFailed = ({ open, handleClose }) => {
  const dispatch = useDispatch();

  return (
    <Dialog open={open} onClose={handleClose}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          //   minWidth: "500px",
        }}
      >
        <DialogContent
          sx={{
            width: "350px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <img src={failedIcon} width="96px" alt="done-icon" />

          <DialogContentText sx={{ textAlign: "center" }}>
            <Typography sx={{ fontSize: 17, fontWeight: 500 }}>
              Unauthorized
            </Typography>
            <Typography
              sx={{ fontSize: 14, fontWeight: 500, color: "#969997" }}
            >
              Failed to send OTP to customer's Mobile Number.
            </Typography>
            <Typography
              sx={{ fontSize: 14, fontWeight: 500, color: "#969997" }}
            >
              Try again
            </Typography>
          </DialogContentText>
        </DialogContent>
      </Box>
    </Dialog>
  );
};

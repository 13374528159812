import whiteCustomerIcon from "../images/white-customer-icon.svg";
import whiteAuditIcon from "../images/white-audit.svg";
// import whiteBridgeIcon from "../images/white-bridge.svg";
// import whiteManagementIcon from "../images/white-user.svg";
// import managementIcon from "../images/userManagement.svg";
import whiteWalletIcon from "../images/white-wallet.svg";
import whiteOverviewIcon from "../images/white-overview.svg";
import whiteChangeDevice from "../images/white-change-device.svg";
import whiteLoansIcon from "../images/white-loan-icon.svg";
import auditIcon from "../images/audit-Icon.svg";
// import bridgeIcon from "../images/bridge-Icon.svg";
import customerIcon from "../images/customer-Icon.svg";
import loansIcon from "../images/loans-icon.svg";
import overviewIcon from "../images/overview-Icon.svg";
import settingsIcon from "../images/settings-Icon.svg";
import settings from "../images/settings.svg";
import walletIcon from "../images/wallet-Icon.svg";
import businessIcon from "../images/business-icon.svg";
import whiteBusinessIcon from "../images/white-business-icon.svg";
// import whiteSmeLoanIcon from "../images/white-sme-loan.svg";
// import smeLoanIcon from "../images/sme-loan.svg";
import { roles } from "./roles";

export const getMenuItems = (role) => [
  {
    id: "overview",
    text: "Overview",
    icon: overviewIcon,
    activeIcon: whiteOverviewIcon,
    path: "/overview",
    visible: true,
  },
  {
    id: "customerVerification",
    text: "Customers",
    icon: customerIcon,
    activeIcon: whiteCustomerIcon,
    path: "/customers",
    visible: true,
    open: false,
    children: [
      {
        id: "customers",
        text: "Accounts",
        icon: walletIcon,
        activeIcon: whiteWalletIcon,
        path: "/customers/accounts",
        visible: true,
      },
      {
        id: "devices",
        text: "Devices",
        icon: auditIcon,
        activeIcon: whiteChangeDevice,
        path: "/customers/change-device",
        visible: true,
      },
      // {
      //   id: "changeDevices",
      //   text: "Devices",
      //   icon: auditIcon,
      //   activeIcon: whiteChangeDevice,
      //   path: "/customers/change-device",
      //   visible: true,
      // },
    ],
  },
  {
    id: "businessVerification",
    text: "Businesses",
    icon: businessIcon,
    activeIcon: whiteBusinessIcon,
    path: "/business",
    visible: true,
    open: false,
    children: [
      {
        id: "registered",
        text: "Registered",
        icon: walletIcon,
        activeIcon: whiteWalletIcon,
        path: "/business/registered",
        visible: true,
      },
      {
        id: "unregistered",
        text: "Unregistered",
        icon: auditIcon,
        activeIcon: whiteChangeDevice,
        path: "/business/unregistered",
        visible: true,
      },
      // {
      //   id: "changeDevices",
      //   text: "Devices",
      //   icon: auditIcon,
      //   activeIcon: whiteChangeDevice,
      //   path: "/customers/change-device",
      //   visible: true,
      // },
    ],
  },
  // {
  //   id: "updatedLoans",
  //   text: "Loans",
  //   icon: loansIcon,
  //   activeIcon: whiteLoansIcon,
  //   path: "/new-loans",
  //   visible: true,
  // },

  {
    id: "loans",
    text: "Loans",
    icon: loansIcon,
    activeIcon: whiteLoansIcon,
    path: "/loans",
    visible: true,
  },

  {
    id: "txns",
    text: "Transactions",
    icon: walletIcon,
    activeIcon: whiteWalletIcon,
    path: "/transactions",
    visible: true,
    open: false,
    children: [
      {
        id: "walletTxns",
        text: "Wallets",
        icon: walletIcon,
        activeIcon: whiteWalletIcon,
        path: "/transactions/wallets",
        visible: true,
      },
      {
        id: "disbursementTxns",
        text: "Disbursements",
        icon: walletIcon,
        activeIcon: whiteWalletIcon,
        path: "/transactions/disbursements",
        visible: true,
      },
      // {
      //   id: "collectionTxns",
      //   text: "Collections",
      //   icon: walletIcon,
      //   activeIcon: whiteWalletIcon,
      //   path: "/transactions/collections",
      //   visible: true,
      // },
    ],
  },

  // {
  //   id: "userMgt",
  //   text: "User Management",
  //   icon: managementIcon,
  //   activeIcon: whiteManagementIcon,
  //   path: "/user-management",
  //   visible: role === roles.MANAGER ? true : false,
  // },
  {
    id: "AdminMgt",
    text: "User Management",
    icon: walletIcon,
    activeIcon: whiteWalletIcon,
    path: "/user-management",
    visible: role === roles.MANAGER ? true : false,
  },
  // {
  //   id: "management",
  //   text: "User Management",
  //   icon: managementIcon,
  //   activeIcon: whiteManagementIcon,
  //   path: "/transactions",
  //   visible: true,
  //   open: false,
  //   children: [
  //     {
  //       id: "AdminMgt",
  //       text: "User Management",
  //       icon: walletIcon,
  //       activeIcon: whiteWalletIcon,
  //       path: "/user-management",
  //       visible: true,
  //     },
  //     {
  //       id: "customerMgt",
  //       text: "Customer Device Management",
  //       icon: auditIcon,
  //       activeIcon: whiteChangeDevice,
  //       path: "/change-device",
  //       visible: true,
  //     },
  //   ],
  // },
  {
    id: "auditLogs",
    text: "Audit Logs",
    icon: auditIcon,
    activeIcon: whiteAuditIcon,
    path: "/audit-logs",
    visible: true,
  },
  {
    id: "settings",
    text: "Settings",
    icon: settingsIcon,
    activeIcon: settings,
    path: "/settings",
    visible: role === roles.MANAGER ? true : false,
    children: [
      {
        id: "interest-rates",
        text: "Interest Rates",
        // icon: walletIcon,
        // activeIcon: whiteWalletIcon,
        path: "/settings/interest-rates",
        visible: true,
      },
      {
        id: "collection-setting",
        text: "Collection Setting",
        // icon: auditIcon,
        // activeIcon: whiteChangeDevice,
        path: "/settings/collection-setting",
        visible: true,
      },
    ],
  },
];

import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import { PhotoReupload } from "./reuploadFilters/PhotoReupload";
import { AddressReupload } from "./reuploadFilters/AddressReupload";
import { GovernmentIDReupload } from "./reuploadFilters/GovernmentIDReupload";
import { useSelector } from "react-redux";

const reuploadFilter = [
  { section: "AddressVerification", label: "Address Verification" },
  { section: "Photo", label: "Photo" },
  { section: "GovernmentID", label: "Government ID" },
];

export const Reupload = ({ customer, data }) => {
  const [selectedSection, setSelectedSection] = useState("AddressVerification");

  const user = useSelector(({ auth }) => auth.user);
  // const customer = loan?.user;
  const role = user?.roles[0]?.name;

  const handleSectionButtonClick = (section) => {
    setSelectedSection(section);
  };

  return (
    <Box sx={{ p: 4, width: "100%" }}>
      {/* Subsection Selectors */}
      <Box sx={{ py: 2 }}>
        {reuploadFilter.map(({ section, label }) => (
          <Button
            key={section}
            variant="outlined"
            sx={{
              height: 30,
              textTransform: "capitalize",
              color: selectedSection === section ? "#000" : "#B5B5B5",
              borderColor: "#B5B5B5",
              borderRadius: 0,
              backgroundColor: selectedSection === section ? "" : "#F7F8F4",
              py: 2,
              fontSize: 12,
            }}
            onClick={() => handleSectionButtonClick(section)}
          >
            {label}
          </Button>
        ))}
      </Box>

      {selectedSection === "Photo" && <PhotoReupload customer={customer} />}

      {role === "MANAGER" && selectedSection === "GovernmentID" && (
        <GovernmentIDReupload customer={customer} />
      )}
      {selectedSection === "AddressVerification" && (
        <AddressReupload customer={customer} kycData={data} />
      )}
    </Box>
  );
};

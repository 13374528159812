import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  Typography,
} from "@mui/material";
import React from "react";
import { styles } from "../../../common/css/styles";

export const DenyLoanView = ({ open, handleClose }) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <DialogContent>
          <Box
            sx={{ width: "280px", display: "flex", justifyContent: "center" }}
          ></Box>
          <DialogContentText>
            <Typography sx={{ fontSize: 17, fontWeight: 500 }}>
              Sorry this loan info can only be viewed by the staff assigned to
              it
            </Typography>
          </DialogContentText>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              mt: 2,
            }}
          >
            <Button
              variant="contained"
              onClick={handleClose}
              sx={{
                ...styles.bridgeCardTop.error,
                fontSize: 11,
                px: 1,
                mx: 1,
              }}
            >
              Cancel
            </Button>
          </Box>
        </DialogContent>
      </Box>
    </Dialog>
  );
};

import { Box, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { styles } from "../../../../../../common/css/styles";
import DownloadIcon from "@mui/icons-material/Download";
import { InfoDialog } from "../../../../../../common/components/InfoDialog";
import { FileOpenOutlined } from "@mui/icons-material";
import { parseUrlFileName } from "../../../../../../common/util";

export const Documents = ({ data }) => {
  const [open, setOpen] = useState(false);
  const [isError, setIsError] = useState(false);
  const [infoText, setInfoText] = useState("");

  const handleDownloadFile = (url, name) => {
    if (!!!url) {
      setIsError(true);
      setInfoText("File not found");
      setOpen(true);
    } else {
      const link = document.createElement("a");
      link.href = url;
      link.download = name; // You can specify a default file name here

      // Append the anchor element to the document body
      document.body.appendChild(link);

      // Programmatically click the anchor element to trigger the download
      link.click();

      // Remove the anchor element from the document
      document.body.removeChild(link);
    }
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          borderBottom: 1,
          pb: 4,
          // pl: 2,
          borderBottomColor: "#F0F1ED",
        }}
      >
        <Box sx={{ width: "100%" }}>
          <Box sx={{ width: "100%" }}>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2 }}>
              <Grid item xs={12}>
                <Typography
                  sx={{ ...styles.kyc.bodyText, py: 1, fontSize: 20 }}
                >
                  Business Documents
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Box>
                  <Box>
                    <Typography
                      sx={{ fontWeight: 400, color: "#62635E", py: 1 }}
                    >
                      RC Number
                    </Typography>
                    <Typography sx={{ fontWeight: 700, color: "#494D4A" }}>
                      {data?.rcNumber ?? "Not Available"}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box>
                  <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
                    Tax ID Number
                  </Typography>
                  <Typography sx={{ fontWeight: 700, color: "#494D4A" }}>
                    {data?.taxIdNumber ?? "Not Available"}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box>
                  <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
                    CAC Number
                  </Typography>
                  <Typography sx={styles.kyc.bodyText}>
                    {data?.cacNumber ?? "Not Available"}
                  </Typography>
                </Box>
              </Grid>
              <Grid xs={6}></Grid>
              <Grid item xs={6} sx={{ mt: 2 }}>
                <Box>
                  <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
                    CAC
                  </Typography>
                  <Box
                    sx={{
                      border: 1,
                      py: 1,
                      px: 2,
                      borderColor: "#DFF3E7",
                      borderRadius: 2,
                      backgroundColor: "#F5FAF7",
                      textTransform: "none",
                      width: "90%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <FileOpenOutlined />
                    <Typography
                      sx={{
                        fontWeight: 600,
                        // color: "#62635E",
                        flex: "1",
                        px: 1,
                        textOverflow: "ellipsis",
                        textWrap: "nowrap",
                        overflow: "hidden",
                      }}
                    >
                      {data?.cacUrl
                        ? parseUrlFileName(data?.cacUrl)
                        : "Not Available"}
                    </Typography>

                    {data?.cacUrl && (
                      <DownloadIcon
                        sx={{ cursor: "pointer" }}
                        onClick={() =>
                          handleDownloadFile(data?.cacUrl, "cac.png")
                        }
                      />
                    )}
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6} sx={{ mt: 2 }}>
                <Box>
                  <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
                    Certificate of business name
                  </Typography>
                  <Box
                    sx={{
                      border: 1,
                      py: 1,
                      px: 2,
                      borderColor: "#DFF3E7",
                      borderRadius: 2,
                      backgroundColor: "#F5FAF7",
                      textTransform: "none",
                      width: "90%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <FileOpenOutlined />
                    <Typography
                      sx={{
                        fontWeight: 600,
                        // color: "#62635E",
                        flex: "1",
                        px: 1,
                        textOverflow: "ellipsis",
                        textWrap: "nowrap",
                        overflow: "hidden",
                      }}
                    >
                      {data?.certificateOfBusinessNameUrl
                        ? parseUrlFileName(data?.certificateOfBusinessNameUrl)
                        : "Not Available"}
                    </Typography>

                    {data?.certificateOfBusinessNameUrl && (
                      <DownloadIcon
                        sx={{ cursor: "pointer" }}
                        onClick={() =>
                          handleDownloadFile(
                            data?.certificateOfBusinessNameUrl,
                            "certificateOfBusinessName.png"
                          )
                        }
                      />
                    )}
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box>
                  <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
                    MEMART
                  </Typography>
                  <Box
                    sx={{
                      border: 1,
                      py: 1,
                      px: 2,
                      borderColor: "#DFF3E7",
                      borderRadius: 2,
                      backgroundColor: "#F5FAF7",
                      textTransform: "none",
                      width: "90%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <FileOpenOutlined />
                    <Typography
                      sx={{
                        fontWeight: 600,
                        // color: "#62635E",
                        flex: "1",
                        px: 1,
                        textOverflow: "ellipsis",
                        textWrap: "nowrap",
                        overflow: "hidden",
                      }}
                    >
                      {data?.memartUrl
                        ? parseUrlFileName(data?.memartUrl)
                        : "Not Available"}
                    </Typography>

                    {data?.memartUrl && (
                      <DownloadIcon
                        sx={{ cursor: "pointer" }}
                        onClick={() =>
                          handleDownloadFile(data?.memartUrl, "memart.png")
                        }
                      />
                    )}
                  </Box>
                </Box>{" "}
              </Grid>
              <Grid item xs={6}>
                <Box>
                  <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
                    SCUML
                  </Typography>
                  <Box
                    sx={{
                      border: 1,
                      py: 1,
                      px: 2,
                      borderColor: "#DFF3E7",
                      borderRadius: 2,
                      backgroundColor: "#F5FAF7",
                      textTransform: "none",
                      width: "90%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <FileOpenOutlined />
                    <Typography
                      sx={{
                        fontWeight: 600,
                        // color: "#62635E",
                        flex: "1",
                        px: 1,
                        textOverflow: "ellipsis",
                        textWrap: "nowrap",
                        overflow: "hidden",
                      }}
                    >
                      {data?.scumlUrl
                        ? parseUrlFileName(data?.scumlUrl)
                        : "Not Available"}
                    </Typography>

                    {data?.scumlUrl && (
                      <DownloadIcon
                        sx={{ cursor: "pointer" }}
                        onClick={() =>
                          handleDownloadFile(data?.scumlUrl, "scuml.png")
                        }
                      />
                    )}
                  </Box>
                </Box>{" "}
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          borderBottom: 1,
          pb: 4,
          borderBottomColor: "#F0F1ED",
        }}
      >
        <Box sx={{ width: "100%" }}>
          <Box sx={{ width: "100%" }}>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2 }}>
              <Grid item xs={12}>
                <Typography
                  sx={{ ...styles.kyc.bodyText, pt: 1, fontSize: 20 }}
                >
                  Guarantor Documents
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Box>
                  <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
                    Board resolution
                  </Typography>
                  <Box
                    sx={{
                      border: 1,
                      py: 1,
                      px: 2,
                      borderColor: "#DFF3E7",
                      borderRadius: 2,
                      backgroundColor: "#F5FAF7",
                      textTransform: "none",
                      width: "90%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <FileOpenOutlined />
                    <Typography
                      sx={{
                        fontWeight: 600,
                        // color: "#62635E",
                        flex: "1",
                        px: 1,
                        textOverflow: "ellipsis",
                        textWrap: "nowrap",
                        overflow: "hidden",
                      }}
                    >
                      {data?.boardResolutionUrl
                        ? parseUrlFileName(data?.boardResolutionUrl)
                        : "Not Available"}
                    </Typography>

                    {data?.boardResolutionUrl && (
                      <DownloadIcon
                        sx={{ cursor: "pointer" }}
                        onClick={() =>
                          handleDownloadFile(
                            data?.boardResolutionUrl,
                            "boardResolution.png"
                          )
                        }
                      />
                    )}
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box>
                  <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
                    Cheque
                  </Typography>
                  <Box
                    sx={{
                      border: 1,
                      py: 1,
                      px: 2,
                      borderColor: "#DFF3E7",
                      borderRadius: 2,
                      backgroundColor: "#F5FAF7",
                      textTransform: "none",
                      width: "90%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <FileOpenOutlined />
                    <Typography
                      sx={{
                        fontWeight: 600,
                        // color: "#62635E",
                        flex: "1",
                        px: 1,
                        textOverflow: "ellipsis",
                        textWrap: "nowrap",
                        overflow: "hidden",
                      }}
                    >
                      {data?.chequeUrl
                        ? parseUrlFileName(data?.chequeUrl)
                        : "Not Available"}
                    </Typography>

                    {data?.chequeUrl && (
                      <DownloadIcon
                        sx={{ cursor: "pointer" }}
                        onClick={() =>
                          handleDownloadFile(data?.chequeUrl, "cheque.png")
                        }
                      />
                    )}
                  </Box>
                </Box>{" "}
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      <InfoDialog
        open={open}
        error={isError}
        text={infoText}
        handleClose={() => setOpen(false)}
      />
    </Box>
  );
};

import { Box, Typography, Button, Stack } from "@mui/material";
import React, { useState } from "react";
import { styles } from "../../../../common/css/styles";
import { businessDetailsFilters } from "../../constants/businessFilters";
import { stage } from "./stages/index";
import { Personal } from "./stages/Personal";
import { Residence } from "./stages/Residence";
import { Photo } from "./stages/Photo";
import { Documents } from "./stages/Documents";
import { BVN } from "./stages/BVN";

export const ContactInfo = ({ data }) => {
  const [activeFilter, setActiveFilter] = useState(
    businessDetailsFilters.PERSONAL
  );

  const filters = [
    {
      label: "Personal",
      value: businessDetailsFilters.PERSONAL,
      visible: true,
    },
    {
      label: "Residence",
      value: businessDetailsFilters.RESIDENCE,
      visible: true,
    },
    {
      label: "Photo",
      value: businessDetailsFilters.PHOTO,
      visible: true,
    },
    {
      label: "BVN",
      value: businessDetailsFilters.BVN,
      visible: true,
    },
    {
      label: "ID Documents",
      value: businessDetailsFilters.IDENTIFICATION,
      visible: true,
    },
  ];

  const handleFilter = (filter) => {
    setActiveFilter(filter);
  };

  const getContactInfoStage = () => {
    switch (activeFilter) {
      case stage.PERSONAL:
        return <Personal data={data} />;
      case stage.RESIDENCE:
        return <Residence data={data} />;
      case stage.PHOTO:
        return <Photo data={data} />;
      case stage.BVN:
        return <BVN data={data} />;
      case stage.IDENTIFICATION:
        return <Documents data={data} />;
      default:
        return <Personal />;
    }
  };

  return (
    <Box>
      <Box sx={{ py: 3, display: "flex" }}>
        {filters
          .filter((filter) => filter.visible)
          .map((filter) => (
            <Button
              onClick={() => handleFilter(filter.value)}
              variant="outlined"
              sx={
                activeFilter === filter.value
                  ? { ...styles.tableFilter.active, fontSize: 14, p: 2 }
                  : { ...styles.tableFilter.inactive, fontSize: 14, p: 2 }
              }
            >
              {filter.label}
            </Button>
          ))}
      </Box>
      <Box sx={{ width: "100%" }}>{getContactInfoStage()}</Box>
    </Box>
  );
};

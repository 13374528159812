import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  Typography,
} from "@mui/material";
import React from "react";
import doneIcon from "../../../common/images/done-icon.svg";

export const ChangeDeviceRejected = ({ open, handleClose }) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          //   minWidth: "500px",
        }}
      >
        <DialogContent
          sx={{
            width: "350px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <img src={doneIcon} width="96px" alt="done-icon" />

          <DialogContentText sx={{ textAlign: "center" }}>
            <Typography sx={{ fontSize: 17, fontWeight: 500 }}>
              Rejected
            </Typography>
            <Typography
              sx={{ fontSize: 14, fontWeight: 500, color: "#969997" }}
            >
              Device change denied.
            </Typography>
            {/* <Typography
              sx={{ fontSize: 14, fontWeight: 500, color: "#969997" }}
            >
              Try again
            </Typography> */}
          </DialogContentText>
        </DialogContent>
      </Box>
    </Dialog>
  );
};

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getbanks } from "../api/bank";
import { handleError } from "../constants/utility";

const initialState = {
  allBanks: [],
  error: [],
};

export const fetchAllBanks = createAsyncThunk(
  "banks/fetchBanks",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await getbanks();
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);

const bankSlice = createSlice({
  name: "bank",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchAllBanks.fulfilled, (state, action) => {
      state.allBanks = action.payload.data.data.bank;
      state.error = [];
    });
    builder.addCase(fetchAllBanks.rejected, (state, action) => {
      state.error = action.payload;
    });
  },
});

export default bankSlice.reducer;

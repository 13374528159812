import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Box, Grid, Typography } from "@mui/material";
import doneIcon from "../../../common/images/done-icon.svg";
import { useDispatch } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useParams } from "react-router-dom";
import { businessFilters } from "../constants/businessFilters";
import { verifyOrDecline } from "../store/businessSlice";

const options = [
  "Contact Info",
  "Business Info",
  "Owner Details",
  "Business Documents",
];

const defaultValues = {
  sections: [],
  reasons: "",
};

const schema = yup.object().shape({
  sections: yup.array().min(1, "Please select at least one section"),
  reasons: yup.string().when("sections", {
    is: (val) => val.length === 0,
    then: () => yup.string().required("Decline reasons are required"),
    otherwise: () => yup.string().notRequired(),
  }),
});

export const Decline = ({ open, handleClose }) => {
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitSuccessful },
    reset,
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  const [openSecondDialog, setOpenSecondDialog] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { uid } = useParams();

  const handleCloseSecondDialog = () => {
    setOpenSecondDialog(false);
    navigate(-1);
  };

  const onSubmit = (data) => {
    dispatch(
      verifyOrDecline({
        businessId: uid,
        status: businessFilters.DENIED,
        message: data.reasons,
        fields: data.sections,
      })
    );

    handleClose();
    setOpenSecondDialog(true);
  };

  useEffect(() => {
    if (isSubmitSuccessful) reset();
  }, [isSubmitSuccessful]);

  useEffect(() => {
    let timeoutId;
    if (openSecondDialog) {
      timeoutId = setTimeout(() => {
        handleCloseSecondDialog();
      }, 2000);
    }

    return () => clearTimeout(timeoutId);
  }, [openSecondDialog]);

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          handleClose();
          reset();
        }}
      >
        <Grid
          component="form"
          noValidate
          onSubmit={handleSubmit(onSubmit)}
          pb={1}
          sx={{ width: 448 }}
        >
          <DialogTitle sx={{ color: "#000" }}>Decline</DialogTitle>

          <DialogContent>
            <Grid container spacing={3}>
              <Grid item sx={{ width: "100%" }}>
                <Typography sx={{ fontSize: 14, fontWeight: 400, pb: 1 }}>
                  Reasons for declining the verification
                </Typography>
                <Controller
                  name="reasons"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      size="small"
                      error={!!errors.reasons}
                      helperText={errors.reasons?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item sx={{ width: "100%" }}>
                <Typography sx={{ fontSize: 14, fontWeight: 400, pb: 1 }}>
                  Please select the section with wrong credentials
                </Typography>
                <Controller
                  name="sections"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <Autocomplete
                      multiple
                      id="multiselect-chip"
                      options={options}
                      onChange={(event, newValue) => onChange(newValue)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Select section"
                          placeholder="Options"
                          size="small"
                          fullWidth
                          error={!!errors.sections}
                          helperText={errors.sections?.message}
                        />
                      )}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            key={index}
                            label={option}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                    />
                  )}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                handleClose();
                reset();
              }}
              sx={{
                textTransform: "capitalize",
                fontSize: 14,
                color: "#616663",
              }}
            >
              Close
            </Button>
            <Button
              variant="contained"
              type="submit"
              sx={{
                boxShadow: "none",
                color: "#D41818",
                textTransform: "capitalize",
                px: 3,
                fontSize: 12,
                fontWeight: 600,
                borderRadius: 2,
                backgroundColor: "#FFF2F2",
                ml: 2,
                mr: 2,
                "&:hover": {
                  backgroundColor: "#f6adad",
                  boxShadow: "none",
                },
              }}
            >
              Reject
            </Button>
          </DialogActions>
        </Grid>
      </Dialog>

      {/* Second Dialog */}
      <Dialog open={openSecondDialog} onClose={handleCloseSecondDialog}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <DialogContent>
            <Box
              sx={{ width: "100%", display: "flex", justifyContent: "center" }}
            >
              <img src={doneIcon} alt="done Icon" />
            </Box>
            <DialogContentText sx={{ textAlign: "center" }}>
              Decline was successful.
            </DialogContentText>
          </DialogContent>
        </Box>
      </Dialog>
    </>
  );
};

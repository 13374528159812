import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import { EditOutlined } from "@mui/icons-material";
import { ManagePenaltyPeriod } from "./components/ManagePenaltyPeriod";

export const PenaltyPeriod = ({ data = {}, refetch }) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  return (
    <>
      <Box
        sx={{
          width: "100%",
          backgroundColor: "white",
          borderRadius: "0.5rem",
        }}
      >
        <Typography sx={{ paddingX: 2, py: 2, fontSize: 20, fontWeight: 500 }}>
          Penalty Period
        </Typography>
        <Box
          sx={{
            display: "flex",
            px: 4,
            pb: 2,
            justifyContent: "space-between",
            color: "#323232",
          }}
        >
          <Typography sx={{ color: "#303332" }}>
            {data?.value ?? 0} day{data?.value && "s"}
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={handleDialogOpen}
          >
            <Typography sx={{ color: "#303332", mr: 1 }}>Edit</Typography>
            <EditOutlined fontSize="15" />
          </Box>
        </Box>
      </Box>
      {dialogOpen && (
        <ManagePenaltyPeriod
          open={dialogOpen}
          handleClose={handleDialogClose}
          data={data}
          refetch={refetch}
        />
      )}
    </>
  );
};

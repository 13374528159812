import { client } from ".";

export const getInterestRates = () => {
  return client.get("get-interest-rates/retail");
};

export const postInterestRate = ({ loanType, interestRates }) => {
  return client.patch("set-interest-rates", { loanType, interestRates });
};

export const getCollection = () => {
  return client.get("/settings/all");
};

export const updateCollection = (data) => {
  return client.patch("/settings/update", data);
};

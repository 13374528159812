import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { toTitleCase } from "../../../common/constants/utility";
import { useSelector } from "react-redux";
import { loanStatusFilters } from "../constants/retailFilters";

export const RecommendationInfo = ({ loan }) => {
  const interestRates = useSelector(({ settings }) => settings.interestRates);

  const getPresetInterestRate = (loanAmount) => {
    const range = interestRates.find(
      (x) => x.lowerBound <= loanAmount && x.upperBound >= loanAmount
    );
    if (range) return `${range.interestRate}%`;
    else return "Not Set";
  };

  const isBeforeOffer =
    loan.status === loanStatusFilters.PENDING_OFFER ||
    loan.status === loanStatusFilters.PENDING_RECOMMENDATION;
  return (
    <>
      <Box
        sx={{
          width: "100%",
          pt: 2,
        }}
      >
        {loan.reasonsForRecommendation ? (
          <Box>
            <Typography
              sx={{ fontWeight: 800, color: "#62635E", fontSize: 18 }}
            >
              {`Recommendation for ${
                loan?.recommendOffer ? "offer" : "decline"
              } by ${toTitleCase(loan.recommender?.firstName)} ${toTitleCase(
                loan.recommender?.lastName
              )}`}
            </Typography>

            <Box
              sx={{ display: "flex", justifyContent: "space-between", my: 2 }}
            >
              <Grid container rowSpacing={2}>
                <Grid item xs={6}>
                  <Typography sx={{ fontSize: 16, fontWeight: 400 }}>
                    Requested loan amount
                  </Typography>
                  <Typography sx={{ fontSize: 16, fontWeight: 700, py: 1 }}>
                    {loan.loanAmount?.toLocaleString("en-NG", {
                      style: "currency",
                      currency: "NGN",
                    }) ?? "Not Available"}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ fontSize: 16, fontWeight: 400 }}>
                    Recomended offer amount
                  </Typography>
                  <Typography sx={{ fontSize: 16, fontWeight: 700, py: 1 }}>
                    {loan.recommendedAmount?.toLocaleString("en-NG", {
                      style: "currency",
                      currency: "NGN",
                    }) ?? "Not Available"}
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <Typography sx={{ fontSize: 16, fontWeight: 400 }}>
                    Preset interest rate
                  </Typography>
                  <Typography sx={{ fontSize: 16, fontWeight: 700, py: 1 }}>
                    {getPresetInterestRate(loan.loanAmount)}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ fontSize: 16, fontWeight: 400 }}>
                    Recommended interest rate
                  </Typography>
                  {loan.recommendedInterestRate && (
                    <Typography sx={{ fontSize: 16, fontWeight: 700, py: 1 }}>
                      {loan.recommendedInterestRate}%
                    </Typography>
                  )}
                </Grid>

                <Grid item xs={6}>
                  <Typography sx={{ fontSize: 16, fontWeight: 400 }}>
                    Requested tenure
                  </Typography>
                  <Typography sx={{ fontSize: 16, fontWeight: 700, py: 1 }}>
                    {loan.loanTenure === 1
                      ? `${loan.loanTenure} Month`
                      : `${loan.loanTenure} Months`}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ fontSize: 16, fontWeight: 400 }}>
                    Recommended tenure
                  </Typography>
                  {loan.recommendedTenure && (
                    <Typography sx={{ fontSize: 16, fontWeight: 700, py: 1 }}>
                      {loan.recommendedTenure === 1
                        ? `${loan.recommendedTenure} Month`
                        : `${loan.recommendedTenure} Months`}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Box>

            <Box sx={{ mb: 2 }}>
              <Typography sx={{ fontSize: 14, fontWeight: 700, mb: 1 }}>
                {`Reason for recommendation for ${
                  loan?.recommendOffer ? "offer" : "decline"
                }`}
              </Typography>
              {loan?.reasonsForRecommendation?.map((reason) => (
                <Typography
                  sx={{
                    py: 2,
                    borderBottom: 1,
                    borderBottomColor: "#F0F1ED",
                    fontSize: 14,
                    fontWeight: 400,
                    // textTransform: "capitalize",
                  }}
                >
                  {`${reason.charAt(0).toUpperCase()}${reason.substring(1)}`}
                  {/* {reason} */}
                </Typography>
              ))}
            </Box>

            {!isBeforeOffer && (
              <>
                <Typography
                  sx={{ fontWeight: 800, color: "#62635E", fontSize: 18 }}
                >
                  {`Offer ${
                    loan?.status === "DECLINED" ? "declined" : "made"
                  } by ${toTitleCase(loan.offerer?.firstName)} ${toTitleCase(
                    loan.offerer?.lastName
                  )}`}
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    my: 2,
                  }}
                >
                  <Grid container rowSpacing={2}>
                    <Grid item xs={6}>
                      <Typography sx={{ fontSize: 16, fontWeight: 400 }}>
                        Requested loan amount
                      </Typography>
                      <Typography sx={{ fontSize: 16, fontWeight: 700, py: 1 }}>
                        {loan.loanAmount?.toLocaleString("en-NG", {
                          style: "currency",
                          currency: "NGN",
                        }) ?? "Not Available"}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography sx={{ fontSize: 16, fontWeight: 400 }}>
                        Offered amount
                      </Typography>
                      <Typography sx={{ fontSize: 16, fontWeight: 700, py: 1 }}>
                        {loan.loanOfferAmount?.toLocaleString("en-NG", {
                          style: "currency",
                          currency: "NGN",
                        }) ?? "Not Available"}
                      </Typography>
                    </Grid>

                    <Grid item xs={6}>
                      <Typography sx={{ fontSize: 16, fontWeight: 400 }}>
                        Preset interest rate
                      </Typography>
                      <Typography sx={{ fontSize: 16, fontWeight: 700, py: 1 }}>
                        {getPresetInterestRate(loan.loanAmount)}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography sx={{ fontSize: 16, fontWeight: 400 }}>
                        Offered Interest rate
                      </Typography>
                      <Typography sx={{ fontSize: 16, fontWeight: 700, py: 1 }}>
                        {loan.recommendedInterestRate}%
                      </Typography>
                    </Grid>

                    <Grid item xs={6}>
                      <Typography sx={{ fontSize: 16, fontWeight: 400 }}>
                        Requested tenure
                      </Typography>
                      <Typography sx={{ fontSize: 16, fontWeight: 700, py: 1 }}>
                        {loan.loanTenure === 1
                          ? `${loan.loanTenure} Month`
                          : `${loan.loanTenure} Months`}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography sx={{ fontSize: 16, fontWeight: 400 }}>
                        Offered tenure
                      </Typography>
                      <Typography sx={{ fontSize: 16, fontWeight: 700, py: 1 }}>
                        {loan.recommendedTenure === 1
                          ? `${loan.loanOfferTenure} Month`
                          : `${loan.loanOfferTenure} Months`}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>

                <Box sx={{ mb: 2 }}>
                  <Typography sx={{ fontSize: 14, fontWeight: 700, mb: 1 }}>
                    {`Reason for  ${
                      loan?.status !== "DECLINED" ? "offer" : "decline"
                    }`}
                  </Typography>
                  {loan?.reasonsForLoanRequestApproval?.map((reason) => (
                    <Typography
                      sx={{
                        py: 2,
                        borderBottom: 1,
                        borderBottomColor: "#F0F1ED",
                        fontSize: 14,
                        fontWeight: 400,
                        // textTransform: "capitalize",
                      }}
                    >
                      {`${reason.charAt(0).toUpperCase()}${reason.substring(
                        1
                      )}`}
                      {/* {reason} */}
                    </Typography>
                  ))}
                </Box>
              </>
            )}
          </Box>
        ) : (
          <Box>
            <Typography sx={{ fontSize: 16, fontWeight: 400 }}>
              Not Available
            </Typography>
          </Box>
        )}
      </Box>
    </>
  );
};

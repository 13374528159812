import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { styles } from "../../../common/css/styles";
import { DisburseSuccess } from "./DisburseSuccess";
import { DisburseFailed } from "./DisburseFailed";
import { useMutation } from "@tanstack/react-query";
import { disburse } from "../../../common/api/loans";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import { parseError } from "../../../common/constants/utility";

export const DisburseConfirmation = ({ open, handleClose, loan }) => {
  const [openDisburseSuccess, setOpenDisburseSuccess] = useState(false);
  const [openDisburseFailed, setOpenDisburseFailed] = useState(false);
  const navigate = useNavigate();
  const handleCloseDisbursedSuccess = () => {
    navigate("/loans", { replace: true });
    // setOpenDisburseSuccess(false);
  };

  const handleCloseDisbursedFailed = () => {
    setOpenDisburseFailed(false);
    if (handleClose) handleClose();
  };

  const { isLoading, mutate } = useMutation({
    mutationFn: (data) => disburse(data),
    mutationKey: "disburseMent",
    onError: (err) => {
      setOpenDisburseFailed(parseError(err));
    },
    onSuccess: (data) => {
      setOpenDisburseSuccess(true);
    },
  });

  const handleDisbursement = () => {
    mutate({
      loanUid: loan.uid ?? loan.loanUid,
      // accountNumber: loan?.walletAccountNumber,
      accountNumber: loan?.wallet?.accountNumber,
      amount: loan?.loanOfferAmount,
    });
  };

  return (
    <>
      {!(openDisburseSuccess || openDisburseFailed) && (
        <Dialog open={open} onClose={handleClose}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              minWidth: "500px",
            }}
          >
            <DialogContent>
              <Box
                sx={{
                  width: "280px",
                  display: "flex",
                  justifyContent: "center",
                }}
              ></Box>
              <DialogContentText>
                <Typography sx={{ fontSize: 17, fontWeight: 500 }}>
                  Disburse Loan
                </Typography>
                <Typography
                  sx={{ fontSize: 14, fontWeight: 500, color: "#969997" }}
                >
                  Are you sure you want to disburse this loan?
                </Typography>
              </DialogContentText>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  mt: 2,
                }}
              >
                <Button
                  variant="contained"
                  onClick={handleClose}
                  sx={{
                    ...styles.bridgeCardTop.error,
                    fontSize: 11,
                    px: 1,
                    mx: 1,
                  }}
                >
                  Cancel
                </Button>
                <LoadingButton
                  loading={isLoading}
                  variant="contained"
                  sx={{ ...styles.bridgeCardTop.success, fontSize: 11, px: 1 }}
                  onClick={handleDisbursement}
                >
                  Accept
                </LoadingButton>
              </Box>
            </DialogContent>
          </Box>
        </Dialog>
      )}

      <DisburseSuccess
        open={openDisburseSuccess}
        handleClose={handleCloseDisbursedSuccess}
        loan={loan}
      />
      <DisburseFailed
        open={openDisburseFailed}
        handleClose={handleCloseDisbursedFailed}
        error={openDisburseFailed}
      />
    </>
  );
};

import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { styles } from "../../../../../common/css/styles";
import { toTitleCase } from "../../../../../common/constants/utility";

export const Operations = ({ data }) => {
  return (
    <Box
      sx={{
        display: "flex",
        // justifyContent: "space-between",
        width: "100%",
      }}
    >
      <Box sx={{ mr: 15 }}>
        <Stack>
          <Box sx={{ py: 1 }}>
            <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
              How business Operates
            </Typography>
            <Typography sx={{ fontWeight: 700, color: "#494D4A" }}>
              {toTitleCase(data?.modeOfOperation)}
            </Typography>
          </Box>
          <Box sx={{ py: 1 }}>
            <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
              Number of Locations
            </Typography>
            <Typography sx={{ fontWeight: 700, color: "#494D4A" }}>
              {data?.numberOfLocations}
            </Typography>
          </Box>
          <Box sx={{ py: 1 }}>
            <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
              Average Monthly Profit
            </Typography>
            <Typography sx={styles.kyc.bodyText}>
              {data?.averageMonthlyProfit}
            </Typography>
          </Box>
          <Box sx={{ py: 1 }}>
            <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
              Average Monthly Salary Of Employee
            </Typography>
            <Typography sx={styles.kyc.bodyText}>
              {data.totalMonthlySalaryOfEmployees}
            </Typography>
          </Box>
        </Stack>
      </Box>

      <Box>
        <Stack>
          <Box sx={{ py: 1 }}>
            <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
              Number Of Employees
            </Typography>
            <Typography sx={{ fontWeight: 700, color: "#494D4A" }}>
              {data?.numbersOfEmployee}
            </Typography>
          </Box>
          <Box sx={{ py: 1 }}>
            <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
              Total number of Sales in the last 6 months
            </Typography>
            <Typography sx={{ fontWeight: 700, color: "#494D4A" }}>
              {data?.totalSalesInLastSixMonths}
            </Typography>
          </Box>
          <Box sx={{ py: 1 }}>
            <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
              average monthly electricity cost
            </Typography>
            <Typography sx={styles.kyc.bodyText}>
              {data?.averageMonthlyElectricityCost}
            </Typography>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};

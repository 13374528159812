import {
  Box,
  Grid,
  TextField,
  Typography,
  Link,
  IconButton,
  FormControl,
  InputAdornment,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { styles } from "../../common/css/styles";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { userLogin } from "./authSlice";
import { useNavigate } from "react-router-dom";
import DisplayError from "../../common/components/DisplayError";
import { LoadingButton } from "@mui/lab";
import { feedback } from "../../common/constants/feedback";

const defaultValues = {
  email: "",
  password: "",
};

const schema = yup.object().shape({
  email: yup
    .string()
    .email("Please enter a valid email")
    .required("Email address is required"),
  password: yup.string().required("Password is required"),
});

export const Login = () => {
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  const [showPassword, setShowPassword] = useState(false);
  const [showError, setShowError] = useState(false);
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onSubmit = (data) => dispatch(userLogin(data));

  useEffect(() => {
    if (auth?.isAuthenticated === feedback.SUCCESSFUL)
      navigate("/overview", { replace: true });
    if (auth?.isAuthenticated === feedback.FAILED) setShowError(true);
  }, [auth.isAuthenticated]);

  return (
    <>
      <Box
        sx={{
          background: "white",
          borderRadius: 2,
          py: 4,
          px: 4,
          justifyContent: "center",
        }}
      >
        <Grid
          container
          item
          position="relative"
          direction="column"
          alignItems="center"
        >
          <Box
            container
            item
            md={6}
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            sx={{
              backgroundColor: "primary.main",
              ml: -1,
              pt: 1,
              pb: 1,
              borderRadius: "30px",
              position: "absolute",
              top: -53,
              pl: 2.5,
              pr: 2.5,
            }}
          >
            <Typography sx={{ color: "white", width: "100%" }}>
              Login
            </Typography>
          </Box>
        </Grid>

        <Typography variant="h6" sx={styles.loginFormHeader}>
          Welcome, Staff! 👋🏽
        </Typography>
        <Typography sx={styles.formSubheader}>
          Enter your details to log into your staff account.
        </Typography>

        <Grid
          container
          direction={"column"}
          spacing={3}
          component="form"
          noValidate
          pt={1}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Grid item>
            <Typography variant="body1" sx={styles.formFieldHeading}>
              Email
            </Typography>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  variant="outlined"
                  size={styles.textfieldSize}
                  required
                  fullWidth
                  placeholder="e.g. johndoe@gmail.com"
                  error={!!errors.email}
                  helperText={errors.email?.message}
                  sx={styles.textField}
                />
              )}
            />
          </Grid>

          <Grid item>
            <Typography variant="body1" sx={styles.formFieldHeading}>
              Password
            </Typography>
            <FormControl variant="outlined" sx={styles.textField} fullWidth>
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    fullWidth
                    size={styles.textfieldSize}
                    placeholder="Enter Password"
                    type={showPassword ? "text" : "password"}
                    error={!!errors.password}
                    helperText={errors.password?.message}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            sx={{ color: "black", mr: -2 }}
                          >
                            {showPassword ? (
                              <VisibilityOffOutlined />
                            ) : (
                              <VisibilityOutlined />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </FormControl>
            <Box
              sx={{
                display: "flex",
                justifyContent: "right",
              }}
            >
              <Link
                href="/reset-password"
                underline="none"
                sx={{
                  fontWeight: 600,
                  fontSize: 14,
                  pt: 2,
                  textTransform: "capitalize",
                  // width: "100%",
                }}
              >
                <Typography sx={{ fontSize: 12 }}>Forgot Password?</Typography>
              </Link>
            </Box>

            {/* <Typography>Forgot Password?</Typography> */}
          </Grid>

          <Grid item>
            <LoadingButton
              variant="contained"
              type="submit"
              disableElevation
              disabled={isSubmitting}
              loading={isSubmitting}
              sx={styles.continueButton2}
              fullWidth
            >
              Continue
            </LoadingButton>
          </Grid>
          {/* <Grid container item spacing={0} direction="column" alignItems="center">
          <Stack>
            <Typography
              variant="body1"
              sx={{
                fontWeight: 600,
                fontSize: 14,
                pb: 0.5,
              }}
            >
              <Link
                href="/reset-password"
                underline="none"
                sx={{
                  fontWeight: 600,
                  fontSize: 14,
                  pb: 1,
                  textTransform: "capitalize",
                }}
              >
                Reset Password
              </Link>
            </Typography>
          </Stack>
        </Grid> */}
        </Grid>
      </Box>
      <DisplayError
        error={auth.error}
        open={showError}
        handleClose={() => setShowError(false)}
      />
    </>
  );
};

import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import {
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { Edit } from "@mui/icons-material";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { nigerianStates } from "../../../common/constants/nigerianStates";
import { useDispatch, useSelector } from "react-redux";
import { styles } from "../../../common/css/styles";
import { stage } from "../stages";
import { clearKycFeedback, submitKyc } from "../kycSlice";
import { toTitleCase } from "../../../common/constants/utility";
import { feedback } from "../../../common/constants/feedback";
import { InfoDialog } from "../../../common/components/InfoDialog";
import axios from "axios";
import { format } from "date-fns";

const defaultValues = {
  residentialAddress: "",
  city: "",
  state: "",
  // utilityBill: "",
};

const schema = yup.object().shape({
  residentialAddress: yup.string().required("Residential address is required"),
  city: yup.string().required("LGA is required"),
  state: yup.string().required("State is required"),
  // utilityBill: yup.mixed().required("Utility bill is required"),
});

export const ResidenceEdit = ({ open, handleClose, kycData }) => {
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitSuccessful },
    watch,
    reset,
    setValue,
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  const [openSecondDialog, setOpenSecondDialog] = useState(false);
  const [isError, setIsError] = useState(false);
  const [infoText, setInfoText] = useState("");
  const customer = useSelector(({ customer }) => customer.customer);
  const kyc = useSelector((state) => state.kyc);
  const dispatch = useDispatch();
  const selectedState = watch("state");

  const handleCloseSecondDialog = () => {
    dispatch(clearKycFeedback());
    setOpenSecondDialog(false);
  };

  const getUtilityBill = async () => {
    const response = await axios.get(
      kycData.utilityBillUrl, // "https://upload.wikimedia.org/wikipedia/commons/7/77/Delete_key1.jpg",
      {
        responseType: "blob",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, HEAD, POST",
        },
      }
    );
    const blob = await response.data;
    let file = new File(
      [blob],
      `utilityBill_${format(new Date(), "ddMMyyyyhhmmss")}`
    );
    setValue("utilityBill", file);
  };

  const onSubmit = (data) => {
    dispatch(
      submitKyc({
        ...data,
        userUid: customer.uid,
        onboardingStage: stage.RESIDENCE,
      })
    );

    handleClose();
  };

  useEffect(() => {
    if (isSubmitSuccessful) reset();
  }, [isSubmitSuccessful]);

  useEffect(() => {
    if (kyc.feedback === feedback.UPDATED) {
      setInfoText("Residence details successfully updated.");
      setIsError(false);
      setOpenSecondDialog(true);
    }
    if (kyc.feedback === feedback.UPDATE_FAILED) {
      setInfoText(kyc?.error?.join("\n") ?? "Failed to update details");
      setIsError(true);
      setOpenSecondDialog(true);
    }
  }, [kyc.feedback]);

  useEffect(() => {
    if (kycData) {
      setValue("residentialAddress", toTitleCase(kycData?.residentialAddress));
      setValue("state", kycData?.state?.toLowerCase());
      setValue("city", kycData?.city?.toLowerCase());
      // getUtilityBill();
    }
  }, []);

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          reset();
          handleClose();
        }}
      >
        <Grid
          py={2}
          sx={{ width: 500 }}
          component="form"
          noValidate
          onSubmit={handleSubmit(onSubmit)}
        >
          <DialogTitle sx={{ color: "#000" }}>
            Edit Residence Information
          </DialogTitle>

          <DialogContent>
            <Grid container spacing={2} direction="column" pr={2}>
              <Grid item>
                <Typography variant="body1" sx={styles.formFieldHeading}>
                  Residential Address
                </Typography>
                <Controller
                  name="residentialAddress"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      fullWidth
                      size={styles.textfieldSize}
                      placeholder="e.g. 123, Four Str."
                      sx={{
                        ...styles.textField,
                        "& fieldset": { width: "100%" },
                      }}
                      error={!!errors.residentialAddress}
                      helperText={errors.residentialAddress?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item>
                <Typography variant="body1" sx={styles.formFieldHeading}>
                  State
                </Typography>
                <Controller
                  name="state"
                  control={control}
                  render={({ field }) => (
                    <FormControl
                      fullWidth
                      sx={{
                        ...styles.textField,
                        "& fieldset": { width: "100%" },
                      }}
                    >
                      <Select
                        {...field}
                        size={styles.textfieldSize}
                        error={!!errors.state}
                      >
                        {nigerianStates.map((state) => (
                          <MenuItem
                            key={state.code}
                            value={state.name.toLowerCase()}
                          >
                            {state.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors.state ? (
                        <FormHelperText error>
                          {errors?.state?.message}
                        </FormHelperText>
                      ) : (
                        <></>
                      )}
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item>
                <Typography variant="body1" sx={styles.formFieldHeading}>
                  LGA
                </Typography>
                <Controller
                  name="city"
                  control={control}
                  render={({ field }) => (
                    <FormControl
                      fullWidth
                      sx={{
                        ...styles.textField,
                        "& fieldset": { width: "100%" },
                      }}
                    >
                      <Select
                        {...field}
                        size={styles.textfieldSize}
                        error={!!errors.city}
                      >
                        {!!selectedState &&
                          nigerianStates
                            .filter(
                              (state) =>
                                state.name.toLowerCase() === selectedState
                            )[0]
                            .lgas.map((lga, index) => (
                              <MenuItem key={index} value={lga.toLowerCase()}>
                                {lga}
                              </MenuItem>
                            ))}
                      </Select>
                      {errors.city ? (
                        <FormHelperText error>
                          {errors?.city?.message}
                        </FormHelperText>
                      ) : (
                        <></>
                      )}
                    </FormControl>
                  )}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                reset();
                handleClose();
              }}
              sx={{
                textTransform: "capitalize",
                fontSize: 14,
                color: "#616663",
              }}
            >
              Close
            </Button>
            <Button
              variant="contained"
              type="submit"
              sx={{
                boxShadow: "none",
                color: "#fff",
                textTransform: "capitalize",
                px: 2,
                fontSize: 12,
                fontWeight: 600,
                borderRadius: 2,
                backgroundColor: "primary.main",
                mx: 2,
              }}
            >
              Edit <Edit sx={{ fontSize: 12, pl: 1 }} />
            </Button>
          </DialogActions>
        </Grid>
      </Dialog>

      {/* Second Dialog */}
      <InfoDialog
        open={openSecondDialog}
        handleClose={handleCloseSecondDialog}
        text={infoText}
        error={isError}
      />
    </>
  );
};

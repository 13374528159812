import {
  Box,
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { DeadState } from "../../../../../common/components/DeadState";
import { styles } from "../../../../../common/css/styles";
import { useNavigate } from "react-router-dom";
import { businessLoanFilters } from "../../constants/businessLoanFilters";

const columns = [
  {
    id: "loanAmount",
    label: "Loan amount",
    minWidth: 100,
    align: "left",
  },
  {
    id: "tenure",
    label: "Tenure",
    minWidth: 50,
    align: "left",
  },
  {
    id: "date",
    label: "Date",
    minWidth: 50,
    align: "left",
  },
  {
    id: "totalInterest",
    label: "Total Interest",
    minWidth: 100,
    align: "left",
  },
  {
    id: "totalRepayment",
    label: "Total Repayment",
    minWidth: 120,
    align: "left",
  },
  {
    id: "loanStatus",
    label: "Loan Status",
    minWidth: 50,
    align: "left",
  },
  {
    id: "view",
    label: "View",
    minWidth: 50,
    align: "left",
  },
];

const tempLoanList = [
  {
    uid: "7e75b85a-8d17-4abb-9958-42695ea0fbca",
    firstName: "Lex",
    lastName: "Luthor",
    loanAmount: "N2,500,400",
    tenure: "3Months",
    totalInterest: "N100,000",
    date: "23/09/2023",
    totalRepayment: "N100,000",
    status: "PENDING_RECOMMENDATION",
  },
  {
    uid: "7e75b85a-8d17-4abb-9958-42695ea0fbca",
    firstName: "Lex",
    lastName: "Luthor",
    loanAmount: "N2,500,000",
    tenure: "3Months",
    totalInterest: "N100,000",
    date: "23/09/2023",
    totalRepayment: "N100,000",
    status: "ACTION",
  },
  {
    uid: "7e75b85a-8d17-4abb-9958-42695ea0fbca",
    firstName: "Lex",
    lastName: "Luthor",
    loanAmount: "N2,500,000",
    tenure: "3Months",
    totalInterest: "N100,000",
    date: "23/09/2023",
    totalRepayment: "N100,000",
    status: "COMPLETE",
  },
  {
    uid: "7e75b85a-8d17-4abb-9958-42695ea0fbca",
    firstName: "Lex",
    lastName: "Luthor",
    loanAmount: "N2,500,000",
    tenure: "3Months",
    totalInterest: "N100,000",
    date: "23/09/2023",
    totalRepayment: "N100,000",
    status: "COMPLETE",
  },
  {
    uid: "7e75b85a-8d17-4abb-9958-42695ea0fbca",
    firstName: "Lex",
    lastName: "Luthor",
    loanAmount: "N2,500,000",
    tenure: "3Months",
    totalInterest: "N100,000",
    date: "23/09/2023",
    totalRepayment: "N100,000",
    status: "COMPLETE",
  },
  {
    uid: "7e75b85a-8d17-4abb-9958-42695ea0fbca",
    firstName: "Lex",
    lastName: "Luthor",
    loanAmount: "N2,500,000",
    tenure: "3Months",
    totalInterest: "N100,000",
    date: "23/09/2023",
    totalRepayment: "N100,000",
    status: "REJECTED_OFFER",
  },
  {
    uid: "7e75b85a-8d17-4abb-9958-42695ea0fbca",
    firstName: "Lex",
    lastName: "Luthor",
    loanAmount: "N2,500,000",
    tenure: "3Months",
    totalInterest: "N100,000",
    date: "23/09/2023",
    totalRepayment: "N100,000",
    status: "OFFERED",
  },
  {
    uid: "7e75b85a-8d17-4abb-9958-42695ea0fbca",
    firstName: "Lex",
    lastName: "Luthor",
    loanAmount: "N2,500,000",
    tenure: "3Months",
    totalInterest: "N100,000",
    date: "23/09/2023",
    totalRepayment: "N100,000",
    status: "DEFAULTING",
  },
];

export const Loan = ({ customer, data }) => {
  const navigate = useNavigate();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);

  const openDialog = () => {
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  //  const openAddDialog = () => {
  //    setIsAddDialogOpen(true);
  //  };

  const closeAddDialog = () => {
    setIsAddDialogOpen(false);
  };

  const handleAddButtonClick = () => {
    setIsAddDialogOpen(true);
  };

  const handleView = (uid) =>
    navigate(
      `/sme-loans/business-loans/business-loan-overview/loan-details/${uid}`
    );

  const getStatusDisplay = (status) => {
    switch (status) {
      case businessLoanFilters.APPLICATION_STATUS:
        return (
          <Typography sx={styles.table.text.customerStatus.prospect}>
            Application
          </Typography>
        );
      case businessLoanFilters.OFFERED:
        return (
          <Typography sx={styles.table.text.customerStatus.pending}>
            Offered
          </Typography>
        );
      case businessLoanFilters.REJECTED_OFFERS:
        return (
          <Typography sx={styles.cardTopStatus.rejected}>
            RejectedOffered
          </Typography>
        );
      case businessLoanFilters.DEFAULTING:
        return (
          <Typography sx={styles.table.text.customerStatus.rejected}>
            Defaulting
          </Typography>
        );
      case businessLoanFilters.DECLINED:
        return (
          <Typography sx={styles.cardTopStatus.rejected}>Declined</Typography>
        );
      case businessLoanFilters.ACTIVE:
        return (
          <Typography sx={styles.table.text.customerStatus.verified}>
            Active
          </Typography>
        );
      case businessLoanFilters.COMPLETED:
        return (
          <Typography sx={styles.table.text.customerStatus.pending}>
            Completed
          </Typography>
        );
      case businessLoanFilters.NTU:
        return (
          <Typography sx={styles.table.text.customerStatus.prospect}>
            Application
          </Typography>
        );
      default:
        return (
          <Typography sx={styles.table.text.customerStatus.pending}>
            Completed
          </Typography>
        );
    }
  };

  return (
    <>
      <Box
        sx={{
          // display: "flex",
          justifyContent: "space-between",
          // width: "70%",
          // width: customer?.status === customerFilters.PENDING ? "100%" : "70%",
        }}
      >
        <Box
          sx={{
            border: 1,
            px: 2,
            py: 1,
            borderRadius: 2,
            borderColor: "#0000000D",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              pb: 2,
            }}
          >
            {/* <Button
                onClick={handleAddButtonClick}
                //   variant="outlined"
                sx={{
                  height: 29,
                  border: 1,
                  color: "#494D4A",
                  borderColor: "#61666333",
                  mt: 2.5,
                  textTransform: "capitalize",
                  fontSize: 12,
                }}
              >
                <Add sx={{ fontSize: 12, pr: 1 }} /> Add
              </Button> */}
          </Box>
          <Paper
            sx={{
              width: "100%",
              overflow: "hidden",
              border: "none",
              boxShadow: "none",
            }}
          >
            <TableContainer sx={{ maxHeight: 400 }}>
              <Table
                // stickyHeader
                aria-label="sticky table"
                sx={{ backgroundColor: "transparent" }}
              >
                <TableHead>
                  <TableRow
                    sx={{
                      "& .MuiTableCell-head": {
                        color: "#000",
                        backgroundColor: "transparent",
                        border: "4px solid black",
                      },
                    }}
                  >
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{
                          minWidth: column.minWidth,
                          borderTop: "1px solid #B5B5B5",
                          borderLeft: "none",
                          borderRight: "none",
                          borderBottom: "1px solid #B5B5B5",
                          fontSize: 13,
                          fontWeight: 600,
                          height: 20,
                        }}
                        noWrap
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tempLoanList ? (
                    tempLoanList?.map((loan) => (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={loan.uid}
                        sx={{
                          "& .MuiTableCell-body": {
                            color: "#62635E",
                            backgroundColor: "#fff",
                            border: "none",
                            fontSize: 13,
                            fontWeight: 400,
                          },
                        }}
                      >
                        <TableCell
                          sx={{ textTransform: "capitalize", width: 50 }}
                        >
                          {loan.loanAmount}
                        </TableCell>
                        <TableCell sx={{ textTransform: "capitalize" }}>
                          {loan.tenure}
                        </TableCell>
                        <TableCell sx={{ textTransform: "capitalize" }}>
                          {loan.date}
                        </TableCell>
                        <TableCell sx={{ textTransform: "capitalize" }}>
                          {loan.totalInterest}
                        </TableCell>
                        <TableCell sx={{ textTransform: "capitalize" }}>
                          {loan.totalRepayment}
                        </TableCell>
                        <TableCell align="left">
                          {getStatusDisplay(loan.status)}
                        </TableCell>
                        <TableCell align="left">
                          <Button
                            variant="outlined"
                            onClick={() => handleView(loan.uid)}
                            sx={styles.table.text.viewButton}
                          >
                            View
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableCell
                      colSpan={columns.length}
                      sx={{ textAlign: "center" }}
                    >
                      <DeadState />
                    </TableCell>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      </Box>
    </>
  );
};

import { Box, Typography } from "@mui/material";
import React from "react";
import { styles } from "../../../common/css/styles";
import { format } from "date-fns";

export const LoanDetails = ({ loan, data }) => {
  return (
    <>
      <Box sx={{ width: "100%", py: 2 }}>
        <Typography sx={{ fontWeight: 600, fontSize: 20, color: "#232900" }}>
          Loan Details
        </Typography>
        <Box
          sx={{
            // width: "100%",
            display: "flex",
            alignItems: "center",
            mt: 1,
          }}
        >
          <Typography sx={styles.loandDetails.cardBox.textTop}>
            Loan Amount
          </Typography>{" "}
          -{" "}
          <Typography sx={styles.loandDetails.cardBox.textBottom}>
            {loan?.loanOfferAmount?.toLocaleString("en-NG", {
              style: "currency",
              currency: "NGN",
            })}
          </Typography>
        </Box>
        <Box
          sx={{
            // width: "100%",
            display: "flex",
            alignItems: "center",
            mt: 1,
          }}
        >
          <Typography sx={styles.loandDetails.cardBox.textTop}>
            Loan Interest Rate
          </Typography>{" "}
          -{" "}
          <Typography sx={styles.loandDetails.cardBox.textBottom}>
            {loan?.loanOfferInterestRate}%
          </Typography>
        </Box>
        <Box
          sx={{
            // width: "100%",
            display: "flex",
            alignItems: "center",
            mt: 1,
          }}
        >
          <Typography sx={styles.loandDetails.cardBox.textTop}>
            Loan Tenure
          </Typography>{" "}
          -{" "}
          <Typography sx={styles.loandDetails.cardBox.textBottom}>
            {loan?.loanOfferTenure} Month(s)
          </Typography>
        </Box>
        <Box
          sx={{
            // width: "100%",
            display: "flex",
            alignItems: "center",
            mt: 1,
          }}
        >
          <Typography sx={styles.loandDetails.cardBox.textTop}>
            Monthly Repayment
          </Typography>{" "}
          -{" "}
          <Typography sx={styles.loandDetails.cardBox.textBottom}>
            {loan?.loanOfferMonthlyRepayment?.toLocaleString("en-NG", {
              style: "currency",
              currency: "NGN",
            })}
          </Typography>
        </Box>
        <Box
          sx={{
            // width: "100%",
            display: "flex",
            alignItems: "center",
            mt: 1,
          }}
        >
          <Typography sx={styles.loandDetails.cardBox.textTop}>
            Total Amount to be repaid
          </Typography>{" "}
          -{" "}
          <Typography sx={styles.loandDetails.cardBox.textBottom}>
            {loan?.loanOfferTotalRepayment?.toLocaleString("en-NG", {
              style: "currency",
              currency: "NGN",
            })}
          </Typography>
        </Box>
        <Box
          sx={{
            // width: "100%",
            display: "flex",
            alignItems: "center",
            mt: 1,
          }}
        >
          <Typography sx={styles.loandDetails.cardBox.textTop}>
            Next Repayment Date
          </Typography>{" "}
          -{" "}
          <Typography sx={styles.loandDetails.cardBox.textBottom}>
            {loan?.nextRepaymentDate
              ? format(
                  new Date(loan.nextRepaymentDate),
                  "dd/MM/yyyy hh:mm:ss aa"
                )
              : "Not Available"}
          </Typography>
        </Box>

        {/* <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            mt: 1,
          }}
        >
          <Box sx={styles.loandDetails.cardBox}>
            <Typography sx={styles.loandDetails.cardBox.textTop}>
              Loan Amount
            </Typography>
          </Box>
          <Box sx={styles.loandDetails.cardBox}>
            <Typography sx={styles.loandDetails.cardBox.textTop}>
              Loan Interest Rate
            </Typography>
            <Typography sx={styles.loandDetails.cardBox.textBottom}>
              {loan?.loanOfferInterestRate}%
            </Typography>
          </Box>
          <Box sx={styles.loandDetails.cardBox}>
            <Typography sx={styles.loandDetails.cardBox.textTop}>
              Loan Tenure
            </Typography>
            <Typography sx={styles.loandDetails.cardBox.textBottom}>
              {loan?.loanOfferTenure} Month(s)
            </Typography>
          </Box>
          <Box sx={styles.loandDetails.cardBox}>
            <Typography sx={styles.loandDetails.cardBox.textTop}>
              Monthly Repayment
            </Typography>
            <Typography sx={styles.loandDetails.cardBox.textBottom}>
              {loan?.loanOfferMonthlyRepayment?.toLocaleString("en-NG", {
                style: "currency",
                currency: "NGN",
              })}
            </Typography>
          </Box>
          <Box sx={styles.loandDetails.cardBox}>
            <Typography sx={styles.loandDetails.cardBox.textTop}>
              Total Amount to be repaid
            </Typography>
            <Typography sx={styles.loandDetails.cardBox.textBottom}>
              {loan?.loanOfferTotalRepayment?.toLocaleString("en-NG", {
                style: "currency",
                currency: "NGN",
              })}
            </Typography>
          </Box>
        </Box> */}
      </Box>
    </>
  );
};

import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { styles } from "../../../../../common/css/styles";
import { format } from "date-fns";
import { toTitleCase } from "../../../../../common/constants/utility";

export const Profile = ({ data }) => {
  return (
    <Box
      sx={{
        display: "flex",
        // justifyContent: "space-between",
        width: "100%",
      }}
    >
      <Box sx={{ mr: 25 }}>
        <Stack>
          <Box sx={{ py: 1 }}>
            <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
              Date business operation started
            </Typography>
            <Typography sx={{ fontWeight: 700, color: "#494D4A" }}>
              {format(
                new Date(data?.dateBusinessOperationStarted),
                "dd/MM/yyyy"
              )}
            </Typography>
          </Box>
          <Box sx={{ py: 1 }}>
            <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
              Industry
            </Typography>
            <Typography sx={{ fontWeight: 700, color: "#494D4A" }}>
              {toTitleCase(data?.industry)}
            </Typography>
          </Box>
          <Box sx={{ py: 1 }}>
            <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
              Business website
            </Typography>
            <Typography sx={styles.kyc.normalText}>
              {data?.businessWebsiteUrl
                ? data?.businessWebsiteUrl.toLowerCase()
                : "Not Available"}
            </Typography>
          </Box>
          <Box sx={{ py: 1 }}>
            <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
              Twitter
            </Typography>
            <Typography sx={styles.kyc.normalText}>
              {data?.twitterUrl
                ? data?.twitterUrl.toLowerCase()
                : "Not Available"}
            </Typography>
          </Box>
          <Box sx={{ py: 1 }}>
            <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
              Instagram
            </Typography>
            <Typography sx={styles.kyc.normalText}>
              {data?.instagramUrl
                ? data?.instagramUrl.toLowerCase()
                : "Not Available"}
            </Typography>
          </Box>
        </Stack>
      </Box>

      <Box>
        <Stack>
          <Box sx={{ py: 1 }}>
            <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
              Number Of Employees
            </Typography>
            <Typography sx={{ fontWeight: 700, color: "#494D4A" }}>
              {data?.numbersOfEmployee}
            </Typography>
          </Box>
          <Box sx={{ py: 1 }}>
            <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
              Sub Industry
            </Typography>
            <Typography sx={{ fontWeight: 700, color: "#494D4A" }}>
              {toTitleCase(data?.subIndustry)}
            </Typography>
          </Box>
          <Box sx={{ py: 1 }}>
            <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
              LinkedIn
            </Typography>
            <Typography sx={styles.kyc.normalText}>
              {data?.linkedinUrl
                ? data?.linkedinUrl.toLowerCase()
                : "Not Available"}
            </Typography>
          </Box>
          <Box sx={{ py: 1 }}>
            <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
              Facebook
            </Typography>
            <Typography sx={styles.kyc.normalText}>
              {data?.facebookUrl
                ? data?.facebookUrl.toLowerCase()
                : "Not Available"}
            </Typography>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};

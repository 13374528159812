import { Grid, Typography, Button, Box } from "@mui/material";
import { Add } from "@mui/icons-material";
import React from "react";

export const SectionHeader = ({ headerText }) => {
  return (
    <Grid>
      <Typography
        sx={{
          fontWeight: 700,
          fontSize: 32,
          py: 3,
          pl: 2,
          // background: "linear-gradient(to bottom, #27AD6014, transparent)",
        }}
      >
        {headerText}
      </Typography>
    </Grid>
  );
};

export const SectionHeaderWithButton = ({ headerText }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        py: 3,
      }}
    >
      <Typography sx={{ fontWeight: 700, fontSize: 32 }}>
        {headerText}
      </Typography>
      {/* <Button
        variant="contained"
        // onClick={handleOpenVerifyDialog}
        sx={{
          boxShadow: "none",
          color: "#fff",
          textTransform: "capitalize",
          px: 4,
          py: 1,
          display: "flex",
          fontSize: 12,
          fontWeight: 600,
          borderRadius: 1,
        }}
      >
        Mark As Completed
      </Button> */}
    </Box>
  );
};

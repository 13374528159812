import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import {
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { Edit } from "@mui/icons-material";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { nigerianStates } from "../../../common/constants/nigerianStates";
import { useDispatch, useSelector } from "react-redux";
import { styles } from "../../../common/css/styles";
import { clearKycFeedback, submitKyc } from "../kycSlice";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format, parse } from "date-fns";
import { stage } from "../stages";
import { phoneRegex } from "../../../common/constants/regex";
import { toTitleCase } from "../../../common/constants/utility";
import { feedback } from "../../../common/constants/feedback";
import { InfoDialog } from "../../../common/components/InfoDialog";

const defaultValues = {
  kinFirstName: "",
  kinLastName: "",
  kinEmail: "",
  kinDateOfBirth: "",
  kinPhoneNumber: "",
  kinRelationship: "",
  kinResidentialAddress: "",
  kinCity: "",
  kinState: "",
};

const schema = yup.object().shape({
  kinFirstName: yup.string().required("First name is required"),
  kinLastName: yup.string().required("Last name is required"),
  kinEmail: yup
    .string()
    .email("Please enter a valid email address")
    .required("Email address is required"),
  kinDateOfBirth: yup
    .date()
    .transform(function (value, originalValue) {
      if (this.isType(value)) {
        return value;
      }
      const result = parse(originalValue, "MM/dd/yyyy", new Date());
      return result;
    })
    .typeError("Please enter a valid date")
    .required("Date of birth is required"),
  kinPhoneNumber: yup
    .string()
    .matches(phoneRegex, "Enter a valid phone number")
    .required("Phone number is required"),
  kinRelationship: yup.string().required("Relationship is required"),
  kinResidentialAddress: yup
    .string()
    .required("Residential address is required"),
  kinCity: yup.string().required("LGA is required"),
  kinState: yup.string().required("State is required"),
});

export const NextOfKinEdit = ({ open, handleClose, kycData }) => {
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitSuccessful },
    watch,
    reset,
    setValue,
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  const [openSecondDialog, setOpenSecondDialog] = useState(false);
  const [isError, setIsError] = useState(false);
  const [infoText, setInfoText] = useState("");
  const customer = useSelector(({ customer }) => customer.customer);
  const kyc = useSelector((state) => state.kyc);
  const dispatch = useDispatch();
  const selectedState = watch("kinState");

  const handleCloseSecondDialog = () => {
    dispatch(clearKycFeedback());
    setOpenSecondDialog(false);
  };

  const onSubmit = (data) => {
    dispatch(
      submitKyc({
        ...data,
        kinDateOfBirth: format(data.kinDateOfBirth, "dd-MM-yyyy"),
        userUid: customer.uid,
        onboardingStage: stage.NEXT_OF_KIN,
      })
    );
    handleClose();
  };

  useEffect(() => {
    if (isSubmitSuccessful) reset();
  }, [isSubmitSuccessful]);

  useEffect(() => {
    if (kyc.feedback === feedback.UPDATED) {
      setInfoText("Next of Kin details successfully updated.");
      setIsError(false);
      setOpenSecondDialog(true);
    }
    if (kyc.feedback === feedback.UPDATE_FAILED) {
      setInfoText(kyc?.error?.join("\n") ?? "Failed to update details");
      setIsError(true);
      setOpenSecondDialog(true);
    }
  }, [kyc.feedback]);

  useEffect(() => {
    if (kycData) {
      setValue("kinFirstName", toTitleCase(kycData?.kinFirstName));
      setValue("kinLastName", toTitleCase(kycData?.kinLastName));
      setValue("kinEmail", kycData?.kinEmail);
      setValue("kinPhoneNumber", kycData?.kinPhoneNumber);
      setValue("kinState", kycData?.kinState?.toLowerCase());
      setValue("kinCity", kycData?.kinCity?.toLowerCase());
      setValue(
        "kinDateOfBirth",
        parse(kycData?.kinDateOfBirth, "dd-MM-yyyy", new Date())
      );
      setValue("kinRelationship", toTitleCase(kycData?.kinRelationship));
      setValue(
        "kinResidentialAddress",
        toTitleCase(kycData?.kinResidentialAddress)
      );
    }
  }, [open]);

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          handleClose();
          reset();
        }}
      >
        <Grid
          py={2}
          sx={{ width: 500 }}
          component="form"
          noValidate
          onSubmit={handleSubmit(onSubmit)}
        >
          <DialogTitle sx={{ color: "#000" }}>
            Edit Next of Kin Information
          </DialogTitle>

          <DialogContent>
            <Grid container spacing={2} direction="column" pr={2}>
              <Grid item>
                <Typography variant="body1" sx={styles.formFieldHeading}>
                  First Name
                </Typography>
                <Controller
                  name="kinFirstName"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      required
                      size={styles.textfieldSize}
                      variant="outlined"
                      fullWidth
                      placeholder="e.g. John"
                      error={!!errors.kinFirstName}
                      helperText={errors.kinFirstName?.message}
                      sx={{
                        ...styles.textField,
                        "& fieldset": { width: "100%" },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item>
                <Typography variant="body1" sx={styles.formFieldHeading}>
                  Last Name
                </Typography>
                <Controller
                  name="kinLastName"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      required
                      variant="outlined"
                      fullWidth
                      size={styles.textfieldSize}
                      placeholder="e.g. Doe"
                      error={!!errors.kinLastName}
                      helperText={errors.kinLastName?.message}
                      sx={{
                        ...styles.textField,
                        "& fieldset": { width: "100%" },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item>
                <Typography variant="body1" sx={styles.formFieldHeading}>
                  Email Address
                </Typography>
                <Controller
                  name="kinEmail"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      required
                      variant="outlined"
                      fullWidth
                      size={styles.textfieldSize}
                      placeholder="e.g. johndoe@gmail.com"
                      error={!!errors.kinEmail}
                      helperText={errors.kinEmail?.message}
                      sx={{
                        ...styles.textField,
                        "& fieldset": { width: "100%" },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item>
                <Typography variant="body1" sx={styles.formFieldHeading}>
                  Phone Number
                </Typography>
                <Controller
                  name="kinPhoneNumber"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      required
                      variant="outlined"
                      fullWidth
                      size={styles.textfieldSize}
                      placeholder="e.g. 12345678901"
                      error={!!errors.kinPhoneNumber}
                      helperText={errors.kinPhoneNumber?.message}
                      sx={{
                        ...styles.textField,
                        "& fieldset": { width: "100%" },
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item>
                <Typography variant="body1" sx={styles.formFieldHeading}>
                  DOB
                </Typography>
                <LocalizationProvider fullWidth dateAdapter={AdapterDateFns}>
                  <Controller
                    name="kinDateOfBirth"
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        {...field}
                        inputFormat="MM/dd/yyyy"
                        sx={styles.datePicker}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            error={!!errors.kinDateOfBirth}
                            helperText={errors.kinDateOfBirth?.message}
                            fullWidth
                            size={styles.textfieldSize}
                            autoComplete="off"
                            variant="outlined"
                            sx={styles.datePicker}
                          />
                        )}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item>
                <Typography variant="body1" sx={styles.formFieldHeading}>
                  Relationship
                </Typography>
                <Controller
                  name="kinRelationship"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      required
                      variant="outlined"
                      fullWidth
                      size={styles.textfieldSize}
                      placeholder="e.g. Brother"
                      error={!!errors.kinRelationship}
                      helperText={errors.kinRelationship?.message}
                      sx={{
                        ...styles.textField,
                        "& fieldset": { width: "100%" },
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item>
                <Typography variant="body1" sx={styles.formFieldHeading}>
                  Residential Address
                </Typography>
                <Controller
                  name="kinResidentialAddress"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      required
                      variant="outlined"
                      fullWidth
                      size={styles.textfieldSize}
                      placeholder="e.g. 123, Four Str."
                      error={!!errors.kinResidentialAddress}
                      helperText={errors.kinResidentialAddress?.message}
                      sx={{
                        ...styles.textField,
                        "& fieldset": { width: "100%" },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item>
                <Typography variant="body1" sx={styles.formFieldHeading}>
                  State
                </Typography>
                <Controller
                  name="kinState"
                  control={control}
                  render={({ field }) => (
                    <FormControl
                      fullWidth
                      sx={{
                        ...styles.textField,
                        "& fieldset": { width: "100%" },
                      }}
                    >
                      <Select
                        {...field}
                        size={styles.textfieldSize}
                        error={!!errors.kinState}
                      >
                        {nigerianStates.map((state) => (
                          <MenuItem
                            key={state.code}
                            value={state.name.toLowerCase()}
                          >
                            {state.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors.kinState ? (
                        <FormHelperText error>
                          {errors?.kinState?.message}
                        </FormHelperText>
                      ) : (
                        <></>
                      )}
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item>
                <Typography variant="body1" sx={styles.formFieldHeading}>
                  LGA
                </Typography>
                <Controller
                  name="kinCity"
                  control={control}
                  render={({ field }) => (
                    <FormControl
                      fullWidth
                      sx={{
                        ...styles.textField,
                        "& fieldset": { width: "100%" },
                      }}
                    >
                      <Select
                        {...field}
                        size={styles.textfieldSize}
                        error={!!errors.kinCity}
                      >
                        {!!selectedState &&
                          nigerianStates
                            .filter(
                              (state) =>
                                state.name.toLowerCase() === selectedState
                            )[0]
                            .lgas.map((lga, index) => (
                              <MenuItem key={index} value={lga.toLowerCase()}>
                                {lga}
                              </MenuItem>
                            ))}
                      </Select>
                      {errors.kinCity ? (
                        <FormHelperText error>
                          {errors?.kinCity?.message}
                        </FormHelperText>
                      ) : (
                        <></>
                      )}
                    </FormControl>
                  )}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                handleClose();
                reset();
              }}
              sx={{
                textTransform: "capitalize",
                fontSize: 14,
                color: "#616663",
              }}
            >
              Close
            </Button>
            <Button
              variant="contained"
              type="submit"
              sx={{
                boxShadow: "none",
                color: "#fff",
                textTransform: "capitalize",
                px: 2,
                fontSize: 12,
                fontWeight: 600,
                borderRadius: 2,
                backgroundColor: "primary.main",
                mx: 2,
              }}
            >
              Edit <Edit sx={{ fontSize: 12, pl: 1 }} />
            </Button>
          </DialogActions>
        </Grid>
      </Dialog>

      {/* Second Dialog */}
      <InfoDialog
        open={openSecondDialog}
        handleClose={handleCloseSecondDialog}
        text={infoText}
        error={isError}
      />
    </>
  );
};

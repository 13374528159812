/* eslint-disable react-hooks/exhaustive-deps */
import { Grid } from "@mui/material";
import React, { useEffect } from "react";

import { SectionHeader } from "../../common/components/SectionHeader";
import { LoanPortfolioMetrics } from "./components/LoanPortfolioMetrics";
import { LoanPortfolioStatistics } from "./components/LoanPortfolioStatistics";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCustomerStats,
  fetchLoanInsights,
  fetchLoanMetrics,
  fetchLoanStats,
} from "./overviewSlice";
import { overviewFilters } from "./overviewFilters";
import { format } from "date-fns";
import { retailFilters } from "../loans/constants/retailFilters";
import { loanType } from "../smeLoans/constants/loanType";
import { LoanInsight } from "./components/LoanInsight";
import { Customers } from "./components/Customers";

const loanStatus = [
  { label: "All Disbursed", value: "" },
  { label: "Active", value: retailFilters.ACTIVE },
  { label: "Defaulting", value: retailFilters.DEFAULTING },
  { label: "Completed", value: retailFilters.COMPLETED },
  { label: "Lost", value: retailFilters.LOST },
];

const loanTypes = [
  { label: "All Loan Types", value: "" },
  {
    label: "Bridge Financing",
    value: loanType.BRIDGE_FINANCING,
  },
  { label: "Retail Loans", value: loanType.RETAIL },
  { label: "Business Loans", value: loanType.BUSINESS },
  { label: "Asset Financing", value: loanType.ASSET_FINANCING },
];

const ranges = [
  { label: "Last 7 days", value: overviewFilters.LAST7DAYS },
  {
    label: "Last 14 days",
    value: overviewFilters.LAST14DAYS,
  },
  { label: "Last 1 month", value: overviewFilters.ONE_MONTH },
  { label: "Last 2 months", value: overviewFilters.TWO_MONTHS },
  { label: "Last 3 months", value: overviewFilters.THREE_MONTHS },
  { label: "Custom Date", value: overviewFilters.CUSTOM },
];

const getDateRange = (filter) => {
  const d = new Date();
  const range = {
    startDate: "",
    endDate: format(d, "yyyy-MM-dd"),
  };

  switch (filter) {
    case overviewFilters.LAST7DAYS:
      range.startDate = format(d.setDate(d.getDate() - 7), "yyyy-MM-dd");
      break;
    case overviewFilters.LAST14DAYS:
      range.startDate = format(d.setDate(d.getDate() - 14), "yyyy-MM-dd");
      break;
    case overviewFilters.ONE_MONTH:
      range.startDate = format(d.setMonth(d.getMonth() - 1), "yyyy-MM-dd");
      break;
    case overviewFilters.TWO_MONTHS:
      range.startDate = format(d.setMonth(d.getMonth() - 2), "yyyy-MM-dd");
      break;
    case overviewFilters.THREE_MONTHS:
      range.startDate = format(d.setMonth(d.getMonth() - 3), "yyyy-MM-dd");
      break;
    default:
      range.startDate = format(d.setDate(d.getDate() - 7), "yyyy-MM-dd");
      break;
  }
  return range;
};

export const Overview = () => {
  const overview = useSelector((state) => state.overview);
  const dispatch = useDispatch();

  useEffect(() => {
    const range = getDateRange(overviewFilters.LAST7DAYS);
    dispatch(
      fetchLoanMetrics({
        ...range,
        type: "ALL",
        status: "ALL",
      })
    );
    dispatch(fetchLoanStats({ type: "ALL", ...range }));
    dispatch(fetchCustomerStats({ ...range }));
    dispatch(
      fetchLoanInsights({ ...range, type: "ALL", status: "ALL", page: 1 })
    );
  }, []);

  return (
    <>
      <Grid
        container
        sx={{ width: "100%", display: "flex", flexDirection: "column" }}
      >
        <SectionHeader headerText="Dashboard" />

        <LoanPortfolioMetrics
          overview={overview}
          loanStatus={loanStatus}
          loanTypes={loanTypes}
          ranges={ranges}
        />
        <LoanPortfolioStatistics
          overview={overview}
          loanTypes={loanTypes}
          ranges={ranges}
        />
        <Customers
          overview={overview}
          ranges={ranges}
          customersStatistics={overview.customersPie}
        />

        <LoanInsight
          overview={overview}
          loanTypes={loanTypes}
          ranges={ranges}
          loanStatus={loanStatus}
        />
      </Grid>
    </>
  );
};

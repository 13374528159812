import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getKyc, getUpdatedNIN, updateKyc } from "../../common/api/kyc";
import { feedback } from "../../common/constants/feedback";
import { handleError } from "../../common/constants/utility";

const initialState = {
  data: null,
  nin: null,
  error: [],
  feedback: "",
  ninFeedback: "",
};

export const getKycInfo = createAsyncThunk(
  "user/getKyc",
  async (uid, { dispatch, rejectWithValue }) => {
    try {
      const response = await getKyc(uid);
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);

export const submitKyc = createAsyncThunk(
  "user/updateKyc",
  async (model, { dispatch, rejectWithValue }) => {
    try {
      const response = await updateKyc(model);
      dispatch(getKycInfo(model.userUid));
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);

export const fetchNINDetails = createAsyncThunk(
  "user/fetchNIN",
  async (userUid, { dispatch, rejectWithValue }) => {
    try {
      const response = await getUpdatedNIN(userUid);
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);

const kycSlice = createSlice({
  name: "kyc",
  initialState,
  reducers: {
    setStage: (state, action) => {
      state.currentStage = action.payload;
    },
    resetKyc: () => initialState,
    clearKycFeedback: (state, action) => {
      state.feedback = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getKycInfo.pending, (state, action) => {
      state.feedback = feedback.PENDING;
      state.error = [];
    });
    builder.addCase(getKycInfo.fulfilled, (state, action) => {
      state.data = action.payload.data;
      state.feedback = feedback.SUCCESSFUL;
      state.error = [];
    });
    builder.addCase(getKycInfo.rejected, (state, action) => {
      state.data = null;
      state.feedback = feedback.FAILED;
      state.error = action.payload;
    });
    builder.addCase(submitKyc.pending, (state, action) => {
      state.feedback = feedback.PENDING;
      state.error = [];
    });
    builder.addCase(submitKyc.fulfilled, (state, action) => {
      state.feedback = feedback.UPDATED;
      state.error = [];
    });
    builder.addCase(submitKyc.rejected, (state, action) => {
      state.feedback = feedback.UPDATE_FAILED;
      state.error = action.payload;
    });
    builder.addCase(fetchNINDetails.pending, (state, action) => {
      state.ninFeedback = feedback.PENDING;
      state.error = [];
    });
    builder.addCase(fetchNINDetails.fulfilled, (state, action) => {
      state.nin = action.payload.data.identificationDetails;
      state.ninFeedback = feedback.SUCCESSFUL;
      state.error = [];
    });
    builder.addCase(fetchNINDetails.rejected, (state, action) => {
      state.ninFeedback = feedback.FAILED;
      state.error = action.payload;
    });
  },
});

export const { setStage, resetKyc, clearKycFeedback } = kycSlice.actions;
export default kycSlice.reducer;

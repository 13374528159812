import React from "react";
import { DeadState } from "../../common/components/DeadState";

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { formatCurrencyvalue } from "../../common/util";
import dayjs from "dayjs";

const columns = [
  {
    id: "amount",
    label: "Principal",
    minWidth: 50,
    align: "left",
  },
  {
    id: "interest",
    label: "Interest",
    minWidth: 50,
    align: "left",
  },
  {
    id: "amount",
    label: "Total Amount",
    minWidth: 50,
    align: "left",
  },
  {
    id: "date",
    label: "Due Date",
    minWidth: 50,
    align: "left",
  },
];

export default function LoanRepaymentSchedule({ loan }) {
  const {
    loanOfferTenure,
    loanOfferAmount,
    loanOfferTotalInterest,
    loanOfferTotalRepayment,
    nextRepayment,
  } = loan;

  const prepareData = () => {
    const items = [];
    for (let index = 0; index < loanOfferTenure; index++) {
      items.push({
        loanOfferMonthlyRepayment: loanOfferAmount / loanOfferTenure,
        loanOfferTotalInterest: loanOfferTotalInterest / loanOfferTenure,
        loanOfferTotalRepayment: loanOfferTotalRepayment / loanOfferTenure,
        date: dayjs(nextRepayment).add(index, "month").format("DD/MM/YYYY"),
      });
    }

    return items;
  };

  return (
    <Paper
      sx={{
        width: "100%",
        overflow: "hidden",
        border: "none",
        boxShadow: "none",
      }}
    >
      <TableContainer sx={{ maxHeight: 400 }}>
        <Table
          stickyHeader
          aria-label="sticky table"
          sx={{ backgroundColor: "transparent" }}
        >
          <TableHead>
            <TableRow
              sx={{
                "& .MuiTableCell-head": {
                  color: "#000",
                  backgroundColor: "transparent",
                  border: "4px solid black",
                },
              }}
            >
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{
                    minWidth: column.minWidth,
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                    borderBottom: "0.3px solid #B5B5B5",
                    fontSize: 13,
                    fontWeight: 600,
                    height: 20,
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {prepareData()?.length > 0 ? (
              prepareData()?.map((loan) => (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={loan.uid}
                  sx={{
                    "& .MuiTableCell-body": {
                      color: "#62635E",
                      backgroundColor: "#fff",
                      border: "none",
                      fontSize: 13,
                      fontWeight: 400,
                    },
                  }}
                >
                  <TableCell sx={{ textTransform: "capitalize" }}>
                    {formatCurrencyvalue(loan.loanOfferMonthlyRepayment)}
                  </TableCell>
                  <TableCell sx={{ textTransform: "capitalize" }}>
                    {formatCurrencyvalue(loan.loanOfferTotalInterest)}
                  </TableCell>
                  <TableCell sx={{ textTransform: "capitalize" }}>
                    {formatCurrencyvalue(loan.loanOfferTotalRepayment)}
                  </TableCell>
                  <TableCell align="left">{loan.date}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableCell colSpan={columns.length} sx={{ textAlign: "center" }}>
                <DeadState />
              </TableCell>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

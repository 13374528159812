import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  Fade,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { ArrowBack } from "@mui/icons-material";
import { styles } from "../../../common/css/styles";
import { CardTop } from "./components/CardTop";
import { useDispatch, useSelector } from "react-redux";
import { stage } from "../../kyc/stages";
import { Personal } from "../../kyc/stages/Personal";
import { useNavigate } from "react-router-dom";
import { WhiteBox } from "../../../common/components/WhiteBox";
import { SectionHeader } from "../../../common/components/SectionHeader";
import { customerStage } from "../constants/customerStage";
import { clearCustomer, clearCustomerFeedback } from "../store/customerSlice";
import { AddPersonal } from "./stages/AddPersonal";
import { AddResidence } from "./stages/AddResidence";
import { AddEmployment } from "./stages/AddEmployment";
import { AddBank } from "./stages/AddBank";
import { AddNextOfKin } from "./stages/AddNextOfKin";
import { AddDocuments } from "./stages/AddDocuments";
import { fetchAllBanks } from "../../../common/store/bankSlice";
import failedIcon from "../../../common/images/close-1con.svg";
import { InfoDialog } from "../../../common/components/InfoDialog";
import { clearKycFeedback } from "../../kyc/kycSlice";
import { feedback } from "../../../common/constants/feedback";

export const AddCustomer = () => {
  const kyc = useSelector((state) => state.kyc);
  const customer = useSelector((state) => state.customer);
  const [open, setOpen] = useState(false);
  const [isError, setIsError] = useState(false);
  const [infoText, setInfoText] = useState("");
  const [customerData, setCustomerData] = useState({});
  const [currentStage, setCurrentStage] = useState(stage.PERSONAL);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClose = () => {
    dispatch(clearCustomerFeedback());
    dispatch(clearKycFeedback());
    setOpen(false);
  };

  const handleAddCustomerData = (data) => {
    setCustomerData(data);
  };

  const getKYCStage = () => {
    switch (currentStage) {
      case stage.PERSONAL:
        return (
          <AddPersonal
            handleTabChange={handleTabChange}
            customerData={customerData}
            handleAddCustomerData={handleAddCustomerData}
          />
        );
      case stage.RESIDENCE:
        return (
          <AddResidence
            handleTabChange={handleTabChange}
            customerData={customerData}
            handleAddCustomerData={handleAddCustomerData}
          />
        );
      case stage.EMPLOYMENT:
        return (
          <AddEmployment
            handleTabChange={handleTabChange}
            customerData={customerData}
            handleAddCustomerData={handleAddCustomerData}
          />
        );
      case stage.BANK:
        return (
          <AddBank
            handleTabChange={handleTabChange}
            customerData={customerData}
            handleAddCustomerData={handleAddCustomerData}
          />
        );
      case stage.NEXT_OF_KIN:
        return (
          <AddNextOfKin
            handleTabChange={handleTabChange}
            customerData={customerData}
            handleAddCustomerData={handleAddCustomerData}
          />
        );
      case customerStage.REUPLOAD:
        return (
          <AddDocuments
            handleTabChange={handleTabChange}
            customerData={customerData}
            handleAddCustomerData={handleAddCustomerData}
          />
        );
      default:
        return (
          <Personal
            handleTabChange={handleTabChange}
            customerData={customerData}
            handleAddCustomerData={handleAddCustomerData}
          />
        );
    }
  };

  const handleBackToTable = () => {
    dispatch(clearCustomer());
    navigate(-1);
  };

  const handleTabChange = (stage) => setCurrentStage(stage);

  useEffect(() => {
    dispatch(fetchAllBanks());
  }, []);

  useEffect(() => {
    if (
      customer.feedback === feedback.SUCCESSFUL ||
      kyc.feedback === feedback.SUCCESSFUL
    ) {
      dispatch(clearCustomerFeedback());
      dispatch(clearKycFeedback());
      switch (currentStage) {
        case stage.PERSONAL:
          handleTabChange(stage.RESIDENCE);
          break;
        case stage.RESIDENCE:
          handleTabChange(stage.EMPLOYMENT);
          break;
        case stage.EMPLOYMENT:
          handleTabChange(stage.BANK);
          break;
        case stage.BANK:
          handleTabChange(stage.NEXT_OF_KIN);
          break;
        case stage.NEXT_OF_KIN:
          handleTabChange(customerStage.REUPLOAD);
          break;
        default:
          break;
      }
    }
    if (
      kyc.feedback === feedback.UPDATED &&
      currentStage === customerStage.REUPLOAD
    ) {
      setIsError(false);
      setInfoText("Successfully uploaded document");
      setOpen(true);
    }
    if (
      kyc.feedback === feedback.UPDATE_FAILED ||
      customer.feedback === feedback.FAILED
    ) {
      setIsError(true);
      setInfoText(kyc?.error?.join("\n") ?? "Failed to insert details");
      setOpen(true);
    }
  }, [customer.feedback, kyc.feedback]);

  return (
    <>
      <SectionHeader
        headerText={
          <Typography sx={{ fontWeight: 700, fontSize: 32 }}>
            <Tooltip
              title="Back to Customers' Table"
              arrow
              placement="top-start"
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
            >
              <IconButton
                aria-label="back"
                onClick={handleBackToTable}
                sx={{ mb: 0.8 }}
              >
                <ArrowBack sx={{ fontSize: 30, color: "#000" }} />
              </IconButton>
            </Tooltip>
            Customer Account Verification
          </Typography>
        }
      />
      <WhiteBox>
        <Box sx={{ p: 4 }}>
          {/* Top */}
          <CardTop customerData={customerData} />
          {/* Section Selectors */}
          <Box sx={{ mt: 1, borderBottom: 1, borderBottomColor: "#F0F1ED" }}>
            <Button
              sx={
                currentStage === stage.PERSONAL
                  ? styles.customerAccountVerification.sectionButton.selected
                  : styles.customerAccountVerification.sectionButton.notSelected
              }
            >
              Personal
            </Button>
            <Button
              // onClick={() => handleTabChange(stage.RESIDENCE)}
              sx={
                currentStage === stage.RESIDENCE
                  ? styles.customerAccountVerification.sectionButton.selected
                  : styles.customerAccountVerification.sectionButton.notSelected
              }
            >
              Residence
            </Button>
            <Button
              // onClick={() => handleTabChange(stage.EMPLOYMENT)}
              sx={
                currentStage === stage.EMPLOYMENT
                  ? styles.customerAccountVerification.sectionButton.selected
                  : styles.customerAccountVerification.sectionButton.notSelected
              }
            >
              Employment
            </Button>
            <Button
              // onClick={() => handleTabChange(stage.BANK)}
              sx={
                currentStage === stage.BANK
                  ? styles.customerAccountVerification.sectionButton.selected
                  : styles.customerAccountVerification.sectionButton.notSelected
              }
            >
              Bank
            </Button>
            <Button
              // onClick={() => handleTabChange(stage.NEXT_OF_KIN)}
              sx={
                currentStage === stage.NEXT_OF_KIN
                  ? styles.customerAccountVerification.sectionButton.selected
                  : styles.customerAccountVerification.sectionButton.notSelected
              }
            >
              Next of Kin
            </Button>
            <Button
              // onClick={() => handleTabChange(customerStage.REUPLOAD)}
              sx={
                currentStage === customerStage.REUPLOAD
                  ? styles.customerAccountVerification.sectionButton.selected
                  : styles.customerAccountVerification.sectionButton.notSelected
              }
            >
              Documents
            </Button>
          </Box>

          <Box
            sx={{
              display: "flex",
              // justifyContent: "space-between",
              width: "100%",
            }}
          >
            {getKYCStage()}
          </Box>
        </Box>
      </WhiteBox>
      <InfoDialog
        open={open}
        error={isError}
        text={infoText}
        handleClose={handleClose}
      />
    </>
  );
};

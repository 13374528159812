import {
  Box,
  Button,
  CircularProgress,
  Fade,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { styles } from "../../common/css/styles";
import { useDispatch, useSelector } from "react-redux";
import { Personal } from "../kyc/stages/Personal";
import { Residence } from "../kyc/stages/Residence";
import { Employment } from "../kyc/stages/Employment";
import { Bank } from "../kyc/stages/Bank";
import { NextOfKin } from "../kyc/stages/NextOfKin";
import { WhiteBox } from "../../common/components/WhiteBox";
import { SectionHeader } from "../../common/components/SectionHeader";
import { stage } from "../kyc/stages";
import { bridgeFinanceStage } from "./constants/bridgeFinanceStage";
import { RecommendationInfo } from "./kyc/RecommendationInfo";
import { InternationalPassport } from "./kyc/InternationalPassport";
import { LienDocs } from "./kyc/LienDocs";
import { CardTop } from "./components/CardTop";
import { bridgeFinanceFilters } from "./constants/bridgeFinanceFilters";
import { ArrowBack } from "@mui/icons-material";
import { customerStage } from "../customers/constants/customerStage";
import { Documents } from "../kyc/stages/Documents";
import {
  clearLoanDetails,
  fetchBridgeLoan,
} from "./store/bridgeFinanceLoanSlice";
import { roles } from "../../common/constants/roles";
import { Wallet } from "../kyc/stages/Wallet";
import { clearCustomer, fetchCustomer } from "../customers/store/customerSlice";
import { getKycInfo, resetKyc } from "../kyc/kycSlice";
import { LoanDetails } from "./kyc/LoanDetails";

export const BridgeFinanceKYC = () => {
  const [currentStage, setCurrentStage] = useState(stage.PERSONAL);
  const bridgeLoan = useSelector((state) => state.bridgeLoan);
  const user = useSelector(({ auth }) => auth.user);
  const customer = useSelector(({ customer }) => customer.customer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loan = bridgeLoan.loan;
  const { loanid } = useParams();
  const kycData = loan && JSON.parse(loan.customerKycObject);
  const customerBankData = loan && JSON.parse(loan.customerBankObject);
  const bvnData = loan && JSON.parse(loan.customerBvnObject);
  const ninData = loan && JSON.parse(loan.customerNationalIdObject);
  const role = user?.roles[0].name;

  const getStage = () => {
    switch (currentStage) {
      case stage.PERSONAL:
        return <Personal customer={loan?.user} />;
      case stage.RESIDENCE:
        return <Residence data={kycData} />;
      case stage.EMPLOYMENT:
        return <Employment data={kycData} />;
      case stage.BANK:
        return <Bank customer={customer} data={kycData} />;
      case stage.NEXT_OF_KIN:
        return <NextOfKin data={kycData} />;
      case stage.WALLET:
        return <Wallet customer={customer} data={kycData} />;
      case bridgeFinanceStage.RECOMMENDATION_INFO:
        return <RecommendationInfo loan={loan} />;
      case bridgeFinanceStage.INTERNATIONAL_PASSPORT:
        return <InternationalPassport loan={loan} data={kycData} />;
      case bridgeFinanceStage.LIEN_DOCS:
        return (
          <LienDocs
            loanUid={loan?.uid}
            offerer={loan?.offerer}
            customer={customer}
          />
        );
      case customerStage.DOCUMENTS:
        return (
          <Documents
            customer={loan?.user}
            kycData={kycData}
            bvnData={bvnData}
            ninData={ninData}
          />
        );
      case bridgeFinanceStage.LOAN_DETAILS:
        return <LoanDetails loan={loan} data={kycData} />;
      default:
        return <Personal customer={loan?.user} />;
    }
  };

  const handleBack = () => navigate("/bridge-finance");
  const handleTabChange = (stage) => setCurrentStage(stage);
  const tabs = [
    { label: "Personal", value: stage.PERSONAL, visible: true },
    { label: "Residence", value: stage.RESIDENCE, visible: true },
    { label: "Employment", value: stage.EMPLOYMENT, visible: true },
    { label: "Bank", value: stage.BANK, visible: true },
    { label: "Next of Kin", value: stage.NEXT_OF_KIN, visible: true },
    { label: "Wallet", value: stage.WALLET, visible: true },
    { label: "Documents", value: customerStage.DOCUMENTS, visible: true },
    {
      label: "Recommendation Info",
      value: bridgeFinanceStage.RECOMMENDATION_INFO,
      visible:
        role === roles.MANAGER &&
        loan?.status === bridgeFinanceFilters.PENDING_OFFER
          ? true
          : false,
    },
    {
      label: "International Passport",
      value: bridgeFinanceStage.INTERNATIONAL_PASSPORT,
      visible:
        loan?.status === bridgeFinanceFilters.PENDING_LIEN ||
        loan?.status === bridgeFinanceFilters.COMPLETED ||
        loan?.status === bridgeFinanceFilters.ACTIVE ||
        loan?.status === bridgeFinanceFilters.DEFAULTING
          ? true
          : false,
    },
    {
      label: "Lien Docs",
      value: bridgeFinanceStage.LIEN_DOCS,
      visible:
        loan?.status === bridgeFinanceFilters.PENDING_LIEN ||
        loan?.status === bridgeFinanceFilters.COMPLETED ||
        loan?.status === bridgeFinanceFilters.ACTIVE ||
        loan?.status === bridgeFinanceFilters.DEFAULTING
          ? true
          : false,
    },
    {
      label: "Loan Details",
      value: customerStage.LOAN_DETAILS,
      visible:
        loan?.status === bridgeFinanceFilters.PENDING_DISBURSEMENT ||
        loan?.status === bridgeFinanceFilters.COMPLETED ||
        loan?.status === bridgeFinanceFilters.ACTIVE
          ? true
          : false,
    },
  ];

  useEffect(() => {
    if (loan === null) dispatch(fetchBridgeLoan(loanid));
    return () => {
      dispatch(clearLoanDetails());
      dispatch(clearCustomer());
      dispatch(resetKyc());
    };
  }, []);

  useEffect(() => {
    if (!!loan) {
      dispatch(fetchCustomer(loan?.user.uid));
      dispatch(getKycInfo(loan?.user.uid));
    }
  }, [bridgeLoan.loading]);

  return (
    <>
      <SectionHeader
        headerText={
          <Typography sx={{ fontWeight: 700, fontSize: 32 }}>
            <Tooltip
              title="Back to Bridge Financing Table"
              arrow
              placement="top-start"
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
            >
              <IconButton
                aria-label="back"
                onClick={handleBack}
                sx={{ mb: 0.8 }}
              >
                <ArrowBack sx={{ fontSize: 30, color: "#000" }} />
              </IconButton>
            </Tooltip>
            Bridge Financing
          </Typography>
        }
      />
      <WhiteBox>
        {bridgeLoan.loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "70vh",
            }}
          >
            <CircularProgress sx={{ alignItems: "center" }} />
          </Box>
        ) : (
          <Box sx={{ p: 4 }}>
            {/* Top */}
            <CardTop loan={loan} />
            {/* Section Selectors */}
            <Box sx={{ mt: 2, borderBottom: 1, borderBottomColor: "#F0F1ED" }}>
              {tabs
                .filter((tab) => tab.visible)
                .map((tab) => (
                  <Button
                    key={tab.value}
                    onClick={() => handleTabChange(tab.value)}
                    sx={
                      currentStage === tab.value
                        ? styles.customerAccountVerification.sectionButton
                            .selected
                        : styles.customerAccountVerification.sectionButton
                            .notSelected
                    }
                  >
                    {tab.label}
                  </Button>
                ))}
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {getStage()}
            </Box>
          </Box>
        )}
      </WhiteBox>
    </>
  );
};

import { Box, Button, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { styles } from "../../../../common/css/styles";
import { useDispatch, useSelector } from "react-redux";
import { fetchNINDetails } from "../../kycSlice";
import { feedback } from "../../../../common/constants/feedback";
import { LoadingButton } from "@mui/lab";

const getIDType = (idType) => {
  switch (idType) {
    case "nin":
      return "NIN";
    default:
      return idType ?? "ID";
  }
};

export const GovernmentID = ({ customer, kycData, ninData }) => {
  // let data = ninData && JSON.parse(ninData.data);
  const kyc = useSelector((state) => state.kyc);
  const dispatch = useDispatch();

  const [data, setData] = useState(ninData && JSON.parse(ninData.data));

  const handleRetrieveNIN = () => {
    dispatch(fetchNINDetails(customer.uid));
  };

  useEffect(() => {
    if (kyc.ninFeedback === feedback.SUCCESSFUL) {
      setData(kyc.nin && JSON.parse(kyc.nin));
    }
  }, [kyc.ninFeedback]);

  return (
    <Box sx={{ display: "flex", flexDirection: "row" }}>
      {/* User Input */}
      <Box sx={{ py: 2, width: "50%" }}>
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: 18,
            py: 2,
            textTransform: "capitalize",
          }}
        >
          {getIDType(kycData?.idMode)}
        </Typography>

        <Box
          sx={{
            border: 1,
            borderRadius: 2,
            minHeight: 200,
            maxWidth: "90%",
            px: 2,
            borderColor: "#979797",
          }}
        >
          <Typography sx={{ fontWeight: 400, fontSize: 16, py: 1 }}>
            ID no.
          </Typography>
          <Typography sx={{ fontWeight: 700, fontSize: 16, py: 1 }}>
            {kycData?.idNumber}
          </Typography>
          <Box sx={{ width: "100%", py: 1 }}>
            <img src={kycData?.idPhotoUrl} alt="drivers license" width="100%" />
          </Box>
        </Box>
      </Box>
      {/* ID Generated */}
      <Box sx={{ py: 2, width: "50%" }}>
        <Typography sx={{ fontWeight: 700, py: 2 }}>
          {getIDType(ninData?.idMode)} Generated
        </Typography>
        <Box
          sx={{
            border: 1,
            borderRadius: 2,
            minHeight: 200,
            maxWidth: "90%",
            px: 2,
            borderColor: "#979797",
            mt: 0.3,
          }}
        >
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ py: 1 }}>
              <Typography sx={styles.bvn.topLeft}>First Name</Typography>
              <Typography sx={styles.bvn.bottomLeft}>
                {data?.firstname ?? "Not Available"}
              </Typography>
            </Box>
            <Box sx={{ py: 1 }}>
              <Typography sx={styles.bvn.topRight}>Last Name</Typography>
              <Typography sx={styles.bvn.bottomRight}>
                {data?.lastname ?? "Not Available"}
              </Typography>
            </Box>
          </Stack>
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ py: 1 }}>
              <Typography sx={styles.bvn.topLeft}>Email Address</Typography>
              <Typography sx={styles.bvn.bottomLeft}>Not Available</Typography>
            </Box>
            <Box sx={{ py: 1 }}>
              <Typography sx={styles.bvn.topRight}>Phone Number</Typography>
              <Typography sx={styles.bvn.bottomRight}>
                {data?.phone ?? "Not Available"}
              </Typography>
            </Box>
          </Stack>
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ py: 1 }}>
              <Typography sx={styles.bvn.topLeft}>DOB</Typography>
              <Typography sx={styles.bvn.bottomLeft}>
                {data?.birthdate?.replaceAll("-", "/") ?? "Not Available"}
              </Typography>
            </Box>
          </Stack>
        </Box>
        {!data?.firstname ? (
          <LoadingButton
            variant="contained"
            onClick={handleRetrieveNIN}
            loading={kyc.ninFeedback === feedback.PENDING}
            sx={{
              ...styles.bridgeCardTop.success,
              height: "40px",
              mt: "20px",
            }}
          >
            Retrieve NIN Data
          </LoadingButton>
        ) : null}
      </Box>
    </Box>
  );
};

export const customerFilters = {
  ALL: "",
  PROSPECT: "PROSPECT",
  PENDING: "PENDING",
  VERIFIED: "ACTIVE",
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
  SUSPENDED: "SUSPENDED",
  DELETED: "DELETED",
  DENIED: "DENIED",
  LOCKED: "LOCKED",
  BLACKLISTED: "BLACKLISTED",
};

import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  Box,
  Typography,
} from "@mui/material";
import { styles } from "../../../common/css/styles";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { InfoDialog } from "../../../common/components/InfoDialog";
import { useMutation } from "@tanstack/react-query";
import { updateCollection } from "../../../common/api/settings";
import { parseError } from "../../../common/constants/utility";
import { LoadingButton } from "@mui/lab";

const schema = yup.object().shape({
  value: yup
    .number()
    .typeError("Grace period must be a number")
    .required("Grace period is required"),
});

export const ManageGracePeriod = ({
  open,
  handleClose,
  data = {},
  refetch,
}) => {
  const { value, id } = data;
  const defaultValues = {
    value: value ?? "",
  };

  console.info({ data, id });

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  const [status, setStatus] = useState();

  const { isLoading, mutate } = useMutation({
    mutationFn: (data) => updateCollection(data),
    mutationKey: "update Grace",
    onError: (err) => setStatus({ isError: true, message: parseError(err) }),
    onSuccess: (data) => {
      setStatus({
        isError: false,
        message: "Grace period saved successfully",
      });
      if (refetch) refetch();
    },
  });

  const onSubmit = (data) => {
    const payload = {
      name: "GRACE_PERIOD",
      description: "Day before penalty is associated with the loan",
      ...data,
    };
    if (id) payload["id"] = id;
    mutate(payload);
  };

  return (
    <>
      {!status ? (
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Grace Period</DialogTitle>
          <DialogContent>
            <Box
              sx={{ width: { xs: "300px", sm: "400px", md: "500px" } }}
              component="form"
              noValidate
              onSubmit={handleSubmit(onSubmit)}
            >
              <Typography sx={{ ...styles.formFieldHeading, color: "#000" }}>
                Number of Days
              </Typography>
              <Controller
                name="value"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    fullWidth
                    size="small"
                    margin="normal"
                    error={!!errors.value}
                    helperText={errors.value?.message}
                    placeholder="2"
                    sx={{ mt: 0, "& fieldset": { borderRadius: 1 } }}
                  />
                )}
              />
              <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
                <Button
                  onClick={handleClose}
                  sx={{ mr: 1, textTransform: "capitalize", color: "#616663" }}
                >
                  Cancel
                </Button>
                <LoadingButton
                  loading={isLoading}
                  variant="contained"
                  type="submit"
                  disabled={isSubmitting}
                  sx={{
                    boxShadow: "none",
                    color: "#fff",
                    textTransform: "capitalize",
                  }}
                >
                  Save
                </LoadingButton>
              </Box>
            </Box>
          </DialogContent>
        </Dialog>
      ) : (
        <InfoDialog
          open={open}
          handleClose={handleClose}
          text={status?.message}
          error={status?.isError}
        />
      )}
    </>
  );
};

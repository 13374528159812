import React, { useMemo, useState } from "react";
import { DeadState } from "../../../../common/components/DeadState";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { styles } from "../../../../common/css/styles";
import { OwnerFullDetails } from "./components/OwnerFullDetails";

export const OwnerInfo = ({ data }) => {
  const [showOwnerFullDetails, setShowOwnerFullDetails] = useState(false);
  const [ownerDetails, setOwnerDetails] = useState(null);

  const handleView = (details) => {
    setOwnerDetails(details);
    setShowOwnerFullDetails(true);
  };

  const columns = useMemo(
    () => [
      {
        id: "name",
        label: "Name",
        minWidth: 50,
        visible: true,
      },
      { id: "email", label: "Email", minWidth: 50, visible: true },
      {
        id: "phoneNo",
        label: "Phone Number",
        minWidth: 50,
        visible: true,
      },
      { id: "mandate", label: "Role", minWidth: 50, visible: true },
      { id: "mandate", label: "Mandate", minWidth: 50, visible: true },
      {
        id: "view",
        label: "View",
        minWidth: 50,
        visible: true,
        render: (loan) => (
          <Button
            variant="outlined"
            onClick={() => handleView(loan)}
            sx={styles.table.text.viewButton}
          >
            View
          </Button>
        ),
      },
    ],
    []
  );
  const handleBackToTable = () => setShowOwnerFullDetails(false);

  return showOwnerFullDetails ? (
    <OwnerFullDetails
      data={ownerDetails}
      handleBackToTable={handleBackToTable}
    />
  ) : (
    <Box>
      <TableContainer
        component={Paper}
        sx={{
          maxHeight: 400,
          boxShadow: "none",
          mt: 2,
        }}
      >
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow
              sx={{
                "& .MuiTableCell-head": {
                  color: "#000",
                  backgroundColor: "#fff",
                  borderTop: 0.5,
                  borderBottom: 0.5,
                  borderColor: "#B5B5B5",
                  py: 2,
                },
              }}
            >
              {columns
                .filter((column) => column.visible === true)
                .map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    <Typography
                      noWrap
                      sx={{
                        color: "#000",
                        backgroundColor: "#fff",
                        fontSize: 13,
                        fontWeight: 600,
                      }}
                    >
                      {column.label}
                    </Typography>
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!!data && data?.length > 0 ? (
              data.map((owner) => (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={owner.uid}
                  sx={{
                    "& .MuiTableCell-body": {
                      color: "#000",
                      backgroundColor: "#fff",
                      borderBottom: 0.5,
                      borderColor: "#F7F8F4",
                    },
                  }}
                >
                  {columns.map((v, i) => (
                    <TableCell key={i} align="left">
                      <Typography sx={styles.table.text.uid}>
                        {v.render ? v.render(owner) : owner[v.id]}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableCell colSpan={columns.length} sx={{ textAlign: "center" }}>
                <DeadState />
              </TableCell>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

import React from "react";
import { WhiteBox } from "./WhiteBox";
import errorImage from "../images/error-image.svg";
import { Box, Button, Icon, Stack, Typography } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";

export const NotFound = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleBack = () => {
    navigate(location.state?.toUnauthorized ? -2 : -1);
  };

  return (
    <WhiteBox>
      <Box
        sx={{
          width: "100vw",
          display: "flex",
          justifyContent: "center",
          position: "absolute",
          top: "25%",
          // bottom: "50%",
        }}
      >
        <Stack
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{ textAlign: "center", fontWeight: 700, fontSize: 24 }}
          >
            Opps!!!!
          </Typography>
          <Typography
            sx={{ textAlign: "center", fontWeight: 700, fontSize: 24 }}
          >
            {" "}
            Page not found
          </Typography>
          <img src={errorImage} alt="" />
          <Button
            variant="outlined"
            onClick={handleBack}
            sx={{
              mt: 3,
              borderColor: "#B5B5B5",
              color: "#000",
              width: "50%",
              textTransform: "capitalize",
            }}
          >
            <ArrowBack sx={{ fontSize: 14, mr: 1 }} />
            Go Back
          </Button>
        </Stack>
      </Box>
    </WhiteBox>
  );
};

import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Grid, Typography } from "@mui/material";
import doneIcon from "../../../../common/images/done-icon.svg";
import { useDispatch } from "react-redux";
import { verifyCustomer } from "../../../customers/store/customerSlice";
import { useNavigate } from "react-router-dom";

export const Verify = ({ open, handleClose, customer }) => {
  const [openSecondDialog, setOpenSecondDialog] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleOpenSecondDialog = () => {
    dispatch(
      verifyCustomer({
        isKycAccepted: true,
        userUid: customer.uid,
        kycRejectionReasons: [],
      })
    );
    handleClose();
    setOpenSecondDialog(true);
  };

  const handleCloseSecondDialog = () => {
    setOpenSecondDialog(false);
    navigate("/customers");
  };

  useEffect(() => {
    let timeoutId;
    if (openSecondDialog) {
      timeoutId = setTimeout(() => {
        handleCloseSecondDialog();
      }, 2000);
    }

    return () => clearTimeout(timeoutId);
  }, [openSecondDialog]);

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <Grid
          py={2}
          sx={{
            width: 500,
          }}
        >
          <DialogTitle sx={{ color: "#000" }}>Accept Verification?</DialogTitle>

          <DialogContent>
            <Grid container spacing={3}>
              <Grid item sx={{ width: "100%" }}>
                <Typography sx={{ fontSize: 14, fontWeight: 400, pb: 1 }}>
                  Are you sure you want to make this user verified ?
                </Typography>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              sx={{
                textTransform: "capitalize",
                fontSize: 14,
                color: "#616663",
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={handleOpenSecondDialog}
              sx={{
                boxShadow: "none",
                color: "#fff",
                textTransform: "capitalize",
                px: 3,
                fontSize: 12,
                fontWeight: 600,
                borderRadius: 2,
                mr: 2,
              }}
            >
              Yes
            </Button>
          </DialogActions>
        </Grid>
      </Dialog>

      {/* Second Dialog */}
      <Dialog open={openSecondDialog} onClose={handleCloseSecondDialog}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: 350,
          }}
        >
          <DialogContent>
            <Box
              sx={{ width: "100%", display: "flex", justifyContent: "center" }}
            >
              <img src={doneIcon} alt="done Icon" />
            </Box>
            <DialogContentText>User Verified</DialogContentText>
          </DialogContent>
        </Box>
      </Dialog>
    </>
  );
};

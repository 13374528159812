export const stage = {
  PERSONAL: "PERSONAL",
  RESIDENCE: "RESIDENCE",
  EMPLOYMENT: "EMPLOYMENT",
  BANK: "BANK",
  NEXT_OF_KIN: "NEXT_OF_KIN",
  IDENTIFICATION: "IDENTIFICATION",
};

export const bridgeFinanceExtraStage = {
  LIEN: "LIEN",
  PASSPORT: "PASSPORT",
};

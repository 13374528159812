import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  ListItem,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { styles } from "../../../../common/css/styles";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format, parse } from "date-fns";
import { passwordRegex, phoneRegex } from "../../../../common/constants/regex";
import {
  ArrowForward,
  VisibilityOffOutlined,
  VisibilityOutlined,
} from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { addCustomer } from "../../store/customerSlice";
import { LoadingButton } from "@mui/lab";

const defaultValues = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  gender: "",
  dateOfBirth: null,
  password: "",
  confirmPassword: "",
  // transactionPin: "",
  // confirmTransactionPin: "",
};

const schema = yup.object().shape({
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name is required"),
  email: yup
    .string()
    .email("Please enter a valid email address")
    .required("Email address is required"),
  phoneNumber: yup
    .string()
    .matches(phoneRegex, "Enter a valid phone number")
    .required("Phone number is required"),
  gender: yup.string().required("Gender is required"),
  dateOfBirth: yup
    .date()
    .transform(function (value, originalValue) {
      if (this.isType(value)) {
        return value;
      }
      const result = parse(originalValue, "MM/dd/yyyy", new Date());
      return result;
    })
    .typeError("Please enter a valid date")
    .required("Date of birth is required"),
  password: yup
    .string()
    .required("Password is required")
    .matches(passwordRegex, "Password does not match required rules"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match"),
  // transactionPin: yup
  //   .string()
  //   .max(4, "Transaction pin must be 4 digits")
  //   .required("Transaction pin is required"),
  // confirmTransactionPin: yup
  //   .string()
  //   .max(4, "Transaction pin must be 4 digits")
  //   .oneOf([yup.ref("transactionPin"), null], "Transaction pin must match"),
});

export const AddPersonal = ({
  handleTabChange,
  customerData,
  handleAddCustomerData,
  openInfo,
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors, isValid, isSubmitting },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showPin, setShowPin] = useState(false);
  const [showConfirmPin, setShowConfirmPin] = useState(false);
  const dispatch = useDispatch();

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);
  const handleClickShowPin = () => setShowPin((show) => !show);
  const handleClickShowConfirmPin = () => setShowConfirmPin((show) => !show);

  const onSubmit = (data) => {
    customerData.personal = {
      ...data,
      dateOfBirth: format(new Date(data.dateOfBirth), "dd-MM-yyyy"),
    };
    handleAddCustomerData(customerData);
    dispatch(addCustomer(customerData.personal));
  };

  return (
    <Grid
      container
      rowSpacing={2}
      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      mt={1}
      component="form"
      noValidate
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid item xs={6}>
        <Typography sx={styles.formFieldHeading}>First Name</Typography>
        <Controller
          name="firstName"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              required
              variant="outlined"
              fullWidth
              size={styles.textfieldSize}
              placeholder="e.g. John"
              error={!!errors.firstName}
              helperText={errors.firstName?.message}
              sx={styles.textField}
            />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Typography sx={styles.formFieldHeading}>Last Name</Typography>
        <Controller
          name="lastName"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              required
              variant="outlined"
              fullWidth
              size={styles.textfieldSize}
              placeholder="e.g. Doe"
              error={!!errors.lastName}
              helperText={errors.lastName?.message}
              sx={styles.textField}
            />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Typography sx={styles.formFieldHeading}>Email Address</Typography>
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              required
              variant="outlined"
              fullWidth
              size={styles.textfieldSize}
              placeholder="e.g. johndoe@gmail.com"
              error={!!errors.email}
              helperText={errors.email?.message}
              sx={styles.textField}
            />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Typography sx={styles.formFieldHeading}>Gender</Typography>
        <Controller
          name="gender"
          control={control}
          render={({ field }) => (
            <FormControl fullWidth>
              <Select
                {...field}
                required
                size={styles.textfieldSize}
                sx={styles.selectField}
              >
                <MenuItem value="MALE">Male</MenuItem>
                <MenuItem value="FEMALE">Female</MenuItem>
              </Select>
              {errors.gender ? (
                <FormHelperText error>{errors?.gender?.message}</FormHelperText>
              ) : (
                <></>
              )}
            </FormControl>
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Typography sx={styles.formFieldHeading}>Phone Number</Typography>
        <Controller
          name="phoneNumber"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              required
              variant="outlined"
              fullWidth
              size={styles.textfieldSize}
              placeholder="e.g. 12345678901"
              error={!!errors.phoneNumber}
              helperText={errors.phoneNumber?.message}
              sx={styles.textField}
            />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Typography sx={styles.formFieldHeading}>DOB</Typography>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Controller
            name="dateOfBirth"
            control={control}
            render={({ field }) => (
              <DatePicker
                {...field}
                inputFormat="MM/dd/yyyy"
                sx={styles.newDatePicker}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    error={!!errors.dateOfBirth}
                    helperText={errors.dateOfBirth?.message}
                    fullWidth
                    autoComplete="off"
                    variant="outlined"
                  />
                )}
              />
            )}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={6}>
        <Typography sx={styles.formFieldHeading}>Password</Typography>
        <Controller
          name="password"
          control={control}
          render={({ field }) => (
            <TextField
              fullWidth
              required
              {...field}
              size={styles.textfieldSize}
              placeholder="Enter Password"
              type={showPassword ? "text" : "password"}
              error={!!errors.password}
              helperText={errors.password?.message}
              sx={styles.textField}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                      sx={{ color: "black" }}
                    >
                      {showPassword ? (
                        <VisibilityOffOutlined />
                      ) : (
                        <VisibilityOutlined />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Typography sx={styles.formFieldHeading}>Confirm Password</Typography>
        <Controller
          name="confirmPassword"
          control={control}
          render={({ field }) => (
            <TextField
              fullWidth
              required
              {...field}
              size={styles.textfieldSize}
              placeholder="Confirm Password"
              type={showConfirmPassword ? "text" : "password"}
              error={!!errors.confirmPassword}
              helperText={errors.confirmPassword?.message}
              sx={styles.textField}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmPassword}
                      edge="end"
                      sx={{ color: "black" }}
                    >
                      {showConfirmPassword ? (
                        <VisibilityOffOutlined />
                      ) : (
                        <VisibilityOutlined />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="body1" sx={{ color: "#9A9FBF", fontSize: 13 }}>
          <ListItem sx={{ display: "list-item", p: 0 }}>
            Eight (8) or more alphabetical characters
          </ListItem>
          <ListItem sx={{ display: "list-item", p: 0 }}>
            one (1) or more uppercase character(s)
          </ListItem>
          <ListItem sx={{ display: "list-item", p: 0 }}>
            One (1) or more numerical character(s)
          </ListItem>
          <ListItem sx={{ display: "list-item", p: 0 }}>
            One (1) or more special character(s)
          </ListItem>
        </Typography>
      </Grid>
      {/* <Grid item xs={6}>
        <Typography sx={styles.formFieldHeading}>Transaction Pin</Typography>
        <Controller
          name="transactionPin"
          control={control}
          render={({ field }) => (
            <TextField
              fullWidth
              required
              {...field}
              size={styles.textfieldSize}
              placeholder="Enter Pin"
              type={showPin ? "text" : "password"}
              error={!!errors.transactionPin}
              helperText={errors.transactionPin?.message}
              sx={styles.textField}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPin}
                      edge="end"
                      sx={{ color: "black" }}
                    >
                      {showPin ? (
                        <VisibilityOffOutlined />
                      ) : (
                        <VisibilityOutlined />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </Grid> */}
      {/* <Grid item xs={6}>
        <Typography sx={styles.formFieldHeading}>
          Confirm Transaction Pin
        </Typography>
        <Controller
          name="confirmTransactionPin"
          control={control}
          render={({ field }) => (
            <TextField
              fullWidth
              required
              {...field}
              size={styles.textfieldSize}
              placeholder="Confirm Pin"
              type={showConfirmPin ? "text" : "password"}
              error={!!errors.confirmTransactionPin}
              helperText={errors.confirmTransactionPin?.message}
              sx={styles.textField}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmPin}
                      edge="end"
                      sx={{ color: "black" }}
                    >
                      {showConfirmPin ? (
                        <VisibilityOffOutlined />
                      ) : (
                        <VisibilityOutlined />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </Grid> */}
      <Grid item xs={12} textAlign="right">
        <Box>
          <LoadingButton
            variant="contained"
            type="submit"
            endIcon={<ArrowForward />}
            loadingPosition="end"
            disabled={!isValid || isSubmitting}
            loading={isSubmitting}
            sx={{
              textTransform: "capitalize",
              height: 40,
              color: "#fff",
              boxShadow: "none",
              fontSize: 16,

              "&:hover": {
                boxShadow: "none",
              },
            }}
          >
            Next
          </LoadingButton>
        </Box>
      </Grid>
    </Grid>
  );
};

import {
  Box,
  Grid,
  Stack,
  TextField,
  Typography,
  Link,
  Button,
} from "@mui/material";
import React from "react";
import { styles } from "../../common/css/styles";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { DevTool } from "@hookform/devtools";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { sendResetOTP } from "./authSlice";

const defaultValues = {
  recipient: "",
};

const schema = yup.object().shape({
  recipient: yup
    .string()
    .email("Please enter a valid email")
    .required("Email address is required"),
});

export const ForgotPassword = () => {
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSubmit = (data) => {
    dispatch(sendResetOTP({ ...data, operation: "RESET_PASSWORD" }));
    navigate("/verify-otp", {
      state: { recipient: data.recipient, operation: "RESET_PASSWORD" },
    });
  };

  const backToLogin = () => {
    navigate("/");
  };

  return (
    <>
      <Box
        sx={{
          background: "white",
          borderRadius: 2,
          py: 4,
          px: 4,
          justifyContent: "center",
        }}
      >
        <Grid
          container
          item
          position="relative"
          direction="column"
          alignItems="center"
        >
          <Box
            container
            item
            md={6}
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            sx={{
              backgroundColor: "primary.main",
              ml: -1,
              pt: 1,
              pb: 1,
              borderRadius: "30px",
              position: "absolute",
              top: -53,
              pl: 2.5,
              pr: 2.5,
            }}
          >
            <Typography sx={{ color: "white", width: "100%" }}>
              Login
            </Typography>
          </Box>
        </Grid>

        <Typography
          variant="h6"
          sx={{ ...styles.formHeader, textAlign: "center" }}
        >
          Reset Password
        </Typography>
        <Grid
          container
          direction={"column"}
          spacing={3}
          component="form"
          noValidate
          onSubmit={handleSubmit(onSubmit)}
          pt={1}
        >
          <Grid item></Grid>
          <Grid item>
            <Typography variant="body1" sx={styles.formFieldHeading}>
              Email
            </Typography>
            <Controller
              name="recipient"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  variant="outlined"
                  size={styles.textfieldSize}
                  required
                  fullWidth
                  placeholder="e.g. johndoe@gmail.com"
                  error={!!errors.recipient}
                  helperText={errors.recipient?.message}
                  sx={styles.textField}
                />
              )}
            />
          </Grid>
          <Grid item sx={{ mt: 2 }}>
            <Button
              variant="contained"
              disableElevation
              disabled={isSubmitting}
              type="submit"
              sx={styles.continueButton2}
              fullWidth
            >
              Reset
            </Button>
          </Grid>
          <Grid
            container
            item
            direction="column"
            alignItems="center"
            sx={{ mt: -2 }}
          >
            <Button
              sx={{
                fontWeight: 600,
                fontSize: 14,
                textTransform: "capitalize",
                p: 0,
                pt: 2,
                color: "#000",
              }}
              onClick={backToLogin}
            >
              Back to
              <span style={{ color: "#19AD42", paddingLeft: "5px" }}>
                Login
              </span>
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

import { Avatar, Box, Button, Stack, Typography } from "@mui/material";
import React from "react";
import avatarDummy from "../../../common/images/avatar-image.jpg";
import checkIcon from "../../../common/images/check-icon.svg";
import closeIcon from "../../../common/images/close-1con.svg";
import { useState } from "react";
import { Decline } from "./Decline";
import { Verify } from "./Verify";
import { useSelector } from "react-redux";
import { businessFilters } from "../constants/businessFilters";
import { styles } from "../../../common/css/styles";
import PersonIcon from "@mui/icons-material/Person";
import { toTitleCase } from "../../../common/constants/utility";

export const CardTop = () => {
  const [openVerifyDialog, setOpenVerifyDialog] = useState(false);
  const [openDeclineDialog, setOpenDeclineDialog] = useState(false);
  const businessInfo = useSelector(({ business }) => business.businessInfo);

  const handleOpenVerifyDialog = () => setOpenVerifyDialog(true);
  const handleCloseVerifyDialog = () => setOpenVerifyDialog(false);
  const handleOpenDeclineDialog = () => setOpenDeclineDialog(true);
  const handleCloseDeclineDialog = () => setOpenDeclineDialog(false);

  const setStatus = () => {
    switch (businessInfo?.status) {
      case businessFilters.VERIFIED:
        return (
          <Typography sx={styles.cardTopStatus.verified}>Verified</Typography>
        );
      case businessFilters.ACTIVE:
        return <Typography sx={styles.cardTopStatus.active}>Active</Typography>;
      case businessFilters.PENDING:
        return (
          <Typography sx={styles.cardTopStatus.pendingRecommendation}>
            Pending Verification
          </Typography>
        );
      case businessFilters.PROSPECT:
        return (
          <Typography sx={styles.cardTopStatus.prospect}>Prospect</Typography>
        );
      case businessFilters.DENIED:
        return <Typography sx={styles.cardTopStatus.denied}>Denied</Typography>;
      case businessFilters.DELETED:
        return (
          <Typography sx={styles.cardTopStatus.deleted}>Deleted</Typography>
        );
      case businessFilters.INACTIVE:
        return (
          <Typography sx={styles.cardTopStatus.inactive}>Inactive</Typography>
        );
      case businessFilters.SUSPENDED:
        return (
          <Typography sx={styles.cardTopStatus.suspended}>Suspended</Typography>
        );

      default:
        break;
    }
  };

  const shouldSeeActionButtons = () => {
    if (businessInfo?.status === businessFilters.PENDING) return true;
    else return false;
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      <Stack>
        <Box sx={{ display: "flex" }}>
          {businessInfo?.companyLogo ? (
            <Avatar
              src={businessInfo?.companyLogo}
              sx={{ width: 60, height: 60 }}
            />
          ) : (
            <Avatar sx={{ width: 60, height: 60 }}>
              <PersonIcon />
            </Avatar>
          )}
          <Stack sx={{ ml: 2 }}>
            <Typography sx={{ fontWeight: 700, fontSize: 24 }}>
              {toTitleCase(businessInfo?.businessName)}
            </Typography>
            <Typography
              sx={{ fontSize: 14, fontWeight: 500, color: "#616663" }}
            >
              {businessInfo?.email}
            </Typography>
          </Stack>
        </Box>
        <Typography sx={{ mt: 1.2 }}>{setStatus()}</Typography>
      </Stack>
      {shouldSeeActionButtons() ? (
        <Box>
          <Button
            variant="contained"
            onClick={handleOpenVerifyDialog}
            sx={{
              boxShadow: "none",
              color: "#fff",
              textTransform: "capitalize",
              px: 3,
              fontSize: 12,
              fontWeight: 600,
              borderRadius: 2,
            }}
          >
            <Box sx={{ mt: 1, pr: 1 }}>
              <img src={checkIcon} alt="check-icon" />
            </Box>
            Verify
          </Button>
          <Button
            variant="contained"
            onClick={handleOpenDeclineDialog}
            sx={{
              boxShadow: "none",
              color: "#D41818",
              textTransform: "capitalize",
              px: 3,
              fontSize: 12,
              fontWeight: 600,
              borderRadius: 2,
              backgroundColor: "#FFF2F2",
              ml: 2,
              "&:hover": {
                backgroundColor: "#f6adad",
                boxShadow: "none",
              },
            }}
          >
            <Box sx={{ mt: 1, pr: 1 }}>
              <img src={closeIcon} alt="close-icon" />
            </Box>
            Reject
          </Button>
        </Box>
      ) : (
        <></>
      )}

      <Decline
        open={openDeclineDialog}
        handleClose={handleCloseDeclineDialog}
      />
      <Verify open={openVerifyDialog} handleClose={handleCloseVerifyDialog} />
    </Box>
  );
};

/* eslint-disable react-hooks/exhaustive-deps */
import { ArrowBackIos } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Fade,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SectionHeader } from "../../common/components/SectionHeader";
import { WhiteBox } from "../../common/components/WhiteBox";
import { styles } from "../../common/css/styles";

import { stage } from "../kyc/stages";
import { BankStatement } from "../kyc/stages/BankStatement";
import { CreditBureau } from "../kyc/stages/CreditBureau";
import { Personal } from "./details/personal/Personal";

import { RecommendationInfo } from "./details/RecommendationInfo";
import { parseStringToObject } from "../../common/util";
import { useQuery } from "@tanstack/react-query";
import { LoanHistoryRetail } from "./details/loanHistory/LoanHistoryRetail";
import { getLoan } from "../../common/api/retail";
import { CardSideNew } from "./components/CardSideNew";

export const RetailLoanOverview = () => {
  const [currentStage, setCurrentStage] = useState(stage.PERSONAL);

  const { loanid } = useParams();
  const { data, isLoading, refetch } = useQuery({
    queryKey: [loanid],
    queryFn: () => getLoan(loanid),
    refetchOnWindowFocus: false,
  });
  const loan = data?.data?.data ?? {};
  const { customerKycObject, user, customerBankObject } = loan;

  const kycData = customerKycObject && parseStringToObject(customerKycObject);
  const bankData =
    customerBankObject && parseStringToObject(customerBankObject);

  const navigate = useNavigate();

  const getKYCStage = () => {
    switch (currentStage) {
      case stage.PERSONAL:
        return (
          <Personal
            customer={user}
            data={kycData}
            bankData={bankData}
            refetch={refetch}
            loan={loan}
          />
        );
      case stage.LOAN:
        return <LoanHistoryRetail userUid={user.uid} data={kycData} />;
      case stage.CREDIT_BUREAU:
        return <CreditBureau customer={user} data={kycData} />;
      case stage.BANK:
        return <BankStatement customer={user} data={bankData} />;
      case stage.RECOMMENDATION:
        return <RecommendationInfo loan={loan} />;
      default:
        return (
          <Personal
            customer={user}
            data={kycData}
            bankData={bankData}
            refetch={refetch}
            loan={loan}
          />
        );
    }
  };

  const handleBackToTable = () => navigate(-1);
  const handleTabChange = (stage) => setCurrentStage(stage);

  if (isLoading)
    return (
      <WhiteBox>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "70vh",
          }}
        >
          <CircularProgress sx={{ alignItems: "center" }} />
        </Box>
      </WhiteBox>
    );

  return (
    <>
      <SectionHeader
        headerText={
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: 32,
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              width: "fit-content",
            }}
            onClick={handleBackToTable}
          >
            <Tooltip
              title="Back to loans table"
              arrow
              placement="top-start"
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
            >
              <ArrowBackIos
                sx={{ fontSize: 30, color: "#000", mr: "5px", ml: "-10px" }}
              />
            </Tooltip>
            Loans
          </Typography>
        }
      />

      <Grid
        container
        sx={{
          display: {
            // xs: "none",
            md: "block",
            minHeight: "100vh",
            // backgroundColor: "yellow"
          },
        }}
      >
        <Grid container spacing={2}>
          <Grid
            container
            item
            xs={12}
            md={4}
            sx={{
              display: {
                // xs: "none",
                md: "block",
                // position: "relative",
                // height: "100vh",
                // backgroundImage: "url('../images/logo-sm.png')",
                // backgroundColor: "yellow"
              },
            }}
          >
            {/* Top */}
            <WhiteBox minHeight={"40vh"}>
              <CardSideNew
                customer={user}
                loan={loan}
                kycData={kycData}
                bankData={bankData}
              />
            </WhiteBox>
          </Grid>

          <Grid item container xs={12} md={8} sx={{ p: 2, minHeight: "100vh" }}>
            <WhiteBox>
              <Box sx={{ p: 2 }}>
                <Box sx={{ borderBottom: 1, borderBottomColor: "#F0F1ED" }}>
                  <Button
                    onClick={() => handleTabChange(stage.PERSONAL)}
                    sx={
                      currentStage === stage.PERSONAL
                        ? styles.customerAccountVerification.sectionButton
                            .selected
                        : styles.customerAccountVerification.sectionButton
                            .notSelected
                    }
                  >
                    Personal Details
                  </Button>
                  <Button
                    onClick={() => handleTabChange(stage.LOAN)}
                    sx={
                      currentStage === stage.LOAN
                        ? styles.customerAccountVerification.sectionButton
                            .selected
                        : styles.customerAccountVerification.sectionButton
                            .notSelected
                    }
                  >
                    Loan History
                  </Button>
                  {/* <Button
                    onClick={() => handleTabChange(stage.CREDIT_BUREAU)}
                    sx={
                      currentStage === stage.CREDIT_BUREAU
                        ? styles.customerAccountVerification.sectionButton
                            .selected
                        : styles.customerAccountVerification.sectionButton
                            .notSelected
                    }
                  >
                    Credit Bureau
                  </Button>
                  <Button
                    onClick={() => handleTabChange(stage.BANK)}
                    sx={
                      currentStage === stage.BANK
                        ? styles.customerAccountVerification.sectionButton
                            .selected
                        : styles.customerAccountVerification.sectionButton
                            .notSelected
                    }
                  >
                    Bank Statement
                  </Button> */}
                  <Button
                    onClick={() => handleTabChange(stage.RECOMMENDATION)}
                    sx={
                      currentStage === stage.RECOMMENDATION
                        ? styles.customerAccountVerification.sectionButton
                            .selected
                        : styles.customerAccountVerification.sectionButton
                            .notSelected
                    }
                  >
                    Recommendation
                  </Button>
                </Box>

                <Box
                  sx={{
                    display: "",
                    justifyContent: "space-between",
                    width: "100%",
                    //   alignItems: "normal",
                  }}
                >
                  {getKYCStage()}
                </Box>
              </Box>
            </WhiteBox>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export const retailFilters = {
  ALL: "",
  PENDING_RECOMMENDATION: "PENDING_RECOMMENDATION",
  PENDING_DISBURSEMENT: "PENDING_DISBURSEMENT",
  PENDING_OFFER: "PENDING_OFFER",
  PENDING_APPROVAL: "PENDING_APPROVAL",
  OFFERED: "OFFERED",
  ACTIVE: "ACTIVE",
  OFFER_REJECTED: "OFFER_REJECTED",
  OFFER_ACCEPTED: "OFFER_ACCEPTED",
  LOAN_REJECTED: "LOAN_REJECTED",
  DEFAULTING: "DEFAULTING",
  REQUEST_DECLINED: "REQUEST_DECLINED",
  COMPLETED: "COMPLETED",
  NTU: "NTU",
};

export const loansDetailsFilters = {
  PERSONAL: "PERSONAL",
  RESIDENCE: "RESIDENCE",
  EMPLOYMENT: "EMPLOYMENT",
  BANK_INFO: "BANK_INFO",
  NEXT_OF_KIN: "NEXT_OF_KIN",
  IDENTIFICATION: "IDENTIFICATION",
  DOCUMENT: "DOCUMENT",
};

export const repaymentFilters = {
  SUCCESSFUL: "SUCCESSFUL",
  FAILED: "FAILED",
  PENDING: "PENDING",
};

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import doneIcon from "../../../common/images/done-icon.svg";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchInterestRates } from "../../settings/settingsSlice";
import { useMutation } from "@tanstack/react-query";
import { recommendLoanOffer } from "../../../common/api/retail";
import { handleErrorAlt } from "../../../common/constants/utility";
import { LoadingButton } from "@mui/lab";

const options = [
  "Debt consolidation",
  "Diversification of portfolio",
  "Meeting Financial Needs",
  "Meeting customer Needs",
  "Acquiring Customer",
  "Interest Income",
  "Meeting Financial Goals",
];

const defaultValues = {
  recommendedAmount: "",
  recommendedInterestRate: 0,
  recommendedTenure: "",
  reasonsForRecommendation: [],
  otherReasons: "",
  interestEditable: false,
};

const numberRegex = /^[0-9]*$/;
const schema = yup.object().shape({
  recommendedAmount: yup
    .string()
    .required("Recommended amount is required")
    .matches(numberRegex, "Recommended amount must be a number"),
  recommendedInterestRate: yup.string().required("Interest rate is required"),
  recommendedTenure: yup
    .string()
    .required("Tenure is required")
    .matches(numberRegex, "Tenure must be a number"),
  reasonsForRecommendation: yup
    .array()
    .min(1, "Please select at least one reason")
    .required("Reasons are required"),
  otherReasons: yup.string().when("reasonsForRecommendation", {
    is: (val) => val.length === 0,
    then: () => yup.string().required("Other reason is required"),
    otherwise: () => yup.string().notRequired(),
  }),
  interestEditable: yup.boolean(),
});

export const RecommendForOffer = ({ open, handleClose, loan }) => {
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitSuccessful },
    reset,
    resetField,
    setValue,
    watch,
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  const [openSecondDialog, setOpenSecondDialog] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const interestRates = useSelector(({ settings }) => settings.interestRates);
  // const loan = useSelector(({ retailLoan }) => retailLoan.loan);
  const interestEditable = watch("interestEditable");

  const handleAmountChange = (loanOfferAmount) => {
    const range = interestRates.find(
      (x) => x.lowerBound <= loanOfferAmount && x.upperBound >= loanOfferAmount
    );
    if (range) setValue("recommendedInterestRate", range.interestRate);
    else setValue("recommendedInterestRate", 0);
  };

  const handleCloseSecondDialog = () => {
    setOpenSecondDialog(false);
    navigate("/loans");
  };

  const { isLoading, mutate } = useMutation({
    mutationFn: (data) => recommendLoanOffer(data),
    mutationKey: "setLoanRecommender",
    onError: (err) => handleErrorAlt(err, dispatch),
    onSuccess: (data) => {
      handleClose();
      setOpenSecondDialog(true);
    },
  });

  const onSubmit = (data) => {
    mutate({
      loanUid: loan.uid ?? loan.loanUid,
      recommendOffer: true,
      recommendedAmount: parseInt(data.recommendedAmount),
      recommendedInterestRate: parseFloat(data.recommendedInterestRate),
      recommendedTenure: parseInt(data.recommendedTenure),
      reasonsForRecommendation: [
        ...data.reasonsForRecommendation,
        data.otherReasons,
      ].filter((n) => n),
    });
  };
  useEffect(() => {
    dispatch(fetchInterestRates());
  }, []);

  useEffect(() => {
    if (isSubmitSuccessful) reset();
  }, [isSubmitSuccessful]);

  useEffect(() => {
    let timeoutId;
    if (openSecondDialog) {
      timeoutId = setTimeout(() => {
        handleCloseSecondDialog();
      }, 2000);
    }

    return () => clearTimeout(timeoutId);
  }, [openSecondDialog]);

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          reset();
          handleClose();
        }}
      >
        <Grid
          component="form"
          noValidate
          onSubmit={handleSubmit(onSubmit)}
          py={2}
        >
          <DialogTitle sx={{ color: "#000" }}>
            Make Loan Offer Recommendation
          </DialogTitle>

          <DialogContent>
            <Grid container spacing={3}>
              <Grid item sx={{ width: "100%" }}>
                <Typography sx={{ fontSize: 14, fontWeight: 400, pb: 1 }}>
                  Loan Offer
                </Typography>
                <Controller
                  name="recommendedAmount"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={!!errors.recommendedAmount}
                      helperText={errors.recommendedAmount?.message}
                      fullWidth
                      size="small"
                      onChange={(e) => {
                        field.onChange(e);
                        handleAmountChange(e.target.value);
                      }}
                      placeholder="50200"
                    />
                  )}
                />
              </Grid>
              <Grid item sx={{ width: "100%" }}>
                <Typography sx={{ fontSize: 14, fontWeight: 400, pb: 1 }}>
                  Loan Tenure (months)
                </Typography>
                <Controller
                  name="recommendedTenure"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={!!errors.recommendedTenure}
                      helperText={errors.recommendedTenure?.message}
                      fullWidth
                      size="small"
                      placeholder="3"
                    />
                  )}
                />
              </Grid>
              <Grid item sx={{ width: "100%" }}>
                <Typography sx={{ fontSize: 14, fontWeight: 400, pb: 1 }}>
                  Reasons for loan offer recommendation
                </Typography>
                <Controller
                  name="reasonsForRecommendation"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <Autocomplete
                      multiple
                      id="multiselect-chip"
                      options={options}
                      onChange={(event, newValue) => onChange(newValue)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={!!errors.reasonsForRecommendation}
                          helperText={errors.reasonsForRecommendation?.message}
                          variant="outlined"
                          label="Select Reason"
                          placeholder="Options"
                          size="small"
                        />
                      )}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            key={index}
                            label={option}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item sx={{ width: "100%" }}>
                <Typography sx={{ fontSize: 14, fontWeight: 400, pb: 1 }}>
                  Other reasons for loan offer recommendation
                </Typography>
                <Controller
                  name="otherReasons"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={!!errors.otherReasons}
                      helperText={errors.otherReasons?.message}
                      fullWidth
                      size="small"
                    />
                  )}
                />
              </Grid>
              <Grid item sx={{ width: "100%" }}>
                <Controller
                  name="interestEditable"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(_, value) => {
                            resetField("recommendedInterestRate");
                            onChange(value);
                          }}
                        />
                      }
                      label="Use custom rate"
                    />
                  )}
                />
                <Controller
                  name="recommendedInterestRate"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      variant="outlined"
                      size="small"
                      placeholder="1.5%"
                      error={!!errors.recommendedInterestRate}
                      helperText={errors.recommendedInterestRate?.message}
                      InputProps={{
                        readOnly: !interestEditable,
                        sx: {
                          backgroundColor: interestEditable ? "" : "#B8B7B74A",
                        },
                      }}
                      sx={{
                        borderColor: "#B8B7B7",
                        "&::placeholder": {
                          color: "#000",
                        },
                        color: "#000",
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                reset();
                handleClose();
              }}
              sx={{
                textTransform: "capitalize",
                fontSize: 14,
                color: "#616663",
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              type="submit"
              sx={{
                boxShadow: "none",
                color: "#fff",
                textTransform: "capitalize",
                mr: 2,
                fontSize: 12,
                fontWeight: 600,
                borderRadius: 2,
              }}
              loading={isLoading}
            >
              Recommend for Offer
            </LoadingButton>
          </DialogActions>
        </Grid>
      </Dialog>

      {/* Second Dialog */}
      <Dialog open={openSecondDialog} onClose={handleCloseSecondDialog}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <DialogContent>
            <Box
              sx={{ width: "100%", display: "flex", justifyContent: "center" }}
            >
              <img src={doneIcon} alt="done-icon" />
            </Box>
            <DialogContentText>
              This request has been recommended for offer
            </DialogContentText>
          </DialogContent>
        </Box>
      </Dialog>
    </>
  );
};

import { Button } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import React from "react";

export const EditButton = ({ openDialog }) => {
  const handleEditButtonClick = () => {
    openDialog();
  };

  return (
    <Button
      onClick={handleEditButtonClick}
      //   variant="outlined"
      sx={{
        height: 39,
        border: 2,
        color: "#494D4A",
        borderColor: "#61666333",
        mt: 2.5,
        textTransform: "capitalize",
        fontSize: 12,
      }}
    >
      Edit <EditIcon sx={{ fontSize: 12, pl: 1 }} />
    </Button>
  );
};

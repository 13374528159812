import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  disburse,
  getLoan,
  internationalPassport,
  lienDocuments,
  offerLoan,
  recommendLoanOffer,
  setLoanOfferer,
  setLoanRecommender,
  uploadProofOfRepayment,
} from "../../../common/api/bridgeFinance";
import { bridgeFinanceFilters } from "../constants/bridgeFinanceFilters";
import { feedback } from "../../../common/constants/feedback";
import { handleError } from "../../../common/constants/utility";

const initialState = {
  loan: null,
  loading: false,
  lienUpload: "",
  disbursed: "",
  feedback: "",
  error: [],
};

export const loanOptions = {
  RECOMMEND: "RECOMMEND",
  MAKE_OFFER: "MAKE_OFFER",
  VIEW: "VIEW",
};

export const fetchBridgeLoan = createAsyncThunk(
  "bridgeFinance/fetchLoan",
  async (uid, { dispatch, rejectWithValue }) => {
    try {
      const response = await getLoan(uid);
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);

export const setRecommender = createAsyncThunk(
  "bridgeFinance/loan/setRecommender",
  async (loanUid, { dispatch, rejectWithValue }) => {
    try {
      const response = await setLoanRecommender(loanUid);
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);

export const recommendOrDeclineOffer = createAsyncThunk(
  "bridgeFinance/loan/recommendForOffer",
  async (model, { dispatch, rejectWithValue }) => {
    try {
      const response = await recommendLoanOffer(model);
      dispatch(fetchBridgeLoan(model.loanUid));
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);

export const setOfferer = createAsyncThunk(
  "bridgeFinance/loan/setOfferer",
  async (loanUid, { dispatch, rejectWithValue }) => {
    try {
      const response = await setLoanOfferer(loanUid);
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);

export const makeOrDeclineOffer = createAsyncThunk(
  "bridgeFinance/loan/offerLoan",
  async (model, { dispatch, rejectWithValue }) => {
    try {
      const response = await offerLoan(model);
      dispatch(
        updateLoanDetails({
          status: model.isLoanOffered
            ? bridgeFinanceFilters.OFFERED
            : bridgeFinanceFilters.DECLINED,
        })
      );
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);

export const uploadPassport = createAsyncThunk(
  "bridgeFinance/loan/passport",
  async (model, { dispatch, rejectWithValue }) => {
    try {
      const response = await internationalPassport(model);
      dispatch(fetchBridgeLoan(model.loanUid));
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);

export const uploadLienDocs = createAsyncThunk(
  "bridgeFinance/loan/lien-docs",
  async (model, { dispatch, rejectWithValue }) => {
    try {
      const response = await lienDocuments(model);
      dispatch(fetchBridgeLoan(model.loanUid));
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);

export const disburseLoan = createAsyncThunk(
  "bridgeFinance/loan/disburse",
  async (model, { dispatch, rejectWithValue }) => {
    try {
      const response = await disburse(model);
      dispatch(fetchBridgeLoan(model.loanUid));
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);

export const closeLoan = createAsyncThunk(
  "bridgeFinance/loan/close",
  async (model, { dispatch, rejectWithValue }) => {
    try {
      const response = await uploadProofOfRepayment(model);
      dispatch(fetchBridgeLoan(model.loanUid));
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);

const bridgeFinanceLoanSlice = createSlice({
  name: "bridgeFinance/loan",
  initialState,
  reducers: {
    updateLoanDetails: (state, action) => {
      state.loan = { ...state.loan, ...action.payload };
      state.error = [];
    },
    clearLoanDetails: (state) => {
      state.loan = null;
      state.error = [];
    },
    clearLoanFeedback: (state) => {
      state.feedback = "";
      state.lienUpload = "";
      state.disbursed = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBridgeLoan.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchBridgeLoan.fulfilled, (state, action) => {
      state.loan = action.payload.data;
      state.loading = false;
      state.error = [];
    });
    builder.addCase(fetchBridgeLoan.rejected, (state, action) => {
      state.loan = null;
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(setRecommender.fulfilled, (state, action) => {
      state.error = [];
    });
    builder.addCase(setRecommender.rejected, (state, action) => {
      state.error = action.payload;
    });
    builder.addCase(recommendOrDeclineOffer.fulfilled, (state, action) => {
      state.error = [];
    });
    builder.addCase(recommendOrDeclineOffer.rejected, (state, action) => {
      state.error = action.payload;
    });
    builder.addCase(setOfferer.fulfilled, (state, action) => {
      state.error = [];
    });
    builder.addCase(setOfferer.rejected, (state, action) => {
      state.error = action.payload;
    });
    builder.addCase(makeOrDeclineOffer.fulfilled, (state, action) => {
      state.error = [];
    });
    builder.addCase(makeOrDeclineOffer.rejected, (state, action) => {
      state.error = action.payload;
    });
    builder.addCase(uploadPassport.fulfilled, (state, action) => {
      state.error = [];
    });
    builder.addCase(uploadPassport.rejected, (state, action) => {
      state.error = action.payload;
    });
    builder.addCase(uploadLienDocs.pending, (state, action) => {
      state.lienUpload = feedback.PENDING;
      state.error = [];
    });
    builder.addCase(uploadLienDocs.fulfilled, (state, action) => {
      state.lienUpload = feedback.SUCCESSFUL;
      state.error = [];
    });
    builder.addCase(uploadLienDocs.rejected, (state, action) => {
      state.lienUpload = feedback.FAILED;
      state.error = action.payload;
    });
    builder.addCase(disburseLoan.pending, (state, action) => {
      state.disbursed = feedback.PENDING;
      state.error = [];
    });
    builder.addCase(disburseLoan.fulfilled, (state, action) => {
      state.disbursed = feedback.SUCCESSFUL;
      state.error = [];
    });
    builder.addCase(disburseLoan.rejected, (state, action) => {
      state.disbursed = feedback.FAILED;
      state.error = action.payload;
    });
    builder.addCase(closeLoan.pending, (state, action) => {
      state.feedback = feedback.PENDING;
      state.error = [];
    });
    builder.addCase(closeLoan.fulfilled, (state, action) => {
      state.feedback = feedback.SUCCESSFUL;
      state.error = [];
    });
    builder.addCase(closeLoan.rejected, (state, action) => {
      state.feedback = feedback.FAILED;
      state.error = action.payload;
    });
  },
});

export const { updateLoanDetails, clearLoanDetails, clearLoanFeedback } =
  bridgeFinanceLoanSlice.actions;
export default bridgeFinanceLoanSlice.reducer;

import { client } from ".";

export const getKyc = (uid) => {
  return client.get(`user/${uid}/kyc`);
};

export const updateKyc = (model) => {
  return client.patch(
    "admin/retail/kyc",
    { ...model },
    { headers: { "Content-Type": "multipart/form-data" } }
  );
};

export const getUpdatedNIN = (userUid) => {
  return client.get(`user/${userUid}/identification-details`);
};

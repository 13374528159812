import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import React from "react";
import { styles } from "../../../../../../common/css/styles";
import { format } from "date-fns";
import { toTitleCase } from "../../../../../../common/constants/utility";
import { WhiteBox } from "../../../../../../common/components/WhiteBox";

export const Profile = ({ data, loading }) => {
  if (loading)
    return (
      <WhiteBox>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <CircularProgress sx={{ alignItems: "center" }} />
        </Box>
      </WhiteBox>
    );

  return (
    <Box sx={{ pl: 2 }}>
      <Box>
        <Box sx={{ width: "100%" }}>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2 }}>
            <Grid xs={6}>
              <Box>
                <Box>
                  <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
                    Date business operations state
                  </Typography>
                  <Typography sx={{ fontWeight: 700, color: "#494D4A" }}>
                    {format(
                      new Date(data?.dateBusinessOperationStarted),
                      "dd/MM/yyyy"
                    )}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid xs={6}>
              <Box>
                <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
                  Number of employees
                </Typography>
                <Typography sx={{ fontWeight: 700, color: "#494D4A" }}>
                  {data?.numbersOfEmployee}
                </Typography>
              </Box>
            </Grid>
            <Grid xs={6}>
              <Box>
                <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
                  Industry
                </Typography>
                <Typography sx={{ fontWeight: 700, color: "#494D4A" }}>
                  {toTitleCase(data?.industry)}
                </Typography>
              </Box>
            </Grid>
            <Grid xs={6}>
              <Box>
                <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
                  Sub Industry
                </Typography>
                <Typography sx={{ fontWeight: 700, color: "#494D4A" }}>
                  {toTitleCase(data?.subIndustry)}
                </Typography>
              </Box>
            </Grid>
            <Grid xs={6}>
              <Box>
                <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
                  Business website
                </Typography>
                <Typography sx={styles.kyc.normalText}>
                  {data?.businessWebsiteUrl
                    ? data?.businessWebsiteUrl.toLowerCase()
                    : "Not Available"}
                </Typography>
              </Box>
            </Grid>
            <Grid xs={6}>
              <Box>
                <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
                  Linkedin
                </Typography>
                <Typography sx={styles.kyc.normalText}>
                  {data?.linkedinUrl
                    ? data?.linkedinUrl.toLowerCase()
                    : "Not Available"}
                </Typography>
              </Box>
            </Grid>
            <Grid xs={6}>
              <Box>
                <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
                  Twitter
                </Typography>
                <Typography sx={styles.kyc.normalText}>
                  {data?.twitterUrl
                    ? data?.twitterUrl.toLowerCase()
                    : "Not Available"}
                </Typography>
              </Box>
            </Grid>
            <Grid xs={6}>
              <Box>
                <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
                  Facebook
                </Typography>
                <Typography sx={styles.kyc.normalText}>
                  {data?.facebookUrl
                    ? data?.facebookUrl.toLowerCase()
                    : "Not Available"}
                </Typography>
              </Box>
            </Grid>
            <Grid xs={6}>
              <Box>
                <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
                  Instagram
                </Typography>
                <Typography sx={styles.kyc.normalText}>
                  {data?.instagramUrl
                    ? data?.instagramUrl.toLowerCase()
                    : "Not Available"}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

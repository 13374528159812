import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { styles } from "../../../../../common/css/styles";

export const NIN = ({ data }) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "row" }}>
      {/* User Input */}
      <Box sx={{ py: 2, width: "50%" }}>
        <Typography sx={{ fontWeight: 700, mb: 3 }}>NIN</Typography>

        <Box
          sx={{
            border: 1,
            borderRadius: 2,
            minHeight: 200,
            maxWidth: "90%",
            px: 2,
            borderColor: "#979797",
          }}
        >
          <Typography sx={{ ...styles.bvn.topLeft, py: 1 }}>NIN no.</Typography>
          <Typography sx={styles.bvn.bottomLeft}>{data?.idNumber}</Typography>
          <img
            src={data?.idPhotoUrl}
            alt="dead-icon"
            width="100%"
            height="400"
          />
        </Box>
      </Box>
      {/* NIN Generated */}
      <Box sx={{ py: 2, width: "50%" }}>
        <Typography sx={{ fontWeight: 700, mb: 3 }}>NIN </Typography>

        <Box
          sx={{
            border: 1,
            borderRadius: 2,
            minHeight: 200,
            px: 2,
            borderColor: "#979797",
          }}
        >
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              mb: 4,
            }}
          >
            <Box sx={{ py: 1 }}>
              <Typography sx={styles.bvn.topLeft}>First Name</Typography>
              <Typography sx={styles.bvn.bottomLeft}>Charles</Typography>
            </Box>
            <Box sx={{ py: 1 }}>
              <Typography sx={styles.bvn.topRight}>Surname</Typography>
              <Typography sx={styles.bvn.bottomRight}>Bradenton</Typography>
            </Box>
          </Stack>
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              mb: 3,
            }}
          >
            <Box sx={{ py: 1 }}>
              <Typography sx={styles.bvn.topLeft}>EmailAddress</Typography>
              <Typography sx={styles.bvn.bottomLeft}>
                charlesbrad@gmail.com
              </Typography>
            </Box>
            <Box sx={{ py: 1 }}>
              <Typography sx={styles.bvn.topLeft}>Phone number</Typography>
              <Typography sx={styles.bvn.bottomLeft}>08123456789</Typography>
            </Box>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

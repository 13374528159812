import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  Typography,
} from "@mui/material";
import React from "react";
import { styles } from "../../../common/css/styles";
import { useNavigate } from "react-router-dom";
import { loanOptions, setOfferer } from "../store/bridgeFinanceLoanSlice";
import { useDispatch, useSelector } from "react-redux";

export const ProceedToOffer = ({ open, handleClose }) => {
  const loan = useSelector(({ bridgeLoan }) => bridgeLoan.loan);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleView = () => {
    dispatch(setOfferer(loan.uid));
    navigate(`/bridge-finance/kyc/${loan.uid}`, {
      state: { option: loanOptions.MAKE_OFFER },
    });
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <DialogContent>
          <Box
            sx={{ width: "280px", display: "flex", justifyContent: "center" }}
          ></Box>
          <DialogContentText>
            <Typography sx={{ fontSize: 17, fontWeight: 500 }}>
              Proceeding to view this loan request assigns this request to you.
            </Typography>
            <Typography
              sx={{ fontSize: 14, fontWeight: 500, color: "#969997" }}
            >
              Would you like to proceed?
            </Typography>
          </DialogContentText>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              mt: 2,
            }}
          >
            <Button
              variant="contained"
              onClick={handleClose}
              sx={{ ...styles.bridgeCardTop.error, fontSize: 11, px: 1, mx: 1 }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={handleView}
              sx={{ ...styles.bridgeCardTop.success, fontSize: 11, px: 1 }}
            >
              Proceed to Offer
            </Button>
          </Box>
        </DialogContent>
      </Box>
    </Dialog>
  );
};

import { ArrowBack } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Fade,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { SectionHeader } from "../../../common/components/SectionHeader";
import { WhiteBox } from "../../../common/components/WhiteBox";
import { styles } from "../../../common/css/styles";
import { BankStatement } from "../../kyc/stages/BankStatement";
import { CreditBureau } from "./details/stages/CreditBureau";
import { Loan } from "./details/stages/Loan";
import { CardSide } from "./components/CardSide";
import { Personal } from "./details/stages/Personal";
import { Recommendation } from "./details/stages/Recommendation";
import {
  clearBusiness,
  clearBusinessFeedback,
  fetchBusinessDocuments,
  fetchBusinessInfo,
  fetchContactInfo,
  fetchOwnerInfo,
} from "../../business/store/businessSlice";
import {
  fetchBusinessDocumentsFromLoan,
  fetchBusinessLoan,
  fetchBusinessLoanHistory,
  fetchBusinessTeamMembers,
  fetchCollateralInfo,
  fetchGuarantorInfo,
} from "./store/businessLoanSlice";
import { stage } from "./constants/kybStage";
import { BusinessInfo } from "./details/businessInfo/BusinessInfo";
import { LoanHistory } from "./details/loanHistory/LoanHistory";
import { loanType } from "../constants/loanType";

export const BusinessLoanOverview = ({ type }) => {
  const [currentStage, setCurrentStage] = useState(stage.BUSINESS_INFO);
  const businessLoan = useSelector((state) => state.businessLoan);
  const business = useSelector((state) => state.business);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loanid } = useParams();
  const loan = businessLoan.loan;

  const getKYBStage = () => {
    switch (currentStage) {
      case stage.BUSINESS_INFO:
        return (
          <BusinessInfo
            businessLoan={businessLoan}
            businessInfo={business.businessInfo}
            ownerInfo={business.ownerInfo}
            documents={business.documents}
            // teamMembers={businessLoan.teamMembers}
          />
        );
      case stage.LOAN_HISTORY:
        return <LoanHistory />;
      case stage.CREDIT_BUREAU:
        return <CreditBureau customer={business} data={null} />;
      case stage.STATEMENT:
        return <BankStatement customer={business} data={null} />;
      case stage.RECOMMENDATION:
        return <Recommendation customer={business} data={null} />;
      default:
        return <Personal customer={business} />;
    }
  };

  const handleBackToTable = () => navigate(-1);
  const handleTabChange = (stage) => setCurrentStage(stage);

  useEffect(() => {
    dispatch(fetchBusinessLoan(loanid));
  }, []);

  useEffect(() => {
    if (!businessLoan.loading) {
      dispatch(fetchBusinessInfo(loan?.businessId));
      dispatch(fetchContactInfo(loan?.businessId));
      dispatch(fetchOwnerInfo({ businessUid: loan?.businessId, page: 1 }));
      dispatch(fetchBusinessDocumentsFromLoan(loanid));
      dispatch(fetchCollateralInfo(loanid));
      dispatch(fetchGuarantorInfo(loanid));
      dispatch(fetchBusinessLoanHistory({ loanUid: loanid, page: 1 }));
    }
    return () => {
      dispatch(clearBusiness());
      dispatch(clearBusinessFeedback());
    };
  }, [businessLoan.loading]);

  return (
    <>
      <SectionHeader
        headerText={
          <Typography sx={{ fontWeight: 700, fontSize: 32 }}>
            <Tooltip
              title={
                type === loanType.BUSINESS
                  ? "Back to business loans table"
                  : "Back to asset financing loans table"
              }
              arrow
              placement="top-start"
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
            >
              <IconButton
                aria-label="back"
                onClick={handleBackToTable}
                sx={{ mb: 0.8 }}
              >
                <ArrowBack sx={{ fontSize: 30, color: "#000" }} />
              </IconButton>
            </Tooltip>
            {type === loanType.BUSINESS ? "Business Loans" : "Asset Financing"}
          </Typography>
        }
      />

      {business.businessInfo === null ? (
        <WhiteBox>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "70vh",
            }}
          >
            <CircularProgress sx={{ alignItems: "center" }} />
          </Box>
        </WhiteBox>
      ) : (
        <Grid
          container
          sx={{
            display: {
              // xs: "none",
              md: "block",
              minHeight: "100vh",
              // backgroundColor: "yellow"
            },
          }}
        >
          <Grid container spacing={2}>
            <Grid
              container
              item
              xs={false}
              md={4}
              sx={{
                display: {
                  xs: "none",
                  md: "block",
                },
              }}
            >
              {/* Top */}
              <WhiteBox>
                <CardSide businessInfo={business.businessInfo} loan={loan} />
              </WhiteBox>
            </Grid>

            <Grid
              item
              container
              xs={12}
              md={8}
              sx={{
                p: 2,
              }}
            >
              <WhiteBox>
                <Box sx={{ p: 2 }}>
                  <Box sx={{ borderBottom: 1, borderBottomColor: "#F0F1ED" }}>
                    <Button
                      onClick={() => handleTabChange(stage.BUSINESS_INFO)}
                      sx={
                        currentStage === stage.BUSINESS_INFO
                          ? styles.customerAccountVerification.sectionButton
                              .selected
                          : styles.customerAccountVerification.sectionButton
                              .notSelected
                      }
                    >
                      Business Info
                    </Button>
                    <Button
                      onClick={() => handleTabChange(stage.LOAN_HISTORY)}
                      sx={
                        currentStage === stage.LOAN_HISTORY
                          ? styles.customerAccountVerification.sectionButton
                              .selected
                          : styles.customerAccountVerification.sectionButton
                              .notSelected
                      }
                    >
                      Loan History
                    </Button>
                    {/* <Button
                      onClick={() => handleTabChange(stage.STATEMENT)}
                      sx={
                        currentStage === stage.STATEMENT
                          ? styles.customerAccountVerification.sectionButton
                              .selected
                          : styles.customerAccountVerification.sectionButton
                              .notSelected
                      }
                    >
                      Statement
                    </Button>
                    <Button
                      onClick={() => handleTabChange(stage.CREDIT_BUREAU)}
                      sx={
                        currentStage === stage.CREDIT_BUREAU
                          ? styles.customerAccountVerification.sectionButton
                              .selected
                          : styles.customerAccountVerification.sectionButton
                              .notSelected
                      }
                    >
                      Credit Bureau
                    </Button>
                    <Button
                      onClick={() => handleTabChange(stage.RECOMMENDATION)}
                      sx={
                        currentStage === stage.RECOMMENDATION
                          ? styles.customerAccountVerification.sectionButton
                              .selected
                          : styles.customerAccountVerification.sectionButton
                              .notSelected
                      }
                    >
                      Recommendation
                    </Button> */}
                  </Box>

                  <Box
                    sx={{
                      display: "",
                      justifyContent: "space-between",
                      width: "100%",
                      //   alignItems: "normal",
                    }}
                  >
                    {getKYBStage()}
                  </Box>
                </Box>
              </WhiteBox>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

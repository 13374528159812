import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getInterestRates, postInterestRate } from "../../common/api/settings";
import { handleError } from "../../common/constants/utility";

const initialState = {
  interestRates: [],
  interestRate: null,
  loading: false,
  error: [],
};

export const fetchInterestRates = createAsyncThunk(
  "settings/interestRates/fetch",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await getInterestRates();
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);

export const setInterestRate = createAsyncThunk(
  "settings/interestRates/set",
  async (model, { dispatch, rejectWithValue }) => {
    try {
      const response = await postInterestRate(model);
      dispatch(fetchInterestRates());
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setRate: (state, action) => {
      state.interestRate = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchInterestRates.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchInterestRates.fulfilled, (state, action) => {
      state.interestRates = action.payload.data.interestRates;
      state.loading = false;
      state.error = [];
    });
    builder.addCase(fetchInterestRates.rejected, (state, action) => {
      state.interestRates = [];
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(setInterestRate.fulfilled, (state, action) => {
      state.loading = false;
      state.error = [];
    });
    builder.addCase(setInterestRate.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export const { setRate } = settingsSlice.actions;
export default settingsSlice.reducer;

import {
  Box,
  Button,
  FormHelperText,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { styles } from "../../common/css/styles";
import { OtpInput } from "mui-otp-input";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/system";
import { validateOTP } from "./authSlice";
import { feedback } from "../../common/constants/feedback";
import DisplayError from "../../common/components/DisplayError";

const defaultValues = {
  otp: "",
};

const schema = yup.object().shape({
  otp: yup.string().required("Please input OTP").min(6),
});

const Countdown = styled("span")(({ theme }) => ({
  color: theme.palette.primary.main,
}));

export const VerifyOTP = () => {
  const {
    handleSubmit,
    control,
    formState: { errors, isValid, isSubmitting },
    getValues,
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [count, setCount] = useState(60);
  const [showError, setShowError] = useState(false);

  const onSubmit = (data) => {
    dispatch(
      validateOTP({
        ...data,
        recipient: location.state.recipient,
        operation: location.state.operation,
      })
    );
  };

  useEffect(() => {
    const timeoutID = setTimeout(() => {
      if (count !== 0) setCount(count - 1);
    }, 1000);

    return () => {
      clearTimeout(timeoutID);
    };
  }, [count]);

  useEffect(() => {
    if (!location.state) navigate("/", { replace: true });
  }, [location.state, navigate]);

  useEffect(() => {
    if (auth.otpValidated === feedback.SUCCESSFUL)
      navigate("/set-password", { state: { otp: getValues("otp") } });
    if (auth.otpValidated === feedback.FAILED) setShowError(true);
  }, [auth.otpValidated]);

  return (
    <>
      {location.state && (
        <Box
          sx={{
            background: "white",
            borderRadius: 2,
            py: 4,
            px: 4,
            justifyContent: "center",
          }}
        >
          <Grid
            container
            item
            position="relative"
            direction="column"
            alignItems="center"
          >
            <Box
              container
              item
              md={6}
              spacing={0}
              direction="column"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
              sx={{
                backgroundColor: "primary.main",
                ml: -1,
                pt: 1,
                pb: 1,
                borderRadius: "30px",
                position: "absolute",
                top: -53,
                pl: 2.5,
                pr: 2.5,
              }}
            >
              <Typography sx={{ color: "white", width: "100%" }}>
                Retail
              </Typography>
            </Box>
          </Grid>

          <Typography variant="h6" sx={styles.loginFormHeader}>
            Enter the code sent to your email
          </Typography>
          <Typography sx={styles.formSubheader}>
            Enter the 6-digit OTP we sent to your email to reset your password
          </Typography>

          <Grid
            container
            direction={"column"}
            spacing={3}
            component="form"
            noValidate
            pt={1}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Grid item sx={{ display: "flex", justifyContent: "center" }}>
              <Controller
                name="otp"
                control={control}
                render={({ field }) => (
                  <Box>
                    {errors.otp ? (
                      <FormHelperText sx={{ textAlign: "center", pb: 2 }} error>
                        {errors.otp?.message}
                      </FormHelperText>
                    ) : (
                      <></>
                    )}
                    <OtpInput {...field} format="______" type="numeric" />
                  </Box>
                )}
              />
            </Grid>

            <Grid item>
              <Button
                variant="contained"
                type="submit"
                disableElevation
                sx={{
                  color: "white",
                  py: 1.5,
                  textTransform: "capitalize",
                  fontSize: 15,
                  fontWeight: 600,
                }}
                fullWidth
                disabled={!isValid || isSubmitting}
              >
                Validate
              </Button>
            </Grid>
            <Grid
              container
              fullWidth
              item
              spacing={0}
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              {count === 0 ? (
                <Link
                  component="button"
                  variant="body2"
                  underline="none"
                  fontSize="small"
                >
                  Resend
                </Link>
              ) : (
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: 600,
                    fontSize: 11,
                    pb: 0.5,
                  }}
                >
                  Resend in <Countdown>{count}</Countdown>
                </Typography>
              )}
            </Grid>
          </Grid>
        </Box>
      )}
      <DisplayError
        error={auth.error}
        open={showError}
        handleClose={() => setShowError(false)}
      />
    </>
  );
};

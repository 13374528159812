import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { styles } from "../../../common/css/styles";
import { useDispatch, useSelector } from "react-redux";
import { OTPSuccess } from "./OTPSuccess";
import { OTPFailed } from "./OTPFailed";
import { RejectRequest } from "./RejectRequest";
import { clearFeedback, sendOTPFromAdmin } from "../../auth/authSlice";
import { feedback } from "../../../common/constants/feedback";
import { toTitleCase } from "../../../common/constants/utility";
import { clearDevicesFeedback } from "../devicesSlice";

export const ProceedChangeDevice = ({ open, handleClose, customer }) => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const changeInfo = useSelector(({ devices }) => devices.info);
  const [openSentOTP, setOpenSentOTP] = useState(false);
  const [openRejectRequest, setOpenRejectRequest] = useState(false);

  const handleSendOTP = () => {
    dispatch(
      sendOTPFromAdmin({
        recipient: customer.email,
        operation: "CHANGE_MOBILE_DEVICE",
        // channel: "SMS",
      })
    );
    handleClose();
  };

  const handleCloseRejectRequest = () => {
    dispatch(clearDevicesFeedback());
    handleClose();
    setOpenRejectRequest(false);
  };

  useEffect(() => {
    if (auth.feedback === feedback.SUCCESSFUL) {
      setOpenSentOTP(true);
    }
  }, [auth.feedback]);

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            minWidth: "500px",
          }}
        >
          <DialogContent>
            <Box
              sx={{ width: "280px", display: "flex", justifyContent: "center" }}
            ></Box>
            <DialogContentText>
              <Typography sx={{ fontSize: 17, fontWeight: 500 }}>
                Change Device
              </Typography>
              <Typography
                sx={{ fontSize: 14, fontWeight: 500, color: "#969997" }}
              >
                Confirm Security questions before sending OTP
              </Typography>
              <Box sx={{ py: 1 }}>
                <Typography sx={styles.otp.headerText}>First Name</Typography>
                <Typography sx={styles.otp.bodyText}>
                  {changeInfo?.firstName}
                </Typography>
              </Box>
              <Box sx={{ py: 1 }}>
                <Typography sx={styles.otp.headerText}>Last Name</Typography>
                <Typography sx={styles.otp.bodyText}>
                  {changeInfo?.lastName}
                </Typography>
              </Box>
              <Box sx={{ py: 1 }}>
                <Typography sx={styles.otp.headerText}>
                  Next of Kin Name
                </Typography>
                <Typography sx={styles.otp.bodyText}>
                  {toTitleCase(changeInfo?.nextOfKinName) ?? "Not Available"}
                </Typography>
              </Box>
              <Box sx={{ py: 1 }}>
                <Typography sx={styles.otp.headerText}>
                  Next of Kin Phone Number
                </Typography>
                <Typography sx={styles.otp.bodyText}>
                  {changeInfo?.nextOfKinPhoneNumber ?? "Not Available"}
                </Typography>
              </Box>
              <Box sx={{ py: 1 }}>
                <Typography sx={styles.otp.headerText}>
                  Last four digits of BVN
                </Typography>
                <Typography
                  sx={{ ...styles.otp.bodyText, textTransform: "none" }}
                >
                  {changeInfo?.lastFourDigitsOfBvn
                    ? `xxxxxxx${changeInfo?.lastFourDigitsOfBvn}`
                    : "Not Available"}
                </Typography>
              </Box>
            </DialogContentText>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                mt: 2,
              }}
            >
              <Button
                variant="contained"
                onClick={() => setOpenRejectRequest(true)}
                sx={{
                  ...styles.bridgeCardTop.error,
                  fontSize: 11,
                  px: 1,
                  mx: 1,
                }}
              >
                Reject Request
              </Button>
              <Button
                variant="contained"
                sx={{ ...styles.bridgeCardTop.success, fontSize: 11, px: 1 }}
                onClick={handleSendOTP}
              >
                Send OTP
              </Button>
            </Box>
          </DialogContent>
        </Box>
      </Dialog>

      <RejectRequest
        open={openRejectRequest}
        handleClose={handleCloseRejectRequest}
        customer={customer}
      />
      <OTPSuccess
        open={openSentOTP}
        handleClose={() => {
          dispatch(clearFeedback());
          setOpenSentOTP(false);
        }}
      />
      {/* <OTPFailed open={openSentOTP} handleClose={() => setopenSentOTP(false)} /> */}
    </>
  );
};

import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import {
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { Edit } from "@mui/icons-material";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { styles } from "../../../common/css/styles";
import { clearKycFeedback, submitKyc } from "../kycSlice";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format, parse, parseISO } from "date-fns";
import { stage } from "../stages";
import { feedback } from "../../../common/constants/feedback";
import { InfoDialog } from "../../../common/components/InfoDialog";
import { toTitleCase } from "../../../common/constants/utility";

const defaultValues = {
  companyName: "",
  dateJoined: "",
  salaryRange: "",
  employmentStatus: "",
  educationHistory: "",
};

const schema = yup.object().shape({
  companyName: yup.string().required("Company name is required"),
  dateJoined: yup
    .date()
    .transform(function (value, originalValue) {
      if (this.isType(value)) {
        return value;
      }
      const result = parse(parseISO(originalValue), "MM/dd/yyyy", new Date());
      return result;
    })
    .typeError("Please enter a valid date")
    .required("Date joined is required"),
  salaryRange: yup.string().required("Salary range is required"),
  employmentStatus: yup.string().required("Employment Status is required"),
  educationHistory: yup.string().required("Education History is required"),
});

export const EmploymentEdit = ({ open, handleClose, kycData }) => {
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitSuccessful },
    reset,
    setValue,
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  const [openSecondDialog, setOpenSecondDialog] = useState(false);
  const [isError, setIsError] = useState(false);
  const [infoText, setInfoText] = useState("");
  const customer = useSelector(({ customer }) => customer.customer);
  const kyc = useSelector((state) => state.kyc);
  const dispatch = useDispatch();

  const handleCloseSecondDialog = () => {
    dispatch(clearKycFeedback());
    setOpenSecondDialog(false);
  };

  const onSubmit = (data) => {
    dispatch(
      submitKyc({
        ...data,
        dateJoined: format(data.dateJoined, "dd-MM-yyyy"),
        userUid: customer.uid,
        onboardingStage: stage.EMPLOYMENT,
      })
    );
    handleClose();
  };

  useEffect(() => {
    if (isSubmitSuccessful) reset();
  }, [isSubmitSuccessful]);

  useEffect(() => {
    if (kyc.feedback === feedback.UPDATED) {
      setInfoText("Employment details successfully updated.");
      setIsError(false);
      setOpenSecondDialog(true);
    }
    if (kyc.feedback === feedback.UPDATE_FAILED) {
      setInfoText(kyc?.error?.join("\n") ?? "Failed to update details");
      setIsError(true);
      setOpenSecondDialog(true);
    }
  }, [kyc.feedback]);

  useEffect(() => {
    if (kycData) {
      setValue("companyName", `${toTitleCase(kycData?.companyName)}`);
      setValue("salaryRange", kycData?.salaryRange);
      setValue("employmentStatus", kycData?.employmentStatus);
      setValue("educationHistory", kycData?.educationHistory);
      setValue("dateJoined", new Date(kycData?.dateJoined));
    }
  }, [open]);

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          handleClose();
          reset();
        }}
      >
        <Grid
          py={2}
          sx={{ minWidth: 400 }}
          component="form"
          noValidate
          onSubmit={handleSubmit(onSubmit)}
        >
          <DialogTitle sx={{ color: "#000" }}>
            Edit Employment Information
          </DialogTitle>

          <DialogContent>
            <Grid
              container
              spacing={2}
              pr={2}
              direction="column"
              sx={{ minWidth: 400 }}
            >
              <Grid item>
                <Typography variant="body1" sx={styles.formFieldHeading}>
                  Company Name
                </Typography>
                <Controller
                  name="companyName"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      fullWidth
                      size={styles.textfieldSize}
                      placeholder="e.g. John"
                      error={!!errors.companyName}
                      helperText={errors.companyName?.message}
                      sx={{
                        ...styles.textField,
                        "& fieldset": { width: "100%" },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item>
                <Typography variant="body1" sx={styles.formFieldHeading}>
                  Education History
                </Typography>
                <Controller
                  name="educationHistory"
                  control={control}
                  render={({ field }) => (
                    <FormControl
                      fullWidth
                      sx={{
                        ...styles.textField,
                        "& fieldset": { width: "100%" },
                      }}
                    >
                      <Select {...field} required size={styles.textfieldSize}>
                        <MenuItem value="primary school certificate">
                          Primary School Certificate
                        </MenuItem>
                        <MenuItem value="secondary school certificate">
                          Secondary School Certificate
                        </MenuItem>
                        <MenuItem value="undergraduate degree holder (e.g bsc/hnd/ba)">
                          Undergraduate Degree Holder (e.g BSc/HND/BA)
                        </MenuItem>
                        <MenuItem value="postgraduate degree holder">
                          Postgraduate Degree Holder
                        </MenuItem>
                        <MenuItem value="none">None</MenuItem>
                      </Select>
                      {errors.educationHistory ? (
                        <FormHelperText error>
                          {errors?.educationHistory?.message}
                        </FormHelperText>
                      ) : (
                        <></>
                      )}
                    </FormControl>
                  )}
                />
              </Grid>

              <Grid item>
                <Typography variant="body1" sx={styles.formFieldHeading}>
                  Employment Status
                </Typography>
                <Controller
                  name="employmentStatus"
                  control={control}
                  render={({ field }) => (
                    <FormControl
                      fullWidth
                      sx={{
                        ...styles.textField,
                        "& fieldset": { width: "100%" },
                      }}
                    >
                      <Select {...field} required size={styles.textfieldSize}>
                        <MenuItem value="EMPLOYED">Employed</MenuItem>
                        <MenuItem value="UNEMPLOYED">Unemployed</MenuItem>
                        <MenuItem value="GOVERNMENT_WORKER">
                          Government Worker
                        </MenuItem>
                        <MenuItem value="SELF_EMPLOYED">Self Employed</MenuItem>
                        <MenuItem value="RETIRED">Retired</MenuItem>
                        <MenuItem value="STUDENT">Student</MenuItem>
                      </Select>
                      {errors.employmentStatus ? (
                        <FormHelperText error>
                          {errors?.employmentStatus?.message}
                        </FormHelperText>
                      ) : (
                        <></>
                      )}
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item>
                <Typography variant="body1" sx={styles.formFieldHeading}>
                  Date Joined
                </Typography>
                <LocalizationProvider fullWidth dateAdapter={AdapterDateFns}>
                  <Controller
                    name="dateJoined"
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        {...field}
                        inputFormat="MM/dd/yyyy"
                        sx={styles.datePicker}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            error={!!errors.dateJoined}
                            helperText={errors.dateJoined?.message}
                            fullWidth
                            size={styles.textfieldSize}
                            autoComplete="off"
                            variant="outlined"
                            sx={styles.datePicker}
                          />
                        )}
                      />
                    )}
                  />
                </LocalizationProvider>
                {errors.dateJoined ? (
                  <FormHelperText error>
                    {errors?.dateJoined?.message}
                  </FormHelperText>
                ) : (
                  <></>
                )}
              </Grid>
              <Grid item>
                <Typography variant="body1" sx={styles.formFieldHeading}>
                  Salary Range
                </Typography>
                <Controller
                  name="salaryRange"
                  control={control}
                  render={({ field }) => (
                    <FormControl
                      fullWidth
                      sx={{
                        ...styles.textField,
                        "& fieldset": { width: "100%" },
                      }}
                    >
                      <Select {...field} required size={styles.textfieldSize}>
                        <MenuItem value="below ₦100k">Below ₦100k</MenuItem>
                        <MenuItem value="₦100k - ₦200k">₦100k - ₦200k</MenuItem>
                        <MenuItem value="₦200k - ₦300k">₦200k - ₦300k</MenuItem>
                        <MenuItem value="₦300k - ₦400k">₦300k - ₦400k</MenuItem>
                        <MenuItem value="₦400k - ₦500k">₦400k - ₦500k</MenuItem>
                        <MenuItem value="₦500k - ₦600k">₦500k - ₦600k</MenuItem>
                        <MenuItem value="₦600k - ₦700k">₦600k - ₦700k</MenuItem>
                        <MenuItem value="₦700k - ₦800k">₦700k - ₦800k</MenuItem>
                        <MenuItem value="₦800k - ₦900k">₦800k - ₦900k</MenuItem>
                        <MenuItem value="₦900k - ₦1m">₦900k - ₦1m</MenuItem>
                        <MenuItem value="above ₦1m">Above ₦1m</MenuItem>
                      </Select>
                      {errors.salaryRange ? (
                        <FormHelperText error>
                          {errors?.salaryRange?.message}
                        </FormHelperText>
                      ) : (
                        <></>
                      )}
                    </FormControl>
                  )}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                handleClose();
                reset();
              }}
              sx={{
                textTransform: "capitalize",
                fontSize: 14,
                color: "#616663",
              }}
            >
              Close
            </Button>
            <Button
              variant="contained"
              type="submit"
              sx={{
                boxShadow: "none",
                color: "#fff",
                textTransform: "capitalize",
                px: 2,
                fontSize: 12,
                fontWeight: 600,
                borderRadius: 2,
                backgroundColor: "primary.main",
                mx: 2,
              }}
            >
              Edit <Edit sx={{ fontSize: 12, pl: 1 }} />
            </Button>
          </DialogActions>
        </Grid>
      </Dialog>

      {/* Second Dialog */}
      <InfoDialog
        open={openSecondDialog}
        handleClose={handleCloseSecondDialog}
        text={infoText}
        error={isError}
      />
    </>
  );
};

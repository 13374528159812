import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  Box,
  Typography,
} from "@mui/material";
import { styles } from "../../../common/css/styles";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { InfoDialog } from "../../../common/components/InfoDialog";
import { setInterestRate } from "../settingsSlice";

const defaultValues = {
  lowerBound: "",
  upperBound: "",
  interestRate: "",
};

const schema = yup.object().shape({
  lowerBound: yup
    .number()
    .typeError("Lower bound must be a number")
    .required("Lower bound is required"),
  upperBound: yup
    .number()
    .typeError("Upper bound must be a number")
    .required("Upper bound is required"),
  interestRate: yup
    .number()
    .typeError("Interest rate must be a number")
    .required("Interest rate is required"),
});

export const AddInterest = ({ open, handleClose }) => {
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting, isSubmitSuccessful },
    reset,
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  const [openDialog, setOpenDialog] = useState(false);
  const [infoText, setInfoText] = useState("");
  const [isError, setIsError] = useState(false);
  const interestRates = useSelector(({ settings }) => settings.interestRates);
  const dispatch = useDispatch();

  const handleCloseDialog = () => setOpenDialog(false);
  const handleSetInfoText = (text) => setInfoText(text);
  const handleSetIsError = () => setIsError(true);

  const onSubmit = (data) => {
    dispatch(
      setInterestRate({
        loanType: "Retail",
        interestRates: [...interestRates, data],
      })
    );

    handleClose();
    setOpenDialog(true);
  };

  useEffect(() => {
    if (isSubmitSuccessful) reset();
  }, [isSubmitSuccessful]);

  useEffect(() => {
    let timeoutId;
    if (openDialog) {
      timeoutId = setTimeout(() => {
        handleCloseDialog();
      }, 2000);
    }

    return () => clearTimeout(timeoutId);
  }, [openDialog]);

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add Interest Rate</DialogTitle>
        <DialogContent>
          <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{ display: "flex", width: "100%" }}>
              <Box sx={{ mr: 1 }}>
                <Typography sx={{ ...styles.formFieldHeading, color: "#000" }}>
                  Lower Bound
                </Typography>
                <Controller
                  name="lowerBound"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      size="small"
                      margin="normal"
                      error={!!errors.lowerBound}
                      helperText={errors.lowerBound?.message}
                      placeholder="20000"
                      sx={{ mt: 0, "& fieldset": { borderRadius: 1 } }}
                    />
                  )}
                />
              </Box>

              <Box sx={{ ml: 1 }}>
                <Typography sx={{ ...styles.formFieldHeading, color: "#000" }}>
                  Upper Bound
                </Typography>
                <Controller
                  name="upperBound"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      size="small"
                      margin="normal"
                      error={!!errors.upperBound}
                      helperText={errors.upperBound?.message}
                      placeholder="50000"
                      sx={{ mt: 0, "& fieldset": { borderRadius: 1 } }}
                    />
                  )}
                />
              </Box>
            </Box>

            <Typography sx={{ ...styles.formFieldHeading, color: "#000" }}>
              Interest Rate
            </Typography>
            <Controller
              name="interestRate"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  variant="outlined"
                  fullWidth
                  size="small"
                  margin="normal"
                  error={!!errors.interestRate}
                  helperText={errors.interestRate?.message}
                  placeholder="2.5"
                  sx={{ mt: 0, "& fieldset": { borderRadius: 1 } }}
                />
              )}
            />
            <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
              <Button
                onClick={() => {
                  reset();
                  handleClose();
                }}
                sx={{ mr: 1, textTransform: "capitalize", color: "#616663" }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                type="submit"
                disabled={isSubmitting}
                sx={{
                  boxShadow: "none",
                  color: "#fff",
                  textTransform: "capitalize",
                }}
              >
                Save
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
      <InfoDialog
        open={openDialog}
        handleClose={handleCloseDialog}
        text="Interest rates added successfully"
        error={isError}
      />
    </>
  );
};

import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { styles } from "../../../../../common/css/styles";
import { toTitleCase } from "../../../../../common/constants/utility";

export const Personal = ({ data }) => {
  return (
    <Box
      sx={{
        display: "flex",
        // justifyContent: "space-between",
        width: "100%",
      }}
    >
      <Box sx={{ mr: 25 }}>
        <Stack>
          <Box sx={{ py: 1 }}>
            <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
              First Name
            </Typography>
            <Typography sx={{ fontWeight: 700, color: "#494D4A" }}>
              {toTitleCase(data?.firstName)}
            </Typography>
          </Box>
          <Box sx={{ py: 1 }}>
            <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
              Email
            </Typography>
            <Typography sx={{ fontWeight: 700, color: "#494D4A" }}>
              {data?.email?.toLowerCase()}
            </Typography>
          </Box>
        </Stack>
      </Box>
      <Box>
        <Stack>
          <Box sx={{ py: 1 }}>
            <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
              Last Name
            </Typography>
            <Typography sx={{ fontWeight: 700, color: "#494D4A" }}>
              {toTitleCase(data?.lastName)}
            </Typography>
          </Box>
          <Box sx={{ py: 1 }}>
            <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
              Phone Number
            </Typography>
            <Typography sx={{ fontWeight: 700, color: "#494D4A" }}>
              {data?.phoneNumber}
            </Typography>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};

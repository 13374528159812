/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useParams } from "react-router-dom";

import { BusinessLoanOverview } from "./BusinessLoanOverview";
import { RetailLoanOverview } from "./RetailLoanOverview";

export const LoanOverviewNew = () => {
  const { loantype } = useParams();

  return (
    <>{loantype == "sme" ? <BusinessLoanOverview /> : <RetailLoanOverview />}</>
  );
};

export const stage = {
  PERSONAL: "PERSONAL",
  RESIDENCE: "RESIDENCE",
  EMPLOYMENT: "EMPLOYMENT",
  BANK: "BANK",
  NEXT_OF_KIN: "NEXT_OF_KIN",
  IDENTIFICATION: "IDENTIFICATION",
  PHOTO: "PHOTO",
  WALLET: "WALLET", // delete from here once refactor is complete
  LOAN: "LOAN",
  CREDIT_BUREAU: "CREDIT_BUREAU",
  RECOMMENDATION: "RECOMMENDATION",
  BUSINESS: "BUSINESS",
  TEAM_MEMBERS: "TEAM_MEMBERS",
  BUSINESS_DOCUMENT: "BUSINESS_DOCUMENT",

  LOAN_HISTORY: "LOAN_HISTORY",
};

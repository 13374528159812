import { Box, Button, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { BusinessTable } from "./BusinessTable";
import { SearchBar } from "../../common/components/SearchBar";
import { styles } from "../../common/css/styles";
import { WhiteBox } from "../../common/components/WhiteBox";
import { SectionHeader } from "../../common/components/SectionHeader";
import { useDispatch, useSelector } from "react-redux";
import { businessFilters, businessType } from "./constants/businessFilters";
import { Add } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { filteredBusinesses, searchBusinesses } from "./store/businessesSlice";

export const UnRegistered = ({ customer }) => {
  const [activeFilter, setActiveFilter] = useState(businessFilters.ALL);
  const [page, setPage] = useState(0);

  const businesses = useSelector((state) => state.businesses);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const openDialog = () => {
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  const handleSearch = (searchTerm) => {
    dispatch(searchBusinesses({ page: 1, searchTerm }));
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage - 1);
    dispatch(filteredBusinesses({ page: newPage, filterOption: activeFilter }));
  };

  const handleFilter = (filter) => {
    setActiveFilter(filter);
  };

  const handleClick = () => {
    navigate("/customers/add-new");
  };

  const handleView = (uid) =>
    navigate(`/business/unregistered/information/${uid}`, {
      state: { type: businessType.UNREGISTERED },
    });

  const filters = [
    { label: "All", value: businessFilters.ALL, visible: true },
    { label: "Prospects", value: businessFilters.PROSPECT, visible: true },
    {
      label: "Pending Verification",
      value: businessFilters.PENDING,
      visible: true,
    },
    { label: "Verified", value: businessFilters.ACTIVE, visible: true },
    { label: "Denied", value: businessFilters.DENIED, visible: true },
  ];

  useEffect(() => {
    dispatch(
      filteredBusinesses({
        page: 1,
        businessType: "UNREGISTERED_BUSINESS",
        filterOption: activeFilter,
      })
    );
  }, [activeFilter]);

  return (
    <>
      <SectionHeader headerText="Business Verification" />
      <WhiteBox>
        <Box
          sx={{
            p: 2,
          }}
        >
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <SearchBar onSearch={handleSearch} />
            <Button
              variant="outlined"
              // onClick={openDialog}
              onClick={handleClick}
              sx={{
                height: 30,
                textTransform: "capitalize",
                color: "#000",
                borderColor: "#000",
                mt: 2.5,
                display: "none",
              }}
            >
              <Add sx={{ fontSize: 12 }} />
              <Typography sx={{ fontSize: 12, pl: 1, pr: 0.5 }}>
                Add Customer
              </Typography>
            </Button>
          </Stack>
          <Box sx={{ py: 2 }}>
            {filters
              .filter((filter) => filter.visible)
              .map((filter) => (
                <Button
                  onClick={() => handleFilter(filter.value)}
                  variant="outlined"
                  sx={
                    activeFilter === filter.value
                      ? styles.tableFilter.active
                      : styles.tableFilter.inactive
                  }
                >
                  {filter.label}
                </Button>
              ))}
          </Box>
          <BusinessTable
            businesses={businesses}
            filterOption={activeFilter}
            page={page}
            handlePageChange={handlePageChange}
            handleView={handleView}
          />
        </Box>
      </WhiteBox>
    </>
  );
};

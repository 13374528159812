import PersonIcon from "@mui/icons-material/Person";
import { Avatar, Box, Button, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { styles } from "../../../../common/css/styles";
import checkIcon from "../../../../common/images/check-icon.svg";
import closeIcon from "../../../../common/images/close-1con.svg";
import { roles } from "../../../../common/constants/roles";
import { businessLoanFilters } from "../constants/businessLoanFilters";
import { toTitleCase } from "../../../../common/constants/utility";
import { RecommendForOffer } from "./RecommendForOffer";
import { RecommendForDecline } from "./RecommendForDecline";
import { Decline } from "./Decline";
import { MakeOffer } from "./MakeOffer";
import { DisburseConfirmation } from "./DisburseConfirmation";
import { ApproveLoan } from "./ApproveLoan";

export const CardSide = ({ businessInfo, loan }) => {
  const user = useSelector(({ auth }) => auth.user);
  const role = user?.roles[0]?.name;

  const [openRecommendForOffer, setOpenRecommendForOffer] = useState(false);
  const [openRecommendForDecline, setOpenRecommendForDecline] = useState(false);
  const [openDecline, setOpenDecline] = useState(false);
  const [openMakeOffer, setOpenMakeOffer] = useState(false);
  const [openApproveLoan, setOpenApproveLoan] = useState(false);
  const [openDisburseLoan, setOpenDisburseLoan] = useState(false);

  const setStatus = () => {
    switch (loan?.status) {
      case businessLoanFilters.ACTIVE:
        return <Typography sx={styles.cardTopStatus.active}>Active</Typography>;
      case businessLoanFilters.COMPLETED:
        return (
          <Typography sx={styles.cardTopStatus.active}>Completed</Typography>
        );
      case businessLoanFilters.DEFAULTING:
        return (
          <Typography sx={styles.cardTopStatus.suspended}>
            Defaulting
          </Typography>
        );
      case businessLoanFilters.NTU:
        return <Typography sx={styles.cardTopStatus.ntu}>NTU</Typography>;
      case businessLoanFilters.REQUEST_DECLINED:
        return (
          <Typography sx={styles.cardTopStatus.denied}>
            Request Declined
          </Typography>
        );
      case businessLoanFilters.OFFERED:
        return (
          <Typography sx={styles.cardTopStatus.offered}>Offered</Typography>
        );
      case businessLoanFilters.OFFER_REJECTED:
        return (
          <Typography sx={styles.cardTopStatus.rejected}>
            Offer Rejected
          </Typography>
        );
      case businessLoanFilters.APPROVED:
        return (
          <Typography sx={styles.cardTopStatus.approved}>Approved</Typography>
        );
      case businessLoanFilters.OFFER_ACCEPTED:
        return (
          <Typography sx={styles.cardTopStatus.accepted}>
            Offer Accepted
          </Typography>
        );
      case businessLoanFilters.LOAN_REJECTED:
        return (
          <Typography sx={styles.cardTopStatus.loanRejected}>
            Loan Rejected
          </Typography>
        );
      case businessLoanFilters.PENDING_AUTHORIZATION:
        return (
          <Typography sx={styles.cardTopStatus.pendingAuth}>
            Pending Autorization
          </Typography>
        );
      case businessLoanFilters.PENDING_OFFER:
        return (
          <Typography sx={styles.cardTopStatus.pending}>
            Pending Offer
          </Typography>
        );
      case businessLoanFilters.PENDING_RECOMMENDATION:
        return (
          <Typography sx={styles.cardTopStatus.pendingRecommendation}>
            Pending Recommendation
          </Typography>
        );
      case businessLoanFilters.PENDING_DISBURSEMENT:
        return (
          <Typography sx={styles.cardTopStatus.pendingDisbursement}>
            Pending Disbursement
          </Typography>
        );
      case businessLoanFilters.PENDING_APPROVAL:
        return (
          <Typography sx={styles.cardTopStatus.pendingReview}>
            Pending Approval
          </Typography>
        );
      case businessLoanFilters.UNDER_REVIEW:
        return (
          <Typography sx={styles.cardTopStatus.underReview}>
            Under Review
          </Typography>
        );
      default:
        break;
    }
  };

  const shouldSeeRecommendButtons = () => {
    if (
      role === roles.MANAGER &&
      loan?.status === businessLoanFilters.PENDING_RECOMMENDATION
      //  && user?.uid === loan?.recommender?.uid
    )
      return true;
    else return false;
  };

  const shouldSeeOfferButtons = () => {
    if (
      role === roles.APPROVER &&
      loan?.status === businessLoanFilters.PENDING_OFFER
      // && user?.uid === loan?.offerer?.uid
    )
      return true;
    else return false;
  };

  const showApprovalButton = () => {
    return (
      role === roles.APPROVER &&
      loan?.status === businessLoanFilters.PENDING_APPROVAL
      // && user?.uid === loan?.offerer?.uid
    );
  };

  const showDisbursementButton = () => {
    return (
      role === roles.FINANCIAL_MANAGER &&
      loan?.status === businessLoanFilters.PENDING_DISBURSEMENT
      // && user?.uid === loan?.offerer?.uid
    );
  };

  return (
    <Box sx={{ p: 2, position: "sticky", background: "#fff" }}>
      <Stack>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Stack sx={{ ml: 2 }} justifyContent="center" alignItems="center">
            {businessInfo?.companyLogo ? (
              <Avatar
                src={businessInfo?.companyLogo}
                sx={{ width: 80, height: 80 }}
              />
            ) : (
              <Avatar sx={{ width: 100, height: 100 }}>
                <PersonIcon />
              </Avatar>
            )}

            <Typography sx={{ fontWeight: 700, fontSize: 24 }}>
              {`${businessInfo?.businessName
                ?.charAt(0)
                .toUpperCase()}${businessInfo?.businessName
                ?.substring(1)
                .toLowerCase()}`}
            </Typography>
            <Typography
              sx={{ fontSize: 14, fontWeight: 500, color: "#616663" }}
            >
              {businessInfo?.email.toLowerCase()}
            </Typography>
            {setStatus()}
          </Stack>
        </Box>
      </Stack>
      <Box sx={{ borderBottom: 1, mt: 5, pb: 2, borderBottomColor: "#F0F1ED" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography sx={styles.kyc.headerText}>Type of Company</Typography>
          <Typography sx={{ fontWeight: 700, py: 1 }}>
            {toTitleCase(
              businessInfo?.companyType.toLowerCase().replace("_", " ")
            )}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography sx={styles.kyc.headerText}>
            Wallet Account Number
          </Typography>
          <Typography sx={{ fontWeight: 700, py: 1 }}>
            {businessInfo?.wallet?.accountNumber ?? "Not Available"}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography sx={styles.kyc.headerText}>Wallet Balance</Typography>
          <Typography sx={{ fontWeight: 700, py: 1 }}>
            {businessInfo?.wallet?.balance?.toLocaleString("en-NG", {
              style: "currency",
              currency: "NGN",
            }) ?? "Not Available"}
          </Typography>
        </Box>
      </Box>

      <Box sx={{ my: 2 }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography sx={styles.kyc.headerText}>Loan Request</Typography>
          <Typography sx={{ fontWeight: 700, py: 1 }}>
            {loan?.loanRequestAmount?.toLocaleString("en-NG", {
              style: "currency",
              currency: "NGN",
            }) ?? "Not Available"}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography sx={styles.kyc.headerText}>Tenor</Typography>
          <Typography sx={{ fontWeight: 700, py: 1 }}>
            {loan?.repaymentPeriod === 1
              ? `${loan?.repaymentPeriod} Month`
              : `${loan?.repaymentPeriod} Months`}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography sx={styles.kyc.headerText}>Purpose</Typography>
          <Typography sx={{ fontWeight: 700, py: 1 }}>
            {toTitleCase(loan?.loanPurpose?.replace("_", " "))}
          </Typography>
        </Box>
      </Box>

      {shouldSeeRecommendButtons() ? (
        <Box sx={{ pt: 2, borderTop: 1, borderTopColor: "#F0F1ED" }}>
          <Stack direction="row" justifyContent="space-between">
            <Button
              variant="contained"
              onClick={() => setOpenRecommendForOffer(true)}
              sx={{ ...styles.bridgeCardTop.success, fontSize: 11 }}
            >
              <Box sx={{ mt: 1, pr: 1 }}>
                <img src={checkIcon} alt="check-icon" />
              </Box>
              Recommend for Offer
            </Button>
            <Button
              variant="contained"
              onClick={() => setOpenRecommendForDecline(true)}
              sx={{ ...styles.bridgeCardTop.error, fontSize: 11 }}
            >
              <Box sx={{ mt: 1, pr: 1 }}>
                <img src={closeIcon} alt="close=icon" />
              </Box>
              Recommend for Decline
            </Button>
          </Stack>
        </Box>
      ) : (
        <></>
      )}
      {shouldSeeOfferButtons() ? (
        <Box sx={{ pt: 2, borderTop: 1, borderTopColor: "#F0F1ED" }}>
          <Stack direction="row" justifyContent="space-between">
            <Button
              variant="contained"
              onClick={() => setOpenMakeOffer(true)}
              sx={styles.bridgeCardTop.success}
            >
              <Box sx={{ mt: 1, pr: 1 }}>
                <img src={checkIcon} alt="check-icon" />
              </Box>
              Make Offer
            </Button>
            <Button
              variant="contained"
              onClick={() => setOpenDecline(true)}
              sx={styles.bridgeCardTop.error}
            >
              <Box sx={{ pr: 2, fontSize: 13.3, mt: 1 }}>
                <img src={closeIcon} alt="close-icon" />
              </Box>
              Decline
            </Button>
          </Stack>
        </Box>
      ) : (
        <></>
      )}
      {showApprovalButton() ? (
        <Box sx={{ pt: 2, borderTop: 1, borderTopColor: "#F0F1ED" }}>
          <Button
            variant="contained"
            onClick={() => setOpenApproveLoan(true)}
            sx={{ ...styles.bridgeCardTop.success, height: "40px" }}
          >
            Approve for Disbursement
          </Button>
        </Box>
      ) : (
        <></>
      )}
      {showDisbursementButton() ? (
        <Box sx={{ pt: 2, borderTop: 1, borderTopColor: "#F0F1ED" }}>
          <Button
            variant="contained"
            onClick={() => setOpenDisburseLoan(true)}
            sx={{ ...styles.bridgeCardTop.success, height: "40px" }}
          >
            Disburse Loan
          </Button>
        </Box>
      ) : (
        <></>
      )}

      <RecommendForOffer
        open={openRecommendForOffer}
        handleClose={() => setOpenRecommendForOffer(false)}
      />
      <RecommendForDecline
        open={openRecommendForDecline}
        handleClose={() => setOpenRecommendForDecline(false)}
      />
      <Decline open={openDecline} handleClose={() => setOpenDecline(false)} />
      <MakeOffer
        open={openMakeOffer}
        handleClose={() => setOpenMakeOffer(false)}
      />
      <DisburseConfirmation
        open={openDisburseLoan}
        handleClose={() => setOpenDisburseLoan(false)}
      />
      <ApproveLoan
        open={openApproveLoan}
        handleClose={() => setOpenApproveLoan(false)}
      />
    </Box>
  );
};

import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { styles } from "../../../../../common/css/styles";
import { Upload } from "@mui/icons-material";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { submitKyc } from "../../../../kyc/kycSlice";
import { stage } from "../../../../kyc/stages";
import { InfoDialog } from "../../../../../common/components/InfoDialog";

const defaultValues = {
  idMode: "",
  idNumber: "",
  idPhoto: "",
};

const schema = yup.object().shape({
  idMode: yup.string().required("Please select an ID type"),
  idNumber: yup.string().required("Please input your ID number"),
  idPhoto: yup.mixed().required("Please upload your ID"),
});

export const GovernmentIDUpload = ({ customerData, handleAddCustomerData }) => {
  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
    setValue,
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  const [selectedFile, setSelectedFile] = useState("");
  const [previewURL, setPreviewURL] = useState("");
  const customer = useSelector(({ customer }) => customer.customer);
  const dispatch = useDispatch();

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewURL(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setSelectedFile("");
      setPreviewURL("");
    }
  };

  const onSubmit = (data) => {
    customerData.identification = {
      ...data,
      userUid: customer.uid,
      // userUid: "b2ccfd72-107d-4ead-9b85-468b710bde9c",
      onboardingStage: stage.IDENTIFICATION,
    };
    handleAddCustomerData(customerData);
    dispatch(submitKyc(customerData.identification));
  };

  useEffect(() => {
    if (customerData.identification) {
      setValue("idMode", customerData.identification.idMode);
      setValue("idNumber", customerData.identification.idNumber);
      setValue("idPhoto", customerData.identification.idPhoto);
    }
  });

  return (
    <Grid container>
      <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)}>
        <Grid item my={1}>
          <Typography sx={styles.formFieldHeading}>ID Type</Typography>
          <Controller
            name="idMode"
            control={control}
            render={({ field }) => (
              <FormControl fullWidth size="small" sx={styles.textField}>
                <Select
                  {...field}
                  placeholder="ID Type"
                  sx={styles.textField}
                  error={!!errors.idMode}
                >
                  <MenuItem value="NIN">NIN</MenuItem>
                  {/* <MenuItem value="DriversLicense">Driver's License</MenuItem>
                  <MenuItem value="Passport">International Passport</MenuItem>
                  <MenuItem value="VotersCard">Voter's Card</MenuItem> */}
                </Select>
                {errors.idMode ? (
                  <FormHelperText error>
                    {errors?.idMode?.message}
                  </FormHelperText>
                ) : (
                  <></>
                )}
              </FormControl>
            )}
          />
        </Grid>
        <Grid item my={1}>
          <Typography sx={styles.formFieldHeading}>ID Number</Typography>
          <Controller
            name="idNumber"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                variant="outlined"
                size="small"
                sx={styles.textField}
                error={!!errors.idNumber}
                helperText={errors.idNumber?.message}
              />
            )}
          />
        </Grid>

        <Controller
          name="idPhoto"
          control={control}
          render={({ field }) => (
            <label htmlFor="file-input">
              <input
                accept="image/*"
                style={{ display: "none" }}
                id="file-input"
                type="file"
                onChange={(e) => {
                  handleFileChange(e);
                  field.onChange(e.target.files);
                }}
              />
              <Button
                variant="contained"
                component="span"
                fullWidth
                sx={{
                  backgroundColor: "#E3E3E7",
                  textTransform: "capitalize",
                  width: 350,
                  mt: 2,
                  boxShadow: "none",
                  "&:hover": {
                    boxShadow: "none",
                    color: "#fff",
                  },
                }}
              >
                {selectedFile ? (
                  "Change File"
                ) : (
                  <>
                    <Upload sx={{ fontSize: 12, pr: 1 }} />
                    Upload ID
                  </>
                )}
              </Button>
            </label>
          )}
        />
        <Box mt={2}>
          {selectedFile && (
            <Stack>
              <Typography variant="subtitle1">File Preview:</Typography>
              <img src={previewURL} alt="Preview" width="360" />
              <Button
                variant="contained"
                color="primary"
                type="submit"
                sx={{
                  textTransform: "capitalize",
                  width: 360,
                  mt: 2,
                  boxShadow: "none",
                  color: "#fff",
                  "&:hover": {
                    boxShadow: "none",
                    color: "#fff",
                  },
                }}
              >
                Upload
              </Button>
            </Stack>
          )}
        </Box>
      </Box>
    </Grid>
  );
};

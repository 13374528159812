import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { styles } from "../../../../../common/css/styles";
import { toTitleCase } from "../../../../../common/constants/utility";

export const Details = ({ data }) => {
  return (
    <Box
      sx={{
        display: "flex",
        // justifyContent: "space-between",
        width: "100%",
      }}
    >
      <Box sx={{ mr: 25 }}>
        <Stack>
          <Box sx={{ py: 1 }}>
            <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
              Business Name
            </Typography>
            <Typography sx={{ fontWeight: 700, color: "#494D4A" }}>
              {toTitleCase(data?.businessName)}
            </Typography>
          </Box>
          <Box sx={{ py: 1 }}>
            <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
              Business Phone
            </Typography>
            <Typography sx={{ fontWeight: 700, color: "#494D4A" }}>
              {toTitleCase(data?.phoneNumber)}
            </Typography>
          </Box>
        </Stack>
      </Box>
      <Box>
        <Stack>
          <Box sx={{ py: 1 }}>
            <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
              Business Email
            </Typography>
            <Typography
              sx={{ ...styles.kyc.bodyText, textTransform: "lowercase" }}
            >
              {data?.email}
            </Typography>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};

import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";

import doneIcon from "../../../common/images/done-icon.svg";

export const OTPSuccess = ({ open, handleClose }) => {
  useEffect(() => {
    let timeoutId;
    if (open) {
      timeoutId = setTimeout(() => {
        handleClose();
      }, 2000);
    }

    return () => clearTimeout(timeoutId);
  }, [open]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          //   minWidth: "500px",
        }}
      >
        <DialogContent
          sx={{
            width: "280px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <img src={doneIcon} width="96px" alt="done-icon" />

          <DialogContentText sx={{ textAlign: "center" }}>
            <Typography sx={{ fontSize: 17, fontWeight: 500 }}>
              Authorized
            </Typography>
            <Typography
              sx={{ fontSize: 14, fontWeight: 500, color: "#969997" }}
            >
              OTP successfully sent to customer's Mobile Number
            </Typography>
          </DialogContentText>
        </DialogContent>
      </Box>
    </Dialog>
  );
};

/* eslint-disable import/no-anonymous-default-export */
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/auth/authSlice";
import customersReducer from "../features/customers/store/customersSlice";
import customerReducer from "../features/customers/store/customerSlice";
import kycReducer from "../features/kyc/kycSlice";
import bridgeLoansReducer from "../features/bridgeFinance/store/bridgeFinanceLoansSlice";
import bridgeLoanReducer from "../features/bridgeFinance/store/bridgeFinanceLoanSlice";
import usersReducer from "../features/userManagement/usersSlice";
import settingsReducer from "../features/settings/settingsSlice";
import walletsReducer from "../features/wallet/walletSlice";
import disbursementsReducer from "../features/disbursements/disbursementsSlice";
import auditLogsReducer from "../features/audit/auditLogsSlice";
import overviewReducer from "../features/overview/overviewSlice";
import bankReducer from "../common/store/bankSlice";
import devicesReducer from "../features/changeDevice/devicesSlice";
import businessesReducer from "../features/business/store/businessesSlice";
import businessReducer from "../features/business/store/businessSlice";
import retailLoansReducer from "../features/loans/store/retailLoansSlice";
import retailLoanReducer from "../features/loans/store/retailLoanSlice";
import businessLoansReducer from "../features/smeLoans/businessLoan/store/businessLoansSlice";
import businessLoanReducer from "../features/smeLoans/businessLoan/store/businessLoanSlice";
import notificationSlice from "../common/notificationSlice";

import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
const persistConfig = {
  key: "laekwebLoanStaff",
  storage,
};

const rootReducer = combineReducers({
  auth: authReducer,
  overview: overviewReducer,
  customers: customersReducer,
  customer: customerReducer,
  businesses: businessesReducer,
  business: businessReducer,
  kyc: kycReducer,
  bridgeLoans: bridgeLoansReducer,
  bridgeLoan: bridgeLoanReducer,
  retailLoans: retailLoansReducer,
  retailLoan: retailLoanReducer,
  businessLoans: businessLoansReducer,
  businessLoan: businessLoanReducer,
  users: usersReducer,
  settings: settingsReducer,
  wallets: walletsReducer,
  disbursements: disbursementsReducer,
  auditLogs: auditLogsReducer,
  bank: bankReducer,
  devices: devicesReducer,
  notification: notificationSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export let store = configureStore({ reducer: persistedReducer });
export let persistor = persistStore(store);

// export const store = configureStore({
//   reducer: {

//   },
// });

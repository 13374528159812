import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  addUser,
  getUser,
  getUsers,
  search,
  updateUser,
} from "../../common/api/users";
import { feedback } from "../../common/constants/feedback";
import { handleError } from "../../common/constants/utility";

const initialState = {
  users: [],
  user: null,
  loading: false,
  loadingUser: false,
  totalRecords: 0,
  error: [],
  feedback: "",
};

export const fetchUser = createAsyncThunk(
  "users/fetch",
  async (userUid, { dispatch, rejectWithValue }) => {
    try {
      const response = await getUser(userUid);
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);

export const fetchUsers = createAsyncThunk(
  "users/fetchAll",
  async ({ page, filterOption }, { dispatch, rejectWithValue }) => {
    try {
      const response = await getUsers(page, filterOption);
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);

export const searchUsers = createAsyncThunk(
  "users/search",
  async ({ page, searchTerm }, { dispatch, rejectWithValue }) => {
    try {
      const response = await search(page, searchTerm);
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);

export const addNewUser = createAsyncThunk(
  "users/add",
  async (model, { dispatch, rejectWithValue }) => {
    try {
      const response = await addUser(model);
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);

export const updateUserInfo = createAsyncThunk(
  "users/update",
  async (model, { dispatch, rejectWithValue }) => {
    try {
      const response = await updateUser(model);
      dispatch(fetchUsers({ page: 1, filterOption: "" }));
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    clearFeedback: (state, action) => {
      state.feedback = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUsers.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchUsers.fulfilled, (state, action) => {
      state.users = action.payload.data.users;
      state.loading = false;
      state.totalRecords = action.payload.data.totalRecords;
      state.error = [];
    });
    builder.addCase(fetchUsers.rejected, (state, action) => {
      state.users = [];
      state.loading = false;
      state.totalRecords = 0;
      state.error = action.payload;
    });
    builder.addCase(searchUsers.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(searchUsers.fulfilled, (state, action) => {
      state.users = action.payload.data.users;
      state.loading = false;
      state.totalRecords = action.payload.data.totalRecords;
      state.error = [];
    });
    builder.addCase(searchUsers.rejected, (state, action) => {
      state.users = [];
      state.loading = false;
      state.totalRecords = 0;
      state.error = action.payload;
    });
    builder.addCase(addNewUser.fulfilled, (state, action) => {
      state.feedback = feedback.CREATED;
    });
    builder.addCase(addNewUser.rejected, (state, action) => {
      state.feedback = feedback.CREATE_FAILED;
      state.error = action.payload;
    });
    builder.addCase(fetchUser.pending, (state, action) => {
      state.loadingUser = true;
    });
    builder.addCase(fetchUser.fulfilled, (state, action) => {
      state.user = action.payload.data;
      state.loadingUser = false;
    });
    builder.addCase(fetchUser.rejected, (state, action) => {
      state.error = action.payload;
      state.loadingUser = false;
    });
    builder.addCase(updateUserInfo.fulfilled, (state, action) => {
      state.feedback = feedback.UPDATED;
    });
    builder.addCase(updateUserInfo.rejected, (state, action) => {
      state.error = action.payload;
      state.feedback = feedback.UPDATE_FAILED;
    });
  },
});

export const { clearFeedback } = usersSlice.actions;
export default usersSlice.reducer;

import {
  Box,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { styles } from "../../../common/css/styles";
import { overviewFilters } from "../overviewFilters";
import { PieChart } from "@mui/x-charts/PieChart";
import { InfoOutlined } from "@mui/icons-material";
import prospectsIcon from "../../../common/images/prospects-pink.svg";
import unverifiedIcon from "../../../common/images/unverified-purple.svg";
import verifiedIcon from "../../../common/images/verified.svg";
import customersIcon from "../../../common/images/customers-yellow.svg";
import { useLoanParams } from "../hooks/useLoanParams";
import dayjs from "dayjs";
import { format } from "date-fns";
import CustomDate from "../../../common/components/CustomDate";

const d = new Date();
export const Customers = ({ overview, customersStatistics, ranges }) => {
  const { getDateRange, fetchCustomerStats, dispatch } = useLoanParams();
  const pieParams = { height: 200, margin: { right: 2 } };
  const [filter, setFilter] = useState(overviewFilters.LAST7DAYS);
  const [customFilter, setCustomFilter] = useState({
    open: false,
    startDate: format(d.setDate(d.getDate()), "yyyy-MM-dd"),
    endDate: format(d.setDate(d.getDate()), "yyyy-MM-dd"),
  });

  const handleStatisticsFilterChange = (event) => {
    const filter = event.target.value;
    setFilter(filter);
    const range = filter === "CUSTOM" ? customFilter : getDateRange(filter);
    if (filter !== "CUSTOM") {
      setCustomFilter({ ...customFilter, open: false });
      dispatch(fetchCustomerStats({ ...range }));
    } else {
      setCustomFilter({
        startDate: range.startDate,
        endDate: range.endDate,
        open: true,
      });
    }
  };
  const handleCustomFilter = (name, value) => {
    setCustomFilter({
      ...customFilter,
      [name]: dayjs(value.$d).format("YYYY-MM-DD"),
    });
  };
  const applyCustomFilter = () => {
    setCustomFilter({ ...customFilter, open: false });
    dispatch(
      fetchCustomerStats({
        startDate: customFilter.startDate,
        endDate: customFilter.endDate,
      })
    );
  };
  return (
    <Box
      item
      sx={{
        width: "100%",
        backgroundColor: "#fff",
        borderRadius: 2,
        mt: 2,
      }}
    >
      <Box
        sx={{
          p: 2,
          px: 3,
          pb: 4,
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography sx={{ fontWeight: 600, fontSize: 16 }}>
            Customers
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              position: "relative",
            }}
          >
            <FormControl sx={{ mt: 1 }}>
              <Select
                value={filter}
                size="small"
                onChange={handleStatisticsFilterChange}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                sx={styles.dropdown}
              >
                {ranges.map((range, n) => (
                  <MenuItem
                    onClick={() =>
                      range.value === "CUSTOM"
                        ? setCustomFilter({ ...customFilter, open: true })
                        : null
                    }
                    key={n}
                    value={range.value}
                  >
                    {range.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {customFilter.open ? (
              <CustomDate
                handleCustomFilter={handleCustomFilter}
                customFilter={customFilter}
                close={() => setCustomFilter({ ...customFilter, open: false })}
                applyCustomFilter={applyCustomFilter}
              />
            ) : (
              <></>
            )}
          </Box>
        </Box>

        <Grid container pt={2}>
          <Grid item xs={6}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
              }}
            >
              <PieChart
                series={[
                  {
                    innerRadius: 60,
                    data: [
                      {
                        value:
                          overview?.customerStats?.totalUnverifiedCustomers,
                        color: "#A5A6F6",
                      },
                      {
                        value:
                          overview?.customerStats?.totalProspectiveCustomers,
                        color: "#EF5DA8",
                      },
                      {
                        value: overview?.customerStats?.totalActiveCustomers,
                        color: "#FFBC57",
                      },
                    ],
                  },
                ]}
                {...pieParams}
              />
              <Box sx={{ position: "relative" }}>
                <Typography sx={styles.pieChart}>
                  {overview?.customerStats?.totalCustomers}
                </Typography>
              </Box>

              <Box sx={{ position: "relative" }}>
                <Typography
                  sx={{
                    fontSize: 12,
                    fontWeight: 400,
                    textAlign: "center",
                    mt: -12,
                    ml: 0.5,
                  }}
                >
                  Total Customers
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6} pr={3} width={"100%"}>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "20px",
                columnGap: "40px",
              }}
            >
              <Box sx={{ ...styles.overview.statistics.box, width: "100%" }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    pl: 1,
                  }}
                >
                  {overview.loadingCustomerStats ? (
                    <Skeleton
                      animation="wave"
                      variant="circular"
                      width={50}
                      style={{ minWidth: "50px" }}
                      height={50}
                    />
                  ) : (
                    <img src={verifiedIcon} alt="customers-icon" />
                  )}
                  <Stack sx={{ pl: 1 }}>
                    {overview.loadingCustomerStats ? (
                      <Skeleton
                        animation="wave"
                        height={20}
                        width={90}
                        style={{ marginBottom: 6 }}
                      />
                    ) : (
                      <Typography sx={styles.overview.statistics.info}>
                        Total Customers
                      </Typography>
                    )}
                    {overview.loadingCustomerStats ? (
                      <Skeleton
                        animation="wave"
                        height={30}
                        width={50}
                        style={{ marginBottom: 6 }}
                      />
                    ) : (
                      <Typography sx={styles.overview.statistics.number}>
                        {overview?.customerStats?.totalCustomers}
                      </Typography>
                    )}
                  </Stack>
                </Box>

                <Tooltip
                  title="Total Customers"
                  placement="top"
                  sx={{ alignSelf: "normal" }}
                >
                  <InfoOutlined sx={styles.overview.statistics.tooltip} />
                </Tooltip>
              </Box>
              <Box sx={{ ...styles.overview.statistics.box, width: "100%" }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    pl: 1,
                  }}
                >
                  {" "}
                  {overview.loadingCustomerStats ? (
                    <Skeleton
                      animation="wave"
                      variant="circular"
                      width={50}
                      style={{ minWidth: "50px" }}
                      height={50}
                    />
                  ) : (
                    <img src={customersIcon} alt="customers-icon" />
                  )}
                  <Stack sx={{ pl: 1 }}>
                    {overview.loadingCustomerStats ? (
                      <Skeleton
                        animation="wave"
                        height={20}
                        width={90}
                        style={{ marginBottom: 6 }}
                      />
                    ) : (
                      <Typography sx={styles.overview.statistics.info}>
                        Verified Customers
                      </Typography>
                    )}
                    {overview.loadingCustomerStats ? (
                      <Skeleton
                        animation="wave"
                        height={30}
                        width={50}
                        style={{ marginBottom: 6 }}
                      />
                    ) : (
                      <Typography sx={styles.overview.statistics.number}>
                        {overview?.customerStats?.totalActiveCustomers}
                      </Typography>
                    )}
                  </Stack>
                </Box>

                <Tooltip
                  title="Verified Customers"
                  placement="top"
                  sx={{ alignSelf: "normal" }}
                >
                  <InfoOutlined sx={styles.overview.statistics.tooltip} />
                </Tooltip>
              </Box>
              <Box sx={{ ...styles.overview.statistics.box, width: "100%" }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    pl: 1,
                  }}
                >
                  {overview.loadingCustomerStats ? (
                    <Skeleton
                      animation="wave"
                      variant="circular"
                      width={50}
                      style={{ minWidth: "50px" }}
                      height={50}
                    />
                  ) : (
                    <img src={prospectsIcon} alt="prospects-icon" />
                  )}
                  <Stack sx={{ pl: 2 }}>
                    {overview.loadingCustomerStats ? (
                      <Skeleton
                        animation="wave"
                        height={20}
                        width={90}
                        style={{ marginBottom: 6 }}
                      />
                    ) : (
                      <Typography sx={styles.overview.statistics.info}>
                        Prospects
                      </Typography>
                    )}
                    {overview.loadingCustomerStats ? (
                      <Skeleton
                        animation="wave"
                        height={30}
                        width={50}
                        style={{ marginBottom: 6 }}
                      />
                    ) : (
                      <Typography sx={styles.overview.statistics.number}>
                        {overview?.customerStats?.totalProspectiveCustomers}
                      </Typography>
                    )}
                  </Stack>
                </Box>

                <Tooltip
                  title="Prospects"
                  placement="top"
                  sx={{ alignSelf: "normal" }}
                >
                  <InfoOutlined sx={styles.overview.statistics.tooltip} />
                </Tooltip>
              </Box>
              <Box sx={{ ...styles.overview.statistics.box, width: "100%" }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    pl: 1,
                  }}
                >
                  {" "}
                  {overview.loadingCustomerStats ? (
                    <Skeleton
                      animation="wave"
                      variant="circular"
                      width={50}
                      style={{ minWidth: "50px" }}
                      height={50}
                    />
                  ) : (
                    <img src={unverifiedIcon} alt="unverified-icon" />
                  )}
                  <Stack sx={{ pl: 1 }}>
                    {overview.loadingCustomerStats ? (
                      <Skeleton
                        animation="wave"
                        height={20}
                        width={90}
                        style={{ marginBottom: 6 }}
                      />
                    ) : (
                      <Typography sx={styles.overview.statistics.info}>
                        Unverified Customers
                      </Typography>
                    )}
                    {overview.loadingCustomerStats ? (
                      <Skeleton
                        animation="wave"
                        height={30}
                        width={50}
                        style={{ marginBottom: 6 }}
                      />
                    ) : (
                      <Typography sx={styles.overview.statistics.number}>
                        {overview?.customerStats?.totalUnverifiedCustomers}
                      </Typography>
                    )}
                  </Stack>
                </Box>

                <Tooltip
                  title="Unverified Customers"
                  placement="top"
                  sx={{ alignSelf: "normal" }}
                >
                  <InfoOutlined sx={styles.overview.statistics.tooltip} />
                </Tooltip>
              </Box>
            </Box>
            {/* <Box  sx={{ width: "100%", display }} spacing={2}>
            </Box>
            <Stack direction="row" spacing={2} mt={2}>
            </Stack> */}
          </Grid>
        </Grid>
        {/* <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
          }}
        >
          <PieChart
            series={[
              {
                innerRadius: 60,
                data: [
                  {
                    value: customersStatistics.totalUnverifiedCustomers,
                    color: "#A5A6F6",
                  },
                  {
                    value: customersStatistics.totalActiveCustomers,
                    color: "#EF5DA8",
                  },
                  {
                    value: customersStatistics.totalProspectiveCustomers,
                    color: "#FFBC57",
                  },
                ],
              },
            ]}
            {...pieParams}
          />
          <Box sx={{ position: "relative" }}>
            <Typography sx={styles.pieChart}>
              {customersStatistics.totalCustomers}
            </Typography>
          </Box>

          <Box sx={{ position: "relative" }}>
            <Typography
              sx={{
                fontSize: 12,
                fontWeight: 400,
                textAlign: "center",
                mt: -12,
                ml: 0.5,
              }}
            >
              Total Customers
            </Typography>
          </Box>
        </Box> */}
      </Box>
    </Box>
  );
};

import { client } from ".";

const size = process.env.REACT_APP_PAGESIZE;

export const getBusinesses = (page, businessType, filterBy) => {
  return client.get(
    `sme/verification/find?page=${page}&size=${size}&companyType=${businessType}&status=${filterBy}`
  );
};

export const getBusiness = (businessUid) => {
  return client.get(`sme/verification/business-info/${businessUid}`);
};
export const getBusinessOwnwersById = (businessId) => {
  return client.get(`/sme/verification/business-owners`, {
    params: { businessId, page: 1, size: 20 },
  });
};
export const search = (page, searchTerm) => {
  return client.get(
    `sme/customers?page=${page}&size=${size}&search=${searchTerm}`
  );
};

export const getContactInfo = (businessUid) => {
  return client.get(`sme/verification/profile-info/${businessUid}`);
};

export const getDocuments = (businessUid) => {
  return client.get(`sme/verification/business-document/${businessUid}`);
};

export const getOwnerInfo = ({ businessUid, page }) => {
  return client.get(
    `sme/verification/business-owners?businessId=${businessUid}&page=${page}&size=${size}`
  );
};

export const updateBusinessStatus = ({
  businessId,
  status,
  fields,
  message,
}) => {
  return client.post("sme/verification/update-status", {
    businessId,
    status,
    fields,
    message,
  });
};

import { ArrowForward } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Button } from "@mui/material";
import React from "react";

export const NextButton = ({ buttonText, isSubmitting }) => {
  return (
    <Box>
      <LoadingButton
        variant="contained"
        type="submit"
        endIcon={buttonText === "Next" ? <ArrowForward /> : null}
        loadingPosition="end"
        loading={isSubmitting}
        sx={{
          textTransform: "capitalize",
          height: 40,
          color: "#fff",
          boxShadow: "none",
          fontSize: 16,

          "&:hover": {
            // backgroundColor: "#f6adad",
            boxShadow: "none",
          },
        }}
      >
        {buttonText}
      </LoadingButton>
    </Box>
  );
};

export const businessFilters = {
  ALL: "",
  PROSPECT: "PROSPECT",
  PENDING: "PENDING",
  VERIFIED: "ACTIVE",
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
  SUSPENDED: "SUSPENDED",
  DELETED: "DELETED",
  DENIED: "DENIED",
  LOCKED: "LOCKED",
  BLACKLISTED: "BLACKLISTED",
  COMPLETED: "COMPLETED",
  DEFAULTING: "DEFAULTING",
};

export const businessDetailsFilters = {
  PERSONAL: "PERSONAL",
  RESIDENCE: "RESIDENCE",
  PHOTO: "PHOTO",
  BVN: "BVN",
  ADDRESS_VERIFICATION: "ADDRESS_VERIFICATION",
  IDENTIFICATION: "IDENTIFICATION",
};

export const businessInfoFilters = {
  DETAILS: "DETAILS",
  ADDRESS: "ADDRESS",
  PROFILE: "PROFILE",
  OPERATIONS: "OPERATIONS",
  ADDRESS_VERIFICATION: "ADDRESS_VERIFICATION",
};

export const businessType = {
  REGISTERED: "REGISTERED",
  UNREGISTERED: "UNREGISTERED",
};

import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { styles } from "../../../../../common/css/styles";

export const AddressVerification = ({ data }) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "row" }}>
      {/* User Input */}
      <Box sx={{ py: 2, width: "50%" }}>
        <Typography sx={{ fontWeight: 700, fontSize: 18, py: 2 }}>
          Address Verification
        </Typography>

        <Box
          sx={{
            border: 1,
            borderRadius: 2,
            minHeight: 200,
            maxWidth: "90%",
            px: 2,
          }}
        >
          <Box sx={{ width: "100%", py: 1 }}>
            <img
              src={data?.proofOfAddressUrl}
              alt="utility bill"
              width="100%"
              height="400"
            />
          </Box>
        </Box>
      </Box>
      {/* BVN Generated */}
      <Box sx={{ py: 2, width: "50%" }}>
        <Typography sx={{ fontWeight: 700, py: 2 }}>User Input</Typography>
        <Box
          sx={{
            border: 1,
            borderRadius: 2,
            minHeight: 200,
            maxWidth: "90%",
            px: 2,
            mt: 0.3,
          }}
        >
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ py: 1 }}>
              <Typography sx={styles.bvn.topLeft}>Address</Typography>
              <Typography sx={styles.bvn.bottomLeft}>
                {data?.businessStreet ?? "Not Available"}
              </Typography>
            </Box>{" "}
          </Stack>
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ py: 1 }}>
              <Typography sx={styles.bvn.topLeft}>State</Typography>
              <Typography sx={styles.bvn.bottomLeft}>
                {data?.businessState ?? "Not Available"}
              </Typography>
            </Box>
            <Box sx={{ py: 1 }}>
              <Typography sx={styles.bvn.topRight}>LGA</Typography>
              <Typography sx={styles.bvn.bottomRight}>
                {data?.localGovernmentArea ?? "Not Available"}
              </Typography>
            </Box>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  Typography,
} from "@mui/material";
import React from "react";
import { styles } from "../../../common/css/styles";
import { useNavigate } from "react-router-dom";
import { loanOptions } from "../store/retailLoanSlice";
import { useDispatch } from "react-redux";
import { setLoanRecommender } from "../../../common/api/loans";
import { handleErrorAlt } from "../../../common/constants/utility";
import { LoadingButton } from "@mui/lab";
import { useMutation } from "@tanstack/react-query";

export const ProceedToRecommend = ({ open, handleClose, loan }) => {
  const { type, uid } = loan;

  const loanType =
    type === "BUSINESS" || type === "ASSET_FINANCING" ? "sme" : "retail";

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isLoading, mutate } = useMutation({
    mutationFn: () => setLoanRecommender(uid),
    mutationKey: "setLoanRecommender",
    onError: (err) => handleErrorAlt(err, dispatch),
    onSuccess: (data) =>
      navigate(`/loans/overview/${loanType}/${uid}`, {
        state: { option: loanOptions.RECOMMEND },
      }),
  });

  return (
    <Dialog open={open} onClose={handleClose}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <DialogContent>
          <Box
            sx={{ width: "280px", display: "flex", justifyContent: "center" }}
          ></Box>
          <DialogContentText>
            <Typography sx={{ fontSize: 17, fontWeight: 500 }}>
              Proceeding to view this loan request assigns this request to you.
            </Typography>
            <Typography
              sx={{ fontSize: 14, fontWeight: 500, color: "#969997" }}
            >
              Would you like to proceed?
            </Typography>
          </DialogContentText>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              mt: 2,
            }}
          >
            <Button
              variant="contained"
              onClick={handleClose}
              sx={{ ...styles.bridgeCardTop.error, fontSize: 11, px: 1, mx: 1 }}
            >
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              onClick={mutate}
              loading={isLoading}
              sx={{ ...styles.bridgeCardTop.success, fontSize: 11, px: 1 }}
            >
              Proceed to Recommend
            </LoadingButton>
          </Box>
        </DialogContent>
      </Box>
    </Dialog>
  );
};

import { client } from ".";

const size = process.env.REACT_APP_PAGESIZE;

export const getLoans = (page, filterBy) => {
  return client.get(
    `loans/bridge-finance?page=${page}&size=${size}&filterBy=${filterBy}`
  );
};

export const getLoan = (loanUid) => {
  return client.get(`loans/${loanUid}`);
};

export const searchLoans = (page, search) => {
  return client.get(
    `loans/bridge-finance?search=${search}&page=${page}&size=${size}`
  );
};

export const setLoanRecommender = (loanUid) => {
  return client.patch(`loans/${loanUid}/set-recommender`);
};

export const recommendLoanOffer = ({
  loanUid,
  recommendOffer,
  recommendedAmount,
  recommendedInterestRate,
  recommendedTenure,
  reasonsForRecommendation,
}) => {
  return client.patch(`loans/recommend-offer`, {
    loanUid,
    recommendOffer,
    recommendedAmount,
    recommendedInterestRate,
    recommendedTenure,
    reasonsForRecommendation,
  });
};

export const setLoanOfferer = (loanUid) => {
  return client.patch(`loans/${loanUid}/set-offerer`);
};

export const offerLoan = ({
  loanUid,
  isLoanOffered,
  loanOfferAmount,
  loanOfferInterestRate,
  loanOfferTenure,
  reasonsForLoanRequestDecision,
}) => {
  return client.patch("loans/process-loan-decision/bridge_financing", {
    loanUid,
    isLoanOffered,
    loanOfferAmount,
    loanOfferInterestRate,
    loanOfferTenure,
    reasonsForLoanRequestDecision,
  });
};

export const internationalPassport = ({ image, loanUid }) => {
  return client.patch(
    "loans/international-passport",
    { image, loanUid },
    { headers: { "Content-Type": "multipart/form-data" } }
  );
};

export const lienDocuments = ({
  loanUid,
  adminOverseeingLoan,
  adminPlacingLien,
  files,
  lienBankAccountName,
  lienBankAccountNumber,
  lienBankName,
  lienBankSortCode,
}) => {
  return client.post(
    "loans/upload-lien-documents",
    {
      loanUid,
      adminOverseeingLoan,
      adminPlacingLien,
      files,
      lienBankAccountName,
      lienBankAccountNumber,
      lienBankName,
      lienBankSortCode,
    },
    { headers: { "Content-Type": "multipart/form-data" } }
  );
};

export const disburse = ({ loanUid, accountNumber, amount }) => {
  return client.post(`disburse`, { loanUid, accountNumber, amount });
};

export const uploadProofOfRepayment = ({ loanUid, proofOfRepayment }) => {
  return client.post(
    "loans/proof-of-repayment",
    { loanUid, proofOfRepayment },
    { headers: { "Content-Type": "multipart/form-data" } }
  );
};

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { handleError } from "../../../common/constants/utility";
import { getLoans, searchLoans } from "../../../common/api/retail";

const initialState = {
  loans: [],
  totalRecords: 0,
  loading: false,
  error: [],
};

export const filteredLoans = createAsyncThunk(
  "retail/loans/filter",
  async ({ page, filterOption }, { dispatch, rejectWithValue }) => {
    try {
      const response = await getLoans(page, filterOption);
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);

export const searchRetailLoans = createAsyncThunk(
  "retail/loans/search",
  async ({ page, searchTerm }, { dispatch, rejectWithValue }) => {
    try {
      const response = await searchLoans(page, searchTerm);
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);

const retailLoansSlice = createSlice({
  name: "retail/loans",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(filteredLoans.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(filteredLoans.fulfilled, (state, action) => {
      state.loans = action.payload.data.loans;
      state.totalRecords = action.payload.data.totalRecords;
      state.loading = false;
      state.error = [];
    });
    builder.addCase(filteredLoans.rejected, (state, action) => {
      state.loans = [];
      state.totalRecords = 0;
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(searchRetailLoans.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(searchRetailLoans.fulfilled, (state, action) => {
      state.loans = action.payload.data.loans;
      state.totalRecords = action.payload.data.totalRecords;
      state.loading = false;
      state.error = "";
    });
    builder.addCase(searchRetailLoans.rejected, (state, action) => {
      state.loans = [];
      state.totalRecords = 0;
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default retailLoansSlice.reducer;

import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { toTitleCase } from "../../../../../common/constants/utility";
import { styles } from "../../../../../common/css/styles";

export const Employment = ({ data }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <Stack direction="row" spacing={29}>
        <Box>
          <Box sx={{ py: 1 }}>
            <Typography sx={styles.kyc.headerText}>Company</Typography>
            <Typography sx={styles.kyc.bodyText}>
              {data?.companyName ?? "Not Available"}
            </Typography>
          </Box>
          <Box sx={{ py: 1 }}>
            <Typography sx={styles.kyc.headerText}>Date Joined</Typography>
            <Typography sx={styles.kyc.bodyText}>
              {data?.dateJoined?.replaceAll("-", "/") ?? "Not Available"}
            </Typography>
          </Box>
          <Box sx={{ py: 1 }}>
            <Typography sx={styles.kyc.headerText}>Salary Range</Typography>
            <Typography sx={styles.kyc.bodyText}>
              {data?.salaryRange ?? "Not Available"}
            </Typography>
          </Box>
        </Box>
        <Box>
          <Box sx={{ py: 1 }}>
            <Typography sx={styles.kyc.headerText}>
              Employment Status
            </Typography>
            <Typography sx={styles.kyc.bodyText}>
              {toTitleCase(data?.employmentStatus?.replace("_", " ")) ??
                "Not Available"}
            </Typography>
          </Box>
          <Box sx={{ py: 1 }}>
            <Typography sx={styles.kyc.headerText}>
              Education History
            </Typography>
            <Typography sx={styles.kyc.bodyText}>
              {data?.educationHistory ?? "Not Available"}
            </Typography>
          </Box>
        </Box>
      </Stack>
    </Box>
  );
};

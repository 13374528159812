import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { nigerianStates } from "../../../../common/constants/nigerianStates";
import { useDispatch, useSelector } from "react-redux";
import { styles } from "../../../../common/css/styles";
import { submitKyc } from "../../../kyc/kycSlice";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format, parse, parseISO } from "date-fns";
import { stage } from "../../../kyc/stages";
import { PreviousButton } from "../components/PreviousButton";
import { LoadingButton } from "@mui/lab";
import { ArrowForward } from "@mui/icons-material";

const defaultValues = {
  companyName: "",
  // companyAddress: "",
  // companyCity: "",
  // companyState: "",
  // role: "",
  dateJoined: "",
  salaryRange: "",
  employmentStatus: "",
  educationHistory: "",
};

const schema = yup.object().shape({
  companyName: yup.string().required("Company name is required"),
  // companyAddress: yup.string().required("Company address is required"),
  // companyCity: yup.string().required("City is required"),
  // companyState: yup.string().required("State is required"),
  // role: yup.string().required("Role is required"),
  dateJoined: yup
    .date()
    .transform(function (value, originalValue) {
      if (this.isType(value)) {
        return value;
      }
      const result = parse(parseISO(originalValue), "MM/dd/yyyy", new Date());
      return result;
    })
    .typeError("Please enter a valid date")
    .required("Date joined is required"),
  salaryRange: yup.string().required("Salary range is required"),
  employmentStatus: yup.string().required("Employment Status is required"),
  educationHistory: yup.string().required("Education History is required"),
});

export const AddEmployment = ({
  handleTabChange,
  customerData,
  handleAddCustomerData,
  openInfo,
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors, isValid, isSubmitting },
    setValue,
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  // const selectedState = watch("companyState");
  const customer = useSelector(({ customer }) => customer.customer);
  const dispatch = useDispatch();

  const onSubmit = (data) => {
    customerData.employment = {
      ...data,
      dateJoined: format(data.dateJoined, "dd-MM-yyyy"),
      userUid: customer.uid,
      onboardingStage: stage.EMPLOYMENT,
    };
    handleAddCustomerData(customerData);
    dispatch(submitKyc(customerData.employment));
  };

  const handlePreviousClick = () => {
    handleTabChange(stage.RESIDENCE);
  };

  useEffect(() => {
    if (customerData.employment) {
      setValue("companyName", customerData.employment.companyName);
      // setValue("companyAddress", customerData.employment.companyAddress);
      // setValue("companyState", customerData.employment.companyState);
      // setValue("companyCity", customerData.employment.companyCity);
      // setValue("role", customerData.employment.role);
      setValue("salaryRange", customerData.employment.salaryRange);
      setValue("employmentStatus", customerData.employment.employmentStatus);
      setValue("educationHistory", customerData.employment.educationHistory);
      setValue("dateJoined", new Date(customerData.employment.dateJoined));
    }
  }, []);

  return (
    <>
      <Grid
        container
        rowSpacing={2}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        mt={1}
        component="form"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid item xs={6}>
          <Typography sx={styles.formFieldHeading}>
            Education History
          </Typography>

          <Controller
            name="educationHistory"
            control={control}
            render={({ field }) => (
              <FormControl fullWidth>
                <Select
                  {...field}
                  required
                  size={styles.textfieldSize}
                  sx={styles.selectField}
                >
                  <MenuItem value="primary school certificate">
                    Primary School Certificate
                  </MenuItem>
                  <MenuItem value="secondary school certificate">
                    Secondary School Certificate
                  </MenuItem>
                  <MenuItem value="undergraduate degree holder (e.g bsc/hnd/ba)">
                    Undergraduate Degree Holder (e.g BSc/HND/BA)
                  </MenuItem>
                  <MenuItem value="postgraduate degree holder">
                    Postgraduate Degree Holder
                  </MenuItem>
                  <MenuItem value="none">None</MenuItem>
                </Select>
                {errors.educationHistory ? (
                  <FormHelperText error>
                    {errors?.educationHistory?.message}
                  </FormHelperText>
                ) : (
                  <></>
                )}
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography sx={styles.formFieldHeading}>
            Employment Status
          </Typography>

          <Controller
            name="employmentStatus"
            control={control}
            render={({ field }) => (
              <FormControl fullWidth>
                <Select
                  {...field}
                  required
                  size={styles.textfieldSize}
                  sx={styles.selectField}
                >
                  <MenuItem value="EMPLOYED">Employed</MenuItem>
                  <MenuItem value="UNEMPLOYED">Unemployed</MenuItem>
                  <MenuItem value="GOVERNMENT_WORKER">
                    Government Worker
                  </MenuItem>
                  <MenuItem value="SELF_EMPLOYED">Self Employed</MenuItem>
                  <MenuItem value="RETIRED">Retired</MenuItem>
                  <MenuItem value="STUDENT">Student</MenuItem>
                </Select>
                {errors.employmentStatus ? (
                  <FormHelperText error>
                    {errors?.employmentStatus?.message}
                  </FormHelperText>
                ) : (
                  <></>
                )}
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography sx={styles.formFieldHeading}>Company</Typography>
          <Controller
            name="companyName"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                variant="outlined"
                fullWidth
                size={styles.textfieldSize}
                placeholder="e.g. John"
                error={!!errors.companyName}
                helperText={errors.companyName?.message}
                sx={styles.textField}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography sx={styles.formFieldHeading}>Date Joined</Typography>

          <LocalizationProvider fullWidth dateAdapter={AdapterDateFns}>
            <Controller
              name="dateJoined"
              control={control}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  inputFormat="MM/dd/yyyy"
                  sx={styles.newDatePicker}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      error={!!errors.dateJoined}
                      helperText={errors.dateJoined?.message}
                      fullWidth
                      size={styles.textfieldSize}
                      autoComplete="off"
                      variant="outlined"
                    />
                  )}
                />
              )}
            />
          </LocalizationProvider>
          {errors.dateJoined ? (
            <FormHelperText error>{errors?.dateJoined?.message}</FormHelperText>
          ) : (
            <></>
          )}
        </Grid>
        <Grid item xs={6}>
          <Typography sx={styles.formFieldHeading}>Salary Range</Typography>

          <Controller
            name="salaryRange"
            control={control}
            render={({ field }) => (
              <FormControl fullWidth>
                <Select
                  {...field}
                  required
                  size={styles.textfieldSize}
                  sx={styles.selectField}
                >
                  <MenuItem value="below ₦100k">Below ₦100k</MenuItem>
                  <MenuItem value="₦100k - ₦200k">₦100k - ₦200k</MenuItem>
                  <MenuItem value="₦200k - ₦300k">₦200k - ₦300k</MenuItem>
                  <MenuItem value="₦300k - ₦400k">₦300k - ₦400k</MenuItem>
                  <MenuItem value="₦400k - ₦500k">₦400k - ₦500k</MenuItem>
                  <MenuItem value="₦500k - ₦600k">₦500k - ₦600k</MenuItem>
                  <MenuItem value="₦600k - ₦700k">₦600k - ₦700k</MenuItem>
                  <MenuItem value="₦700k - ₦800k">₦700k - ₦800k</MenuItem>
                  <MenuItem value="₦800k - ₦900k">₦800k - ₦900k</MenuItem>
                  <MenuItem value="₦900k - ₦1m">₦900k - ₦1m</MenuItem>
                  <MenuItem value="above ₦1m">Above ₦1m</MenuItem>
                </Select>
                {errors.salaryRange ? (
                  <FormHelperText error>
                    {errors?.salaryRange?.message}
                  </FormHelperText>
                ) : (
                  <></>
                )}
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={6}></Grid>

        <Grid xs={12}>
          <Stack spacing={1} direction="row" justifyContent="flex-end">
            <PreviousButton handlePreviousClick={handlePreviousClick} />
            <Box>
              <LoadingButton
                variant="contained"
                type="submit"
                endIcon={<ArrowForward />}
                loadingPosition="end"
                disabled={!isValid || isSubmitting}
                loading={isSubmitting}
                sx={{
                  textTransform: "capitalize",
                  height: 40,
                  color: "#fff",
                  boxShadow: "none",
                  fontSize: 16,

                  "&:hover": {
                    boxShadow: "none",
                  },
                }}
              >
                Next
              </LoadingButton>
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Box, Grid, Typography } from "@mui/material";
import doneIcon from "../../../../common/images/done-icon.svg";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { makeOrDeclineOffer } from "../store/businessLoanSlice";

const options = [
  "Lack of credit History",
  "Poor credit history",
  "insufficient income",
  "unstable Employment",
  "Age",
  "Residency requirement",
  "Incomplete application",
  "Defaulted loan",
];

const defaultValues = {
  reasonsForLoanRequestDecision: [],
  otherReasons: "",
};

const schema = yup.object().shape({
  reasonsForLoanRequestDecision: yup
    .array()
    .min(1, "Please select at least one reason"),
  otherReasons: yup.string().when("reasonsForLoanRequestDecision", {
    is: (val) => val.length === 0,
    then: () => yup.string().required("Other reason is required"),
    otherwise: () => yup.string().notRequired(),
  }),
});

export const Decline = ({ open, handleClose }) => {
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitSuccessful },
    reset,
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  const [openSecondDialog, setOpenSecondDialog] = useState(false);
  const loan = useSelector(({ businessLoan }) => businessLoan.loan);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleCloseSecondDialog = () => {
    setOpenSecondDialog(false);
    navigate("/sme/loans");
  };

  const onSubmit = (data) => {
    dispatch(
      makeOrDeclineOffer({
        loanUid: loan.loanUid,
        isLoanOffered: false,
        reasonsForLoanRequestDecision: [
          ...data.reasonsForLoanRequestDecision,
          data.otherReasons,
        ].filter((n) => n),
      })
    );
    handleClose();
    setOpenSecondDialog(true);
  };

  useEffect(() => {
    if (isSubmitSuccessful) reset();
  }, [isSubmitSuccessful]);

  useEffect(() => {
    let timeoutId;
    if (openSecondDialog) {
      timeoutId = setTimeout(() => {
        handleCloseSecondDialog();
      }, 2000);
    }

    return () => clearTimeout(timeoutId);
  }, [openSecondDialog]);

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          handleClose();
          reset();
        }}
      >
        <Grid
          component="form"
          noValidate
          onSubmit={handleSubmit(onSubmit)}
          py={2}
          sx={{ width: 448 }}
        >
          <DialogTitle sx={{ color: "#000" }}>Decline Loan Request</DialogTitle>

          <DialogContent>
            <Grid container spacing={3}>
              <Grid item sx={{ width: "100%" }}>
                <Typography sx={{ fontSize: 14, fontWeight: 400, pb: 1 }}>
                  Please provide your reasons for declining this loan request.
                </Typography>
                <Controller
                  name="reasonsForLoanRequestDecision"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <Autocomplete
                      multiple
                      id="multiselect-chip"
                      options={options}
                      onChange={(_, newValue) => onChange(newValue)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Select Reason"
                          placeholder="Options"
                          size="small"
                          fullWidth
                          error={!!errors.reasonsForLoanRequestDecision}
                          helperText={
                            errors.reasonsForLoanRequestDecision?.message
                          }
                        />
                      )}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            key={index}
                            label={option}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item sx={{ width: "100%" }}>
                <Typography sx={{ fontSize: 14, fontWeight: 400, pb: 1 }}>
                  Other reasons for decline loan request
                </Typography>
                <Controller
                  name="otherReasons"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      size="small"
                      error={!!errors.otherReasons}
                      helperText={errors.otherReasons?.message}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                handleClose();
                reset();
              }}
              sx={{
                textTransform: "capitalize",
                fontSize: 14,
                color: "#616663",
              }}
            >
              Close
            </Button>
            <Button
              variant="contained"
              type="submit"
              sx={{
                boxShadow: "none",
                color: "#D41818",
                textTransform: "capitalize",
                // px: 3,
                fontSize: 12,
                fontWeight: 600,
                borderRadius: 2,
                backgroundColor: "#FFF2F2",
                // ml: 2,
                mr: 2,
                "&:hover": {
                  backgroundColor: "#f6adad",
                  boxShadow: "none",
                },
              }}
            >
              Decline
            </Button>
          </DialogActions>
        </Grid>
      </Dialog>

      {/* Second Dialog */}
      <Dialog open={openSecondDialog} onClose={handleCloseSecondDialog}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <DialogContent sx={{ minWidth: 300 }}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img src={doneIcon} alt="done-icon" />
            </Box>
            <DialogContentText sx={{ textAlign: "center" }}>
              Loan Request Declined
            </DialogContentText>
          </DialogContent>
        </Box>
      </Dialog>
    </>
  );
};

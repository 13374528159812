/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { Upload } from "@mui/icons-material";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { InfoDialog } from "../../../../../common/components/InfoDialog";
import { useMutation } from "@tanstack/react-query";
import { internationalPassport } from "../../../../../common/api/loans";
import { LoadingButton } from "@mui/lab";

const defaultValues = {
  image: "",
};

const schema = yup.object().shape({
  image: yup.mixed().required("Please upload international passport"),
});

export const InternationalPassport = ({ loan, refetch }) => {
  const { handleSubmit, control } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  const [open, setOpen] = useState(false);
  const [isError, setIsError] = useState(false);
  const [infoText, setInfoText] = useState("");
  const [showUpload, setShowUpload] = useState(false);
  const [selectedFile, setSelectedFile] = useState("");
  const [previewURL, setPreviewURL] = useState("");

  const handleClose = () => setOpen(false);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewURL(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setSelectedFile("");
      setPreviewURL("");
    }
  };

  const { isLoading, mutate } = useMutation({
    mutationFn: (data) => internationalPassport(data),
    mutationKey: "uploadPassword",
    onError: (err) => {
      setIsError(true);
      setInfoText("Passport upload failed");
      setOpen(true);
    },
    onSuccess: (data) => {
      if (refetch) refetch();
      setIsError(false);
      setInfoText("Passport upload successful");
      setOpen(true);
    },
  });

  const onSubmit = (data) => {
    mutate({
      ...data,
      loanUid: loan?.loanUid ?? loan?.uid,
    });
  };

  // useEffect(() => {
  //   if (isSubmitSuccessful) {
  //     reset();
  //     setSelectedFile("");
  //     setPreviewURL("");
  //     if (error.length > 0) {
  //       setIsError(true);
  //       setInfoText("Passport upload failed");
  //       setOpen(true);
  //     } else {
  //       setIsError(false);
  //       setInfoText("Passport upload successful");
  //       setOpen(true);
  //     }
  //   }
  // }, [isSubmitSuccessful]);

  return (
    <Box sx={{ p: 4, width: "100%" }}>
      {/* Subsection Selectors */}
      <Box sx={{ py: 2 }}>
        <Button
          variant="outlined"
          onClick={() => setShowUpload(false)}
          sx={{
            height: 30,
            textTransform: "capitalize",
            color: "#000",
            borderColor: "#B5B5B5",
            borderRadius: 0,
            py: 2,
            fontSize: 12,
            // mt: 2,
          }}
        >
          View
        </Button>
        <Button
          variant="outlined"
          onClick={() => setShowUpload(true)}
          sx={{
            height: 30,
            textTransform: "capitalize",
            color: "#B5B5B5",
            borderColor: "#B5B5B5",

            borderRadius: 0,
            backgroundColor: "#F7F8F4",
            py: 2,
            fontSize: 12,
            // mt: 2,
          }}
        >
          Reupload
        </Button>
      </Box>

      <Box sx={{ display: "flex", flexDirection: "row" }}>
        {!showUpload && (
          <>
            <Box sx={{ py: 2, width: "50%" }}>
              <Typography sx={{ fontWeight: 700, fontSize: 18, py: 2 }}>
                International Passport
              </Typography>

              <Box
                sx={{
                  border: 1,
                  borderRadius: 2,
                  minHeight: 200,
                  maxWidth: "85%",
                  px: 2,
                  borderColor: "#979797",
                }}
              >
                {
                  /* {data?.idMode === "NIGERIAN_PASSPORT" ? (
                  <>
                    <Typography sx={{ fontWeight: 400, fontSize: 16, py: 1 }}>
                      Passport no.
                    </Typography>
                    <Typography sx={{ fontWeight: 700, fontSize: 16, py: 1 }}>
                      {data?.idNumber}
                    </Typography>
                    <Box sx={{ width: "90%", py: 1 }}>
                      <img src={data?.idPhotoUrl} alt="passport" width="100%" />
                    </Box>
                  </>
                ) : (
                  <Typography sx={{ py: 1 }}>Not available</Typography>
                )} */
                  loan?.internationalPassportUrl ? (
                    <Box sx={{ width: "90%", py: 1 }}>
                      <img
                        src={loan?.internationalPassportUrl}
                        alt="passport"
                        width="100%"
                      />
                    </Box>
                  ) : (
                    <Typography sx={{ py: 1 }}>Not available</Typography>
                  )
                }
              </Box>
            </Box>
          </>
        )}
        {showUpload && (
          <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="image"
              control={control}
              render={({ field }) => (
                <label htmlFor="file-input">
                  <input
                    accept="image/*"
                    style={{ display: "none" }}
                    id="file-input"
                    type="file"
                    onChange={(e) => {
                      handleFileChange(e);
                      field.onChange(e.target.files);
                    }}
                  />
                  <Button
                    variant="contained"
                    component="span"
                    sx={{
                      backgroundColor: "#E3E3E7",
                      textTransform: "capitalize",
                      width: 400,
                      boxShadow: "none",
                      "&:hover": {
                        boxShadow: "none",
                        color: "#fff",
                      },
                    }}
                  >
                    {selectedFile ? (
                      "Change File"
                    ) : (
                      <>
                        <Upload sx={{ fontSize: 12, pr: 1 }} />
                        Upload Photo
                      </>
                    )}
                  </Button>
                </label>
              )}
            />
            <Box mt={2}>
              {selectedFile && (
                <Stack>
                  <Typography variant="subtitle1">File Preview:</Typography>
                  <img src={previewURL} alt="Preview" width="400" />
                  <LoadingButton
                    variant="contained"
                    color="primary"
                    type="submit"
                    loading={isLoading}
                    sx={{
                      textTransform: "capitalize",
                      width: 400,
                      mt: 2,
                      boxShadow: "none",
                      "&:hover": {
                        boxShadow: "none",
                        color: "#fff",
                      },
                    }}
                  >
                    Upload
                  </LoadingButton>
                </Stack>
              )}
            </Box>
          </Box>
        )}
      </Box>
      <InfoDialog
        text={infoText}
        error={isError}
        open={open}
        handleClose={handleClose}
      />
    </Box>
  );
};

import { Box, Button, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Upload } from "@mui/icons-material";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { submitKyc } from "../../../kyc/kycSlice";
import { stage } from "../../../kyc/stages";
import { InfoDialog } from "../../../../common/components/InfoDialog";

const defaultValues = {
  utilityBill: "",
};

const schema = yup.object().shape({
  utilityBill: yup.mixed().required("Please upload your ID"),
});

export const AddressReupload = ({ customer, kycData }) => {
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitSuccessful },
    reset,
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  const [open, setOpen] = useState(false);
  const [isError, setIsError] = useState(false);
  const [infoText, setInfoText] = useState("");
  const [selectedFile, setSelectedFile] = useState("");
  const [previewURL, setPreviewURL] = useState("");
  const dispatch = useDispatch();
  const kyc = useSelector((state) => state.kyc);

  const handleClose = () => setOpen(false);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewURL(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setSelectedFile("");
      setPreviewURL("");
    }
  };

  const onSubmit = (data) => {
    dispatch(
      submitKyc({
        residentialAddress: kycData.residentialAddress,
        city: kycData.city,
        state: kycData.state,
        ...data,
        userUid: customer.uid,
        onboardingStage: stage.RESIDENCE,
      })
    );
    setOpen(true);
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
      setSelectedFile("");
      setPreviewURL("");
      if (kyc.error.length > 0) {
        setIsError(true);
        setInfoText("Address upload failed");
        setOpen(true);
      } else {
        setIsError(false);
        setInfoText("Address upload successful");
        setOpen(true);
      }
    }
  }, [isSubmitSuccessful]);

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        {/* User Input */}
        <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="utilityBill"
            control={control}
            render={({ field }) => (
              <label htmlFor="file-input">
                <input
                  accept="image/*"
                  style={{ display: "none" }}
                  id="file-input"
                  type="file"
                  onChange={(e) => {
                    handleFileChange(e);
                    field.onChange(e.target.files);
                  }}
                />
                <Button
                  variant="contained"
                  component="span"
                  sx={{
                    backgroundColor: "#E3E3E7",
                    textTransform: "capitalize",
                    width: 400,
                    boxShadow: "none",
                    "&:hover": {
                      boxShadow: "none",
                      color: "#fff",
                    },
                  }}
                >
                  {selectedFile ? (
                    "Change File"
                  ) : (
                    <>
                      <Upload sx={{ fontSize: 12, pr: 1 }} />
                      Upload Proof of Address
                    </>
                  )}
                </Button>
              </label>
            )}
          />
          <Box mt={2}>
            {selectedFile && (
              <Stack>
                <Typography variant="subtitle1">File Preview:</Typography>
                <img src={previewURL} alt="Preview" width="400" />
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  sx={{
                    textTransform: "capitalize",
                    width: 400,
                    mt: 2,
                    boxShadow: "none",
                    color: "#fff",
                    "&:hover": {
                      boxShadow: "none",
                      color: "#fff",
                    },
                  }}
                >
                  Upload
                </Button>
              </Stack>
            )}
          </Box>
        </Box>
      </Box>
      <InfoDialog
        text={infoText}
        error={isError}
        open={open}
        handleClose={handleClose}
      />
    </>
  );
};

import { Box, Button, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CustomerTable } from "./CustomerTable";
import { SearchBar } from "../../common/components/SearchBar";
import { styles } from "../../common/css/styles";
import { WhiteBox } from "../../common/components/WhiteBox";
import { SectionHeader } from "../../common/components/SectionHeader";
import { useDispatch, useSelector } from "react-redux";
import { filteredCustomers, searchCustomers } from "./store/customersSlice";
import { customerFilters } from "./constants/customerFilters";
import { Add } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

export const Customers = ({ customer }) => {
  const [activeFilter, setActiveFilter] = useState(customerFilters.ALL);
  const [page, setPage] = useState(0);

  const customers = useSelector((state) => state.customers);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const openDialog = () => {
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  const handleSearch = (searchTerm) => {
    dispatch(searchCustomers({ page: 1, searchTerm }));
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage - 1);
    dispatch(filteredCustomers({ page: newPage, filterOption: activeFilter }));
  };

  const handleFilter = (filter) => {
    setActiveFilter(filter);
  };

  const handleClick = () => {
    navigate("/customers/add-new");
  };

  const handleView = (uid) => navigate(`/customers/accounts/kyc/${uid}`);

  const filters = [
    { label: "All", value: customerFilters.ALL, visible: true },
    { label: "Prospects", value: customerFilters.PROSPECT, visible: true },
    {
      label: "Pending Verification",
      value: customerFilters.PENDING,
      visible: true,
    },
    { label: "Verified", value: customerFilters.ACTIVE, visible: true },
    { label: "Denied", value: customerFilters.DENIED, visible: true },
    { label: "Suspended", value: customerFilters.SUSPENDED, visible: true },
  ];

  useEffect(() => {
    dispatch(filteredCustomers({ page: 1, filterOption: activeFilter }));
  }, [activeFilter]);

  return (
    <>
      <SectionHeader headerText="Customer Accounts" />
      <WhiteBox>
        <Box
          sx={{
            p: 2,
          }}
        >
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <SearchBar onSearch={handleSearch} />
            <Button
              variant="outlined"
              // onClick={openDialog}
              onClick={handleClick}
              sx={{
                height: 30,
                textTransform: "capitalize",
                color: "#000",
                borderColor: "#000",
                mt: 2.5,
              }}
            >
              <Add sx={{ fontSize: 12 }} />
              <Typography sx={{ fontSize: 12, pl: 1, pr: 0.5 }}>
                Add Customer
              </Typography>
            </Button>
          </Stack>
          <Box sx={{ py: 2 }}>
            {filters
              .filter((filter) => filter.visible)
              .map((filter) => (
                <Button
                  onClick={() => handleFilter(filter.value)}
                  variant="outlined"
                  sx={
                    activeFilter === filter.value
                      ? styles.tableFilter.active
                      : styles.tableFilter.inactive
                  }
                >
                  {filter.label}
                </Button>
              ))}
          </Box>
          <CustomerTable
            customers={customers}
            filterOption={activeFilter}
            page={page}
            handlePageChange={handlePageChange}
            handleView={handleView}
          />
        </Box>
      </WhiteBox>
    </>
  );
};

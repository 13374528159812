import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { nigerianStates } from "../../../../common/constants/nigerianStates";
import { useDispatch, useSelector } from "react-redux";
import { styles } from "../../../../common/css/styles";
import { submitKyc } from "../../../kyc/kycSlice";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format, parse } from "date-fns";
import { stage } from "../../../kyc/stages";
import { phoneRegex } from "../../../../common/constants/regex";
import { NextButton } from "../components/NextButton";
import { customerStage } from "../../constants/customerStage";
import { PreviousButton } from "../components/PreviousButton";
import { ArrowForward } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { DevTool } from "@hookform/devtools";

const defaultValues = {
  kinFirstName: "",
  kinLastName: "",
  kinEmail: "",
  kinDateOfBirth: "",
  kinPhoneNumber: "",
  kinRelationship: "",
  kinResidentialAddress: "",
  kinCity: "",
  kinState: "",
};

const schema = yup.object().shape({
  kinFirstName: yup.string().required("First name is required"),
  kinLastName: yup.string().required("Last name is required"),
  kinEmail: yup
    .string()
    .email("Please enter a valid email address")
    .required("Email address is required"),
  kinDateOfBirth: yup
    .date()
    .transform(function (value, originalValue) {
      if (this.isType(value)) {
        return value;
      }
      const result = parse(originalValue, "MM/dd/yyyy", new Date());
      return result;
    })
    .typeError("Please enter a valid date")
    .required("Date of birth is required"),
  kinPhoneNumber: yup
    .string()
    .matches(phoneRegex, "Enter a valid phone number")
    .required("Phone number is required"),
  kinRelationship: yup.string().required("Relationship is required"),
  kinResidentialAddress: yup
    .string()
    .required("Residential address is required"),
  kinCity: yup.string().required("LGA is required"),
  kinState: yup.string().required("State is required"),
});
export const AddNextOfKin = ({
  handleTabChange,
  customerData,
  handleAddCustomerData,
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors, isValid, isSubmitting },
    watch,
    setValue,
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  const customer = useSelector(({ customer }) => customer.customer);
  const dispatch = useDispatch();
  const selectedState = watch("kinState");

  const onSubmit = (data) => {
    customerData.nextOfKin = {
      ...data,
      kinDateOfBirth: format(data.kinDateOfBirth, "dd-MM-yyyy"),
      userUid: customer.uid,
      onboardingStage: stage.NEXT_OF_KIN,
    };
    handleAddCustomerData(customerData);
    dispatch(submitKyc(customerData.nextOfKin));
  };

  const handlePreviousClick = () => {
    handleTabChange(stage.BANK);
  };

  useEffect(() => {
    if (customerData.nextOfKin) {
      setValue("kinFirstName", customerData.nextOfKin.kinFirstName);
      setValue("kinLastName", customerData.nextOfKin.kinLastName);
      setValue("kinEmail", customerData.nextOfKin.kinEmail);
      setValue("kinPhoneNumber", customerData.nextOfKin.kinPhoneNumber);
      setValue("kinState", customerData.nextOfKin.kinState);
      setValue("kinCity", customerData.nextOfKin.kinCity);
      setValue(
        "kinDateOfBirth",
        new Date(customerData.nextOfKin.kinDateOfBirth)
      );
      setValue("kinRelationship", customerData.nextOfKin.kinRelationship);
      setValue(
        "kinResidentialAddress",
        customerData.nextOfKin.kinResidentialAddress
      );
    }
  }, []);

  return (
    <>
      <Grid
        container
        rowSpacing={2}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        mt={1}
        component="form"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid item xs={6}>
          <Typography sx={styles.formFieldHeading}>First Name</Typography>
          <Controller
            name="kinFirstName"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                required
                size={styles.textfieldSize}
                variant="outlined"
                fullWidth
                placeholder="e.g. John"
                error={!!errors.kinFirstName}
                helperText={errors.kinFirstName?.message}
                sx={styles.textField}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography sx={styles.formFieldHeading}>Last Name</Typography>
          <Controller
            name="kinLastName"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                required
                size={styles.textfieldSize}
                variant="outlined"
                fullWidth
                placeholder="e.g. Doe"
                error={!!errors.kinLastName}
                helperText={errors.kinLastName?.message}
                sx={styles.textField}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography sx={styles.formFieldHeading}>Address</Typography>
          <Controller
            name="kinResidentialAddress"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                required
                variant="outlined"
                fullWidth
                size={styles.textfieldSize}
                placeholder="e.g. 123, Four Str."
                error={!!errors.kinResidentialAddress}
                helperText={errors.kinResidentialAddress?.message}
                sx={styles.textField}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography sx={styles.formFieldHeading}>Phone Number</Typography>
          <Controller
            name="kinPhoneNumber"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                required
                variant="outlined"
                fullWidth
                size={styles.textfieldSize}
                placeholder="e.g. 12345678901"
                error={!!errors.kinPhoneNumber}
                helperText={errors.kinPhoneNumber?.message}
                sx={styles.textField}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography sx={styles.formFieldHeading}>Email Address</Typography>
          <Controller
            name="kinEmail"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                required
                variant="outlined"
                fullWidth
                size={styles.textfieldSize}
                placeholder="e.g. johndoe@gmail.com"
                error={!!errors.kinEmail}
                helperText={errors.kinEmail?.message}
                sx={styles.textField}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography sx={styles.formFieldHeading}>DOB</Typography>

          <LocalizationProvider fullWidth dateAdapter={AdapterDateFns}>
            <Controller
              name="kinDateOfBirth"
              control={control}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  inputFormat="MM/dd/yyyy"
                  sx={styles.newDatePicker}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      error={!!errors.kinDateOfBirth}
                      helperText={errors.kinDateOfBirth?.message}
                      fullWidth
                      size={styles.textfieldSize}
                      autoComplete="off"
                      variant="outlined"
                    />
                  )}
                />
              )}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item xs={6}>
          <Typography sx={styles.formFieldHeading}>Relationship</Typography>
          <Controller
            name="kinRelationship"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                required
                variant="outlined"
                fullWidth
                size={styles.textfieldSize}
                placeholder="e.g. Brother"
                error={!!errors.kinRelationship}
                helperText={errors.kinRelationship?.message}
                sx={styles.textField}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography sx={styles.formFieldHeading}>State</Typography>
          <Controller
            name="kinState"
            control={control}
            render={({ field }) => (
              <FormControl fullWidth>
                <Select
                  {...field}
                  size={styles.textfieldSize}
                  error={!!errors.kinState}
                  sx={styles.selectField}
                >
                  {nigerianStates.map((state) => (
                    <MenuItem key={state.code} value={state.name.toLowerCase()}>
                      {state.name}
                    </MenuItem>
                  ))}
                </Select>
                {errors.kinState ? (
                  <FormHelperText error>
                    {errors?.kinState?.message}
                  </FormHelperText>
                ) : (
                  <></>
                )}
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography sx={styles.formFieldHeading}>LGA</Typography>
          <Controller
            name="kinCity"
            control={control}
            render={({ field }) => (
              <FormControl fullWidth>
                <Select
                  {...field}
                  size={styles.textfieldSize}
                  error={!!errors.kinCity}
                  sx={styles.selectField}
                >
                  {!!selectedState &&
                    nigerianStates
                      .filter(
                        (state) => state.name.toLowerCase() === selectedState
                      )[0]
                      .lgas.map((lga, index) => (
                        <MenuItem key={index} value={lga.toLowerCase()}>
                          {lga}
                        </MenuItem>
                      ))}
                </Select>
                {errors.kinCity ? (
                  <FormHelperText error>
                    {errors?.kinCity?.message}
                  </FormHelperText>
                ) : (
                  <></>
                )}
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={1} direction="row" justifyContent="flex-end">
            <PreviousButton handlePreviousClick={handlePreviousClick} />
            <Box>
              <LoadingButton
                variant="contained"
                type="submit"
                endIcon={<ArrowForward />}
                loadingPosition="end"
                disabled={!isValid || isSubmitting}
                loading={isSubmitting}
                sx={{
                  textTransform: "capitalize",
                  height: 40,
                  color: "#fff",
                  boxShadow: "none",
                  fontSize: 16,

                  "&:hover": {
                    boxShadow: "none",
                  },
                }}
              >
                Next
              </LoadingButton>
            </Box>
          </Stack>
        </Grid>
      </Grid>
      <DevTool control={control} />
    </>
  );
};

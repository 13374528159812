import React, { useEffect, useState } from "react";
import { Box, Stack, FormControl, Select, MenuItem } from "@mui/material";
import { SectionHeader } from "../../../common/components/SectionHeader";
import { WhiteBox } from "../../../common/components/WhiteBox";
import { SearchBar } from "../../../common/components/SearchBar";
import { styles } from "../../../common/css/styles";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  filteredSmeLoans,
  searchBusinessLoans,
} from "./store/businessLoansSlice";
import { businessLoanFilters } from "./constants/businessLoanFilters";
import { BusinessLoanTable } from "./BusinessLoanTable";
import { loanType } from "../constants/loanType";

export const BusinessLoans = ({ type }) => {
  const businessLoans = useSelector((state) => state.businessLoans);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);

  const [activeFilter, setActiveFilter] = useState(businessLoanFilters.ALL);

  const handleSearch = (searchTerm) => {
    dispatch(searchBusinessLoans(1, searchTerm));
  };

  const handleFilter = (filter) => {
    setActiveFilter(filter);
  };

  const filters = [
    { label: "All", value: businessLoanFilters.ALL, visible: true },
    {
      label: "Pending Recommendation",
      value: businessLoanFilters.PENDING_RECOMMENDATION,
      visible: true,
    },
    {
      label: "Pending Offer",
      value: businessLoanFilters.PENDING_OFFER,
      visible: true,
    },
    {
      label: "Offered",
      value: businessLoanFilters.OFFERED,
      visible: true,
    },
    {
      label: "Offer Rejected",
      value: businessLoanFilters.OFFER_REJECTED,
      visible: true,
    },
    {
      label: "Approved",
      value: businessLoanFilters.APPROVED,
      visible: true,
    },
    {
      label: "Loan Rejected",
      value: businessLoanFilters.LOAN_REJECTED,
      visible: true,
    },
    {
      label: "Offer Accepted",
      value: businessLoanFilters.OFFER_ACCEPTED,
      visible: true,
    },
    {
      label: "Pending Authorization",
      value: businessLoanFilters.PENDING_AUTHORIZATION,
      visible: true,
    },
    {
      label: "Pending Disbursement",
      value: businessLoanFilters.PENDING_DISBURSEMENT,
      visible: true,
    },
    {
      label: "Pending Review",
      value: businessLoanFilters.PENDING_REVIEW,
      visible: true,
    },
    {
      label: "Under Review",
      value: businessLoanFilters.UNDER_REVIEW,
      visible: true,
    },
    {
      label: "Request Declined",
      value: businessLoanFilters.REQUEST_DECLINED,
      visible: true,
    },
    { label: "Active", value: businessLoanFilters.ACTIVE, visible: true },
    {
      label: "Defaulting",
      value: businessLoanFilters.DEFAULTING,
      visible: true,
    },
    {
      label: "Completed",
      value: businessLoanFilters.COMPLETED,
      visible: true,
    },
    { label: "NTU", value: businessLoanFilters.NTU, visible: true },
  ];

  useEffect(() => {
    dispatch(
      filteredSmeLoans({
        page: 1,
        loanType: type,
        filterOption: activeFilter,
      })
    );
  }, [activeFilter, type]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage - 1);
    dispatch(
      filteredSmeLoans({
        page: newPage,
        loanType: type,
        filterOption: activeFilter,
      })
    );
  };

  const handleView = (uid) => {
    if (type === loanType.BUSINESS) navigate(`/sme/loans/overview/${uid}`);
    else navigate(`/sme/asset-financing/overview/${uid}`);
  };

  return (
    <>
      <SectionHeader
        headerText={
          type === loanType.BUSINESS ? "Business Loans" : "Asset Financing"
        }
      />
      <WhiteBox>
        <Box
          sx={{
            p: 2,
          }}
        >
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <SearchBar onSearch={handleSearch} />
            <FormControl sx={{ minWidth: 120, my: 2 }}>
              <Select
                value={activeFilter}
                size="small"
                // onChange={handleLoanMetricsDateFilterChange}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                sx={{ ...styles.dropdown, py: 2, height: 40, fontWeight: 900 }}
              >
                {filters
                  .filter((filter) => filter.visible === true)
                  .map((filter) => (
                    <MenuItem
                      key={filter.value}
                      sx={{ fontSize: 12, fontWeight: 700 }}
                      onClick={() => handleFilter(filter.value)}
                      variant="outlined"
                      value={filter.value}
                    >
                      {filter.label}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Stack>
          <BusinessLoanTable
            businessLoans={businessLoans}
            filterOption={activeFilter}
            page={page}
            handlePageChange={handlePageChange}
            handleView={handleView}
          />
        </Box>
      </WhiteBox>
    </>
  );
};

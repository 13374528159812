import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { styles } from "../../../common/css/styles";
import { useDispatch, useSelector } from "react-redux";
import { DisburseSuccess } from "./DisburseSuccess";
import {
  clearLoanFeedback,
  disburseLoan,
} from "../store/bridgeFinanceLoanSlice";
import { useEffect } from "react";
import { feedback } from "../../../common/constants/feedback";
import { DisburseFailed } from "./DisburseFailed";

export const DisburseConfirmation = ({ open, handleClose }) => {
  const bridgeLoan = useSelector((state) => state.bridgeLoan);
  const customer = useSelector(({ customer }) => customer?.customer);
  const wallet = customer?.wallet;
  const loan = bridgeLoan?.loan;
  const dispatch = useDispatch();

  const [openDisburseSuccess, setOpenDisburseSuccess] = useState(false);
  const [openDisburseFailed, setOpenDisburseFailed] = useState(false);

  const handleCloseDisbursedSuccess = () => {
    dispatch(clearLoanFeedback());
    setOpenDisburseSuccess(false);
  };

  const handleCloseDisbursedFailed = () => {
    dispatch(clearLoanFeedback());
    setOpenDisburseFailed(false);
  };

  const handleDisbursement = () => {
    handleClose();
    dispatch(
      disburseLoan({
        loanUid: loan.uid,
        accountNumber: wallet.accountNumber,
        amount: loan.loanOfferAmount,
      })
    );
  };

  useEffect(() => {
    if (bridgeLoan.disbursed === feedback.SUCCESSFUL)
      setOpenDisburseSuccess(true);
    if (bridgeLoan.disbursed === feedback.FAILED) setOpenDisburseFailed(true);
  }, [bridgeLoan.disbursed]);

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            minWidth: "500px",
          }}
        >
          <DialogContent>
            <Box
              sx={{ width: "280px", display: "flex", justifyContent: "center" }}
            ></Box>
            <DialogContentText>
              <Typography sx={{ fontSize: 17, fontWeight: 500 }}>
                Disburse Loan
              </Typography>
              <Typography
                sx={{ fontSize: 14, fontWeight: 500, color: "#969997" }}
              >
                Are you sure you want to disburse this loan?
              </Typography>
            </DialogContentText>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                mt: 2,
              }}
            >
              <Button
                variant="contained"
                onClick={handleClose}
                sx={{
                  ...styles.bridgeCardTop.error,
                  fontSize: 11,
                  px: 1,
                  mx: 1,
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                sx={{ ...styles.bridgeCardTop.success, fontSize: 11, px: 1 }}
                onClick={handleDisbursement}
              >
                Accept
              </Button>
            </Box>
          </DialogContent>
        </Box>
      </Dialog>

      <DisburseSuccess
        open={openDisburseSuccess}
        handleClose={handleCloseDisbursedSuccess}
      />
      <DisburseFailed
        open={openDisburseFailed}
        handleClose={handleCloseDisbursedFailed}
      />
    </>
  );
};

import { Avatar, Box, Button, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import avatarDummy from "../../../common/images/avatar-image.jpg";
import avatarGuy from "../../../common/images/avatar-guy.jpg";
import checkIcon from "../../../common/images/check-icon.svg";
import closeIcon from "../../../common/images/close-1con.svg";
import { useSelector } from "react-redux";
import { Decline } from "./Decline";
import { MakeOffer } from "./MakeOffer";
import { bridgeFinanceFilters } from "../constants/bridgeFinanceFilters";
import { styles } from "../../../common/css/styles";
import { RecommendForDecline } from "./RecommendForDecline";
import { RecommendForOffer } from "./RecommendForOffer";
import { roles } from "../../../common/constants/roles";
import { DisburseConfirmation } from "./DisburseConfirmation";
import { CompleteLoan } from "./CompleteLoan";

export const CardTop = ({ loan }) => {
  const [openRecommendForOffer, setOpenRecommendForOffer] = useState(false);
  const [openRecommendForDecline, setOpenRecommendForDecline] = useState(false);
  const [openDecline, setOpenDecline] = useState(false);
  const [openMakeOffer, setOpenMakeOffer] = useState(false);
  const [openDisburseLoan, setOpenDisburseLoan] = useState(false);
  const [openCompleteLoan, setOpenCompleteLoan] = useState(false);
  const user = useSelector(({ auth }) => auth.user);
  const customer = loan?.user;
  const kycData = loan && JSON.parse(loan.customerKycObject);
  const role = user?.roles[0]?.name;

  const setStatus = () => {
    switch (loan?.status) {
      case bridgeFinanceFilters.PENDING_RECOMMENDATION:
        return (
          <Typography sx={styles.cardTopStatus.pendingRecommendation}>
            Pending Recommendation
          </Typography>
        );
      case bridgeFinanceFilters.PENDING_OFFER:
        return (
          <Typography sx={styles.cardTopStatus.pending}>
            Pending Offer
          </Typography>
        );

      case bridgeFinanceFilters.OFFERED:
        return (
          <Typography sx={{ ...styles.cardTopStatus.prospect, width: "60px" }}>
            Offered
          </Typography>
        );
      case bridgeFinanceFilters.PENDING_LIEN:
        return (
          <Typography sx={styles.cardTopStatus.pending}>
            Pending Lien
          </Typography>
        );
      case bridgeFinanceFilters.DECLINED:
        return (
          <Typography sx={styles.cardTopStatus.denied}>
            Request Declined
          </Typography>
        );
      case bridgeFinanceFilters.OFFER_REJECTED:
        return (
          <Typography sx={styles.cardTopStatus.pending}>
            Offer Rejected
          </Typography>
        );
      case bridgeFinanceFilters.PENDING_DISBURSEMENT:
        return (
          <Typography sx={styles.cardTopStatus.pendingDisbursement}>
            Pending Disbursement
          </Typography>
        );
      case bridgeFinanceFilters.NTU:
        return <Typography sx={styles.cardTopStatus.ntu}>NTU</Typography>;
      case bridgeFinanceFilters.COMPLETED:
        return (
          <Typography sx={styles.cardTopStatus.active}>Completed</Typography>
        );
      case bridgeFinanceFilters.ACTIVE:
        return <Typography sx={styles.cardTopStatus.active}>Active</Typography>;
      case bridgeFinanceFilters.PAY_INTEREST:
        return (
          <Typography sx={styles.cardTopStatus.payInterest}>
            Pay Interest
          </Typography>
        );
      default:
        return (
          <Typography
            sx={styles.cardTopStatus.pendingRecommendation}
          ></Typography>
        );
    }
  };

  const shouldSeeRecommendButtons = () => {
    if (
      loan?.status === bridgeFinanceFilters.PENDING_RECOMMENDATION &&
      user?.uid === loan?.recommender?.uid
    )
      return true;
    else return false;
  };

  const shouldSeeOfferButtons = () => {
    if (
      role === roles.MANAGER &&
      loan?.status === bridgeFinanceFilters.PENDING_OFFER &&
      user?.uid === loan?.offerer?.uid
    )
      return true;
    else return false;
  };

  const showDisbursementButton = () => {
    return (
      loan?.status === bridgeFinanceFilters.PENDING_DISBURSEMENT &&
      user?.uid === loan?.offerer?.uid
    );
  };
  const showCompletedButton = () => {
    return (
      (loan?.status === bridgeFinanceFilters.ACTIVE ||
        loan?.status === bridgeFinanceFilters.DEFAULTING) &&
      user?.uid === loan?.offerer?.uid
    );
  };

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box sx={{ display: "flex" }}>
          {kycData?.photoUrl ? (
            <Avatar src={kycData?.photoUrl} sx={{ width: 60, height: 60 }} />
          ) : customer?.gender === "FEMALE" ? (
            <Avatar src={avatarDummy} sx={{ width: 60, height: 60 }} />
          ) : (
            <Avatar src={avatarGuy} sx={{ width: 60, height: 60 }} />
          )}

          <Stack sx={{ ml: 2 }}>
            <Typography sx={{ fontWeight: 700, fontSize: 24 }}>
              {`${customer?.firstName
                .charAt(0)
                .toUpperCase()}${customer?.firstName?.substring(
                1
              )} ${customer?.lastName
                .charAt(0)
                .toUpperCase()}${customer?.lastName?.substring(1)}`}
            </Typography>
            <Typography
              sx={{ fontSize: 14, fontWeight: 500, color: "#616663" }}
            >
              {customer?.email}
            </Typography>
          </Stack>
        </Box>
        {shouldSeeRecommendButtons() ? (
          <Box>
            <Button
              variant="contained"
              onClick={() => setOpenRecommendForOffer(true)}
              sx={styles.bridgeCardTop.success}
            >
              <Box sx={{ mt: 1, pr: 1 }}>
                <img src={checkIcon} alt="check-icon" />
              </Box>
              Recommend for Offer
            </Button>
            <Button
              variant="contained"
              onClick={() => setOpenRecommendForDecline(true)}
              sx={styles.bridgeCardTop.error}
            >
              <Box sx={{ pr: 2, fontSize: 13.3, mt: 1 }}>
                <img src={closeIcon} alt="close=icon" />
              </Box>
              Recommend for Decline
            </Button>
          </Box>
        ) : (
          <></>
        )}
        {shouldSeeOfferButtons() ? (
          <Box>
            <Button
              variant="contained"
              onClick={() => setOpenMakeOffer(true)}
              sx={styles.bridgeCardTop.success}
            >
              <Box sx={{ mt: 1, pr: 1 }}>
                <img src={checkIcon} alt="check-icon" />
              </Box>
              Make Offer
            </Button>
            <Button
              variant="contained"
              onClick={() => setOpenDecline(true)}
              sx={styles.bridgeCardTop.error}
            >
              <Box sx={{ pr: 2, fontSize: 13.3, mt: 1 }}>
                <img src={closeIcon} alt="close-icon" />
              </Box>
              Decline
            </Button>
          </Box>
        ) : (
          <></>
        )}
        {showDisbursementButton() ? (
          <Button
            variant="contained"
            onClick={() => setOpenDisburseLoan(true)}
            sx={{ ...styles.bridgeCardTop.success, height: "40px" }}
          >
            Disburse Loan
          </Button>
        ) : (
          <></>
        )}
        {showCompletedButton() ? (
          <Button
            variant="contained"
            onClick={() => setOpenCompleteLoan(true)}
            sx={{ ...styles.bridgeCardTop.success, height: "40px" }}
          >
            Mark as Completed
          </Button>
        ) : (
          <></>
        )}
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", mt: 4 }}>
        <Stack>
          <Typography sx={{ fontWeight: 700, fontSize: 24, color: "#3C3D39" }}>
            Loan Request
          </Typography>
          {setStatus()}
        </Stack>
        <Stack>
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: 24,
              color: "#3C3D39",
              textAlign: "Right",
            }}
          >
            {loan?.loanAmount?.toLocaleString("en-NG", {
              style: "currency",
              currency: "NGN",
            })}
          </Typography>
          <Typography
            sx={{
              fontSize: 11,
              fontWeight: 600,
              color: "#62635E",
              textAlign: "Right",
              mr: 0.3,
            }}
          >
            <span style={{ color: "#27AE60" }}> Duration:</span>{" "}
            {loan?.loanTenure} {loan?.loanTenure === 1 ? "month" : "months"}
          </Typography>
        </Stack>
      </Box>
      <RecommendForDecline
        open={openRecommendForDecline}
        handleClose={() => setOpenRecommendForDecline(false)}
      />
      <RecommendForOffer
        open={openRecommendForOffer}
        handleClose={() => setOpenRecommendForOffer(false)}
      />
      <Decline open={openDecline} handleClose={() => setOpenDecline(false)} />
      <MakeOffer
        open={openMakeOffer}
        handleClose={() => setOpenMakeOffer(false)}
      />
      <DisburseConfirmation
        open={openDisburseLoan}
        handleClose={() => setOpenDisburseLoan(false)}
      />
      <CompleteLoan
        openModal={openCompleteLoan}
        handleCloseModal={() => setOpenCompleteLoan(false)}
      />
    </>
  );
};

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  denyChange,
  getAddCustomers,
  getChangeCustomers,
  getChangeInfo,
  searchAddCustomers,
  searchChangeCustomers,
} from "../../common/api/devices";
import { handleError } from "../../common/constants/utility";
import { feedback } from "../../common/constants/feedback";

const initialState = {
  customers: [],
  totalRecords: 0,
  loading: false,
  loadingInfo: false,
  info: null,
  feedback: "",
  error: [],
};

export const fetchCustomersForDeviceAdd = createAsyncThunk(
  "devices/add-list",
  async (page, { dispatch, rejectWithValue }) => {
    try {
      const response = await getAddCustomers(page);
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);

export const searchCustomersForDeviceAdd = createAsyncThunk(
  "devices/add-search",
  async ({ page, searchTerm }, { dispatch, rejectWithValue }) => {
    try {
      const response = await searchAddCustomers(page, searchTerm);
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);

export const fetchCustomersForDeviceChange = createAsyncThunk(
  "devices/change-list",
  async (page, { dispatch, rejectWithValue }) => {
    try {
      const response = await getChangeCustomers(page);
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);

export const searchCustomersForDeviceChange = createAsyncThunk(
  "devices/change-search",
  async ({ page, searchTerm }, { dispatch, rejectWithValue }) => {
    try {
      const response = await searchChangeCustomers(page, searchTerm);
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);

export const fetchDeviceChangeInfo = createAsyncThunk(
  "devices/change-info",
  async (userUid, { dispatch, rejectWithValue }) => {
    try {
      const response = await getChangeInfo(userUid);
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);

export const denyDeviceChangeRequest = createAsyncThunk(
  "devices/deny-change",
  async (model, { dispatch, rejectWithValue }) => {
    try {
      const response = await denyChange(model);
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);

const deviceSlice = createSlice({
  name: "devices",
  initialState,
  reducers: {
    clearDevicesFeedback: (state, action) => {
      state.feedback = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCustomersForDeviceAdd.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchCustomersForDeviceAdd.fulfilled, (state, action) => {
      state.customers = action.payload.data.users;
      state.totalRecords = action.payload.data.totalRecords;
      state.loading = false;
      state.error = [];
    });
    builder.addCase(fetchCustomersForDeviceAdd.rejected, (state, action) => {
      state.customers = [];
      state.totalRecords = 0;
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(searchCustomersForDeviceAdd.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(searchCustomersForDeviceAdd.fulfilled, (state, action) => {
      state.customers = action.payload.data.users;
      state.totalRecords = action.payload.data.totalRecords;
      state.loading = false;
      state.error = [];
    });
    builder.addCase(searchCustomersForDeviceAdd.rejected, (state, action) => {
      state.customers = [];
      state.totalRecords = 0;
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(fetchCustomersForDeviceChange.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(
      fetchCustomersForDeviceChange.fulfilled,
      (state, action) => {
        state.customers = action.payload.data.users;
        state.totalRecords = action.payload.data.totalRecords;
        state.loading = false;
        state.error = [];
      }
    );
    builder.addCase(fetchCustomersForDeviceChange.rejected, (state, action) => {
      state.customers = [];
      state.totalRecords = 0;
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(searchCustomersForDeviceChange.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(
      searchCustomersForDeviceChange.fulfilled,
      (state, action) => {
        state.customers = action.payload.data.users;
        state.totalRecords = action.payload.data.totalRecords;
        state.loading = false;
        state.error = [];
      }
    );
    builder.addCase(
      searchCustomersForDeviceChange.rejected,
      (state, action) => {
        state.customers = [];
        state.totalRecords = 0;
        state.loading = false;
        state.error = action.payload;
      }
    );
    builder.addCase(fetchDeviceChangeInfo.pending, (state, action) => {
      state.feedback = feedback.PENDING;
      state.info = null;
      state.error = [];
    });
    builder.addCase(fetchDeviceChangeInfo.fulfilled, (state, action) => {
      state.info = action.payload.data;
      state.feedback = feedback.SUCCESSFUL;
      state.error = [];
    });
    builder.addCase(fetchDeviceChangeInfo.rejected, (state, action) => {
      state.feedback = feedback.FAILED;
      state.error = action.payload;
    });
    builder.addCase(denyDeviceChangeRequest.pending, (state, action) => {
      state.loadingInfo = true;
      state.feedback = feedback.PENDING;
      state.error = [];
    });
    builder.addCase(denyDeviceChangeRequest.fulfilled, (state, action) => {
      state.loadingInfo = false;
      state.feedback = feedback.SUCCESSFUL;
      state.error = [];
    });
    builder.addCase(denyDeviceChangeRequest.rejected, (state, action) => {
      state.loadingInfo = false;
      state.feedback = feedback.FAILED;
      state.error = action.payload;
    });
  },
});

export const { clearDevicesFeedback } = deviceSlice.actions;
export default deviceSlice.reducer;

import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { styles } from "../../../../common/css/styles";
import { nigerianStates } from "../../../../common/constants/nigerianStates";
import { stage } from "../../../kyc/stages";
import { Add, ArrowForward } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { submitKyc } from "../../../kyc/kycSlice";
import { LoadingButton } from "@mui/lab";

const defaultValues = {
  residentialAddress: "",
  city: "",
  state: "",
  utilityBill: "",
};

const schema = yup.object().shape({
  residentialAddress: yup.string().required("Residential address is required"),
  city: yup.string().required("LGA is required"),
  state: yup.string().required("State is required"),
  utilityBill: yup.mixed().required("Utility bill is required"),
});

export const AddResidence = ({
  handleTabChange,
  customerData,
  handleAddCustomerData,
  openInfo,
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors, isValid, isSubmitting },
    watch,
    getValues,
    setValue,
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  const selectedState = watch("state");
  const [selectedFile, setSelectedFile] = useState("");
  const customer = useSelector(({ customer }) => customer.customer);
  const dispatch = useDispatch();

  const onSubmit = (data) => {
    customerData.residence = {
      ...data,
      userUid: customer.uid,
      onboardingStage: stage.RESIDENCE,
    };
    handleAddCustomerData(customerData);
    dispatch(submitKyc(customerData.residence));
  };

  const handlePreviousClick = () => {
    handleTabChange(stage.PERSONAL);
  };

  useEffect(() => {
    if (customerData.residence) {
      setValue("residentialAddress", customerData.residence.residentialAddress);
      setValue("state", customerData.residence.state);
      setValue("city", customerData.residence.city);
      setValue("utilityBill", customerData.residence.utilityBill);
    }
  }, []);

  return (
    <>
      <Grid
        container
        rowSpacing={2}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        mt={1}
        component="form"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid item xs={6}>
          <Typography sx={styles.formFieldHeading}>
            Residential Address
          </Typography>
          <Controller
            name="residentialAddress"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                variant="outlined"
                fullWidth
                size={styles.textfieldSize}
                placeholder="e.g. 123, Four Str."
                error={!!errors.residentialAddress}
                helperText={errors.residentialAddress?.message}
                sx={styles.textField}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}></Grid>
        <Grid item xs={6}>
          <Typography sx={styles.formFieldHeading}>State</Typography>
          <Controller
            name="state"
            control={control}
            render={({ field }) => (
              <FormControl fullWidth>
                <Select
                  {...field}
                  size={styles.textfieldSize}
                  error={!!errors.state}
                  sx={styles.selectField}
                >
                  {nigerianStates.map((state) => (
                    <MenuItem key={state.code} value={state.name.toLowerCase()}>
                      {state.name}
                    </MenuItem>
                  ))}
                </Select>
                {errors.state ? (
                  <FormHelperText error>
                    {errors?.state?.message}
                  </FormHelperText>
                ) : (
                  <></>
                )}
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={6}></Grid>

        <Grid item xs={6}>
          <Typography sx={styles.formFieldHeading}>LGA</Typography>
          <Controller
            name="city"
            control={control}
            render={({ field }) => (
              <FormControl fullWidth>
                <Select
                  {...field}
                  size={styles.textfieldSize}
                  error={!!errors.city}
                  sx={styles.selectField}
                >
                  {!!selectedState &&
                    nigerianStates
                      .filter(
                        (state) => state.name.toLowerCase() === selectedState
                      )[0]
                      .lgas.map((lga, index) => (
                        <MenuItem key={index} value={lga.toLowerCase()}>
                          {lga}
                        </MenuItem>
                      ))}
                </Select>
                {errors.city ? (
                  <FormHelperText error>{errors?.city?.message}</FormHelperText>
                ) : (
                  <></>
                )}
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={6}></Grid>

        <Grid item xs={6}>
          <Typography variant="body1" sx={styles.formFieldHeading}>
            Utility Bill
          </Typography>
          <Controller
            name="utilityBill"
            control={control}
            render={({ field }) => (
              <label htmlFor="file-upload">
                <input
                  {...field}
                  accept="*/*"
                  style={styles.uploadButton.input}
                  id="file-upload"
                  type="file"
                  value={selectedFile}
                  onChange={(e) => {
                    setSelectedFile(e.target.value);
                    field.onChange(e.target.files);
                  }}
                />
                <Button
                  // fullWidth
                  variant="contained"
                  component="span"
                  style={{
                    ...styles.uploadButton.button,
                    width: "100%",
                    p: 0,
                    borderRadius: 2,
                  }}
                  startIcon={<Add sx={{ color: "primary.main" }} />}
                >
                  {!!getValues("utilityBill")
                    ? getValues("utilityBill")[0].name
                    : "Upload"}
                </Button>
              </label>
            )}
          />
        </Grid>
        <Grid item xs={6}></Grid>

        <Grid item xs={12} sx={{ textAlign: "right" }}>
          <Box>
            <LoadingButton
              variant="contained"
              type="submit"
              endIcon={<ArrowForward />}
              loadingPosition="end"
              disabled={!isValid || isSubmitting}
              loading={isSubmitting}
              sx={{
                textTransform: "capitalize",
                height: 40,
                color: "#fff",
                boxShadow: "none",
                fontSize: 16,

                "&:hover": {
                  boxShadow: "none",
                },
              }}
            >
              Next
            </LoadingButton>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { DeadState } from "../../../../common/components/DeadState";

const tempLoanList = [
  // {
  //   loanAmount: "N2,500,000",
  //   date: "23/09/2023",
  //   source: "Wallet",
  //   status: "Paid",
  // },
];

const columns = [
  {
    id: "amount",
    label: "Repayment Amount",
    minWidth: 50,
    align: "left",
  },
  {
    id: "interest",
    label: "Interest",
    minWidth: 50,
    align: "left",
  },
  {
    id: "repaymentDate",
    label: "Repayment Date",
    minWidth: 50,
    align: "left",
  },
  {
    id: "dueDate",
    label: "Due Date",
    minWidth: 50,
    align: "left",
  },
];

export const RepaymentHistory = () => {
  return (
    <Paper
      sx={{
        width: "100%",
        overflow: "hidden",
        border: "none",
        boxShadow: "none",
      }}
    >
      <TableContainer sx={{ maxHeight: 400 }}>
        <Table
          stickyHeader
          aria-label="sticky table"
          sx={{ backgroundColor: "transparent" }}
        >
          <TableHead>
            <TableRow
              sx={{
                "& .MuiTableCell-head": {
                  color: "#000",
                  backgroundColor: "transparent",
                  border: "4px solid black",
                },
              }}
            >
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{
                    minWidth: column.minWidth,
                    borderTop: "1px solid #B5B5B5",
                    borderLeft: "none",
                    borderRight: "none",
                    borderBottom: "1px solid #B5B5B5",
                    fontSize: 13,
                    fontWeight: 600,
                    height: 20,
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tempLoanList.length > 0 ? (
              tempLoanList?.map((loan) => (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={loan.uid}
                  sx={{
                    "& .MuiTableCell-body": {
                      color: "#62635E",
                      backgroundColor: "#fff",
                      border: "none",
                      fontSize: 13,
                      fontWeight: 400,
                    },
                  }}
                >
                  <TableCell sx={{ textTransform: "capitalize" }}>
                    {loan.loanAmount}
                  </TableCell>
                  <TableCell sx={{ textTransform: "capitalize" }}>
                    {loan.date}
                  </TableCell>
                  <TableCell sx={{ textTransform: "capitalize" }}>
                    {loan.source}
                  </TableCell>
                  <TableCell align="left">
                    {/* {getRepaymentStatusDisplay(loan.status)} */}
                    23/09/23
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableCell colSpan={columns.length} sx={{ textAlign: "center" }}>
                <DeadState />
              </TableCell>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

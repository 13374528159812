import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { styles } from "../../../../../common/css/styles";

export const NextOfKin = ({ data }) => {
  return (
    <Box
      sx={{
        display: "flex",
        // justifyContent: "space-between",
        width: "100%",
      }}
      spacing={2}
      useFlexGap
    >
      <Box sx={{ mr: 25 }}>
        <Stack>
          <Box sx={{ py: 1 }}>
            <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
              Full Name
            </Typography>
            <Typography sx={{ fontWeight: 700, color: "#494D4A" }}>
              {data?.kinFirstName
                ? `${data?.kinFirstName
                    ?.charAt(0)
                    .toUpperCase()}${data?.kinFirstName?.substring(
                    1
                  )} ${data?.kinLastName
                    ?.charAt(0)
                    .toUpperCase()}${data?.kinLastName?.substring(1)}`
                : "Not Available"}
            </Typography>
          </Box>
          <Box sx={{ py: 1 }}>
            <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
              Phone Number
            </Typography>
            <Typography sx={{ fontWeight: 700, color: "#494D4A" }}>
              {data?.kinPhoneNumber ?? "Not Available"}
            </Typography>
          </Box>
          <Box sx={{ py: 1 }}>
            <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
              Relationship
            </Typography>
            <Typography sx={styles.kyc.bodyText}>
              {data?.kinRelationship ?? "Not Available"}
            </Typography>
          </Box>
          <Box sx={{ py: 1 }}>
            <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
              LGA
            </Typography>
            <Typography sx={styles.kyc.bodyText}>
              {data?.kinCity ?? "Not Available"}
            </Typography>
          </Box>
        </Stack>
      </Box>
      <Box>
        <Stack>
          <Box sx={{ py: 1 }}>
            <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
              Email Address
            </Typography>
            <Typography
              sx={{ ...styles.kyc.bodyText, textTransform: "lowercase" }}
            >
              {data?.kinEmail ? data.kinEmail.toLowerCase() : "Not Available"}
            </Typography>
          </Box>
          <Box sx={{ py: 1 }}>
            <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
              DOB
            </Typography>
            <Typography sx={{ fontWeight: 700, color: "#494D4A" }}>
              {data?.kinDateOfBirth?.replaceAll("-", "/") ?? "Not Available"}
            </Typography>
          </Box>
          <Box sx={{ py: 1 }}>
            <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
              Address
            </Typography>
            <Typography sx={styles.kyc.bodyText}>
              {data?.kinResidentialAddress ?? "Not Available"}
            </Typography>
          </Box>
          <Box sx={{ py: 1 }}>
            <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
              State
            </Typography>
            <Typography sx={styles.kyc.bodyText}>
              {data?.kinState ?? "Not Available"}
            </Typography>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};

import { ArrowBackIos } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Fade,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SectionHeader } from "../../common/components/SectionHeader";
import { WhiteBox } from "../../common/components/WhiteBox";
import { styles } from "../../common/css/styles";
import { BankStatement } from "../kyc/stages/BankStatement";

import { Recommendation } from "../smeLoans/businessLoan/details/stages/Recommendation";

import { BusinessInfo } from "../smeLoans/businessLoan/details/businessInfo/BusinessInfo";
import { CreditBureau } from "../smeLoans/businessLoan/details/stages/CreditBureau";
import { stage } from "../kyc/stages";
import { CardSideNew } from "./components/CardSideNew";
import { useSmeLoans } from "./hooks/smeloans";
import { LoanHistorySME } from "./details/loanHistory/LoanHistorySME";

export const BusinessLoanOverview = ({ type }) => {
  const [currentStage, setCurrentStage] = useState(stage.BUSINESS_INFO);
  const navigate = useNavigate();

  const { loanid } = useParams();

  const { loan, loanAlt, anyLoading } = useSmeLoans(loanid);

  const getKYBStage = () => {
    switch (currentStage) {
      case stage.BUSINESS_INFO:
        return <BusinessInfo businessLoan={loan} />;
      case stage.LOAN_HISTORY:
        return <LoanHistorySME businessId={loan?.businessId} />;

      case stage.CREDIT_BUREAU:
        return <CreditBureau customer={loan} data={null} />;
      case stage.STATEMENT:
        return <BankStatement customer={loan} data={null} />;
      case stage.RECOMMENDATION:
        return <Recommendation customer={loan} data={null} />;
      default:
        <BusinessInfo businessLoan={loan} />;
    }
  };

  const handleBackToTable = () => navigate("/loans", { replace: true });
  const handleTabChange = (stage) => setCurrentStage(stage);

  const { businessName, email, wallet } = loan;

  if (anyLoading)
    return (
      <WhiteBox>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "70vh",
          }}
        >
          <CircularProgress sx={{ alignItems: "center" }} />
        </Box>
      </WhiteBox>
    );

  return (
    <>
      <SectionHeader
        headerText={
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: 32,
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              width: "fit-content",
            }}
            onClick={handleBackToTable}
          >
            <Tooltip
              title="Back to loans table"
              arrow
              placement="top-start"
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
            >
              {/* <IconButton
              aria-label="back"
              onClick={handleBackToTable}
              sx={{ mb: 0.8 }}
            > */}
              <ArrowBackIos
                sx={{ fontSize: 30, color: "#000", mr: "5px", ml: "-10px" }}
              />
              {/* </IconButton> */}
            </Tooltip>
            Loans
          </Typography>
        }
      />

      <Grid
        container
        sx={{
          display: {
            // xs: "none",
            md: "block",
            minHeight: "100vh",
            // backgroundColor: "yellow"
          },
        }}
      >
        <Grid container spacing={2}>
          <Grid
            container
            item
            xs={12}
            md={4}
            sx={{
              display: {
                // xs: "none",
                md: "block",
              },
            }}
          >
            {/* Top */}
            <WhiteBox minHeight={"40vh"}>
              <CardSideNew
                loan={{ ...loanAlt, ...loan }}
                wallet={wallet}
                customer={{ firstName: businessName, email }}
                // bankData={bankData}
              />
            </WhiteBox>
          </Grid>

          <Grid
            item
            container
            xs={12}
            md={8}
            sx={{
              p: 2,
            }}
          >
            <WhiteBox>
              <Box sx={{ p: 2 }}>
                <Box sx={{ borderBottom: 1, borderBottomColor: "#F0F1ED" }}>
                  <Button
                    onClick={() => handleTabChange(stage.BUSINESS_INFO)}
                    sx={
                      currentStage === stage.BUSINESS_INFO
                        ? styles.customerAccountVerification.sectionButton
                            .selected
                        : styles.customerAccountVerification.sectionButton
                            .notSelected
                    }
                  >
                    Business Info
                  </Button>
                  <Button
                    onClick={() => handleTabChange(stage.LOAN_HISTORY)}
                    sx={
                      currentStage === stage.LOAN_HISTORY
                        ? styles.customerAccountVerification.sectionButton
                            .selected
                        : styles.customerAccountVerification.sectionButton
                            .notSelected
                    }
                  >
                    Loan History
                  </Button>
                  {/* <Button
                      onClick={() => handleTabChange(stage.STATEMENT)}
                      sx={
                        currentStage === stage.STATEMENT
                          ? styles.customerAccountVerification.sectionButton
                              .selected
                          : styles.customerAccountVerification.sectionButton
                              .notSelected
                      }
                    >
                      Statement
                    </Button>
                    <Button
                      onClick={() => handleTabChange(stage.CREDIT_BUREAU)}
                      sx={
                        currentStage === stage.CREDIT_BUREAU
                          ? styles.customerAccountVerification.sectionButton
                              .selected
                          : styles.customerAccountVerification.sectionButton
                              .notSelected
                      }
                    >
                      Credit Bureau
                    </Button>
                    <Button
                      onClick={() => handleTabChange(stage.RECOMMENDATION)}
                      sx={
                        currentStage === stage.RECOMMENDATION
                          ? styles.customerAccountVerification.sectionButton
                              .selected
                          : styles.customerAccountVerification.sectionButton
                              .notSelected
                      }
                    >
                      Recommendation
                    </Button> */}
                </Box>

                <Box
                  sx={{
                    display: "",
                    justifyContent: "space-between",
                    width: "100%",
                    //   alignItems: "normal",
                  }}
                >
                  {getKYBStage()}
                </Box>
              </Box>
            </WhiteBox>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

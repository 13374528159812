import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { handleError } from "../../../common/constants/utility";
import { getBusinesses, search } from "../../../common/api/businesses";

const initialState = {
  businesses: [],
  totalRecords: 0,
  loading: false,
  error: [],
};

export const filteredBusinesses = createAsyncThunk(
  "businesses/filterBy",
  async (
    { page, businessType, filterOption },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await getBusinesses(page, businessType, filterOption);
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);

export const searchBusinesses = createAsyncThunk(
  "businesses/search",
  async ({ page, searchTerm }, { dispatch, rejectWithValue }) => {
    try {
      const response = await search(page, searchTerm);
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);

const businessesSlice = createSlice({
  name: "businesses",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(filteredBusinesses.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(filteredBusinesses.fulfilled, (state, action) => {
      state.businesses = action.payload.data.businessList;
      state.totalRecords = action.payload.data.totalRecords;
      state.loading = false;
      state.error = [];
    });
    builder.addCase(filteredBusinesses.rejected, (state, action) => {
      state.businesses = [];
      state.totalRecords = 0;
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(searchBusinesses.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(searchBusinesses.fulfilled, (state, action) => {
      state.businesses = action.payload.data.businessList;
      state.totalRecords = action.payload.data.totalRecords;
      state.loading = false;
      state.error = "";
    });
    builder.addCase(searchBusinesses.rejected, (state, action) => {
      state.businesses = [];
      state.totalRecords = 0;
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default businessesSlice.reducer;

import React, { useState } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  Paper,
  Typography,
  CircularProgress,
} from "@mui/material";
import { styles } from "../../common/css/styles";
import { DeadState } from "../../common/components/DeadState";
import { format } from "date-fns";
import { PageNavigation } from "../../common/components/PageNavigation";

const columns = [
  { id: "staff", label: "Staff" },
  { id: "phoneNumber", label: "Phone Number" },
  { id: "email", label: "Email" },
  { id: "log", label: "Log" },
  { id: "timeStamp", label: "Timestamp" },
];

export const AuditLogsTable = ({ auditLogs, page, handlePageChange }) => {
  const logs = auditLogs.logs;
  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState("asc");

  const handleSort = (columnId) => {
    if (orderBy === columnId) {
      setOrder(order === "asc" ? "desc" : "asc");
    } else {
      setOrderBy(columnId);
      setOrder("asc");
    }
  };

  return (
    <Paper
      sx={{
        boxShadow: "none",
        pt: 2,
      }}
    >
      <Table size="small">
        <TableHead>
          <TableRow
            sx={{
              "& .MuiTableCell-head": {
                color: "#000",
                backgroundColor: "#fff",
                borderTop: 0.5,
                borderBottom: 0.5,
                borderColor: "#B5B5B5",
                py: 2,
              },
            }}
          >
            {columns.map((column) => (
              <TableCell
                key={column.id}
                sx={{
                  fontSize: 13,
                  fontWeight: 600,
                  // color: "#62635E",
                }}
              >
                <TableSortLabel
                  active={orderBy === column.id}
                  direction={orderBy === column.id ? order : "asc"}
                  onClick={() => handleSort(column.id)}
                >
                  {column.label}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody
          sx={{
            "& .MuiTableCell-body": {
              color: "#000",
              backgroundColor: "#fff",
              borderBottom: 0.5,
              borderColor: "#F7F8F4",
            },
          }}
        >
          {auditLogs.loading ? (
            <TableCell
              colSpan={columns.length}
              sx={{ textAlign: "center", borderBottom: 0, pt: 4 }}
            >
              <CircularProgress />
            </TableCell>
          ) : logs.length > 0 ? (
            logs.map((log, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Typography noWrap sx={styles.table.text}>
                    {`${log.user.firstName} ${log.user.lastName}`}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography noWrap sx={styles.table.text}>
                    {log.user.phoneNumber}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    noWrap
                    sx={{ ...styles.table.text, textTransform: "lowercase" }}
                  >
                    {log.user.email}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={styles.table.text}>{log.message}</Typography>
                </TableCell>
                <TableCell>
                  <Typography noWrap sx={styles.table.text}>
                    {format(new Date(log.createdAt), "dd/MM/yyyy hh:mm:ss aa")}
                  </Typography>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableCell colSpan={columns.length} sx={{ textAlign: "center" }}>
              <DeadState />
            </TableCell>
          )}
        </TableBody>
      </Table>
      {!auditLogs.loading && logs.length > 0 ? (
        <PageNavigation
          page={page}
          totalRecords={auditLogs.totalRecords}
          handlePageChange={handlePageChange}
        />
      ) : (
        <></>
      )}
    </Paper>
  );
};

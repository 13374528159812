import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { styles } from "../../../../common/css/styles";
import { ApproveSuccess } from "./ApproveSuccess";
import { ApproveFailed } from "./ApproveFailed";
import { useMutation } from "@tanstack/react-query";
import { approveForDisbursement } from "../../../../common/api/sme";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import { parseError } from "../../../../common/constants/utility";

export const ApproveLoan = ({ open, handleClose, loan }) => {
  const [openApproveSuccess, setOpenApproveSuccess] = useState(false);
  const [openApproveFailed, setOpenApproveFailed] = useState(false);
  const navigate = useNavigate();
  const handleCloseApproveSuccess = () => {
    setOpenApproveSuccess(false);
    navigate("/loans", { replace: true });
  };

  const handleCloseApproveFailed = () => {
    setOpenApproveFailed(false);
    if (handleClose) handleClose();
  };

  const { isLoading, mutate } = useMutation({
    mutationFn: (data) => approveForDisbursement(loan?.loanUid ?? loan.uid),
    mutationKey: "approve",
    onError: (err) => {
      setOpenApproveFailed(parseError(err));
    },
    onSuccess: (data) => {
      setOpenApproveSuccess(true);
    },
  });

  const handleApproval = () => {
    mutate();
  };

  return (
    <>
      {!(openApproveSuccess || openApproveFailed) && (
        <Dialog open={open} onClose={handleClose}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              minWidth: "500px",
            }}
          >
            <DialogContent>
              <Box
                sx={{
                  width: "280px",
                  display: "flex",
                  justifyContent: "center",
                }}
              ></Box>
              <DialogContentText>
                <Typography sx={{ fontSize: 17, fontWeight: 500 }}>
                  Approve Loan
                </Typography>
                <Typography
                  sx={{ fontSize: 14, fontWeight: 500, color: "#969997" }}
                >
                  Are you sure you want to approve this loan for disbursement?
                </Typography>
              </DialogContentText>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  mt: 2,
                }}
              >
                <Button
                  variant="contained"
                  onClick={handleClose}
                  sx={{
                    ...styles.bridgeCardTop.error,
                    fontSize: 11,
                    px: 1,
                    mx: 1,
                  }}
                >
                  Cancel
                </Button>
                <LoadingButton
                  loading={isLoading}
                  variant="contained"
                  sx={{ ...styles.bridgeCardTop.success, fontSize: 11, px: 1 }}
                  onClick={handleApproval}
                >
                  Accept
                </LoadingButton>
              </Box>
            </DialogContent>
          </Box>
        </Dialog>
      )}

      <ApproveSuccess
        loan={loan}
        open={openApproveSuccess}
        handleClose={handleCloseApproveSuccess}
      />
      <ApproveFailed
        open={openApproveFailed}
        handleClose={handleCloseApproveFailed}
        error={openApproveFailed}
      />
    </>
  );
};

import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { styles } from "../../../../../common/css/styles";
import { toTitleCase } from "../../../../../common/constants/utility";

export const Residence = ({ data }) => {
  return (
    <Box
      sx={{
        display: "flex",
        // justifyContent: "space-between",
        width: "100%",
      }}
    >
      <Box sx={{ mr: 25 }}>
        <Stack>
          <Box sx={{ py: 1 }}>
            <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
              Address
            </Typography>
            <Typography sx={{ fontWeight: 700, color: "#494D4A" }}>
              {toTitleCase(data?.street)}
            </Typography>
          </Box>
          <Box sx={{ py: 1 }}>
            <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
              State
            </Typography>
            <Typography sx={{ fontWeight: 700, color: "#494D4A" }}>
              {toTitleCase(data?.state)}
            </Typography>
          </Box>
        </Stack>
      </Box>
      <Box>
        <Stack>
          <Box sx={{ py: 1 }}>
            <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
              City
            </Typography>
            <Typography sx={{ fontWeight: 700, color: "#494D4A" }}>
              {toTitleCase(data?.city)}
            </Typography>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};

import { Grid, Button, Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { PhotoUpload } from "../components/documents/PhotoUpload";
import { GovernmentIDUpload } from "../components/documents/GovernmentIDUpload";
import { PreviousButton } from "../components/PreviousButton";
import { NextButton } from "../components/NextButton";
import { stage } from "../../../kyc/stages";
import { LoadingButton } from "@mui/lab";
import { InfoDialog } from "../../../../common/components/InfoDialog";
import { useNavigate } from "react-router-dom";

const reuploadFilter = [
  { section: "GovernmentID", label: "Government ID" },
  // { section: "AddressVerification", label: "Address Verification" },
  { section: "Photo", label: "Photo" },
];

export const AddDocuments = ({
  handleTabChange,
  customerData,
  handleAddCustomerData,
}) => {
  const [selectedSection, setSelectedSection] = useState("GovernmentID");
  const [openDialog, setOpenDialog] = useState(false);
  const navigate = useNavigate();

  const handleSectionButtonClick = (section) => {
    setSelectedSection(section);
  };

  const handlePreviousClick = () => {
    handleTabChange(stage.NEXT_OF_KIN);
  };

  const handleCompleteClick = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    navigate("/customers/accounts");
  };

  useEffect(() => {
    let timeoutId;
    if (openDialog) {
      timeoutId = setTimeout(() => {
        handleCloseDialog();
      }, 2000);
    }

    return () => clearTimeout(timeoutId);
  }, [openDialog]);

  return (
    <>
      <Grid sx={{ pt: 4, width: "100%" }}>
        {/* Subsection Selectors */}
        <Grid sx={{ py: 2 }}>
          {reuploadFilter.map(({ section, label }) => (
            <Button
              key={section}
              variant="outlined"
              sx={{
                height: 30,
                textTransform: "capitalize",
                color: selectedSection === section ? "#000" : "#B5B5B5",
                borderColor: "#B5B5B5",
                borderRadius: 0,
                backgroundColor: selectedSection === section ? "" : "#F7F8F4",
                py: 2,
                fontSize: 12,
              }}
              onClick={() => handleSectionButtonClick(section)}
            >
              {label}
            </Button>
          ))}
        </Grid>

        {selectedSection === "Photo" && (
          <PhotoUpload
            customerData={customerData}
            handleAddCustomerData={handleAddCustomerData}
          />
        )}
        {selectedSection === "GovernmentID" && (
          <GovernmentIDUpload
            customerData={customerData}
            handleAddCustomerData={handleAddCustomerData}
          />
        )}
        {/* {selectedSection === "AddressVerification" && <AddressUpload />} */}

        <Grid sx={{ py: 1, display: "flex", justifyContent: "flex-end" }}>
          <Grid
            sx={{
              display: "flex",
              // width: "220px",
              // justifyContent: "space-between",
            }}
          >
            <Box sx={{ mr: 2 }}>
              <PreviousButton handlePreviousClick={handlePreviousClick} />
            </Box>
            <Box>
              <Button
                variant="contained"
                onClick={handleCompleteClick}
                sx={{
                  textTransform: "capitalize",
                  height: 40,
                  color: "#fff",
                  boxShadow: "none",
                  fontSize: 16,

                  "&:hover": {
                    boxShadow: "none",
                  },
                }}
              >
                Complete
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <InfoDialog
        open={openDialog}
        text="User successfully added"
        error={false}
        handleClose={handleCloseDialog}
      />
    </>
  );
};

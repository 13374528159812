import { Box, Typography, Button, Stack, IconButton } from "@mui/material";
import React, { useState } from "react";
import { styles } from "../../../../../common/css/styles";
import { loansDetailsFilters } from "../../constants/loansFilters";
import { stage } from "./index";
import { Bank } from "./Bank";
import { Guarantee } from "./Guarantee";
import Grid from "@mui/material/Unstable_Grid2";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";

export const Personal = ({ customer }) => {
  // const [currentStage, setCurrentStage] = useState(stage.PERSONAL);
  const [activeFilter, setActiveFilter] = useState(
    loansDetailsFilters.PERSONAL
  );
  // const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const handleView = () =>
    navigate(
      `/sme-loans/business-loans/business-loan-overview/team-member-details`
    );
  const handlePageChange = (event, newPage) => {
    setPage(newPage - 1);
    // dispatch(filteredLoans({ page: newPage, filterOption: activeFilter }));
  };

  const customers = useSelector((state) => state.customers);

  const filters = [
    { label: "Details", value: loansDetailsFilters.PERSONAL, visible: true },
    { label: "Profile", value: loansDetailsFilters.RESIDENCE, visible: true },
    {
      label: "Operations",
      value: loansDetailsFilters.EMPLOYMENT,
      visible: true,
    },
    {
      label: "TeamMembers",
      value: loansDetailsFilters.BANK_INFO,
      visible: true,
    },
    {
      label: "Guarantee",
      value: loansDetailsFilters.NEXT_OF_KIN,
      visible: true,
    },
    {
      label: "Colleteral",
      value: loansDetailsFilters.IDENTIFICATION,
      visible: true,
    },
    {
      label: "Documents",
      value: loansDetailsFilters.DOCUMENT,
      visible: true,
    },
  ];
  const handleFilter = (filter) => {
    setActiveFilter(filter);
  };

  const getPersonalStage = () => {
    switch (activeFilter) {
      case stage.PERSONAL:
        return <PersonalDetails />;
      case stage.RESIDENCE:
        return <ResidenceDetails />;
      case stage.EMPLOYMENT:
        return <EmploymentDetails />;
      case stage.BANK_INFO:
        return (
          <Bank
            page={page}
            handlePageChange={handlePageChange}
            handleView={handleView}
            loans={customers}
            customer={null}
            data={null}
          />
        );
      case stage.IDENTIFICATION:
        return <Collateral />;
      case stage.NEXT_OF_KIN:
        return (
          <Guarantee
            page={page}
            handlePageChange={handlePageChange}
            handleView={handleView}
            loans={customers}
            customer={null}
            data={null}
          />
        );
      case stage.DOCUMENT:
        return <Document />;
      default:
        return <PersonalDetails />;
    }
  };

  return (
    <Box>
      <Box sx={{ py: 3, display: "flex" }}>
        {filters
          .filter((filter) => filter.visible)
          .map((filter) => (
            <Button
              onClick={() => handleFilter(filter.value)}
              variant="outlined"
              sx={
                activeFilter === filter.value
                  ? { ...styles.tableFilter.active, fontSize: 14, p: 2 }
                  : { ...styles.tableFilter.inactive, fontSize: 14, p: 2 }
              }
            >
              {filter.label}
            </Button>
          ))}
      </Box>
      {getPersonalStage()}
    </Box>
  );
};

export const PersonalDetails = () => {
  // const data = {};
  return (
    <Box sx={{ position: "sticky", background: "#fff", borderRadius: 8 }}>
      <Box
        sx={{
          display: "flex",
          borderBottom: 1,
          pb: 4,
          borderBottomColor: "#F0F1ED",
        }}
      >
        <Box>
          <Box sx={{ width: "100%" }}>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 10 }}
            >
              <Grid xs={6}>
                <Box>
                  <Box>
                    <Typography
                      sx={{ fontWeight: 400, color: "#62635E", py: 1 }}
                    >
                      Business Name
                    </Typography>
                    <Typography sx={{ fontWeight: 700, color: "#494D4A" }}>
                      Kate’s Bakery
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid xs={6}>
                <Box>
                  <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
                    Type of Business
                  </Typography>
                  <Typography
                    sx={{ ...styles.kyc.bodyText, textTransform: "lowercase" }}
                  >
                    Limited Liability
                  </Typography>
                </Box>
              </Grid>
              <Grid xs={6}>
                <Box>
                  <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
                    Business Email
                  </Typography>
                  <Typography sx={{ fontWeight: 700, color: "#494D4A" }}>
                    Katiesgroup@gmail.com
                  </Typography>
                </Box>
              </Grid>
              <Grid xs={6}>
                <Box>
                  <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
                    Business Phone
                  </Typography>
                  <Typography sx={{ fontWeight: 700, color: "#494D4A" }}>
                    09012345678
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          pt: 4,
          pb: 18,
        }}
      >
        <Box>
          <Box sx={{ width: "100%" }}>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 16 }}
            >
              <Grid xs={6}>
                <Box>
                  <Box>
                    <Typography
                      sx={{
                        fontWeight: 400,
                        color: "#62635E",
                        py: 1,
                      }}
                    >
                      Address
                    </Typography>
                    <Typography sx={styles.kyc.bodyText}>
                      {" "}
                      4th Mary high street
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid xs={6}>
                <Box>
                  <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
                    City
                  </Typography>
                  <Typography sx={{ fontWeight: 700, color: "#494D4A" }}>
                    Ikeja
                  </Typography>
                </Box>
              </Grid>
              <Grid xs={6}>
                <Box>
                  <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
                    LGA
                  </Typography>
                  <Typography sx={styles.kyc.bodyText}>Mainland</Typography>
                </Box>
              </Grid>
              <Grid xs={6}>
                <Box>
                  <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
                    State
                  </Typography>
                  <Typography sx={{ fontWeight: 700, color: "#494D4A" }}>
                    Lagos
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export const ResidenceDetails = () => {
  return (
    <Box
      sx={{
        // display: "flex",

        pb: 18,
      }}
    >
      <Box>
        <Box sx={{ width: "100%" }}>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 1 }}
          >
            <Grid xs={4}>
              <Box>
                <Box>
                  <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
                    Date business operations state
                  </Typography>
                  <Typography sx={{ fontWeight: 700, color: "#494D4A" }}>
                    09/09/2013
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid xs={6}>
              <Box>
                <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
                  Number of employees
                </Typography>
                <Typography
                  sx={{ ...styles.kyc.bodyText, textTransform: "lowercase" }}
                >
                  20
                </Typography>
              </Box>
            </Grid>
            <Grid xs={4}>
              <Box>
                <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
                  Industry
                </Typography>
                <Typography sx={{ fontWeight: 700, color: "#494D4A" }}>
                  Food and sundry
                </Typography>
              </Box>
            </Grid>
            <Grid xs={6}>
              <Box>
                <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
                  Sub Industry
                </Typography>
                <Typography sx={{ fontWeight: 700, color: "#494D4A" }}>
                  Bakery
                </Typography>
              </Box>
            </Grid>
            <Grid xs={4}>
              <Box>
                <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
                  Business website
                </Typography>
                <Typography sx={{ fontWeight: 700, color: "#494D4A" }}>
                  https://trustworthy-wagon.org
                </Typography>
              </Box>
            </Grid>
            <Grid xs={6}>
              <Box>
                <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
                  Linkedin
                </Typography>
                <Typography sx={{ fontWeight: 700, color: "#494D4A" }}>
                  https://trustworthy-wagon.org
                </Typography>
              </Box>
            </Grid>
            <Grid xs={4}>
              <Box>
                <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
                  Twitter
                </Typography>
                <Typography sx={{ fontWeight: 700, color: "#494D4A" }}>
                  @Adrienne6
                </Typography>
              </Box>
            </Grid>
            <Grid xs={6}>
              <Box>
                <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
                  Facebook
                </Typography>
                <Typography sx={{ fontWeight: 700, color: "#494D4A" }}>
                  .Dietrich-Hammes68
                </Typography>
              </Box>
            </Grid>
            <Grid xs={6}>
              <Box>
                <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
                  Instagram
                </Typography>
                <Typography sx={{ fontWeight: 700, color: "#494D4A" }}>
                  @Adrienne6
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export const EmploymentDetails = () => {
  return (
    <Box
      sx={{
        display: "flex",
        // justifyContent: "space-between",
        width: "100%",
      }}
    >
      <Box sx={{ mr: 13 }}>
        <Stack>
          <Box sx={{ py: 1 }}>
            <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
              How business operates
            </Typography>
            <Typography sx={{ fontWeight: 700, color: "#494D4A" }}>
              09/09/2013
            </Typography>
          </Box>
          <Box sx={{ py: 1 }}>
            <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
              Number of locations
            </Typography>
            <Typography sx={{ fontWeight: 700, color: "#494D4A" }}>
              Food and sundry
            </Typography>
          </Box>
          <Box sx={{ py: 1 }}>
            <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
              Average monthly profit
            </Typography>
            <Typography sx={styles.kyc.bodyText}>₦2,000,000</Typography>
          </Box>
          <Box sx={{ py: 1 }}>
            <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
              Average monthly Salary of employees
            </Typography>
            <Typography sx={styles.kyc.bodyText}>₦1,000,000</Typography>
          </Box>
        </Stack>
      </Box>

      <Box>
        <Stack>
          <Box sx={{ py: 1 }}>
            <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
              Average number of Customers
            </Typography>
            <Typography sx={{ fontWeight: 700, color: "#494D4A" }}>
              20
            </Typography>
          </Box>
          <Box sx={{ py: 1 }}>
            <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
              Total number of sales in the last 6 months
            </Typography>
            <Typography sx={{ fontWeight: 700, color: "#494D4A" }}>
              Bakery
            </Typography>
          </Box>
          <Box sx={{ py: 1 }}>
            <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
              Average Monthly electricity cost
            </Typography>
            <Typography sx={styles.kyc.bodyText}>26/01/2020</Typography>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};

export const Collateral = () => {
  const bridgeLoan = useSelector((state) => state.bridgeLoan);
  const defaultTag =
    bridgeLoan?.loan?.user?.firstName +
    "_" +
    bridgeLoan?.loan?.user?.lastName +
    "_";

  const pdfFiles = bridgeLoan?.loan?.signedDocumentsUrls;

  const handleDownloadFile = (fileUrl) => {
    // Create a hidden link element
    const link = document.createElement("a");
    link.style.display = "none";
    document.body.appendChild(link);

    // Set the link's href to the file URL
    link.href = fileUrl;

    // Set the download attribute with the desired file name
    link.download = "downloaded_file.pdf"; // You can customize the file name here

    // Trigger a click on the link to start the download
    link.click();

    // Remove the link element from the DOM
    document.body.removeChild(link);
  };

  const [showUpload, setShowUpload] = useState(false);

  const data = {};
  return (
    <Box
      sx={{
        display: "flex",
        pb: 36,
      }}
    >
      <Box>
        <Box sx={{ width: "100%", height: "100%" }}>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 10 }}
          >
            <Grid xs={6}>
              <Box>
                <Box>
                  <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
                    Type of collateral
                  </Typography>
                  <Typography sx={{ fontWeight: 700, color: "#494D4A" }}>
                    House
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid xs={6}>
              <Box>
                <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
                  Value of collateral
                </Typography>
                <Typography
                  sx={{ ...styles.kyc.bodyText, textTransform: "lowercase" }}
                >
                  ₦200,000
                </Typography>
              </Box>
            </Grid>
            <Grid xs={6}>
              <Box>
                <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
                  Certificate of ownership
                </Typography>
                <Box
                  sx={{
                    border: 1,
                    py: 1,
                    px: 2,
                    borderColor: "#DFF3E7",
                    borderRadius: 2,
                    backgroundColor: "#F5FAF7",
                    width: "100%",
                    alignItems: "left",
                    cursor: "pointer",
                    display: "flex",
                    justifyontent: "center",
                    rowGap: "10px",
                  }}
                  // onClick={() => handleDownloadFile(file)}
                >
                  <IconButton edge="start">
                    <InsertDriveFileOutlinedIcon />
                  </IconButton>{" "}
                  <Typography
                    sx={{
                      fontWeight: 600,
                      color: " #000000",
                      mt: 1,
                      size: "14px",
                    }}
                  >
                    20230101.pdf
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid xs={6}>
              <Box>
                <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
                  Picture
                </Typography>
                <Box
                  sx={{
                    border: 1,
                    py: 1,
                    px: 2,
                    borderColor: "#DFF3E7",
                    borderRadius: 2,
                    backgroundColor: "#F5FAF7",
                    width: "100%",
                    alignItems: "left",
                    cursor: "pointer",
                    display: "flex",
                    // justifyContent: "center",
                    // justifyItems:"center",
                    // justifySelf:"center",
                    rowGap: "10px",
                  }}
                  // onClick={() => handleDownloadFile(file)}
                >
                  <IconButton edge="start">
                    <InsertDriveFileOutlinedIcon />
                  </IconButton>{" "}
                  <Typography
                    sx={{
                      fontWeight: 600,
                      mt: 1,
                      color: " #000000",
                      size: "14px",
                    }}
                  >
                    Cheque.pdf
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export const Document = () => {
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          borderBottom: 1,
          pb: 4,
          borderBottomColor: "#F0F1ED",
        }}
      >
        <Box>
          <Box sx={{ width: "100%" }}>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 5 }}
            >
              <Grid xs={6}>
                <Box>
                  <Box>
                    <Typography
                      sx={{ fontWeight: 400, color: "#62635E", py: 1 }}
                    >
                      Business Name
                    </Typography>
                    <Typography sx={{ fontWeight: 700, color: "#494D4A" }}>
                      Kate’s Bakery
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid xs={6}>
                <Box>
                  <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
                    Type of Business
                  </Typography>
                  <Typography
                    sx={{ ...styles.kyc.bodyText, textTransform: "lowercase" }}
                  >
                    Limited Liability
                  </Typography>
                </Box>
              </Grid>
              <Grid xs={6}>
                <Box>
                  <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
                    Business Email
                  </Typography>
                  <Typography sx={{ fontWeight: 700, color: "#494D4A" }}>
                    Katiesgroup@gmail.com
                  </Typography>
                </Box>
              </Grid>
              <Grid xs={6}>
                <Box>
                  <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
                    Business Phone
                  </Typography>
                  <Typography sx={{ fontWeight: 700, color: "#494D4A" }}>
                    09012345678
                  </Typography>
                </Box>
              </Grid>
              <Grid xs={6}>
                <Box>
                  <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
                    Certificate of ownership
                  </Typography>
                  <Box
                    sx={{
                      border: 1,
                      py: 1,
                      px: 2,
                      borderColor: "#DFF3E7",
                      borderRadius: 2,
                      backgroundColor: "#F5FAF7",
                      width: "80%",
                      alignItems: "left",
                      cursor: "pointer",
                      display: "flex",
                      justifyontent: "center",
                      rowGap: "10px",
                    }}
                    // onClick={() => handleDownloadFile(file)}
                  >
                    <IconButton edge="start">
                      <InsertDriveFileOutlinedIcon />
                    </IconButton>{" "}
                    <Typography
                      sx={{
                        fontWeight: 600,
                        color: " #000000",
                        mt: 1,
                        size: "14px",
                      }}
                    >
                      20230101.pdf
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid xs={6}>
                <Box>
                  <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
                    Certificate of ownership
                  </Typography>
                  <Box
                    sx={{
                      border: 1,
                      py: 1,
                      px: 2,
                      borderColor: "#DFF3E7",
                      borderRadius: 2,
                      backgroundColor: "#F5FAF7",
                      width: "80%",
                      alignItems: "left",
                      cursor: "pointer",
                      display: "flex",
                      justifyontent: "center",
                      rowGap: "10px",
                    }}
                    // onClick={() => handleDownloadFile(file)}
                  >
                    <IconButton edge="start">
                      <InsertDriveFileOutlinedIcon />
                    </IconButton>{" "}
                    <Typography
                      sx={{
                        fontWeight: 600,
                        color: " #000000",
                        mt: 1,
                        size: "14px",
                      }}
                    >
                      20230101.pdf
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid xs={6}>
                <Box>
                  <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
                    Certificate of ownership
                  </Typography>
                  <Box
                    sx={{
                      border: 1,
                      py: 1,
                      px: 2,
                      borderColor: "#DFF3E7",
                      borderRadius: 2,
                      backgroundColor: "#F5FAF7",
                      width: "80%",
                      alignItems: "left",
                      cursor: "pointer",
                      display: "flex",
                      justifyontent: "center",
                      rowGap: "10px",
                    }}
                    // onClick={() => handleDownloadFile(file)}
                  >
                    <IconButton edge="start">
                      <InsertDriveFileOutlinedIcon />
                    </IconButton>{" "}
                    <Typography
                      sx={{
                        fontWeight: 600,
                        color: " #000000",
                        mt: 1,
                        size: "14px",
                      }}
                    >
                      20230101.pdf
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid xs={6}>
                <Box>
                  <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
                    Certificate of ownership
                  </Typography>
                  <Box
                    sx={{
                      border: 1,
                      py: 1,
                      px: 2,
                      borderColor: "#DFF3E7",
                      borderRadius: 2,
                      backgroundColor: "#F5FAF7",
                      width: "80%",
                      alignItems: "left",
                      cursor: "pointer",
                      display: "flex",
                      justifyontent: "center",
                      rowGap: "10px",
                    }}
                    // onClick={() => handleDownloadFile(file)}
                  >
                    <IconButton edge="start">
                      <InsertDriveFileOutlinedIcon />
                    </IconButton>{" "}
                    <Typography
                      sx={{
                        fontWeight: 600,
                        color: " #000000",
                        mt: 1,
                        size: "14px",
                      }}
                    >
                      20230101.pdf
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          borderBottom: 1,
          pb: 4,
          borderBottomColor: "#F0F1ED",
        }}
      >
        <Box>
          <Box sx={{ width: "100%" }}>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 10 }}
            >
              <Grid xs={6}>
                <Box>
                  <Box>
                    <Typography
                      sx={{ fontWeight: 400, color: "#62635E", py: 1 }}
                    >
                      Business Name
                    </Typography>
                    <Typography sx={{ fontWeight: 700, color: "#494D4A" }}>
                      Kate’s Bakery
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid xs={6}>
                <Box>
                  <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
                    Type of Business
                  </Typography>
                  <Typography
                    sx={{ ...styles.kyc.bodyText, textTransform: "lowercase" }}
                  >
                    Limited Liability
                  </Typography>
                </Box>
              </Grid>

              <Grid xs={6}>
                <Box>
                  <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
                    Certificate of ownership
                  </Typography>
                  <Box
                    sx={{
                      border: 1,
                      py: 1,
                      px: 2,
                      borderColor: "#DFF3E7",
                      borderRadius: 2,
                      backgroundColor: "#F5FAF7",
                      width: "80%",
                      alignItems: "left",
                      cursor: "pointer",
                      display: "flex",
                      justifyontent: "center",
                      rowGap: "10px",
                    }}
                    // onClick={() => handleDownloadFile(file)}
                  >
                    <IconButton edge="start">
                      <InsertDriveFileOutlinedIcon />
                    </IconButton>{" "}
                    <Typography
                      sx={{
                        fontWeight: 600,
                        color: " #000000",
                        mt: 1,
                        size: "14px",
                      }}
                    >
                      20230101.pdf
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid xs={6}>
                <Box>
                  <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
                    Certificate of ownership
                  </Typography>
                  <Box
                    sx={{
                      border: 1,
                      py: 1,
                      px: 2,
                      borderColor: "#DFF3E7",
                      borderRadius: 2,
                      backgroundColor: "#F5FAF7",
                      width: "80%",
                      alignItems: "left",
                      cursor: "pointer",
                      display: "flex",
                      justifyontent: "center",
                      rowGap: "10px",
                    }}
                    // onClick={() => handleDownloadFile(file)}
                  >
                    <IconButton edge="start">
                      <InsertDriveFileOutlinedIcon />
                    </IconButton>{" "}
                    <Typography
                      sx={{
                        fontWeight: 600,
                        color: " #000000",
                        mt: 1,
                        size: "14px",
                      }}
                    >
                      20230101.pdf
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

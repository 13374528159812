import React from "react";
import {
  Box,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
} from "@mui/material";
import { styles } from "../../../common/css/styles";
import { useNavigate } from "react-router-dom";
import { businessLoanFilters } from "./constants/businessLoanFilters";
import { DeadState } from "../../../common/components/DeadState";
import { PageNavigation } from "../../../common/components/PageNavigation";
import { toTitleCase } from "../../../common/constants/utility";

export const BusinessLoanTable = ({
  businessLoans,
  filterOption,
  page,
  handlePageChange,
  handleView,
}) => {
  const navigate = useNavigate();
  const loans = businessLoans.loans;

  const columns = [
    { id: "uid", label: "UID", minWidth: 10, visible: true },
    { id: "companyName", label: "Company Name", minWidth: 50, visible: true },
    { id: "companyType", label: "Company Type", minWidth: 50, visible: true },
    { id: "amount", label: "Amount (₦)", minWidth: 50, visible: true },
    { id: "tenure", label: "Tenure", minWidth: 50, visible: true },
    { id: "interest", label: "Interest (₦)", minWidth: 50, visible: false },
    {
      id: "status",
      label: "Status",
      minWidth: 50,
      visible:
        filterOption === businessLoanFilters.ALL ||
        filterOption === businessLoanFilters.VERIFIED
          ? true
          : false,
    },
    {
      id: "view",
      label: "View",
      minWidth: 50,
      visible: true,
      // filterOption === businessLoanFilters.ALL ||
      // filterOption === businessLoanFilters.PROSPECT
      //   ? false
      //   : true,
    },
  ];

  const getStatusDisplay = (status) => {
    switch (status) {
      case businessLoanFilters.APPROVED:
        return (
          <Typography sx={styles.table.text.customerStatus.prospect}>
            Approved
          </Typography>
        );
      case businessLoanFilters.OFFERED:
        return (
          <Typography sx={styles.table.text.customerStatus.offered}>
            Offered
          </Typography>
        );
      case businessLoanFilters.OFFER_REJECTED:
        return (
          <Typography sx={styles.cardTopStatus.rejected}>
            Offer Rejected
          </Typography>
        );
      case businessLoanFilters.DEFAULTING:
        return (
          <Typography sx={styles.table.text.customerStatus.rejected}>
            Defaulting
          </Typography>
        );
      case businessLoanFilters.REQUEST_DECLINED:
        return (
          <Typography sx={styles.cardTopStatus.rejected}>
            Request Declined
          </Typography>
        );
      case businessLoanFilters.ACTIVE:
        return (
          <Typography sx={styles.table.text.customerStatus.verified}>
            Active
          </Typography>
        );
      case businessLoanFilters.COMPLETED:
        return (
          <Typography sx={styles.table.text.customerStatus.completed}>
            Completed
          </Typography>
        );
      case businessLoanFilters.NTU:
        return (
          <Typography sx={styles.table.text.customerStatus.prospect}>
            NTU
          </Typography>
        );
      case businessLoanFilters.LOAN_REJECTED:
        return (
          <Typography sx={styles.table.text.customerStatus.loanRejected}>
            Loan Rejected
          </Typography>
        );
      case businessLoanFilters.OFFER_ACCEPTED:
        return (
          <Typography sx={styles.table.text.customerStatus.accepted}>
            Offer Accepted
          </Typography>
        );
      case businessLoanFilters.PAY_INTEREST:
        return (
          <Typography sx={styles.table.text.customerStatus.prospect}>
            Pay Interest
          </Typography>
        );
      case businessLoanFilters.PENDING_AUTHORIZATION:
        return (
          <Typography sx={styles.table.text.customerStatus.pendingAuth}>
            Pending Authorization
          </Typography>
        );
      case businessLoanFilters.PENDING_DISBURSEMENT:
        return (
          <Typography sx={styles.table.text.customerStatus.pendingDisbursement}>
            Pending Disbursement
          </Typography>
        );
      case businessLoanFilters.PENDING_APPROVAL:
        return (
          <Typography sx={styles.table.text.customerStatus.pendingReview}>
            Pending Approval
          </Typography>
        );
      case businessLoanFilters.PENDING_LIEN:
        return (
          <Typography sx={styles.table.text.customerStatus.prospect}>
            Pending Lien
          </Typography>
        );
      case businessLoanFilters.PENDING_OFFER:
        return (
          <Typography sx={styles.table.text.customerStatus.pendingOffer}>
            Pending Offer
          </Typography>
        );
      case businessLoanFilters.PENDING_RECOMMENDATION:
        return (
          <Typography
            sx={styles.table.text.customerStatus.pendingRecommendation}
          >
            Pending Recommendation
          </Typography>
        );
      case businessLoanFilters.UNDER_REVIEW:
        return (
          <Typography sx={styles.table.text.customerStatus.prospect}>
            Under Review
          </Typography>
        );

      default:
        break;
    }
  };

  return (
    <Box>
      <TableContainer
        component={Paper}
        sx={{
          maxHeight: 400,
          boxShadow: "none",
          mt: 2,
        }}
      >
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow
              sx={{
                "& .MuiTableCell-head": {
                  color: "#000",
                  backgroundColor: "#fff",
                  borderTop: 0.5,
                  borderBottom: 0.5,
                  borderColor: "#B5B5B5",
                  py: 2,
                },
              }}
            >
              {columns
                ?.filter((column) => column.visible === true)
                .map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    <Typography
                      noWrap
                      sx={{
                        color: "#000",
                        backgroundColor: "#fff",
                        fontSize: 13,
                        fontWeight: 600,
                      }}
                    >
                      {column.label}
                    </Typography>
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {businessLoans.loading ? (
              <TableCell
                colSpan={columns.length}
                sx={{ textAlign: "center", borderBottom: 0, pt: 4 }}
              >
                <CircularProgress />
              </TableCell>
            ) : loans?.length > 0 ? (
              loans?.map((loan) => (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={loan.loanUid}
                  sx={{
                    "& .MuiTableCell-body": {
                      color: "#000",
                      backgroundColor: "#fff",
                      borderBottom: 0.5,
                      borderColor: "#F7F8F4",
                    },
                  }}
                >
                  <TableCell align="left">
                    <Typography sx={styles.table.text}>
                      {loan.loanUid}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography sx={styles.table.text}>
                      {loan.companyName}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography sx={styles.table.text}>
                      {toTitleCase(
                        loan.companyType.toLowerCase().replace(/_/g, " ")
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography sx={styles.table.text}>
                      {parseFloat(loan.loanAmount).toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                      })}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography sx={styles.table.text}>
                      {loan.repaymentPeriod === 1
                        ? `${loan.repaymentPeriod} Month`
                        : `${loan.repaymentPeriod} Months`}
                    </Typography>
                  </TableCell>
                  {columns?.find((column) => column.id === "interest")
                    ?.visible ? (
                    <TableCell align="left">
                      <Typography sx={styles.table.text}>
                        {loan.interest}
                      </Typography>
                    </TableCell>
                  ) : null}
                  {columns?.find((column) => column.id === "status")
                    ?.visible ? (
                    <TableCell align="left">
                      {getStatusDisplay(loan.status)}
                    </TableCell>
                  ) : null}
                  {columns?.find((column) => column.id === "view")?.visible ? (
                    <TableCell align="left">
                      <Button
                        variant="outlined"
                        onClick={() => handleView(loan.loanUid)}
                        sx={styles.table.text.viewButton}
                      >
                        View
                      </Button>
                    </TableCell>
                  ) : null}
                </TableRow>
              ))
            ) : (
              <TableCell colSpan={columns?.length} sx={{ textAlign: "center" }}>
                <DeadState />
              </TableCell>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {!businessLoans.loading && loans?.length > 0 ? (
        <PageNavigation
          page={page}
          totalRecords={businessLoans.totalRecords}
          handlePageChange={handlePageChange}
        />
      ) : (
        <></>
      )}
    </Box>
  );
};

import React from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
} from "@mui/material";
import { styles } from "../../../common/css/styles";
import { bridgeFinanceFilters } from "../../bridgeFinance/constants/bridgeFinanceFilters";
import { DeadState } from "../../../common/components/DeadState";
import { customerFilters } from "../../customers/constants/customerFilters";
import { format } from "date-fns";

const getStatusDisplay = (status) => {
  switch (status) {
    case bridgeFinanceFilters.PENDING_RECOMMENDATION:
      return (
        <Typography sx={styles.bridgeTable.status.pendingVerification}>
          Pending Recommendation
        </Typography>
      );
    case bridgeFinanceFilters.PENDING_OFFER:
      return (
        <Typography sx={styles.bridgeTable.status.pendingOffer}>
          Pending Offer
        </Typography>
      );
    case customerFilters.PENDING:
      return (
        <Typography sx={styles.bridgeTable.status.rejected}>
          Pending Verification
        </Typography>
      );
    default:
      break;
  }
};

export const CollectionsTable = ({ overview }) => {
  const loans = overview.pendingLoans;
  const customers = overview.pendingCustomers;
  const columns = [
    { id: "uid", label: "Loan UID", minWidth: 20, visible: true },
    {
      id: "status",
      label: "Status",
      minWidth: 20,
      visible: true,
    },
    { id: "type", label: "Loan Type", minWidth: 20, visible: true },
    { id: "source", label: "Source", minWidth: 20, visible: true },
    { id: "processor", label: "Processor", minWidth: 20, visible: true },
    {
      id: "amount",
      label: "Amount (₦)",
      minWidth: 20,
      visible: true,
    },
    { id: "date", label: "Next Repayment Date", minWidth: 20, visible: true },
    { id: "view", label: "View", minWidth: 20, visible: true },
  ];

  return (
    <Box>
      <TableContainer
        component={Paper}
        sx={{
          maxHeight: 400,
          boxShadow: "none",
          mt: 2,
        }}
      >
        <Table size="small">
          <TableHead>
            <TableRow
              sx={{
                "& .MuiTableCell-head": {
                  color: "#000",
                  backgroundColor: "#fff",
                  fontSize: 13,
                  borderTop: 0.5,
                  borderBottom: 0.5,
                  borderColor: "#B5B5B5",
                  py: 2,
                },
              }}
            >
              {columns
                .filter((column) => column.visible === true)
                .map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    <Typography
                      noWrap
                      sx={{
                        color: "#000",
                        backgroundColor: "#fff",
                        fontSize: 13,
                        fontWeight: 600,
                      }}
                    >
                      {column.label}
                    </Typography>
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {overview.loadingPendingLoans ? (
              <TableCell
                colSpan={columns.length}
                sx={{ textAlign: "center", borderBottom: 0, pt: 4 }}
              >
                <CircularProgress />
              </TableCell>
            ) : loans.length > 0 ? (
              loans.map((loan) => (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={loan.uid}
                  sx={{
                    "& .MuiTableCell-body": {
                      color: "#000",
                      backgroundColor: "#fff",
                      border: "none",
                    },
                  }}
                >
                  <TableCell align="left">
                    <Typography sx={styles.table.text}>
                      {loan.user.firstName}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography sx={styles.table.text}>
                      {loan.user.lastName}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography
                      sx={{
                        ...styles.table.text,
                        textTransform: "lowercase",
                      }}
                    >
                      {loan.user.email}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography sx={styles.table.text}>
                      {parseFloat(loan.loanAmount).toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                      })}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography
                      sx={{
                        ...styles.table.text,
                        textTransform: "lowercase",
                        justifyContent: "center",
                      }}
                    >
                      {loan.loanTenure}
                    </Typography>
                  </TableCell>

                  <TableCell align="left">
                    <Typography sx={styles.table.text}>
                      {loan.createdAt &&
                        format(
                          new Date(loan.createdAt),
                          "dd/MM/yyyy hh:mm:ss aa"
                        )}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography noWrap sx={styles.table.text}>
                      {getStatusDisplay(loan.status)}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableCell colSpan={columns.length} sx={{ textAlign: "center" }}>
                <DeadState />
              </TableCell>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

import { client } from ".";

const size = process.env.REACT_APP_PAGESIZE;

export const getUsers = (page, filterOption) => {
  return client.get(
    `admins?page=${page}&size=${size}&filterBy=${filterOption}`
  );
};

export const getUser = (userUid) => {
  return client.get(`users/${userUid}`);
};

export const addUser = ({
  firstName,
  lastName,
  email,
  phoneNumber,
  role,
  password,
  confirmPassword,
  gender,
}) => {
  return client.post("user/admin/admin/create", {
    firstName,
    lastName,
    email,
    phoneNumber,
    role,
    password,
    confirmPassword,
    gender,
  });
};

export const search = (page, searchTerm) => {
  return client.get(`admins?page=${page}&size=${size}&search=${searchTerm}`);
};

export const updateUser = ({
  userUid,
  firstName,
  lastName,
  email,
  gender,
  phoneNumber,
  dateOfBirth,
}) => {
  return client.patch("admin/personal-info", {
    userUid,
    firstName,
    lastName,
    email,
    gender,
    phoneNumber,
    dateOfBirth,
  });
};

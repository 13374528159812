export const bridgeFinanceFilters = {
  ALL: "",
  PENDING_RECOMMENDATION: "PENDING_RECOMMENDATION",
  PENDING_OFFER: "PENDING_OFFER",
  DECLINED: "REQUEST_DECLINED",
  OFFERED: "OFFERED",
  OFFER_REJECTED: "OFFER_REJECTED",
  PENDING_LIEN: "PENDING_LIEN",
  PAY_INTEREST: "PAY_INTEREST",
  ACTIVE: "ACTIVE",
  DEFAULTING: "DEFAULTING",
  NTU: "NTU",
  COMPLETED: "COMPLETED",
  PENDING_DISBURSEMENT: "PENDING_DISBURSEMENT",
};

import { ArrowBackIos } from "@mui/icons-material";
import PersonIcon from "@mui/icons-material/Person";

import {
  Avatar,
  Box,
  CircularProgress,
  Fade,
  Grid,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SectionHeaderWithButton } from "../../common/components/SectionHeader";
import { styles } from "../../common/css/styles";
import { getRoundedAmount, parseStringToObject } from "../../common/util";
import { retailFilters } from "./constants/retailFilters";
import { format } from "date-fns";
import { useQuery } from "@tanstack/react-query";
import { getLoan, getLoanStatById } from "../../common/api/loans";
import { WhiteBox } from "../../common/components/WhiteBox";
import LoanRepaymentHistory from "./LoanRepaymentHistory";
import { getSmeLoan } from "../../common/api/sme";

const getStatusDisplay = (status) => {
  switch (status) {
    case retailFilters.COMPLETED:
      return (
        <Typography sx={styles.cardTopStatus.verified}>Completed</Typography>
      );
    case retailFilters.ACTIVE:
      return <Typography sx={styles.cardTopStatus.active}>Active</Typography>;
    case retailFilters.PENDING_RECOMMENDATION:
      return (
        <Typography sx={styles.cardTopStatus.pendingRecommendation}>
          Pending Recommendation
        </Typography>
      );
    case retailFilters.PENDING_OFFER:
      return (
        <Typography sx={styles.cardTopStatus.pending}>Pending Offer</Typography>
      );
    case retailFilters.PENDING_DISBURSEMENT:
      return (
        <Typography sx={styles.cardTopStatus.pendingDisbursement}>
          Pending Disbursement
        </Typography>
      );
    case retailFilters.DECLINED:
      return <Typography sx={styles.cardTopStatus.denied}>Declined</Typography>;
    case retailFilters.DEFAULTING:
      return (
        <Typography sx={styles.cardTopStatus.denied}>Defaulting</Typography>
      );
    case retailFilters.OFFERED:
      return <Typography sx={styles.cardTopStatus.offered}>Offered</Typography>;
    case retailFilters.OFFER_REJECTED:
      return (
        <Typography sx={styles.cardTopStatus.rejected}>
          Offer Rejected
        </Typography>
      );
    case retailFilters.NTUS:
      return <Typography sx={styles.cardTopStatus.ntu}>NTU</Typography>;
    default:
      break;
  }
};

const loanLabels = {
  RETAIL: "Retail Loan ",
  BUSINESS: "Business Loan",
  ASSET: "Asset Financing",
  BRIDGE_FINANCING: "Bridge Financing",
};

export const LoanPreview = () => {
  const { loanid, loantype } = useParams();

  const { data, isLoading } = useQuery({
    queryKey: [loanid],
    queryFn: () =>
      loantype === "retail" ? getLoan(loanid) : getSmeLoan(loanid),
    refetchOnWindowFocus: false,
  });

  const loan = data?.data?.data ?? {};

  const { data: loanStatData, isLoading: loanStatDataIsloading } = useQuery({
    queryKey: [loanid, "loanStat"],
    queryFn: () => getLoanStatById(loanid),

    refetchOnWindowFocus: false,
  });

  const loanStat = loanStatData?.data?.data ?? {};

  const {
    amountOutstanding,
    amountRepaid,
    nextDueDate,
    penalty,
    totalRepayment,
  } = loanStat;

  const kycData =
    !isLoading && loan && parseStringToObject(loan.customerKycObject);

  const loanDetails = [
    {
      label: `Penalty(${loan.loanOfferInterestRate}%)`,
      value: penalty ? getRoundedAmount(penalty) : "₦0",
      tooltip: "Defaulting penalty fee",
    },
    {
      label: "Next Due Date",
      value: nextDueDate ? format(new Date(nextDueDate), "dd/MM/yyyy") : "N/A",
      tooltip: "",
    },
    {
      label: "Amount repaid",
      value: amountRepaid ? getRoundedAmount(amountRepaid) : "₦0",
      tooltip: "",
    },
    // {
    //   label: "Outstanding",
    //   value: pendingAmount ? getRoundedAmount(pendingAmount) : "N/A",
    //   tooltip: "",
    // },

    {
      label: "Pending Payment",
      value: amountOutstanding ? getRoundedAmount(amountOutstanding) : "N/A",
      tooltip: "",
    },
    {
      label: "Total Repayment",
      value: totalRepayment ? getRoundedAmount(totalRepayment) : "₦0",
      tooltip: "",
    },
  ];

  const navigate = useNavigate();

  const handleBackToTable = () => navigate(-1);

  if (isLoading || loanStatDataIsloading)
    return (
      <WhiteBox>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "70vh",
          }}
        >
          <CircularProgress sx={{ alignItems: "center" }} />
        </Box>
      </WhiteBox>
    );

  const imgUrl = loantype === "sme" ? loan.companyLogo : kycData?.photoUrl;

  return (
    <>
      <SectionHeaderWithButton
        headerText={
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: 32,
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              width: "fit-content",
            }}
            onClick={handleBackToTable}
          >
            <Tooltip
              title="Back to loans table"
              arrow
              placement="top-start"
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
            >
              {/* <IconButton
            aria-label="back"
            onClick={handleBackToTable}
            sx={{ mb: 0.8 }}
          > */}
              <ArrowBackIos
                sx={{ fontSize: 30, color: "#000", mr: "5px", ml: "0px" }}
              />
              {/* </IconButton> */}
            </Tooltip>
            Loan History
          </Typography>
        }
      />
      <Box
        sx={{
          p: 2,
          pt: 4,
          backgroundColor: "#fff",
          width: "96%",
          borderRadius: 2,
          mb: 2,
        }}
      >
        <Grid
          container
          sx={{
            display: {
              // xs: "none",
              md: "block",
              // backgroundColor: "yellow"
            },
            borderBottom: 1,
            mt: 2,
            pb: 3,
            borderBottomColor: "#F0F1ED",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Stack>
              <Box sx={{ display: "flex" }}>
                {imgUrl ? (
                  <Avatar
                    src={imgUrl}
                    sx={{
                      width: 80,
                      height: 80,
                      backgroundColor: "#19AD421A",
                      mr: 3,
                    }}
                  />
                ) : (
                  <Avatar sx={{ width: 80, height: 80 }}>
                    <PersonIcon />
                  </Avatar>
                )}
                <Stack sx={{}}>
                  <Typography
                    sx={{
                      fontWeight: 700,
                      fontSize: 28,
                      textTransform: "capitalize",
                    }}
                  >
                    {
                      loanLabels[
                        loantype === "sme" ? loan.smeLoanType : loan.type
                      ]
                    }
                  </Typography>
                  {getStatusDisplay(loan?.status)}
                </Stack>
              </Box>
              {/* <Typography sx={{ mt: 1.2 }}>{setStatus()}</Typography> */}
            </Stack>
            <Box>
              <Stack>
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontSize: 24,
                    color: "#3C3D39",
                    textAlign: "Right",
                  }}
                >
                  {loan?.loanOfferAmount?.toLocaleString("en-NG", {
                    style: "currency",
                    currency: "NGN",
                  }) ?? "₦0.00"}{" "}
                  <Typography
                    component="sup"
                    sx={{
                      display: "inline",
                      fontWeight: 700,
                      fontSize: 10,
                      color: "#3C3D39",
                      textAlign: "Right",
                    }}
                  >
                    ({loan.loanOfferInterestRate}%/Month)
                  </Typography>
                </Typography>
                <Typography
                  sx={{
                    fontSize: 11,
                    fontWeight: 600,
                    color: "#62635E",
                    textAlign: "Right",
                    mr: 0.3,
                  }}
                >
                  <span style={{ color: "#27AE60" }}> Duration:</span>{" "}
                  {loan?.loanTenure}{" "}
                  {loan?.loanTenure === 1 ? "month" : "months"}
                </Typography>
              </Stack>
            </Box>
          </Box>
        </Grid>

        <Box
          item
          sx={{
            width: "100%",

            borderRadius: 2,
            my: 2,
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between", pb: 2 }}>
            <Typography sx={{ fontWeight: 600, fontSize: 20 }}>
              Loan Details
            </Typography>
          </Box>

          <Grid container>
            {loanDetails.map((detail) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                xl={2.4}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: 110,
                  p: 1,
                }}
              >
                <Box
                  sx={{
                    border: 1,
                    borderRadius: 2,
                    borderColor: "#00000014",
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Stack sx={{ px: 1 }}>
                    <Typography sx={styles.overview.statistics.info}>
                      {detail.label}
                    </Typography>
                    <Typography sx={styles.overview.statistics.number}>
                      {detail.value ?? "N/A"}
                    </Typography>
                  </Stack>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>

      <Box
        sx={{
          p: 2,
          pt: 4,
          backgroundColor: "#fff",
          width: "96%",
          borderRadius: 2,
          mb: 2,
        }}
      >
        <LoanRepaymentHistory loanId={loan.loanUid ?? loan.uid} />
      </Box>
    </>
  );
};

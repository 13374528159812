import { Box, Button } from "@mui/material";
import React, { useState } from "react";
import { styles } from "../../../../common/css/styles";
import { loansDetailsFilters } from "../../constants/retailFilters";
import { bridgeFinanceExtraStage, stage } from "./stages/index";
import { Bank } from "./stages/Bank";
import { NIN } from "./stages/NIN";
import { PersonalDetails } from "./stages/PersonalDetails";
import { Residence } from "./stages/Residence";
import { Employment } from "./stages/Employment";
import { NextOfKin } from "./stages/NextOfKin";
import { LienDocs } from "./stages/LienDocs";
import { InternationalPassport } from "./stages/InternationalPassport";
import { loanStatusFilters } from "../../constants/loanFilters";
import { loanType } from "../../../smeLoans/constants/loanType";
import { BankStatement } from "./stages/BankStatement";

export const Personal = ({ customer, data, bankData, loan, refetch }) => {
  const preLienStatuses = [
    loanStatusFilters.PENDING_APPROVAL,
    loanStatusFilters.PENDING_AUTHORIZATION,
    loanStatusFilters.PENDING_OFFER,
    loanStatusFilters.PENDING_RECOMMENDATION,
  ];

  const [activeFilter, setActiveFilter] = useState(
    loansDetailsFilters.PERSONAL
  );

  const bridgeFinanceExtrafilters = [
    {
      label: "International Passport",
      value: bridgeFinanceExtraStage.PASSPORT,
      visible: true,
    },
    {
      label: "Lien Docs",
      value: bridgeFinanceExtraStage.LIEN,
      visible: true,
    },
  ];

  const filters = [
    { label: "Personal", value: stage.PERSONAL, visible: true },
    { label: "Residence", value: stage.RESIDENCE, visible: true },
    {
      label: "Employment",
      value: stage.EMPLOYMENT,
      visible: true,
    },
    { label: "Bank Info", value: stage.BANK, visible: true },
    {
      label: "Next Of Kin",
      value: stage.NEXT_OF_KIN,
      visible: true,
    },
    {
      label: "ID Documents",
      value: stage.IDENTIFICATION,
      visible: true,
    },
    {
      label: "Bank Statement",
      value: stage.BANK_STATEMENT,
      visible: true,
    },
  ];

  if (
    !preLienStatuses.includes(loan?.status) &&
    loan.type === loanType.BRIDGE_FINANCING
  )
    filters.push(...bridgeFinanceExtrafilters);

  const handleFilter = (filter) => {
    setActiveFilter(filter);
  };

  const getPersonalStage = () => {
    switch (activeFilter) {
      case stage.PERSONAL:
        return <PersonalDetails customer={customer} />;
      case stage.RESIDENCE:
        return <Residence data={data} />;
      case stage.EMPLOYMENT:
        return <Employment data={data} />;
      case stage.BANK:
        return <Bank customer={customer} data={data} bankData={bankData} />;
      case stage.IDENTIFICATION:
        return <NIN data={data} />;
      case stage.BANK_STATEMENT:
        return <BankStatement data={data} />;
      case stage.NEXT_OF_KIN:
        return <NextOfKin data={data} />;
      case bridgeFinanceExtraStage.LIEN:
        return (
          <LienDocs
            loan={loan}
            refetch={refetch}
            data={data}
            bankData={bankData}
          />
        );
      case bridgeFinanceExtraStage.PASSPORT:
        return <InternationalPassport loan={loan} refetch={refetch} />;
      default:
        return <PersonalDetails customer={customer} />;
    }
  };

  const moreStyles = {
    fontSize: { xs: 10, lg: 12, xl: 14 },
    p: 2,
    // whiteSpace: "nowrap",
  };

  return (
    <Box>
      <Box sx={{ py: 3, display: "flex" }}>
        {filters
          .filter((filter) => filter.visible)
          .map((filter) => (
            <Button
              onClick={() => handleFilter(filter.value)}
              variant="outlined"
              sx={
                activeFilter === filter.value
                  ? {
                      ...styles.tableFilter.active,
                      ...moreStyles,
                    }
                  : {
                      ...styles.tableFilter.inactive,
                      ...moreStyles,
                    }
              }
            >
              {filter.label}
            </Button>
          ))}
      </Box>
      {getPersonalStage()}
    </Box>
  );
};

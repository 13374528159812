import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { refreshUserToken } from "../../common/api/auth";
import { shouldRefresh } from "../../common/util";
import { handleErrorAlt } from "../../common/constants/utility";

export const useManageSession = () => {
  const dispatch = useDispatch();
  const refreshToken = async () => {
    try {
      const token = localStorage.getItem("refreshToken");
      localStorage.setItem("isRefreshing", true);
      const { data } = await refreshUserToken(token);
      localStorage.removeItem("isRefreshing");
      if (data) {
        localStorage.setItem("userToken", data?.data?.access_token);
        localStorage.setItem("refreshToken", data?.data?.refresh_token);
      }
    } catch (error) {
      localStorage.removeItem("isRefreshing");

      handleErrorAlt(error, dispatch);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const isRefreshing = localStorage.getItem("isRefreshing");

      if (shouldRefresh() && !isRefreshing) {
        refreshToken();
      }
    }, 10000);

    return () => {
      localStorage.removeItem("isRefreshing");
      clearInterval(interval);
    };
  }, []);
};

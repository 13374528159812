import {
  Grid,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
  Typography,
  Stack,
  Box,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { styles } from "../../../../common/css/styles";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { PreviousButton } from "../components/PreviousButton";
import { stage } from "../../../kyc/stages";
import { useDispatch, useSelector } from "react-redux";
import { validateBVN, verifyBankAccount } from "../../../../common/api/bank";
import { submitKyc } from "../../../kyc/kycSlice";
import { DevTool } from "@hookform/devtools";
import { ArrowForward } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";

const defaultValues = {
  bvn: "",
  bankName: "",
  bankSortCode: "",
  bankAccountNumber: "",
  bankAccountName: "",
};

export const AddBank = ({
  handleTabChange,
  customerData,
  handleAddCustomerData,
}) => {
  const checkBVNValidity = async (bvn) => {
    try {
      const response = await validateBVN(customer.uid, bvn);
      if (response.data.status) setBvnValidated(true);
      return await response.data.status;
    } catch (error) {
      return error.response?.data?.message[0];
    }
  };

  const schema = yup.object().shape({
    bvn: yup
      .string()
      .required("BVN is required")
      .min(11)
      .test({
        name: "validateBVN",
        skipAbsent: true,
        test: async (value, ctx) => {
          if (value.length === 11 && !bvnValidated) {
            const response = await checkBVNValidity(value);
            if (response !== true) {
              return ctx.createError({ message: response });
            }
          }
          return true;
        },
      }),
    bankName: yup.string().required("Bank name is required"),
    bankSortCode: yup.string(),
    bankAccountNumber: yup
      .string()
      .required("Bank account number is required")
      .max(10, "Bank account number must be 10 digits"),
    bankAccountName: yup.string().required("Bank account name is required"),
  });

  const {
    handleSubmit,
    control,
    formState: { errors, isValid, isSubmitting },
    watch,
    getValues,
    setValue,
    setError,
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  const [bvnValidated, setBvnValidated] = useState(false);
  const customer = useSelector(({ customer }) => customer.customer);
  const allBanks = useSelector(({ bank }) => bank.allBanks);
  const dispatch = useDispatch();
  const bankAccountNumber = watch("bankAccountNumber");
  const bankName = watch("bankName");

  const onSubmit = (data) => {
    customerData.bank = {
      ...data,
      userUid: customer.uid,
      onboardingStage: stage.BANK,
    };
    handleAddCustomerData(customerData);
    dispatch(submitKyc(customerData.bank));
  };

  const handlePreviousClick = () => {
    handleTabChange(stage.EMPLOYMENT);
  };

  useEffect(() => {
    if (bankName) {
      setValue(
        "bankSortCode",
        allBanks.filter((bank) => bank.name === bankName)[0].code,
        { shouldDirty: true, shouldTouch: true }
      );
    }
  }, [bankName]);

  useEffect(() => {
    if (bankAccountNumber.length === 10) {
      verifyBankAccount(bankAccountNumber, getValues("bankSortCode"))
        .then((response) => {
          setValue("bankAccountName", response.data.data.accountName, {
            shouldDirty: true,
            shouldTouch: true,
            shouldValidate: true,
          });
        })
        .catch((err) => {
          setError("bankAccountNumber", {
            type: "custom",
            message: "Invalid account number",
          });
        });
    }
  }, [bankAccountNumber]);

  useEffect(() => {
    if (customerData.bank) {
      setValue("bvn", customerData.bank.bvn);
      setValue("bankName", customerData.bank.bankName);
      setValue("bankAccountNumber", customerData.bank.bankAccountNumber);
      setValue("bankSortCode", customerData.bank.bankSortCode);
      setValue("bankAccountName", customerData.bank.bankAccountName);
    }
  }, []);

  return (
    <>
      <Grid
        container
        rowSpacing={2}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        mt={1}
        component="form"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid item xs={6}>
          <Typography sx={styles.formFieldHeading}>BVN</Typography>
          <Controller
            name="bvn"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                required
                variant="outlined"
                fullWidth
                size={styles.textfieldSize}
                placeholder="e.g. 12345678901"
                error={!!errors.bvn}
                helperText={errors.bvn?.message}
                sx={styles.textField}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography sx={styles.formFieldHeading}>Bank Name</Typography>
          <Controller
            name="bankName"
            control={control}
            render={({ field }) => (
              <FormControl fullWidth>
                <Select
                  {...field}
                  required
                  size={styles.textfieldSize}
                  sx={styles.selectField}
                >
                  {allBanks.map((bank) => (
                    <MenuItem key={bank.code} value={bank.name}>
                      {bank.name}
                    </MenuItem>
                  ))}
                </Select>
                {errors.bankName ? (
                  <FormHelperText error>
                    {errors?.bankName?.message}
                  </FormHelperText>
                ) : (
                  <></>
                )}
              </FormControl>
            )}
          />
        </Grid>

        <Grid item xs={6}>
          <Typography sx={styles.formFieldHeading}>
            Bank Account Number
          </Typography>
          <Controller
            name="bankAccountNumber"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                required
                variant="outlined"
                fullWidth
                size={styles.textfieldSize}
                placeholder="e.g. 1234567890"
                error={!!errors.bankAccountNumber}
                helperText={errors.bankAccountNumber?.message}
                sx={styles.textField}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography sx={styles.formFieldHeading}>
            Bank Account Name
          </Typography>
          <Controller
            name="bankAccountName"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                required
                variant="outlined"
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
                size={styles.textfieldSize}
                placeholder=" "
                error={!!errors.bankAccountName}
                helperText={errors.bankAccountName?.message}
                sx={styles.textField}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="bankSortCode"
            control={control}
            render={({ field }) => (
              <TextField {...field} sx={{ display: "none" }} />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={1} direction="row" justifyContent="flex-end">
            <PreviousButton handlePreviousClick={handlePreviousClick} />
            <Box>
              <LoadingButton
                variant="contained"
                type="submit"
                endIcon={<ArrowForward />}
                loadingPosition="end"
                disabled={!isValid || isSubmitting}
                loading={isSubmitting}
                sx={{
                  textTransform: "capitalize",
                  height: 40,
                  color: "#fff",
                  boxShadow: "none",
                  fontSize: 16,

                  "&:hover": {
                    boxShadow: "none",
                  },
                }}
              >
                Next
              </LoadingButton>
            </Box>
          </Stack>
        </Grid>
      </Grid>
      <DevTool control={control} />
    </>
  );
};

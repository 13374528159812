import { Box, Typography } from "@mui/material";
import { PieChart } from "@mui/x-charts/PieChart";
import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { useDrawingArea } from "@mui/x-charts/hooks";
// import { EditButton } from "../components/EditButton";
// import { NextOfKinEdit } from "../components/NextOfKinEdit";
// import { customerFilters } from "../../customers/constants/customerFilters";
// import { styles } from "../../../common/css/styles";

const StyledText = styled("text")(({ theme }) => ({
  fill: theme.palette.text.primary,
  textAnchor: "middle",
  dominantBaseline: "central",
  fontSize: 32,
  fontWeight: 700,
}));

const StyledText2 = styled("text")(({ theme }) => ({
  fill: theme.palette.text.primary,
  textAnchor: "middle",
  dominantBaseline: "central",
  fontSize: 14,
  fontWeight: 400,
}));

function PieCenterLabel({ children }) {
  const { width, height, left, top } = useDrawingArea();
  return (
    <>
      <StyledText x={left + width / 2} y={top + height / 2}>
        {children}
      </StyledText>
      <StyledText2 x={left + width / 2} y={(top + height) / 2 + 25}>
        Doing great
      </StyledText2>
    </>
  );
}

function PieLeftLabel({ children }) {
  const { width, height, left, top } = useDrawingArea();
  return (
    <>
      <StyledText2 x={left + width / 2 - 78} y={(top + height) / 2 + 78}>
        {children}
      </StyledText2>
    </>
  );
}

function PieRightLabel({ children }) {
  const { width, height, left, top } = useDrawingArea();
  return (
    <>
      <StyledText2 x={left + width / 2 + 78} y={(top + height) / 2 + 78}>
        {children}
      </StyledText2>
    </>
  );
}
const pieParams = { height: 200, margin: { right: 5 } };

export const Recommendation = ({ customer, data }) => {
  return (
    <>
      <Box
        sx={{
          width: "100%",
          pt: 2,
        }}
      >
        <Box>
          <Box sx={{ display: "flex", mb: 2 }}>
            <Typography
              sx={{ fontWeight: 800, color: "#62635E", fontSize: 18 }}
            >
              System Recommendation:
            </Typography>
            <Typography
              sx={{ fontWeight: 700, color: "#D41818", fontSize: 18 }}
            >
              DECLINE
            </Typography>
          </Box>

          <Box>
            <PieChart
              series={[
                {
                  data: [
                    { value: 10, color: "red" },
                    { value: 20, color: "orange" },
                    { value: 15, color: "green" },
                    { value: 10, color: "grey" },
                  ],
                  innerRadius: 87,
                  outerRadius: 103,
                  paddingAngle: 1,
                  cornerRadius: 5,
                  startAngle: -125,
                  endAngle: 126,
                  cx: "50%",
                  cy: "55%",
                },
              ]}
              {...pieParams}
            >
              <PieCenterLabel>87</PieCenterLabel>
              <PieLeftLabel>300</PieLeftLabel>
              <PieRightLabel>850</PieRightLabel>
            </PieChart>
          </Box>

          {/* <Box sx={{ mb: 2 }}>
            <Typography sx={{ fontSize: 14, fontWeight: 700, mb: 1 }}>
              Reason for recomendation for Offer
            </Typography>

            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 400,
                mb: 2,
                pb: 2,
                borderBottom: 1,
                borderBottomColor: "#F0F1ED",
              }}
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis
            </Typography>

            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 400,
                mb: 2,
                pb: 2,
                borderBottom: 1,
                borderBottomColor: "#F0F1ED",
              }}
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis
            </Typography>

            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 400,
                mb: 2,
                pb: 2,
                borderBottom: 1,
                borderBottomColor: "#F0F1ED",
              }}
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis
            </Typography>
          </Box> */}
        </Box>

        {/* <Box>
          <Typography sx={{ fontWeight: 800, color: "#62635E", fontSize: 18 }}>
            Recommendation for Offer by Loreta
          </Typography>

          <Box sx={{ display: "flex", justifyContent: "space-between", my: 2 }}>
            <Box>
              <Typography sx={{ fontSize: 16, fontWeight: 400 }}>
                Requested loan amount
              </Typography>
              <Typography sx={{ fontSize: 16, fontWeight: 700, py: 1 }}>
                ₦550,200.00
              </Typography>
            </Box>

            <Box>
              <Typography sx={{ fontSize: 16, fontWeight: 400 }}>
                Preset interest rate
              </Typography>
              <Typography sx={{ fontSize: 16, fontWeight: 700, py: 1 }}>
                ₦550,200.00
              </Typography>
            </Box>

            <Box>
              <Typography sx={{ fontSize: 16, fontWeight: 400 }}>
                Preset interest rate
              </Typography>
              <Typography sx={{ fontSize: 16, fontWeight: 700, py: 1 }}>
                ₦550,200.00
              </Typography>
            </Box>
          </Box>

          <Box sx={{ mb: 2 }}>
            <Typography sx={{ fontSize: 14, fontWeight: 700, mb: 1 }}>
              Reason for recomendation for Offer
            </Typography>

            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 400,
                mb: 2,
                pb: 2,
                borderBottom: 1,
                borderBottomColor: "#F0F1ED",
              }}
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis
            </Typography>

            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 400,
                mb: 2,
                pb: 2,
                borderBottom: 1,
                borderBottomColor: "#F0F1ED",
              }}
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis
            </Typography>

            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 400,
                mb: 2,
                pb: 2,
                borderBottom: 1,
                borderBottomColor: "#F0F1ED",
              }}
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis
            </Typography>
          </Box>
        </Box>

        <Box>
          <Typography sx={{ fontWeight: 800, color: "#62635E", fontSize: 18 }}>
            Recommendation for Offer by Ayorinde
          </Typography>

          <Box sx={{ display: "flex", justifyContent: "space-between", my: 2 }}>
            <Box>
              <Typography sx={{ fontSize: 16, fontWeight: 400 }}>
                Requested loan amount
              </Typography>
              <Typography sx={{ fontSize: 16, fontWeight: 700, py: 1 }}>
                ₦550,200.00
              </Typography>
            </Box>

            <Box>
              <Typography sx={{ fontSize: 16, fontWeight: 400 }}>
                Preset interest rate
              </Typography>
              <Typography sx={{ fontSize: 16, fontWeight: 700, py: 1 }}>
                ₦550,200.00
              </Typography>
            </Box>

            <Box>
              <Typography sx={{ fontSize: 16, fontWeight: 400 }}>
                Preset interest rate
              </Typography>
              <Typography sx={{ fontSize: 16, fontWeight: 700, py: 1 }}>
                ₦550,200.00
              </Typography>
            </Box>
          </Box>

          <Box sx={{ mb: 2 }}>
            <Typography sx={{ fontSize: 14, fontWeight: 700, mb: 1 }}>
              Reason for recomendation for Offer
            </Typography>

            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 400,
                mb: 2,
                pb: 2,
                borderBottom: 1,
                borderBottomColor: "#F0F1ED",
              }}
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis
            </Typography>

            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 400,
                mb: 2,
                pb: 2,
                borderBottom: 1,
                borderBottomColor: "#F0F1ED",
              }}
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis
            </Typography>

            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 400,
                mb: 2,
                pb: 2,
                borderBottom: 1,
                borderBottomColor: "#F0F1ED",
              }}
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis
            </Typography>
          </Box>
        </Box> */}
      </Box>
    </>
  );
};

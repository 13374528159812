import { client } from ".";

const size = process.env.REACT_APP_PAGESIZE;

export const denyChange = ({ userUid, reasonsForRejection }) => {
  return client.post("device/deny-request", {
    userUid,
    reasonsForRejection,
  });
};

export const getChangeCustomers = (page) => {
  return client.get(`users/device-change-requests?page=${page}&size=${size}`);
};

export const searchChangeCustomers = (page, filter) => {
  return client.get(
    `users/device-change-requests?page=${page}&size=${size}&search=${filter}`
  );
};

export const getAddCustomers = (page) => {
  return client.get(`users/pending-device-addition?page=${page}&size=${size}`);
};

export const searchAddCustomers = (page, filter) => {
  return client.get(
    `users/pending-device-addition?page=${page}&size=${size}&search=${filter}`
  );
};

export const getChangeInfo = (userUid) => {
  return client.get(`users/${userUid}/device-change-information`);
};

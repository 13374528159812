import React, { useEffect, useState } from "react";
import { InterestRateTable } from "./InterestRateTable";
import { Box, Button, Typography } from "@mui/material";
import { Add } from "@mui/icons-material";
import { AddInterest } from "./components/AddInterest";
import { useDispatch, useSelector } from "react-redux";
import { fetchInterestRates } from "./settingsSlice";

export const InterestRate = () => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const dispatch = useDispatch();
  const settings = useSelector((state) => state.settings);

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  useEffect(() => {
    dispatch(fetchInterestRates());
  }, []);

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          variant="outlined"
          onClick={handleDialogOpen}
          sx={{
            height: 30,
            textTransform: "capitalize",
            color: "#000",
            borderColor: "#000",
            mt: 2.5,
          }}
        >
          <Add sx={{ fontSize: 12 }} />
          <Typography sx={{ fontSize: 12, pl: 1, pr: 0.5 }}>
            Add Interest Rate
          </Typography>
        </Button>
      </Box>

      <InterestRateTable settings={settings} />
      <AddInterest open={dialogOpen} handleClose={handleDialogClose} />
    </>
  );
};

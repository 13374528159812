import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { styles } from "../../../../../common/css/styles";
import { toTitleCase } from "../../../../../common/constants/utility";

export const BVN = ({ data }) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "row" }}>
      {/* User Input */}
      <Box sx={{ py: 2, width: "50%" }}>
        <Typography sx={{ fontWeight: 700 }}>User Input</Typography>
        <Box sx={{ width: "90%", pt: 2, pb: 3 }}>
          {data?.profileImageUrl ? (
            <img
              src={data?.profileImageUrl}
              alt="avatar"
              width="100px"
              height="100px"
            />
          ) : (
            <img
              src={data?.profileImageUrl}
              alt="avatar"
              style={{
                minWidth: "100px",
                minHeight: "100px",
                border: "1px solid",
              }}
            />
          )}
        </Box>
        <Box
          sx={{
            border: 1,
            borderRadius: 2,
            minHeight: 200,
            maxWidth: "90%",
            px: 2,
            borderColor: "#979797",
          }}
        >
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ py: 1 }}>
              <Typography sx={styles.bvn.topLeft}>First Name</Typography>
              <Typography sx={styles.bvn.bottomLeft}>
                {toTitleCase(data?.firstName)}
              </Typography>
            </Box>
            <Box sx={{ py: 1 }}>
              <Typography sx={styles.bvn.topRight}>Last Name</Typography>
              <Typography sx={styles.bvn.bottomRight}>
                {toTitleCase(data?.lastName)}
              </Typography>
            </Box>
          </Stack>
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ py: 1 }}>
              <Typography sx={styles.bvn.topLeft}>Email Address</Typography>
              <Typography
                sx={{ ...styles.bvn.bottomLeft, textTransform: "lowercase" }}
              >
                {data?.email}
              </Typography>
            </Box>
            <Box sx={{ py: 1 }}>
              <Typography sx={styles.bvn.topRight}>Phone Number</Typography>
              <Typography sx={styles.bvn.bottomRight}>
                {data?.phoneNumber}
              </Typography>
            </Box>
          </Stack>
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ py: 1 }}>
              <Typography sx={styles.bvn.topLeft}>Address</Typography>
              <Typography sx={styles.bvn.bottomLeft}>
                {toTitleCase(data?.street)}
              </Typography>
            </Box>
            {/* <Box sx={{ py: 1 }}>
              <Typography sx={styles.bvn.topRight}>DOB</Typography>
              <Typography sx={styles.bvn.bottomRight}>
                {data?.dateOfBirth.replaceAll("-", "/")}
              </Typography>
            </Box> */}
          </Stack>
        </Box>
      </Box>
      {/* BVN Generated */}
      <Box sx={{ py: 2, width: "50%" }}>
        <Typography sx={{ fontWeight: 700 }}>BVN Generated</Typography>
        <Box sx={{ width: "90%", pt: 2, pb: 3 }}>
          {data?.profileImageUrl ? (
            <img
              src={data?.profileImageUrl}
              alt="avatar"
              width="100px"
              height="100px"
            />
          ) : (
            <img
              src={data?.profileImageUrl}
              alt="avatar"
              style={{
                minWidth: "100px",
                minHeight: "100px",
                border: "1px solid",
              }}
            />
          )}
        </Box>
        <Box
          sx={{
            border: 1,
            borderRadius: 2,
            minHeight: 200,
            maxWidth: "90%",
            px: 2,
            borderColor: "#979797",
          }}
        >
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ py: 1 }}>
              <Typography sx={styles.bvn.topLeft}>First Name</Typography>
              <Typography sx={styles.bvn.bottomLeft}>
                {data?.firstName ?? "Not Available"}
              </Typography>
            </Box>
            <Box sx={{ py: 1 }}>
              <Typography sx={styles.bvn.topRight}>Last Name</Typography>
              <Typography sx={styles.bvn.bottomRight}>
                {data?.lastName ?? "Not Available"}
              </Typography>
            </Box>
          </Stack>
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ py: 1 }}>
              <Typography sx={styles.bvn.topLeft}>Email Address</Typography>
              <Typography sx={{ ...styles.bvn.bottomLeft }}>
                {data?.email}
              </Typography>
            </Box>
            <Box sx={{ py: 1 }}>
              <Typography sx={styles.bvn.topRight}>Phone Number</Typography>
              <Typography sx={styles.bvn.bottomRight}>
                {data?.phoneNumber}
              </Typography>
            </Box>
          </Stack>
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ py: 1 }}>
              <Typography sx={styles.bvn.topLeft}>Address</Typography>
              <Typography sx={styles.bvn.bottomLeft}>{data?.street}</Typography>
            </Box>
            <Box sx={{ py: 1 }}>
              <Typography sx={styles.bvn.topRight}>DOB</Typography>
              <Typography sx={styles.bvn.bottomRight}>
                {data?.birthdate?.replaceAll("-", "/") ?? "Not Available"}
              </Typography>
            </Box>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};
